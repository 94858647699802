import React, { ReactNode, useState } from 'react';
import { Select } from 'antd';
import { SearchOutlined as SearchOutlinedIcon } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface SelectSearchProps {
    children: ReactNode,
    loadMore: ( param: { page: number; } ) => void;
    handleCreatorworkedWith: ( value: string[] ) => void;
    handleSearch: ( value: string ) => void;
    mode?: 'multiple' | 'tags',
    className?: string,
    popUpClassName?: string
}

const SelectSearch = ( {
                           children,
                           loadMore,
                           handleCreatorworkedWith,
                           handleSearch,
                           mode = 'multiple',
                           className,
                           popUpClassName
                       }: SelectSearchProps ) => {

    const [ tagPageNum, setTagPageNum ] = useState( 1 );

    const { t } = useTranslation();

    const handleTagePopupScroll = ( { target }: any ) => {
        const { clientHeight, scrollHeight, scrollTop } = target;
        if( clientHeight + scrollTop === scrollHeight && tagPageNum < 1 ) {
            const page = tagPageNum + 1;
            setTagPageNum( page );
            loadMore( { page } );
        }
    };

    return (
        <div className={ twMerge( 'custom-search-select', className ) }>
            <div className="prefix-icon-wrapper h-7 w-7 rounded-full bg-primary-500">
                <SearchOutlinedIcon className="text-white text-lg" />
            </div>
            <Select placement="bottomRight"
                    className="w-full"
                    popupClassName={ popUpClassName }
                    placeholder={ t( 'client:jobPosts.searchPlaceholder' ) }
                    allowClear
                    mode={ mode }
                    onPopupScroll={ handleTagePopupScroll }
                    onChange={ handleCreatorworkedWith }
                    onSearch={ handleSearch }
                    suffixIcon={ <div /> }>
                { children }
            </Select>
        </div>
    );
};

export default SelectSearch;
