import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { Channel } from '@/gql/graphql';
import { UserOutlined } from '@ant-design/icons';
import { twMerge } from 'tailwind-merge';

interface ChannelItemProps {
    channel: Channel;
    avatarSize?: number;
    onClick?: ( channel: Channel ) => void;
    titleClass?: string;
    infoClass?: string;
    className?: string;
}

export default function ChannelItem( {
                                         channel,
                                         avatarSize = 42,
                                         onClick,
                                         titleClass,
                                         infoClass,
                                         className
                                     }: ChannelItemProps ) {

    if( channel == null ) {
        return null;
    }

    const { t } = useTranslation();

    const handleOnClick = ( channel: Channel ) => onClick?.( channel );

    return (
        <div className={ `flex items-center ${ className }` }
             onClick={ () => handleOnClick( channel ) }>
            <Avatar size={ avatarSize }
                    icon={ <UserOutlined /> }
                    className="flex-none"
                    src={ channel?.thumbnailUrl } />

            <div className="flex flex-col ml-2">
                <h4 className={ twMerge( 'hover:text-primary-500 group-hover:text-primary-500 font-medium text-sm leading-5', titleClass ) }>
                    { channel?.name }
                </h4>
                <div className={ twMerge( 'font-normal text-grey text-xs leading-4', infoClass ) }>
                    <span>{ abbreviationNumberFormatter( channel?.subscriberCount ) }</span>
                    <span className="ml-1">
                        { t( 'common:channel-summary.follower', { count: channel?.subscriberCount } ) }
                    </span>
                    <span> - </span>
                    <span>{ t( 'common:channel-summary.videoWithCount', { count: ( channel?.videoCount || 0 ) } ) }</span>
                </div>
            </div>
        </div>
    );
}