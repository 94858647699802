import { PortfolioElement } from '@/gql/graphql';
import FreelancerProfileLoaderFeaturedPart from '@/components/freelance-profile/components/loader/FreelancerProfileLoaderFeaturedPart';
import FeaturedPortfolioElement from '@/components/freelance-profile/components/FeaturedPortfolioElement';
import { useTranslation } from 'react-i18next';

interface FreelancerProfileFeaturedSectionProps {
    featured: PortfolioElement;
    isLoading: boolean;
    editable?: boolean;
    className?: string;
}

export default function FreelancerProfileFeaturedSection( {
                                                              featured,
                                                              isLoading,
                                                              editable,
                                                              className
                                                          }: FreelancerProfileFeaturedSectionProps ) {

    const { t } = useTranslation();

    return (
        <section className={ className }>
            <h2>{ t( 'common:freelancerProfile.card.featuredVideo' ) }</h2>
            <div className="mt-4">
                {
                    isLoading ?
                    <FreelancerProfileLoaderFeaturedPart /> :
                    <FeaturedPortfolioElement editable={ editable }
                                              portfolioElement={ featured } />
                }
            </div>
        </section>
    );
}