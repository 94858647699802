import { gql } from '@apollo/client';

export const UpdateAvailabilityGQL = gql`
    mutation UpdateAvailabilityGQL($isAvailable: Boolean!) {
        updateAvailability(isAvailable: $isAvailable) {
            id
            profilePicture
        }
    }`;


