import ErrorBoundary from '@/pages/errors/ErrorBoundary';
import TransverseRoot from '@/pages/transverse/views/TransverseRoot';
import { Navigate } from 'react-router-dom';
import ClientFreelancerProfile from '../client/views/freelancer/ClientFreelancerProfile';

export const TransverseRouting = {
    path: '',
    element: <TransverseRoot />,
    errorElement: <ErrorBoundary />,
    children: [
        {
            path: '',
            element: <Navigate to="/auth/sign-in" replace />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'p/:id',
            element: <ClientFreelancerProfile />,
            errorElement: <ErrorBoundary />
        }
    ]
};