import { createBrowserRouter } from 'react-router-dom';
import { ClientRouting } from '@/pages/client/ClientRouting';
import { FreelancerRouting } from '@/pages/freelancer/FreelancerRouting';
import { AuthRouting } from '@/pages/auth/AuthRouting';
import { TransverseRouting } from '@/pages/transverse/TranserveRouting';
import { ErrorRouting } from '@/pages/errors/ErrorRouting';
import './styles.scss';
import './i18n';
import './plugins/sentry';
import * as Sentry from '@sentry/react';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter( createBrowserRouter );

const router = sentryCreateBrowserRouter(
    [
        TransverseRouting,
        AuthRouting,
        ClientRouting,
        FreelancerRouting,
        ...ErrorRouting
    ], {
        basename: '/'
    }
);

export { router };
