import { useTranslation } from 'react-i18next';
import YoutubeChannelListManagement from '@/components/YoutubeChannelListManagement';
import { PageName, trackPageView } from '@/utils/analytics';
import { useEffect } from 'react';

export default function ClientAccountEditYTChannels() {

    const { t } = useTranslation();

    useEffect( () => {
        trackPageView( PageName.ClientEditChannelYT );
    }, [ ] );

    return (
        <div className="h-full w-full flex flex-col">

            <h1>{ t( 'client:account.youtube-channels.title' ) }</h1>

            <p className="font-normal text-sm text-grey mt-2">
                { t( 'client:account.youtube-channels.description' ) }
            </p>

            <YoutubeChannelListManagement buttonPlacement="right" />
        </div>
    );
}