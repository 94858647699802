import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Tag } from 'antd';

import { SessionRepository } from '@/core/auth/sessionRepository';
import { RoundedCheckFilledIcon, FileOutlinedIcon, MyProfileIconFilledIcon, FlashFilledIcon } from '@/assets/icons/CustomIcons';
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { identifySegmentUser, userTypeName } from '@/utils/analytics';
import { ClientProfileGQL } from '@/pages/client/views/account/client-account-edit-profile/queries/clientProfileGQL';
import { Client } from '@/gql/graphql';

export default function SignUpClientStepWelcome() {

    const { t } = useTranslation();
    const session = new SessionRepository();

    const nav = useNavigate();

    const nextButton = () => nav( '/client/discover' );

    const clientsQuery = useLazyQuery( ClientProfileGQL );
    useEffect( () => {
        if( clientsQuery[ 1 ].data ) {
            const result = clientsQuery[ 1 ].data.client;
            identifySegmentUser(result as Client, userTypeName.Creator)
        }
    }, [ clientsQuery[ 1 ].data ] );

    useEffect( () => {
        clientsQuery[ 0 ]() 
    }, [ ] );

    return (
        <div className="main-container w-full flex-1 flex justify-center pt-12 lg:pt-32 px-5">
            <div className="bg-white h-fit w-full max-w-[600px]">
                <div className="flex flex-col gap-6">
                    <FlashFilledIcon className="text-[46px] text-primary-500" />
                    <div className='flex flex-col gap-2'>
                        <div className="text-xl lg:text-2xl font-sharp font-medium">
                            { t( 'auth:sign-up.client.welcome-page.title') }
                        </div>
                        <div className="text-base font-normal">
                            { t( 'auth:sign-up.client.welcome-page.description' ) }
                        </div>
                    </div>

                    <div className='flex flex-col gap-4'>
                        <Link to="/client/create-job-post">
                            <div className='border border-[#E6E9F0] rounded-xl py-6 px-6 text-black'>
                                <div className='flex flex-col-reverse lg:flex-row gap-1.5 lg:gap-8'>
                                    <div className='flex flex-col gap-1.5'>
                                        <span className='text-lg font-semibold'>{ t( 'auth:sign-up.client.welcome-page.postFeatureTitle' ) }</span>
                                        <span className='text-[#71767B] text-sm'>{ t( 'auth:sign-up.client.welcome-page.postFeatureDescription' ) }</span>
                                    </div>
                                    <FileOutlinedIcon className='text-4xl' />
                                </div>
                            </div>
                        </Link>

                        <div className='border border-[#E6E9F0] rounded-xl py-6 px-6 cursor-not-allowed'>
                            <div className='flex flex-col-reverse lg:flex-row gap-1.5 lg:gap-8'>
                                <div className='flex flex-col gap-1.5'>
                                    <div className="flex gap-3">
                                        <span className='text-[#71767B]/40 text-lg font-semibold'>{ t( 'auth:sign-up.client.welcome-page.exploreTalentFeatureTitle' ) }</span>
                                        <Tag bordered={false} className='py-0 px-2 text-xs text-black font-normal bg-[#F8F8FA]'>{ t( 'auth:sign-up.client.welcome-page.comingSoon' ) }</Tag>
                                    </div>
                                    <span className='text-[#71767B]/40 text-sm'>{ t( 'auth:sign-up.client.welcome-page.exploreTalentFeatureDescription' ) }</span>
                                </div>
                                <MyProfileIconFilledIcon className='text-[#71767B]/40 text-4xl' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}