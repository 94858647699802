import { NavigateFunction } from 'react-router-dom';

export default function replaceLastSegmentOfUrl (navigate: NavigateFunction, newId: string) {
    // Get the current URL path
    const currentPath = window.location.pathname;

    // Split the path into segments
    const pathSegments = currentPath.split('/');

    // Replace the last segment with the new ID
    pathSegments[pathSegments.length - 1] = newId;

    // Join the segments back together to form the new URL path
    const newPath = pathSegments.join('/');

    // Navigate to the new URL path
    navigate(newPath, { replace: true });
};