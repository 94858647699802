import { gql } from '@apollo/client';

const clientApplicationsGQL = gql`
    query clientApplications($args: FindAllApplicationInput!, $page: Int, $limit: Int) {
        applications( args: $args, limit: $limit, page: $page ) {
            data {
                id
                superLikeMessage
                message
                appliedAt
                isFavoriteAt
                isUnlock
                isUnlockAt
                jobPostId
                freelancer {
                    id
                    creatorsIWorkedWith {
                        thumbnailUrl
                        name
                    }
                    firstName
                    lastName
                    profilePicture
                    portfolioElementStats {
                        countElements
                        countViews
                    }
                    mainPortfolioElements {
                        url
                        thumbnailUrl
                    }
                    experience
                    profilePicture
                    mainJob
                }
            }
            total,
            page
        }
    }`;

export default clientApplicationsGQL;