import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import { useTranslation } from 'react-i18next';
import ListSkeleton from '@/components/ListSkeleton';
import ClientJobPostCard from './ClientJobPostCard';
import ClientNoJobPostCard from './ClientNoJobPostCard';
import { NavLink } from 'react-router-dom';
import PairChannelModal from '@/pages/client/views/job-post/PairChannelModal';
import { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { App } from 'antd';
import { JobPost } from '@/gql/graphql';
import { attachChannelToJobPostGQL } from '@/gql/global-queries';

interface FreelancerProjectListDesktopProps {
    jobPosts: any[];
    jobPostsRefetch: any;
    isLoading: boolean;
    isGettingStartedCompleted: boolean;
}

export default function clientJobPostList( {
                                                jobPosts,
                                                jobPostsRefetch,
                                                isLoading,
                                                isGettingStartedCompleted
                                            }: FreelancerProjectListDesktopProps ) {
    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: false
    } );

    const { t } = useTranslation();

    const pairChannelModalRef = useRef( null );

    const attachChannelToJobPostMutation = useMutation( attachChannelToJobPostGQL );

    const { message } = App.useApp();

    const [ selectedJobPost, setSelectedJobPost ] = useState<JobPost>( null );

    const handlePairChannel = ( jobPost: JobPost ) => {
        setSelectedJobPost( jobPost );
        pairChannelModalRef.current.open();
    };

    const onPairChannel = async( channelId ) => {
        try {
            const result = await attachChannelToJobPostMutation[ 0 ]( {
                variables: {
                    channelId,
                    jobPostId: selectedJobPost.id
                }
            } );

            if( result.data ) {
                jobPostsRefetch();
                pairChannelModalRef.current.close();
                message.success( t( 'client:jobPost.pairChannel.success' ) );
            }
            else {
                message.error( t( 'translation:error.default' ) );
            }
        } catch( e ) {
            message.error( t( 'translation:error.default' ) );
        }
    };

    if( isLoading ) {
        return <div className="flex flex-col gap-y-4">
            <ListSkeleton isLoading={ true }
                          height={ 130 }
                          count={ 4 } />
        </div>;
    }

    if( !isLoading && jobPosts?.length > 0 ) {
        return (
            <>
                <div className="flex flex-col gap-4">
                    {
                        jobPosts?.map( ( jobPost, index ) => (
                            <NavLink className="text-black"
                                     key={ index }
                                     to={ isGettingStartedCompleted
                                          ? `/client/job-posts/${ jobPost.id }/interested`
                                          : `/client/job-posts/${ jobPost.id }/getting-started` }> { /* TODO refacto pour gérer la redirection côté job post et non comme ça*/ }
                                <ClientJobPostCard jobPost={ jobPost }
                                                    onAuthenticate={ handlePairChannel } />
                            </NavLink>
                        ) )
                    }
                </div>
                <PairChannelModal ref={ pairChannelModalRef }
                                  onChannelIdSumbitted={ ( channelId: string ) => onPairChannel( channelId ) } />
            </>
        );
    }

    return (
        <div className="flex justify-center items-center">
            <ClientNoJobPostCard />
        </div>
    );
}