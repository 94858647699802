import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import { LayoutRepository } from '@/pages/freelancer/layout/layoutRepository';
import { useParams } from 'react-router-dom';

interface LayoutVisibilityHookProps {
    headerVisible?: boolean | 'isTabletOrMobile' | '!isTabletOrMobile';
    bottomNavBarVisible?: boolean | 'isTabletOrMobile' | '!isTabletOrMobile';
}

export default function LayoutVisibilityHook( {
                                                  headerVisible,
                                                  bottomNavBarVisible
                                              }: LayoutVisibilityHookProps ) {
    const isTabletOrMobile: boolean = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const freelancerLayoutRepository: LayoutRepository = new LayoutRepository();
    const params = useParams();

    const [ isHeaderVisible, setIsHeaderVisible ] = useState( true );
    const [ isBottomNavBarVisible, setIsBottomNavBarVisible ] = useState( true );

    useEffect( () => {
        const observable = freelancerLayoutRepository.layoutVisibility$.subscribe( ( visibility ) => {
            setIsHeaderVisible( visibility.headerVisible );
            setIsBottomNavBarVisible( visibility.bottomNavBarVisible );
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [] );

    useEffect( () => {
        const isHeaderVisible = shouldDisplayComponent( headerVisible, isTabletOrMobile );
        const isBottomNavBarVisible = shouldDisplayComponent( bottomNavBarVisible, isTabletOrMobile );

        freelancerLayoutRepository.setVisibility( {
            headerVisible: isHeaderVisible,
            bottomNavBarVisible: isBottomNavBarVisible
        } );

    }, [ isTabletOrMobile, headerVisible, bottomNavBarVisible, params ] );

    const refreshLayoutVisibility = ( args: LayoutVisibilityHookProps ): void => {
        setupLayoutVisibility( args.headerVisible, args.bottomNavBarVisible );
    };

    const shouldDisplayComponent = ( visible: boolean | 'isTabletOrMobile' | '!isTabletOrMobile',
                                     isTabletOrMobile: boolean ) => {
        if( typeof visible === 'boolean' ) {
            return visible;
        }
        if( visible === 'isTabletOrMobile' ) {
            return isTabletOrMobile;
        }
        if( visible === '!isTabletOrMobile' ) {
            return !isTabletOrMobile;
        }
        return true;
    };

    const setupLayoutVisibility = ( headerVisible, bottomNavBarVisible ) => {
        const isHeaderVisible: boolean = shouldDisplayComponent( headerVisible, isTabletOrMobile );
        const isBottomNavBarVisible: boolean = shouldDisplayComponent( bottomNavBarVisible, isTabletOrMobile );

        freelancerLayoutRepository.setVisibility( {
            headerVisible: isHeaderVisible,
            bottomNavBarVisible: isBottomNavBarVisible
        } );
    };

    return { isTabletOrMobile, isHeaderVisible, isBottomNavBarVisible, refreshLayoutVisibility };
}