import { useLazyQuery } from '@apollo/client';
import { SegmentPortfolioElementEnum } from '@/gql/graphql';
import portfolioElementsGQL from '@/gql/global-queries/portfolioElementsGQL';

export default function portfolioElementsHook() {

    const [ getFeatured, { data: portfolioElementFeatured, loading: loadingFeatured, error: errorFeatured, refetch } ] = useLazyQuery( portfolioElementsGQL );
    const [ getSelected, { data: portfolioElementSelected, loading: loadingSelected, error: errorSelected, refetch: refetchSelected } ] = useLazyQuery( portfolioElementsGQL );

    const loadFeatured = ( freelancerId: string ) => {
        getFeatured( {
            variables: {
                freelancerId,
                args: {
                    segment : SegmentPortfolioElementEnum.FEATURED
                }
            }
        } );
    };

    const loadSelected = (freelancerId: string) => {
        getSelected( {
            variables: {
                freelancerId,
                args: {
                    segment: SegmentPortfolioElementEnum.SELECTED
                }
            }
        } );
    }

    return {
        featured: portfolioElementFeatured?.portfolioElements?.data?.length > 0 ? portfolioElementFeatured?.portfolioElements.data[0] : null,
        loadFeatured,
        loadingFeatured,
        selected: portfolioElementSelected?.portfolioElements?.data,
        loadSelected,
        loadingSelected
    }
}