import { useMutation } from '@apollo/client';
import { UpdateAvailabilityGQL } from '@/pages/freelancer/views/account/freelancer-account-edit-availability/updateAvailabilityGQL';
import { message, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { identifySegmentUser, userTypeName } from '@/utils/analytics';

interface FreelancerAccountEditAvailabilityProps {
    defaultValue: boolean;
}

export default function FreelancerAccountEditAvailability( { defaultValue }: FreelancerAccountEditAvailabilityProps ) {

    const { t } = useTranslation();
    const [ messageApi, contextHolder ] = message.useMessage();
    const [ _availability, setAvailability ] = useState( false );

    useEffect( () => {
        setAvailability( defaultValue );
    }, [] );

    const updateAvailabilityMutation = useMutation( UpdateAvailabilityGQL );

    const onSubmit = async(): Promise<void> => {
        try {
            await updateAvailabilityMutation[ 0 ]( {
                variables: {
                    isAvailable: !_availability
                }
            } );

            await identifySegmentUser({ isAvailable: !_availability }, userTypeName.Freelancer);
            setAvailability( !_availability );
            messageApi.success( t( 'freelancer:account.availability.updateSuccess' ) );
        } catch( e ) {
            Sentry.captureException( 'An error occur when freelancer tried to update is availability ', e );
            messageApi.error( t( 'translation:error.default' ) );
        }
    };

    return (
        <div className="w-full">
            { contextHolder }
            <div className="text-base font-medium ">
                { t( 'freelancer:account.availability.title' ) }
            </div>
            <div className="flex mt-1 justify-between items-start gap-x-4">
                <div className="text-grey text-xs">
                    { t( 'freelancer:account.availability.description' ) }
                </div>
                <div className="flex-none flex justify-end items-center w-[50px]">
                    <Switch loading={ updateAvailabilityMutation[ 1 ].loading }
                            className="primary"
                            checked={ _availability }
                            onClick={ onSubmit } />
                </div>
            </div>
        </div>
    );
}