import { useMediaQuery } from 'react-responsive';
import FreelancerHeaderTouch from '@/pages/freelancer/layout/header/FreelancerHeaderTouch';
import { FreelancerHeaderDesktop } from '@/pages/freelancer/layout/header/FreelancerHeaderDesktop';

export function FreelancerHeader() {
    const isTabletOrMobile: boolean = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    return (
        <>
            {
                isTabletOrMobile ?
                <FreelancerHeaderTouch /> :
                <FreelancerHeaderDesktop />
            }
        </>
    );
}