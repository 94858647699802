import { CheckFilledIcon } from '@/assets/icons/CustomIcons';

interface StepsProps {
    steps: string[];
    currentStep: number;
    onStepClick: ( value: number ) => void;
}

export default function Stepper( { steps, currentStep, onStepClick }: StepsProps ) {

    const onStepChange = ( index ) => {       
        if( index < currentStep ) onStepClick( index );
    }

    return (
        <div className="relative pb-4">
            <div className="w-full mt-2 flex justify-between">
                {
                    steps.map( ( label, index ) => (
                        <div key={ label } className='flex w-full items-center after:content-[""] after:w-full after:h-1 after:border-t after:border-gray-200 after:border-1 after:inline-block after:mx-2.5 last:after:content-none last:w-auto'>
                            <div onClick={ () => onStepChange( index ) }  className={`flex flex-col items-center ${index < currentStep && 'cursor-pointer'}`}>
                                <div className={`relative flex justify-center items-center h-6 w-6 rounded-full ${currentStep >= index && 'bg-purple-100' }`}>
                                    <div className={`flex justify-center items-center h-4 w-4 rounded-full ${currentStep >= index ? 'bg-violet-500' : 'bg-gray-200'}`}>
                                        {
                                            currentStep > index ?
                                                <CheckFilledIcon className='text-violet-500' />
                                                :
                                                <div className='h-1.5 w-1.5 bg-white rounded-full'></div>
                                        }
                                    </div>
                                </div>
                                <span
                                    key={ index }
                                    className={`hidden lg:block text-sm whitespace-nowrap absolute -bottom-2 ${currentStep == index ? 'font-semibold text-black' : 'text-grey'}`}>
                                    { label }
                                </span>
                            </div>
                        </div>
                    ) )
                }
            </div>
        </div>
    );
}