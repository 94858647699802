import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { App } from 'antd';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { sendVerificationCodeGQL, verifyPhoneNumberGQL } from '@/gql/global-queries';

interface PhoneNumberVerificationHookProps {
    phoneNumber: string;
    lastCodeSentAt: Date;
}

export const phoneNumberVerificationHook = ( { lastCodeSentAt }: PhoneNumberVerificationHookProps ) => {
    const { t } = useTranslation();
    const { message } = App.useApp();

    const [ code, setCode ] = useState<string>( null );
    const [ timeRemaining, setTimeRemaining ] = useState( 0 );
    const [ _lastCodeSentAt, _setLastCodeSentAt ] = useState<Date>( lastCodeSentAt );

    const countdownInterval = useRef( null );

    const sendVerificationCodeMutation = useMutation( sendVerificationCodeGQL );
    const verifyPhoneNumberQuery = useLazyQuery( verifyPhoneNumberGQL );

    const startCountdown = useCallback( () => {
        if( !_lastCodeSentAt ) {
            return;
        }

        if( countdownInterval.current ) {
            clearInterval( countdownInterval.current );
        }

        const now: Date = new Date();
        const diff: number = now.getTime() - _lastCodeSentAt.getTime();
        const diffInSeconds: number = Math.floor( diff / 1000 );

        if( diffInSeconds < 60 ) {
            setTimeRemaining( 60 - diffInSeconds );

            countdownInterval.current = setInterval( () => {
                setTimeRemaining( ( prevTimeRemaining ) => {
                    if( prevTimeRemaining === 1 ) {
                        clearInterval( countdownInterval.current );
                        return 0;
                    }
                    else {
                        return prevTimeRemaining - 1;
                    }
                } );
            }, 1000 );
        }
    }, [ _lastCodeSentAt ] );

    useEffect( () => {
        startCountdown();

        return () => {
            if( countdownInterval.current ) {
                clearInterval( countdownInterval.current );
            }
        };
    }, [ startCountdown ] );

    const sendCode = async( phoneNumber: string, displayResendMessage: boolean = false ): Promise<void> => {
        if( _lastCodeSentAt != null ) {
            const now: DateTime = DateTime.now();
            const lastCodeSentAtFormatted: DateTime = DateTime.fromJSDate( _lastCodeSentAt );
            const diffInMinutes: number = now.diff( lastCodeSentAtFormatted, 'minutes' ).minutes;
            if( diffInMinutes < 1 ) {
                return;
            }
        }

        try {
            if( import.meta.env.VITE_SMS_ENABLED ) {
                await sendVerificationCodeMutation[ 0 ]( {
                    variables: {
                        phoneNumber
                    }
                } );
            }
            else {
                message.info( {
                    content: 'The verification code has been disabled in development mode. You can skip this step by clicking on the "Next" button.',
                    duration: 5
                } );
            }

            _setLastCodeSentAt( new Date() );

            if( displayResendMessage ) {
                message.success( {
                    content: t( 'common:phoneNumber.verification-code-modal.codeSent' ),
                    duration: 5
                } );
            }
        } catch( e ) {
            message.error( t( 'translation:error.default' ) );
        }
    };

    const verifyCode = async( phoneNumber: string, codeToVerify: string = code ): Promise<boolean> => {
        if( !import.meta.env.VITE_SMS_ENABLED ) {
            return true;
        }

        if( !code && !codeToVerify ) {
            message.error( t( 'common:phoneNumber.verification-code-modal.codeRequired' ) );
            return false;
        }

        try {
            const response = await verifyPhoneNumberQuery[ 0 ]( {
                variables: {
                    args: {
                        number: phoneNumber,
                        code: +code | +codeToVerify
                    }
                }
            } );

            if( response.data.verifyPhoneNumber ) {
                return true;
            }
            else {
                message.error( t( 'common:phoneNumber.verification-code-modal.wrongCode' ) );
                return false;
            }
        } catch( e ) {
            message.error( t( 'translation:error.default' ) );
            return false;
        }
    };

    return {
        code,
        setCode,
        timeRemaining,
        sendCode,
        verifyCode,
        _lastCodeSentAt,
        isSendingNewCode: sendVerificationCodeMutation[ 1 ].loading,
        isVerifying: verifyPhoneNumberQuery[ 1 ].loading
    };
};