import { gql } from '@apollo/client';

const updatePortfolioElementsOrderGQL = gql`
    mutation UpdatePortfolioElementsOrderGQL($args: [UpdateOrderPortfolioElementInput!]!) {
        updatePortfolioElementsOrder(args: $args) {
            id
        }
    }
`;

export default updatePortfolioElementsOrderGQL;