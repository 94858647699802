import { useTranslation } from 'react-i18next';
import { UserTypeEnum } from '@/gql/graphql';
import { NavLink, useNavigate } from 'react-router-dom';
import MyAccountButton from '@/components/MyAccountButton';
import Header from '@/components/layout/Header';
import { PlusCircleFilled } from '@ant-design/icons';
import { ProjectFolderOutlinedIcon } from '@/assets/icons/CustomIcons';

import { useQuery } from '@apollo/client';
import { useRef } from 'react';
import UpgradePlanModal from '@/pages/client/views/account/client-account-plans-billing/UpgradePlanModal';
import isAllowedToCreateJobPostGQL from '@/gql/global-queries/isAllowedToCreateJobPostGQL';

interface ClientHeaderDesktopProps {
    user: any;
    customMyAccountSection?: JSX.Element[];
}

export function ClientHeaderDesktop( {
                                         user,
                                         customMyAccountSection
                                     }: ClientHeaderDesktopProps ) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navs = [
        {
            to: '/client/job-posts',
            text: t( 'client:header.desktop.jobPosts' ),
            icon: <ProjectFolderOutlinedIcon className="text-[24px]" />
        }
    ];

    const upgradePlanModalRef = useRef( null );
    //const { data: isAllowedToCreateJobPostData } = useQuery( isAllowedToCreateJobPostGQL );

    const onPostNewJobClicked = () => {
       /* if( isAllowedToCreateJobPostData?.isAllowedToCreateJobPost ) {
            navigate( '/client/job-post/create' );
        }
        else {
            upgradePlanModalRef.current.open();
        }*/

        navigate( '/client/create-job-post' );
    };

    const leftJSX = (
        <>
            {
                navs.map( ( nav, index ) => {
                    return (
                        <NavLink key={ index }
                                 to={ nav.to }
                                 className={ ( { isActive } ) => `
                                            block px-4 py-2 rounded-4xl font-semibold hover:text-primary-400 text-base
                                            ${ isActive
                                               ? 'text-primary-500 bg-primary-100'
                                               : 'text-grey' }
                                            ` }>
                            <span className="flex items-center gap-x-2 text-inherit">
                                <div className="text-inherit">
                                    { nav.icon }
                                </div>
                                { nav.text }
                            </span>
                        </NavLink>
                    );
                } )
            }
        </>
    );

    const rightJSX = (
        <div className="flex items-center justify-center gap-x-4">
            <div onClick={ () => onPostNewJobClicked() }
                 className="flex gap-2 text-primary-500 cursor-pointer">
                <PlusCircleFilled className="text-lg" />
                <span className="font-semibold text-base">
                    { t( 'client:postOfferButton' ) }
                </span>
            </div>

            <MyAccountButton customSection={ customMyAccountSection }
                             data={ {
                                 type: UserTypeEnum.CLIENT,
                                 userName: user?.userName,
                                 profilePicture: user?.profilePicture,
                             } } />
        </div>
    );


    return (
        <>
            <Header logoTo="/client"
                    left={ leftJSX }
                    right={ rightJSX } />
            <UpgradePlanModal ref={ upgradePlanModalRef } />
        </>
    );
}