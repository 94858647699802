import { useForm } from 'react-hook-form';
import { Form } from 'react-router-dom';
import { App, Button, Divider, Skeleton } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { Freelancer } from '@/gql/graphql';
import { SessionRepository } from '@/core/auth/sessionRepository';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import InputUrlComponent from '@/components/custom-element-form/InputUrlComponent';
import FreelancerAccountEditAvailability from '@/pages/freelancer/views/account/freelancer-account-edit-availability/FreelancerAccountEditAvailability';
import { validateCharacters, validateLength, validateReserved, validateUnique } from '@/utils/customUrlValidation';
import { customUrlHook } from '@/hooks/customUrlHook';
import truncateHttpFormatter from '@/utils/truncateHttpFormatter';
import PhoneNumberInputs from '@/components/PhoneNumberInputs';
import extractDetailsFromPhoneNumber from '@/utils/extractDetailsFromPhoneNumber';
import phoneNumberFromForm from '@/utils/phoneNumberFromForm';
import { phoneNumberVerificationHook } from '@/hooks/phoneNumberVerificationHook';
import PhoneNumberCodeVerificationModal from '@/components/PhoneNumberCodeVerificationModal';
import { identifySegmentUser, PageName, trackPageView, userTypeName } from '@/utils/analytics';
import { freelancerProfileGQL, updateFreelancerProfileGQL } from '@/gql/global-queries';
import FreelancerAccountEditProfilePicture from '@/pages/freelancer/views/account/freelancer-account-edit-profile/components/freelancerAccountEditProfilePicture';

export default function FreelancerProfileEditProfile() {

    const maxLengthBio: number = 1000;

    const { t } = useTranslation();
    const {
              trigger,
              control,
              getValues,
              unregister,
              register,
              setValue,
              watch,
              reset,
              formState: { errors, dirtyFields },
              handleSubmit
          } = useForm();

    const profilePictureRef = useRef( null );

    const sessionRepository: SessionRepository = new SessionRepository();
    const { message } = App.useApp();

    const [ isLoading, setIsLoading ] = useState<boolean>( true );
    const [ phoneNumberFormatted, setPhoneNumberFormatted ] = useState<string>( null );
    const [ hasCustomUrl, setHasCustomUrl ] = useState( false );

    const { checkIfCustomUrlExists, createCustomUrl } = customUrlHook();
    const { sendCode, isVerifying } = phoneNumberVerificationHook( {
        lastCodeSentAt: null,
        phoneNumber: phoneNumberFormatted
    } );

    const freelancerProfileQuery = useQuery( freelancerProfileGQL );
    const updateFreelancerProfileMutation = useMutation( updateFreelancerProfileGQL );

    const phoneVerificationRef = useRef( null );

    const subscription = watch( ( value ) => {
        const countryCode = value.phoneNumberCodeCountrySelect;
        const phoneNumber = value.phoneNumberInput;
        const fullPhoneNumber: string = phoneNumberFromForm( countryCode, phoneNumber );
        setPhoneNumberFormatted( fullPhoneNumber );
    } );

    useEffect( () => {
        trackPageView( PageName.FreelancerEditAccountInformation );
        return () => subscription?.unsubscribe();
    }, [] );

    useEffect( () => {
        if( freelancerProfileQuery.data ) {
            const freelancer: Freelancer = freelancerProfileQuery.data.meFreelancer as Freelancer;
            sessionRepository.updateUser( freelancer );

            setFormValues( freelancer );
            setIsLoading( false );
        }
    }, [ freelancerProfileQuery.data ] );

    const setFormValues = ( freelancer: Freelancer ) => {

        setValue( 'firstName', freelancer.firstName );
        setValue( 'lastName', freelancer.lastName );
        setValue( 'email', freelancer.email );
        setValue( 'bio', freelancer.bio );
        setValue( 'customUrl', freelancer.customUrl );

        if( freelancer.phone != null ) {
            const phoneDetails = extractDetailsFromPhoneNumber( freelancer.phone );
            setValue( 'phoneNumberCodeCountrySelect', phoneDetails?.countryCode );
            setValue( 'phoneNumberInput', phoneDetails?.number );
        }

        setHasCustomUrl( !!freelancer.customUrl );
    };

    const hasPhoneNumberChanged = ( form ) => {
        const freelancer: Freelancer = freelancerProfileQuery.data.meFreelancer as Freelancer;

        if( freelancer.phone == null ) {
            return true;
        }

        const phoneDetails = extractDetailsFromPhoneNumber( freelancer.phone );

        return form.phoneNumberInput != phoneDetails?.number || form.phoneNumberCodeCountrySelect != phoneDetails?.countryCode;
    };

    const onSubmit = async( data, codeVerified: boolean = false ): Promise<void> => {

        await profilePictureRef.current.uploadProfilePicture();

        if( !( await trigger() ) ) {
            return;
        }

        if( hasPhoneNumberChanged( data ) && !codeVerified ) {
            await sendCode( phoneNumberFormatted );
            phoneVerificationRef.current.open();
            return;
        }

        const args = {
            firstName: data.firstName,
            lastName: data.lastName,
            bio: data.bio,
            phone: phoneNumberFormatted,
            email: data.email
        };

        try {
            if( !hasCustomUrl && data.customUrl ) {
                await createCustomUrl( data.customUrl );
            }

            const response = await updateFreelancerProfileMutation[ 0 ]( {
                variables: {
                    args
                }
            } );

            if( response.data?.freelancerUpdate ) {
                const refetchData = await freelancerProfileQuery.refetch();
                await identifySegmentUser( refetchData.data.meFreelancer, userTypeName.Freelancer );
                unregisterCustomUrl();
                sessionRepository.updateUser( response.data.freelancerUpdate );
                message.success( t( 'common:form.successfullySubmitted' ) );
                reset( {}, { keepValues: true } );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to edit freelancer profile', e );
            message.error( t( 'common:form.submitError' ) );
        }
    };

    const handleCodeVerified = async( isVerified ) => {
        if( isVerified ) {
            onSubmit( getValues(), true );
        }
    };

    const unregisterCustomUrl = () => {
        setHasCustomUrl( true );
        const customUrl = getValues( 'customUrl' );
        unregister( 'customUrl' );
        register( 'customUrl' );
        setValue( 'customUrl', customUrl, { shouldValidate: false } );
    };

    const loadingJSX = (
        <Skeleton active={ true } />
    );

    const contentJSX = (
        <Form className="flex flex-col gap-y-4"
              onSubmit={ handleSubmit( ( data ) => onSubmit( data, false ) ) }>

            <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/2">
                    <FreelancerAccountEditProfilePicture ref={ profilePictureRef }/>
                </div>

                <Divider type="vertical"
                         className="h-[128px] mx-8 hidden md:block" />

                <div className="w-full md:w-1/2 gap-y-2 mt-6 md:mt-0">
                    <label className="text-base font-medium">
                        { t( 'freelancer:account.edit-profile.myCustomUrl' ) }
                    </label>
                    <InputUrlComponent control={ control }
                                       className="mt-4"
                                       domain={ truncateHttpFormatter( import.meta.env.VITE_ENDPOINT ) + '/p/' }
                                       name="customUrl"
                                       placeholder={ t( 'freelancer:account.edit-profile.myCustomUrlPlaceholder' ) }
                                       rules={ !hasCustomUrl ? {
                                           required: t( 'common:error.form.required' ),
                                           validate: {
                                               length: ( value ) => validateLength( value, t( 'common:error.form.lengthCustomUrl' ) ),
                                               characters: ( value ) => validateCharacters( value, t( 'common:error.form.invalidCustomUrl' ) ),
                                               reserved: ( value ) => validateReserved( value, t( 'common:error.form.reservedCustomUrl' ) ),
                                               unique: ( value ) => validateUnique( value, checkIfCustomUrlExists, t( 'common:error.form.existingCustomUrl' ) )
                                           }
                                       } : {} }
                                       errors={ errors }
                                       disabled={ hasCustomUrl } />
                </div>
            </div>


            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <FreelancerAccountEditAvailability defaultValue={ freelancerProfileQuery?.data?.meFreelancer.isAvailable } />

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <div className="text-base font-medium ">
                { t( 'freelancer:account.personalInformationTitle' ) }
            </div>

            <div className="flex flex-wrap lg:flex-nowrap gap-2 gap-y-6">
                <InputComponent control={ control }
                                name="firstName"
                                label={ t( 'freelancer:account.edit-profile.firstName' ) }
                                placeholder={ t( 'freelancer:account.edit-profile.firstNamePlaceholder' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors } />

                <InputComponent control={ control }
                                name="lastName"
                                label={ t( 'freelancer:account.edit-profile.lastName' ) }
                                placeholder={ t( 'freelancer:account.edit-profile.lastNamePlaceholder' ) }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } }
                                errors={ errors } />
            </div>

            <InputComponent control={ control }
                            name="email"
                            label={ t( 'client:account.edit-profile.inputs.email.label' ) }
                            placeholder={ t( 'client:account.edit-profile.inputs.email.placeholder' ) }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                pattern: {
                                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                    message: t( 'common:error.form.invalidEmail' )
                                }
                            } }
                            errors={ errors } />

            <PhoneNumberInputs control={ control }
                               inputName="phoneNumberInput"
                               selectName="phoneNumberCodeCountrySelect"
                               errors={ errors }
                               watch={ watch } />

            <Button type="primary"
                    htmlType="submit"
                    loading={ updateFreelancerProfileMutation[ 1 ].loading || isVerifying }
                    className="w-full lg:w-[160px] mt-8">
                { t( 'freelancer:account.edit-profile.submit' ) }
            </Button>
        </Form>
    );

    return (
        <>
            <div className="w-full mt-4">
                <h1 className="lg:hidden text-2xl font-bold">
                    { t( 'client:account.edit-profile.title' ) }
                    <Divider type="horizontal" />
                </h1>
                {
                    isLoading ?
                    loadingJSX :
                    contentJSX
                }
            </div>
            <PhoneNumberCodeVerificationModal ref={ phoneVerificationRef }
                                              phoneNumber={ phoneNumberFormatted }
                                              onCodeVerified={ ( isVerified ) => handleCodeVerified( isVerified ) } />
        </>
    );
};