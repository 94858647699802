import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import MenuSection, { AccountNavigationItem } from '@/components/MenuSection';
import { Button } from 'antd';
import { LinkFilledIcon } from '@/assets/icons/CustomIcons';
import { freelancerProfileLinkHook } from '@/hooks/freelancerProfileLinkHook';

export default function FreelancerSettings() {

    const { t } = useTranslation();
    const { getProfileLink } = freelancerProfileLinkHook();

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    const navigationSections: AccountNavigationItem[] = [
        {
            title: t( 'freelancer:account.navigation.editProfile' ),
            route: '/freelancer/settings/edit-profile'
        },
        {
            title: t( 'freelancer:account.navigation.connectedAccounts' ),
            route: '/freelancer/settings/connected-account'
        },
        {
            title: t( 'freelancer:account.navigation.editPassword' ),
            route: '/freelancer/settings/edit-password'
        }
    ];

    return (
        <div className="w-full h-full">
            <div className="flex flex-col lg:flex-row">
                <div className="flex-none lg:w-[250px]">
                    <div className="hidden lg:block text-2xl font-medium font-sharp">
                        { t( 'freelancer:account.navigation.settings' ) }
                    </div>
                    <MenuSection routes={ navigationSections }/>
                </div>

                <div className="w-full lg:max-w-[977px] h-full lg:px-6 mt-4 md:mt-0">
                    <div className="flex md:justify-end ">
                        <Button type="primary"
                                onClick={ () => getProfileLink() }
                                icon={ <LinkFilledIcon className="text-lg" /> }>
                            { t( 'freelancer:account.shareProfile' ) }
                        </Button>
                    </div>
                    <div className="mt-8">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}