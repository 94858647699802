import { PortfolioElement } from '@/gql/graphql';
import React from 'react';
import FreelancerProfilePortfolioListItem from '@/components/freelance-profile/FreelancerProfilePortfolioListItem';

interface FreelancerProfilePortfolioListProps {
    portfolio: PortfolioElement[];
}

export default function FreelancerProfilePortfolioList( { portfolio }: FreelancerProfilePortfolioListProps ) {

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {
                portfolio?.map( ( portfolioElement: PortfolioElement, index ) => {
                    return (
                        <FreelancerProfilePortfolioListItem portfolioElement={ portfolioElement }
                                                            key={ index } />
                    );
                } )
            }
        </div>
    );
}