export const validateLength = ( value, errorMessage ) => {
    if( value && ( value.length < 3 || value.length > 50 ) ) {
        return errorMessage;
    }
    return true;
};

export const validateCharacters = ( value, errorMessage ) => {
    if( value && !/^[a-z0-9-_]+$/.test( value ) ) {
        return errorMessage;
    }
    return true;
};

export const validateUnique = async( customUrl, checkIfCustomUrlExists, errorMessage ) => {
    if( customUrl ) {
        const isUrlExists = await checkIfCustomUrlExists( customUrl );
        if( isUrlExists ) {
            return errorMessage;
        }
    }
    return true;
};

export const validateReserved = ( value, errorMessage ) => {
    if( value && [
        'admin',
        'login',
        'dashboard',
        'profile',
        'settings',
        'freelancers',
        'messages'
    ].includes( value ) ) {
        return errorMessage;
    }
    return true;
};
