import { gql } from '@apollo/client';

const clientApplicationChannelsGQL = gql`
    query findChannelByJobPost($args: GetFreelancerPortfolioChannelByJobPostInput!, $page: Int, $limit: Int) {
        findChannelByJobPost( args: $args, limit: $limit, page: $page ) {
            id
            name
            thumbnailUrl
            subscriberCount
            youtubeId
        }
    }`;

export default clientApplicationChannelsGQL;