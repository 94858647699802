import { AnalyticsBrowser } from '@segment/analytics-next';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { getI18n } from 'react-i18next';

const analytics = AnalyticsBrowser.load({ writeKey: import.meta.env.VITE_SEGMENT_KEY, });
const enableAnalytics = import.meta.env.VITE_ANALYTICS_ENABLED === 'TRUE';

export enum SegmentEvent {
    SignUpFunnelFistScreen = 'Sign Up Funnel - Dispatch First Screen',
    SignUpCompleted = 'Signup Completed - Front',
    LoginClicked = 'Login Clicked',
    ProfileClicked = 'Profile Clicked',
    PaywallClicked = 'Paywall clicked',
    UpgradePlanClicked = 'Upgrade Plan Clicked',
    GettingStartedFinishedCreator = 'Getting Started Finished - Creator',
    ContactDetailsClicked = 'Contact Details Clicked',

    FreelancerApplied = 'Freelancer Applied',
    FreelancerNotInterested = 'Freelancer Not Interested',
    ProfileUpdated = 'Profile Updated',
    PortfolioUpdated = 'Portfolio Updated',
    AddedOnFavorite = 'Added on Favorite',
    SuperlikeSent = 'Superlike sent'
};

export enum PageName {
    FreelancerJobPosts = 'Freelancer Job Posts',
    FreelancerJobPostDetail = 'Freelancer Job Post Detail',
    FreelancerList = 'Freelancer List',
    FreelancerProfile = 'Freelancer Profile',
    FreelancerEditProfile = 'Freelancer Edit Profile',
    FreelancerEditAccountInformation = 'Freelancer Edit Account Information',
    FreelancerEditPortfolioChannels = 'Freelancer Edit Portfolio Channels',
    FreelancerEditPortfolio = 'Freelancer Edit Portfolio',
    FreelancerEditPassword = 'Freelancer Edit Password',
    FreelancerEditConnectedAccount = 'Freelancer Edit Connected Account',

    ClientJobPosts = 'Client Job Posts',
    ClientJobPostDetails = 'Client Job Post Details',
    ClientJobPostNotifiedCandidates = 'Client Job Post Notified Candidates',
    ClientJobPostFavoriteCandidates = 'Client Job Post Favorite Candidates',
    ClientJobPostInterestedCandidates = 'Client Job Post Interested Candidates',
    ClientEditProfile = 'Client Edit Profile',
    ClientEditPassword = 'Client Edit Password',
    ClientEditCompanyDetails = 'Client Edit Company Details',
    ClientEditPaymentMethods = 'Client Edit Payment Methods',
    ClientEditChannelYT = 'Client Edit Channel YT',
    ClientPlansAndBilling = 'Client Plans And Billing',

    ClientFavorites = 'Client Favorites',
    
    SignUp = 'Signup',
    SignIn = 'Signin',
};

export enum FreelancerSignupStep {
    Country = "Country",
    UnsupportedCountry = "Unsupported Country",
    PersonnalInfo = "Personal Info",
    PhoneVerification = "Phone Number Verification",
    Price = "Price",
    Job = "Job",
    Experience = "Experience",
    Skill = "Skill",
    Expertise = "Expertise",
    Language = "Language",
    PortfolioVideo = "Portfolio Video",
    PortfolioChannel = "Portfolio Channel",
    Bio = "Bio",
    Invoicing = "Invoicing",
    Preview = "Preview",
};

export enum userTypeName {
    Freelancer = 'Freelancer',
    Creator = 'Creator',
};

export enum paywallOriginTypeName {
    Interested = 'Interested',
    Banner = 'Banner',
};

export enum planTypeName {
    JobPass = 'Job Pass', 
    AnnualPass = 'Annual Pass'
}

export enum applicationTypeStatus {
    INTERESTED = 'Interested',
    FAVORITE = 'Favorite',
    OUTREACHED = "Outreached"
};

export enum jobPostOriginTypeName {
    Listing = 'Listing', 
    Details = 'Details'
}

export async function identifySegmentUser( user, userType ) {

    if ( enableAnalytics ) {

        const segmentData: any = {
            user_type: userType,
            email: user.email || null,
        };

        if( userType === userTypeName.Freelancer ) {
            const name = `${user.firstName || ''} ${ user.lastName || ''}`
            segmentData.is_freelancer_available = typeof user.isAvailable === 'boolean' ? user.isAvailable ? 'Yes' : 'No' : null;
            segmentData.freelancer_name = name.trim().length ? name : null;
            segmentData.freelancer_status = user.accountStatus ? getI18n().t(`enum.accountStatus.${user.accountStatus}`) : null;
            segmentData.freelancer_rating = user.avgRate || null;
            segmentData.freelancer_price = user.avgPrice;
            segmentData.freelancer_mainjob = user.mainJob ? getI18n().t(`enum.jobs.${user.mainJob}`) : null;
            segmentData.freelancer_experience = user.experience ? firstLetterUppercaseFormatter( user.experience ) : null;
            segmentData.freelancer_portfolio_views = user.totalPortfolioViews;
        } else {
            segmentData.client_name = user.userName;
            segmentData.client_rating = user.avgRate || null;
            segmentData.creator_size = user.creatorSize ? firstLetterUppercaseFormatter( user.creatorSize ) : null;
            segmentData.client_channel_count = user.channels?.length;
            segmentData.client_channel_names = user.channels?.map(( channel ) => channel.name);
        }
        
        const filteredSegmentData = Object.fromEntries(
            Object.entries(segmentData).filter(([key, value]) => value !== null && value !== undefined)
        );

        analytics.identify( user?.id, filteredSegmentData );
    };
};

export async function trackSegmentEvent(
    event: SegmentEvent,
    properties?: Record<string, any>,
) {
    if( enableAnalytics ) {
        try {
            analytics.track(event, properties);
        } catch ( error ) {
            console.error('[analytics] Unexpected error while sending segment event', error)
        };
    };
};

export async function trackPageView( title: string, param:{} = {} ) {
    if( enableAnalytics ) {
        try {
            analytics.page('Page Loaded', {
                title,
                ...param 
            });
        } catch ( error ) {
            console.error('[analytics] Unexpected error while sending segment page event', error)
        };
    };
};
