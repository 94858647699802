import { useLazyQuery } from '@apollo/client';
import { portfolioChannelsGQL } from '@/gql/global-queries/portfolioChannelsGQL';

export default function portfolioChannelsHook() {
    const [
              loadPortfolioChannels, {
            data,
            loading,
            error
        }
          ] = useLazyQuery( portfolioChannelsGQL );

    const getPortfolioChannels = ( freelancerId ) => {
        loadPortfolioChannels( {
            variables: {
                freelancerId
            }
        } );
    };

    return {
        getPortfolioChannels,
        portfolioChannels: data?.portfolioChannels,
        loading,
        error
    };
}