import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { NavLink } from 'react-router-dom';
import MyAccountButton from '@/components/MyAccountButton';
import Header from '@/components/layout/Header';
import { DiscordLogoIcon, LightningBoltFilledIcon, LinkFilledIcon, MyProfileIconFilledIcon, ProjectFolderOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Freelancer, FreelancerAccountStatusEnum, UserTypeEnum } from '@/gql/graphql';
import SupportButton from '@/components/SupportButton';
import { freelancerProfileLinkHook } from '@/hooks/freelancerProfileLinkHook';

export function FreelancerHeaderDesktop() {
    const { t } = useTranslation();

    const sessionRepository = new SessionRepository();

    const [ user, setUser ] = useState<Partial<Freelancer>>( null );
    const [ navs, setNavs ] = useState<any[]>( [
        {
            to: '/freelancer/job-posts',
            text: t( 'freelancer:header.desktop.jobPosts' ),
            icon: <ProjectFolderOutlinedIcon className="text-[24px]" />,
            count: null
        },
        {
            to: '/freelancer/profile',
            text: t( 'freelancer:header.desktop.myProfile' ),
            icon: <MyProfileIconFilledIcon className="text-[24px]" />,
            count: null
        }
    ]);
    const { getProfileLink } = freelancerProfileLinkHook();


    useEffect( () => {
        const observable = sessionRepository.session$.subscribe( ( session ) => {
            setUser( session.user as Freelancer );

            if( ( session.user as Freelancer )?.accountStatus != FreelancerAccountStatusEnum.APPROVED ) {
                setNavs( (nav) => [
                    {
                        to: '/freelancer/getting-started',
                        text: t( 'freelancer:header.desktop.gettingStarted' ),
                        icon: <LightningBoltFilledIcon className="text-[24px]" />,
                        count: null
                    },
                    ...navs
                ]);
            }
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [] );

    const goToDiscord = () => {
        window.open( 'https://discord.com/invite/s3uMYNPtwV', '_blank', 'noreferrer' );
    };


    const leftJSX = (
        <>
            {
                navs.map( ( nav, index ) => {
                    return (
                        <NavLink key={ index }
                                 to={ nav.to }
                                 className={ ( { isActive } ) => `
                                            block px-4 py-2 rounded-4xl font-semibold hover:text-primary-400 text-base
                                            ${ isActive
                                               ? 'text-primary-500 bg-primary-100'
                                               : 'text-grey' }
                                            ` }>
                                <span className="flex items-center gap-x-2 text-inherit">
                                    <div className="text-inherit">
                                        { nav.icon }
                                    </div>
                                    { nav.text }
                                </span>
                        </NavLink>
                    );
                } )
            }
        </>
    );

    const customSection = [
        {
            key: 'share-my-profile',
            label: (
                <div className="text-sm font-medium py-1.5">
                    { t( 'freelancer:account.shareProfile' ) }
                </div>
            ),
            icon: <LinkFilledIcon className="font-medium text-base" />,
            onClick: () => getProfileLink()
        },
        {
            key: 'discord',
            label: (
                <div className="text-sm font-medium py-1.5">
                    { t( 'freelancer:account.joinDiscord' ) }
                </div>
            ),
            icon: <DiscordLogoIcon className="text-base" />,
            onClick: () => goToDiscord()
        }
    ];

    const rightJSX = (
        <div className="flex items-center justify-center gap-x-4">
            <SupportButton />
            <MyAccountButton customActions={ customSection }
                             data={ {
                                 type: UserTypeEnum.FREELANCER,
                                 profilePicture: user?.profilePicture,
                                 userName: user?.firstName
                             } } />
        </div>
    );

    return (
        <Header left={ leftJSX }
                right={ rightJSX }
                logoTo="/freelancer/job-posts/all" />
    );
}