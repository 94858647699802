import JobPostDetailHeader from '@/components/job-post/component/JobPostDetailHeader';
import JobPostDetailJobInfo from '@/components/job-post/component/JobPostDetailJobInfo';
import { JobPost } from '@/gql/graphql';
import { useOutletContext } from 'react-router-dom';
import ClientJobPostDetailActions from './component/ClientJobPostDetailActions';
import { useEffect, useRef } from 'react';
import { PageName, trackPageView } from '@/utils/analytics';
import InfoMessage from '@/components/InfoMessage';
import { Trans, useTranslation } from 'react-i18next';
import { App, Button } from 'antd';
import { ChainLinkFilledIcon } from '@/assets/icons/CustomIcons';
import PairChannelModal from '../PairChannelModal';
import { useMutation } from '@apollo/client';
import { attachChannelToJobPostGQL } from '@/gql/global-queries';

export default function ClientJobPostDetails( ) {

    const { jobPost, jobPostRefetch } = useOutletContext<{ jobPost: JobPost,  jobPostRefetch: any }>();

    const { message } = App.useApp();

    const { t } = useTranslation();

    const pairChannelModalRef = useRef( null );

    const handlePairChannel = () => {
        pairChannelModalRef.current.open();
    };

    const attachChannelToJobPostMutation = useMutation( attachChannelToJobPostGQL );

    const onPairChannel = async ( channelId ) => {
        try {            
            const result = await attachChannelToJobPostMutation[ 0 ]( {
                variables: {
                    channelId,
                    jobPostId: jobPost?.id,
                }
            } );

            if( result.data ) {
                jobPostRefetch();
                pairChannelModalRef.current.close();
                message.success( t( 'client:jobPost.pairChannel.success' ));
            } else {
                message.error( t( 'translation:error.default' ) );
            }
        } catch( e ) {            
            message.error( t( 'translation:error.default' ) );
        }
    }

    useEffect( () => {
        trackPageView( PageName.ClientJobPostDetails );
    }, [ ] );

    return (
        <div className="flex flex-col gap-y-12">
            <JobPostDetailHeader jobPost={ jobPost } />
            {
                !jobPost?.channel &&
                <InfoMessage>
                    <div className='flex flex-col gap-2'>
                        <span>
                            <Trans 
                                i18nKey="client:jobPost.pairChannel.description" 
                                components={[<span/>,<strong />]}
                            />
                        </span>
                        <Button onClick={ () => handlePairChannel() } className='px-4 py-0 h-8 text-sm w-fit' type="default">
                            <ChainLinkFilledIcon />
                            {t('client:jobPost.pairChannel.button')}
                        </Button>
                    </div>
                </InfoMessage>
            }
            <div className='flex flex-col lg:flex-row gap-8'>
                <JobPostDetailJobInfo jobPost={ jobPost } />
                <div className="w-full lg:w-1/5">
                    <ClientJobPostDetailActions onEdit={ () => console.log('edit job offer') } jobPost={ jobPost } />
                    {/*TODO Edit job offer */}
                </div>
            </div>

            <PairChannelModal ref={ pairChannelModalRef } onChannelIdSumbitted={( channelId: string ) => onPairChannel( channelId )} />
        </div>
    )
};
