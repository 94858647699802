import { Trans, useTranslation } from 'react-i18next';
import { FilmsFilledIcon, UsersPlusFilledIcon } from '@/assets/icons/CustomIcons';
import SignUpOptions from '@/pages/auth/sign-up/components/SignUpOptions';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import SignUpLayout from '@/pages/auth/sign-up/components/SignUpLayout';
import { applicationTypeStatus, PageName, SegmentEvent, trackPageView, trackSegmentEvent } from '@/utils/analytics';


export default function SignUp() {

    const { t } = useTranslation( 'auth' );
    const navigate = useNavigate();
    const location = useLocation();
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const [ optionSelected, setOptionSelected ] = useState( null );

    useEffect( () => {
        trackPageView( PageName.SignUp );
    }, [] );

    const options = [
        {
            id: 'hiring',
            icon: <UsersPlusFilledIcon />,
            name: t( 'auth:sign-up.options.client.title' ),
            description: t( 'auth:sign-up.options.client.subtitle' ),
            link: '/auth/sign-up/client'
        },
        {
            id: 'LFWork',
            icon: <FilmsFilledIcon />,
            name: t( 'auth:sign-up.options.freelancer.title' ),
            description: t( 'auth:sign-up.options.freelancer.subtitle' ),
            link: '/auth/sign-up/freelancer'
        }
    ];

    const handleOptionSelected = ( option ) => {

        trackSegmentEvent( SegmentEvent.SignUpFunnelFistScreen, { user_type_clicked: option.id } );

        if( isTabletOrMobile ) {
            setOptionSelected( option );
        }
        else {
            navigate( option.link + location.search );
        }
    };

    const next = () => navigate( optionSelected?.link );

    const rightSide = (
        <>
            <div className="flex-grow">
                <div className="font-sharp text-2xl leading-[33px] font-medium tracking-[-0.48px]">
                    { t( 'auth:sign-up.titleRight' ) }
                </div>
                <div className="text-normal text-base mt-4">
                    { t( 'auth:sign-up.subtitleRight' ) }
                </div>

                <div className="mt-10 flex flex-col gap-y-6">
                    {
                        options?.map( ( option, index ) => (
                            <div onClick={ () => handleOptionSelected( option ) }
                                 key={ index }>
                                <SignUpOptions data={ option } />
                            </div>
                        ) )
                    }
                </div>

                <div className="mt-4 lg:mt-10 text-sm text-center">
                    <Trans i18nKey="auth:alreadyAccount">
                        <span className="text-center text-sm font-normal text-grey"></span>
                        <Link to="/auth/sign-in"
                              onClick={ () => trackSegmentEvent(
                                  SegmentEvent.LoginClicked, { login_started_origin: 'Signup Page' }
                              ) }
                              className="text-sm font-normal text-primary-400 cursor-pointer ml-2"></Link>
                    </Trans>
                </div>

                <div className="text-center mt-4 lg:mt-6">
                    <Trans i18nKey="auth:agreement">
                        <span className="font-normal text-grey text-sm"></span>
                        <a href="https://jellysmack.com/privacy-policy/"
                           target="_blank"
                           className="text-grey text-sm underline font-medium"
                           rel="noopener noreferrer">
                        </a>
                        <a href="https://jellysmack.com/terms-of-use/"
                           target="_blank"
                           className="text-grey text-sm underline font-medium"
                           rel="noopener noreferrer">
                        </a>
                    </Trans>
                </div>
            </div>
            <Button type="primary"
                    disabled={ !optionSelected }
                    onClick={ () => next() }
                    className="w-full lg:hidden mt-6">
                { t( 'common:button.next' ) }
            </Button>
        </>
    );

    return (
        <SignUpLayout rightSide={ rightSide }
                      hideNextButton={ true } />
    );
}