import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export default function GoogleAuthRedirect() {
    let [ searchParams, setSearchParams ] = useSearchParams();
    const navigate = useNavigate();

    useEffect( () => {
        navigate( '/' );
    } );

    return (
        <>
        </>
    );
}