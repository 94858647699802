import React, { useEffect, useState } from 'react';
import { Button, Divider, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { PageName, SegmentEvent, trackPageView, trackSegmentEvent } from '@/utils/analytics';
import FreelancerProfileEditPortfolioOrderedList from '@/pages/freelancer/views/profile/profile-edit-portfolio/components/FreelancerProfileEditPortfolioOrderedList';
import portfolioHook from '@/hooks/portfolioHook';
import { SessionRepository } from '@/core/auth/sessionRepository';
import FreelancerProfileEditPortfolioFeaturedItem from '@/pages/freelancer/views/profile/profile-edit-portfolio/components/FreelancerProfileEditPortfolioFeaturedItem';
import FreelancerProfileEditPortfolioBucket from '@/pages/freelancer/views/profile/profile-edit-portfolio/components/FreelancerProfileEditPortfolioBucket';
import FreelancerPortfolioStats from '@/components/freelance-profile/components/FreelancerPortfolioStats';
import SkeletonInput from 'antd/es/skeleton/Input';
import { PortfolioElement, SegmentPortfolioElementEnum } from '@/gql/graphql';
import { GoBackFilledIcon, PlusFilledIcon } from '@/assets/icons/CustomIcons';
import FreelancerProfileEditPortfolioChannelOrderedList from '@/pages/freelancer/views/profile/profile-edit-portfolio/components/FreelancerProfileEditPortfolioChannelOrderedList';
import BackButton from '@/components/BackButton';

export default function FreelancerProfileEditPortfolio() {

    const videoLimit: number = 6;

    const { t } = useTranslation();
    const { trigger, control, formState: { errors }, handleSubmit, reset } = useForm();
    const sessionRepository: SessionRepository = new SessionRepository();

    const [ searchTerm, setSearchTerm ] = useState( undefined );

    const [ messageApi, contextHolder ] = message.useMessage();

    const freelancer = sessionRepository.user;

    const {
              featuredItem,
              selectedItems,
              bucketItems,
              channels,
              statistics,
              handleOrderChange,
              handleDelete,
              getFeatured,
              getSelected,
              getBucket,
              getChannels,
              getStats,
              handleUpdateSegment,
              handleCreatePortfolioElement,
              handleBucketChangePage,
              handleSearchBucketByName,
              loadingSelected,
              loadingFeatured,
              loadingBucket,
              loadingChannels,
              loadingCreatePortfolioElement,
              loadingStats
          } = portfolioHook();

    useEffect( () => {
        getFeatured( freelancer.id );
        getSelected( freelancer.id );
        getBucket( freelancer.id );
        getChannels( freelancer.id );
        getStats( freelancer.id );
        trackPageView( PageName.FreelancerEditPortfolio );
    }, [] );

    useEffect( () => {
        if( searchTerm != null ) {
            const delayDebounceFn = setTimeout( () => {
                const freelancer = sessionRepository.user;
                handleSearchBucketByName( freelancer.id, searchTerm );

            }, 500 );
            return () => clearTimeout( delayDebounceFn );
        }
    }, [ searchTerm ] );

    const onBucketPageChange = ( page: number ) => {
        handleBucketChangePage( freelancer.id, { search: searchTerm, page, limit: 5 } );
    };

    const handleSearchChange = ( value ) => {
        setSearchTerm( value );
    };

    const onSubmit = async( data ) => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            handleCreatePortfolioElement( data.url, freelancer.id, { search: searchTerm, page: 1, limit: 5 } );
            reset( {
                url: ''
            } );

            trackSegmentEvent( SegmentEvent.PortfolioUpdated );
            
        } catch( e ) {
            let errorMessage: string = t( 'translation:error.default' );
            if( e.message === 'You can\'t add more than 6 portfolio elements' ) {
                errorMessage = t( 'freelancer:account.edit-portfolio.errorRichLimit', { limit: videoLimit } );
            }
            Sentry.captureException( 'An error occur when trying to add new portfolio element', e );
            messageApi.error( errorMessage );
        }
    };

    const handleBack = () => window.history.back();

    return (
        <div className="w-full flex flex-col">
            <BackButton onClick={ () => handleBack() }
                        icon={ <GoBackFilledIcon className="text-lg" /> }
                        className="w-fit" />

            <section className="mt-6">
                <h1>{ t( 'freelancer:account.edit-portfolio.title' ) }</h1>
                <p className="text-sm font-normal text-grey">
                    { t( 'freelancer:account.edit-portfolio.subTitle' ) }
                </p>

                <h3 className="text-black text-lg font-normal mt-4">
                    { t( 'freelancer:account.edit-portfolio.addVideoOrPlaylist' ) }
                </h3>

                <Form className="flex flex-col lg:flex-row mt-2"
                      onSubmit={ handleSubmit( onSubmit ) }>

                    <InputComponent control={ control }
                                    name="url"
                                    rules={ {
                                        required: t( 'common:error.form.required' ),
                                        pattern: {
                                            value: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*\??[\w=&\+\%]*)*\/?$/,
                                            message: t( 'freelancer:account.edit-portfolio.wrongUrl' )
                                        }
                                    } }
                                    placeholder={
                                        t( 'freelancer:account.edit-portfolio.inputPlaceholder' )
                                    }
                                    errors={ errors } />

                    <Button type="primary"
                            htmlType="submit"
                            loading={ loadingCreatePortfolioElement }
                            className="w-full lg:w-[160px] lg:ml-4 mt-4 lg:mt-0 flex items-center">
                        <PlusFilledIcon />
                        { t( 'freelancer:account.edit-portfolio.submit' ) }
                    </Button>
                </Form>
            </section>

            <Divider className="my-8" />

            <section>

                <h3 className="text-black text-lg font-normal">
                    { t( 'freelancer:account.edit-portfolio.channelsTitle' ) }
                </h3>

                <FreelancerProfileEditPortfolioChannelOrderedList portfolioChannels={ channels }
                                                                  isLoading={ loadingChannels }
                                                                  className="mt-2" />
            </section>


            <Divider className="my-8" />

            <section className="flex items-center justify-between">

                <h3 className="text-black text-lg font-normal">
                    {
                        t( 'freelancer:account.edit-portfolio.statisticTitle' )
                    }
                </h3>

                {
                    loadingStats ?
                    <SkeletonInput active={ true }
                                   className="w-[200px] h-[40px]" /> :
                    <FreelancerPortfolioStats stats={ {
                        countElements: statistics?.countElements,
                        countViews: statistics?.countViews
                    } } />
                }
            </section>

            <Divider className="my-8" />

            <section>
                <h3 className="text-black text-lg font-normal">
                    {
                        t( 'freelancer:account.edit-portfolio.videoFeaturedTitle', {
                            count: featuredItem != null ? 1 : 0,
                            total: 1
                        } )
                    }
                </h3>
                <FreelancerProfileEditPortfolioFeaturedItem portfolioElement={ featuredItem }
                                                            onUpdateSegment={ ( segment: SegmentPortfolioElementEnum ) => handleUpdateSegment( featuredItem?.id, segment ) }
                                                            onDeleteItem={ ( portfolioElement ) => handleDelete( portfolioElement ) }
                                                            className="mt-2"
                                                            isDeleting={ true }
                                                            isLoading={ loadingFeatured } />
            </section>

            <Divider className="my-8" />

            <section>
                <h3 className="text-black text-lg font-normal">
                    { t( 'freelancer:account.edit-portfolio.videosSelectedTitle', {
                        count: selectedItems?.length || 0,
                        total: 6
                    } ) }
                </h3>
                <p className="text-sm font-normal text-grey">
                    { t( 'freelancer:account.edit-portfolio.featuredDescription' ) }
                </p>

                <FreelancerProfileEditPortfolioOrderedList portfolioElements={ selectedItems }
                                                           className="mt-4"
                                                           isLoading={ loadingSelected }
                                                           onSegmentChange={ ( item,
                                                                               segment: SegmentPortfolioElementEnum ) => handleUpdateSegment( item.id, segment ) }
                                                           onOrderChange={ ( orderedList: PortfolioElement[] ) => handleOrderChange( orderedList ) }
                                                           onDeleteItem={ ( portfolioElement: PortfolioElement ) => handleDelete( portfolioElement ) } />
            </section>

            <Divider className="my-8" />

            <section>
                <div className="flex gap-6 items-center justify-between">
                    <div className="flex-none">
                        <h3 className="text-black text-lg font-normal">
                            { t( 'freelancer:account.edit-portfolio.videosBucketTitle', {
                                count: bucketItems?.total || 0
                            } ) }
                        </h3>
                        
                        <p className="text-sm font-normal text-grey">
                            { t( 'freelancer:account.edit-portfolio.bucketSubTitle' ) }
                        </p>
                        
                    </div>
                    <Input className="w-[285px] rounded-[10px]"
                           onChange={ ( e ) => handleSearchChange( e.target.value ) }
                           placeholder={ t( 'freelancer:account.edit-portfolio.bucketSearchInputPlaceholder' ) } />
                </div>

                <FreelancerProfileEditPortfolioBucket portfolioElementsPaginated={ bucketItems }
                                                      className="mt-4"
                                                      isLoading={ loadingBucket }
                                                      onSegmentChange={ ( portfolioElementId: string,
                                                                          segment: SegmentPortfolioElementEnum ) => handleUpdateSegment( portfolioElementId, segment ) }
                                                      onPageChange={ ( page: number ) => onBucketPageChange( page ) }
                                                      onDeleteItem={ ( portfolioElement ) => handleDelete( portfolioElement ) } />
            </section>
        </div>
    );
}