import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { LanguageEnum } from '@/gql/graphql';
import { twMerge } from 'tailwind-merge';
import { TranslateOutlinedIcon } from '@/assets/icons/CustomIcons';

interface JobPostSkillTagProps {
    languages: LanguageEnum[];
    bordered?: boolean;
    className?: string;
    iconClassName?: string;
}

export default function LanguagesTag( {
                                          languages,
                                          bordered,
                                          className
                                      }: JobPostSkillTagProps ) {

    const { t } = useTranslation();

    return (
        <div className="flex flex-wrap justify-center space-x-2">
            {
                languages?.map( ( language, index ) => (
                    <Tag bordered={ bordered }
                         key={ index }
                         icon={ <TranslateOutlinedIcon className="text-black"/> }
                         className={ twMerge('text-black border-greyscale bg-greyscale ', className) }>
            <span>
                { t( 'translation:enum.languages.' + language?.toUpperCase() ) }
            </span>
                    </Tag>
                ) )
            }
        </div>

    );
}