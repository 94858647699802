import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Avatar, Divider, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import ResponsiveModal from '@/components/ResponsiveModal';
import { Application } from '@/gql/graphql';
import { DiscordLogoIcon, InstagramOutlinedIcon, PhoneFilledIcon, TwitterOutlinedIcon } from '@/assets/icons/CustomIcons';
import { UserOutlined } from '@ant-design/icons';
import jobToIcon from '@/utils/jobToIcon';
import { useLazyQuery } from '@apollo/client';
import { freelancerContactDetailsGQL } from '@/gql/global-queries';

interface ApplicationContactDetailsModalProps {
    application: Application;
}

const ApplicationContactDetailsModal = forwardRef( ( props: ApplicationContactDetailsModalProps, ref ) => {
    const { application } = props;
    const { t } = useTranslation();

    const modalRef = useRef( null );

    const [ contactDetailsQuery, { data, loading, error } ] = useLazyQuery( freelancerContactDetailsGQL );

    useEffect( () => {
        if( application !== null ) {
            contactDetailsQuery( {
                    variables: {
                        applicationId: application?.id
                    }
                }
            );
        }
    }, [ application ] );

    useImperativeHandle( ref, () => ( {
        open() {
            modalRef.current.open();
        },
        close() {
            modalRef.current.close();
        }
    } ) );

    const contactElements = [
        {
            icon: <PhoneFilledIcon className="text-[#71767B]" />,
            value: data?.freelancerContactDetails?.phone
        },
        {
            icon: <DiscordLogoIcon className="text-[#5865F2]" />,
            value: data?.freelancerContactDetails?.discordUserTag
        },
        {
            icon: <TwitterOutlinedIcon />,
            value: data?.freelancerContactDetails?.twitterUserTag
        },
        {
            icon: <InstagramOutlinedIcon />,
            value: data?.freelancerContactDetails?.instagramUserTag
        }
    ];

    const allContactElementsAreEmpty = contactElements.every( element => !element.value );

    return (
        <ResponsiveModal ref={ modalRef }
                         closable={ true }
                         destroyOnClose={ true }
                         desktopWidth="400px"
                         mobileHeight="h-fit"
                         title={
                            <div className="flex flex-col md:px-6 md:pt-4">
                                     <div className="flex items-center">
                                         <Avatar size={ 48 }
                                                 className="flex-none"
                                                 icon={ <UserOutlined /> }
                                                 src={ application?.freelancer?.profilePicture } />
                                         <div className="flex flex-col justify-between ml-2 ">
                                             <span className="text-base font-sharp">{ application?.freelancer?.firstName } { application?.freelancer?.lastName }.</span>
                                             <div className="text-primary-500 max-w-[250px] whitespace-nowrap h-7 flex items-center gap-1">
                                                 { jobToIcon( application?.freelancer?.mainJob?.toUpperCase(), 'text-inherit inline-block' ) }
                                                 <span>
                                    { t( 'translation:enum.jobs.' + application?.freelancer?.mainJob?.toUpperCase(), { defaultValue: t( 'translation:enum.expertises.unknown' ) } ) }
                                </span>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                         }
                         rootClassName="custom-modal">
            {
                loading ?
                <Skeleton active={ true } /> :
                <>
                    <Divider className="mt-0 mb-4" />
                    <div className="flex gap-28 md:px-6 md:pb-6">
                        <div className="flex flex-col gap-6">
                            <div className="flex flex-col gap-1">
                                <span className="font-sharp">{ t( 'client:jobPost.contactDetailsModal.title', { name: application?.freelancer?.firstName } ) }</span>
                                <span className="text-[#71767B]">{ t( 'client:jobPost.contactDetailsModal.description' ) }</span>
                            </div>

                            <div className="flex flex-col gap-4">
                                {
                                    allContactElementsAreEmpty ? (
                                        <span className="italic">{ t( 'client:jobPost.contactDetailsModal.noContactInfo' ) }</span>
                                    ) : (
                                        contactElements.map( ( element ) => {
                                            if( element.value ) {
                                                return (
                                                    <div key={ element.value }
                                                         className="flex gap-2 text-base justify-center border border-[#E6E9F0] rounded-4xl px-6 py-2">
                                                        { element.icon } { element.value }
                                                    </div>
                                                );
                                            }
                                            return null;
                                        } )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </>
            }

        </ResponsiveModal>
    );
} );

export default ApplicationContactDetailsModal;
