import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoopStarsOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

interface NoDataIconProps {
    className?: string;
    message?: string;
    subMessage?: string;
    icon?: JSX.Element;
    button?: {
        to: string;
        text: string;
    };
}

export default function NoDataIcon( { className, message, subMessage, icon, button }: NoDataIconProps ) {

    const { t } = useTranslation();
    const defaultIcon = (
        <div className="w-[120px] h-[120px] rounded-full flex justify-center items-center bg-primary-100">
            <div className="w-[56px] h-[56px] rounded-full flex justify-center items-center bg-primary-500">
                <LoopStarsOutlinedIcon className="text-[28px] text-white" />
            </div>
        </div>
    );

    if( !icon ) {
        icon = defaultIcon;
    }
    if( !message ) {
        message = t( 'common:noData.defaultText' );
    }
    if( !subMessage ) {
        subMessage = t( 'common:noData.defaultSubText' );
    }

    return (
        <div className={ `h-fit lg:w-[512px] flex flex-col items-center justify-center ${ className }` }>
            {
                icon
            }
            <div className="text-2xl font-medium text-center mt-4">
                { message }
            </div>
            <div className="mt-2 font-normal text-lg text-grey text-center">
                { subMessage }
            </div>
            {
                button &&
                <Link to={ button?.to }
                      className="mt-6">
                  <Button type="primary"
                          size="large">
                      { button?.text }
                  </Button>
                </Link>
            }
        </div>

    );

}