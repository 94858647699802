import { createStore, select, withProps } from '@ngneat/elf';
import { RcFile } from 'antd/es/upload';

import parsePhoneNumber, { CountryCode, getCountryCallingCode, PhoneNumber } from 'libphonenumber-js';

export interface SignUpFreelancerProps {
    data: {
        email: string | null;
        firstName: string | null;
        lastName: string | null;
        phoneCodeCountry: string | null,
        phoneNumber: string | null,
        password: string | null;
        isAgreeForNewsletter: boolean | null;
        profilePicture: RcFile | null;
        profilePictureVirtualUrl: string | null;
        profilePictureUploaderRef: null;
    };
    step: number;
    isLoadingNextStep: boolean;
    lastCodeSentAt?: Date;
    phoneNumberVerified: boolean | null;
}

const signUpFreelancerStore = createStore(
    { name: 'signUpFreelancer' },
    withProps<SignUpFreelancerProps>( {
        data: {
            email: null,
            firstName: null,
            lastName: null,
            password: null,
            isAgreeForNewsletter: null,
            profilePicture: null,
            profilePictureVirtualUrl: null,
            profilePictureUploaderRef: null,
            phoneCodeCountry: null,
            phoneNumber: null,
        },
        step: 0,
        isLoadingNextStep: false,
        lastCodeSentAt: null,
        phoneNumberVerified: null
    } )
);

export class SignUpFreelancerRepository {

    step$ = signUpFreelancerStore.pipe( select( ( state ) => state.step ) );

    isLoadingNextStep$ = signUpFreelancerStore.pipe( select( ( state ) => state.isLoadingNextStep ) );

    get data() {
        return signUpFreelancerStore.getValue().data;
    }

    set data( data: SignUpFreelancerProps['data'] ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data
            };
        } );
    }

    get email(): string {
        return signUpFreelancerStore.getValue().data.email;
    }

    set email( email: string ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    email
                }
            };
        } );
    }

    get firstName(): string {
        return signUpFreelancerStore.getValue().data.firstName;
    }

    get lastName(): string {
        return signUpFreelancerStore.getValue().data.lastName;
    }

    get password(): string {
        return signUpFreelancerStore.getValue().data.password;
    }

    set companyCountry( companyCountry: string ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    companyCountry
                }
            };
        } );
    }

    get phoneNumberVerified(): boolean {
        return signUpFreelancerStore.getValue().phoneNumberVerified;
    }

    set phoneNumberVerified( phoneNumberVerified: boolean ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                phoneNumberVerified
            };
        } );
    }

    get lastCodeSentAt(): Date {
        return signUpFreelancerStore.getValue().lastCodeSentAt;
    }

    set lastCodeSentAt( lastCodeSentAt: Date ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                lastCodeSentAt
            };
        } );
    }

    get profilePicture(): RcFile {
        return signUpFreelancerStore.getValue().data.profilePicture;
    }

    set profilePicture( profilePicture: RcFile ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    profilePicture
                }
            };
        } );
    }

    get phoneCodeCountry(): string {
        return signUpFreelancerStore.getValue().data.phoneCodeCountry;
    }

    get phoneCodeCountryNumber(): string {
        if( !this.phoneCodeCountry ) {
            return null;
        }

        return `+${ getCountryCallingCode( this.phoneCodeCountry as CountryCode ) }`;
    }

    get phoneNumber(): string {
        return signUpFreelancerStore.getValue().data.phoneNumber;
    }

    get fullPhoneNumber(): string {
        const phoneNumber: string = this.phoneNumber;

        if( !phoneNumber ) {
            return null;
        }

        return `${ this.phoneCodeCountryNumber }${ this.phoneNumber }`;
    }

    get fullPhoneNumberFormatted(): string {
        const fullPhoneNumber: string = this.fullPhoneNumber;

        if( !fullPhoneNumber ) {
            return null;
        }

        const phoneNumber: PhoneNumber = parsePhoneNumber( this.fullPhoneNumber );
        return phoneNumber.format( 'E.164' );
    }

    get profilePictureVirtualUrl(): string {
        return signUpFreelancerStore.getValue().data.profilePictureVirtualUrl;
    }

    set profilePictureVirtualUrl( profilePictureVirtualUrl: string ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    profilePictureVirtualUrl
                }
            };
        } );
    }

    get profilePictureUploaderRef(): any {
        return signUpFreelancerStore.getValue().data.profilePictureUploaderRef;
    }

    set profilePictureUploaderRef( profilePictureUploaderRef: any ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    profilePictureUploaderRef
                }
            };
        } );
    }

    get isAgreeForNewsletter(): boolean {
        return signUpFreelancerStore.getValue().data.isAgreeForNewsletter;
    }

    set props( props: SignUpFreelancerProps ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                ...props
            };
        } );
    }

    set isLoadingNextStep( isLoadingNextStep: boolean ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                isLoadingNextStep
            };
        } );
    }

    updateData( data: Partial<SignUpFreelancerProps['data']> ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...data
                }
            };
        } );
    }

    nextStep( count: number = 1 ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                step: state.step + count
            };
        } );
    }

    previousStep( count: number = 1 ) {
        signUpFreelancerStore.update( ( state ) => {
            return {
                ...state,
                step: state.step - count
            };
        } );
    }

    clearStore(): void {
        signUpFreelancerStore.reset();
    }
}