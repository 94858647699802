import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { freelancerGQL } from '@/gql/global-queries';

export default function freelancerProfileHook() {
    const nav = useNavigate();

    const [ freelancerQuery, { data, error, loading, refetch } ] = useLazyQuery( freelancerGQL );

    useEffect( () => {
        if( error ) {
            // @ts-ignore
            if( error.graphQLErrors?.length > 0 && error?.graphQLErrors[ 0 ]?.extensions?.exception?.status == 404 ) {
                nav( '/404' );
            }
            else {
                nav( '/500' );
            }
        }
    }, [ error ] );

    const load = ( freelancerId: string ): void => {
        freelancerQuery( {
            variables: {
                id: freelancerId
            }
        } );
    };

    return {
        load,
        freelancerProfile: data?.freelancer,
        isLoading: loading,
        refetch
    };
}