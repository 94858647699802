import { ColorSchemeEnum, ColorSchemeRepository, ColorSchemeSettingsEnum } from '@/core/dark-mode/colorSchemeRepository';
import { createContext, useEffect, useState } from 'react';
import { antThemeDark, antThemeLight } from '@/assets/theme/ant-design/ant-theme';
import { ConfigProvider } from 'antd';


export const ColorSchemeContext = createContext( ColorSchemeEnum.DARK );

export default function ColorSchemeProvider( { children } ) {
    const darkModeRepository = new ColorSchemeRepository();
    const darkMode$ = darkModeRepository.colorScheme$;

    const [ darkMode, setDarkMode ] = useState<ColorSchemeSettingsEnum>( ColorSchemeSettingsEnum.SYSTEM );
    const [ colorScheme, setColorScheme ] = useState<ColorSchemeEnum>( ColorSchemeEnum.LIGHT );

    useEffect( () => {
        const observable = darkMode$.subscribe( darkMode => {
            setDarkMode( darkMode );
        } );

        return () => observable.unsubscribe();
    } );

    useEffect( () => {
        if( darkMode === ColorSchemeSettingsEnum.SYSTEM ) {
            if( window.matchMedia( '(prefers-color-scheme: dark)' ).matches ) {
                setColorScheme( ColorSchemeEnum.DARK );
            }
            else {
                setColorScheme( ColorSchemeEnum.LIGHT );
            }

            window.matchMedia( '(prefers-color-scheme: dark)' )
                  .addEventListener( 'change', event => {
                      const colorScheme = event.matches ? ColorSchemeEnum.DARK : ColorSchemeEnum.LIGHT;
                      setColorScheme( colorScheme );
                  } );
        }
        else if( darkMode === ColorSchemeSettingsEnum.DARK ) {
            setColorScheme( ColorSchemeEnum.DARK );
        }
        else {
            setColorScheme( ColorSchemeEnum.LIGHT );
        }

    } );

    return (
        <ColorSchemeContext.Provider value={ colorScheme }>
            <div className={ colorScheme }>
                <ConfigProvider
                    theme={ colorScheme == ColorSchemeEnum.LIGHT ? antThemeLight : antThemeDark }>
                    { children }
                </ConfigProvider>
            </div>
        </ColorSchemeContext.Provider>
    );
}