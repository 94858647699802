import ProfilePictureUploader from '@/components/ProfilePictureUploader';
import React, { forwardRef, MutableRefObject, useImperativeHandle, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ProfilePicturePreSignUrl } from '@/pages/client/views/account/client-account-edit-profile/queries/getProfilePicturePresignUrl';
import { SessionRepository } from '@/core/auth/sessionRepository';
import SkeletonAvatar from 'antd/es/skeleton/Avatar';
import * as Sentry from '@sentry/react';
import { SegmentEvent, trackSegmentEvent } from '@/utils/analytics';
import { updateProfilePictureGQL, userProfilePictureGQL } from '@/gql/global-queries';

const FreelancerAccountEditProfilePicture = forwardRef( ( props, ref ) => {
    const fileUploaderRef: MutableRefObject<any> = useRef( null );
    const [ isSaving, setIsSaving ] = useState( false );
    const { t } = useTranslation();
    const sessionRepository = new SessionRepository();
    const [ messageApi, contextHolder ] = message.useMessage();

    const { data, loading, error } = useQuery( userProfilePictureGQL );
    const updateProfilePictureMutation = useMutation( updateProfilePictureGQL );
    const getPreSignedUrl = useLazyQuery( ProfilePicturePreSignUrl );

    useImperativeHandle( ref, () => ( {
            async uploadProfilePicture(): Promise<any> {
                return onSubmit();
            }
        } )
    );

    const uploadProfilePicture = async(): Promise<string> => {
        try {
            const preSignedUrl = await getPreSignedUrl[ 0 ]( {
                variables: {
                    contentType: fileUploaderRef.current.getFile().type
                }
            } );
            return fileUploaderRef.current.upload( preSignedUrl.data.profilePicturePreSignUrl );
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to get preSignUrl', e );
            messageApi.error( t( 'error.default' ) );
        }
    };

    const onSubmit = async(): Promise<void> => {
        setIsSaving( true );
        let profilePictureUrl: string = null;

        if( fileUploaderRef.current.getFile() != null ) {
            try {
                profilePictureUrl = await uploadProfilePicture();
            } catch( e ) {
                Sentry.captureException( 'An error occur when trying to upload profile picture', e );
                setIsSaving( false );
            }
        }


        if( profilePictureUrl ) {
            try {
                const response = await updateProfilePictureMutation[ 0 ]( {
                    variables: {
                        profilePictureUrl
                    }
                } );
                sessionRepository.updateUser( response.data?.updateProfilePicture );
                messageApi.success( t( 'client:account.edit-profile.success' ) );
                trackSegmentEvent( SegmentEvent.ProfileUpdated );
                return response.data?.updateProfilePicture;
            } catch( e ) {
                Sentry.captureException( 'An error occur when trying to update freelancer profile', e );
                messageApi.error( t( 'error.default' ) );
                setIsSaving( false );
            }
            setIsSaving( false );
        }
        return null;
    };

    if( loading ) {
        return <SkeletonAvatar active
                               size={ 100 } />;
    }

    return (
        <ProfilePictureUploader ref={ fileUploaderRef }
                                displayEditButton={ false }
                                onSubmit={ () => onSubmit() }
                                isSaving={ isSaving }
                                defaultProfilePictureUrl={ data?.me?.profilePicture } />
    );
} );

export default FreelancerAccountEditProfilePicture;
