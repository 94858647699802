import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'antd';
import { ChevronLeftFilledIcon, ChevronRightFilledIcon } from '@/assets/icons/CustomIcons';

interface CarouselYoutubeVideosProps {
    videos: string[];
    arrows?: boolean;
    play: boolean;
    height?: string;
    width?: string;
}

export default function CarouselYoutubeVideos( { videos, arrows, play, width, height }: CarouselYoutubeVideosProps ) {
    const [ activeIndex, setActiveIndex ] = useState( 0 );
    const [ _arrows, setArrows ] = useState( arrows );

    useEffect( () => {
        if( arrows == null ) {
            setArrows( false );
        }
        else {
            setArrows( arrows );
        }
    }, [ arrows ] );

    const carouselRef = useRef( null ) as React.MutableRefObject<any>;

    const handleOnChange = ( value: number ) => {
        setActiveIndex( value );
    };

    const next = (e) => {
        e.preventDefault();
        if( carouselRef?.current ) {
            carouselRef.current.next();
        }
    };

    const previous = (e) => {
        e.preventDefault();
        if( carouselRef?.current ) {
            carouselRef.current.prev();
        }
    };

    const noPortfolioJSX = (
        <div className="h-full w-full bg-blue-100"></div>
    );

    const carouselJSX = (
        <>
            <Carousel afterChange={ handleOnChange }
                      ref={ carouselRef }>
                {
                    videos?.map( ( video, index ) => (
                        <div key={ index }>
                            {
                                index === activeIndex ? (
                                    <div className="player-wrapper">
                                        <ReactPlayer url={ video }
                                                     width={ width || '100%' }
                                                     height={ height || '100%' }
                                                     volume={ 0 }
                                                     playing={ play }
                                                     muted={ true }
                                                     playIcon={ <span></span> }
                                                     light={ !play }
                                                     config={ {
                                                         youtube: {
                                                             playerVars: {
                                                                 showinfo: 0,
                                                                 modestbranding: 1,
                                                                 controls: 0
                                                             }
                                                         }
                                                     } }
                                        />
                                        <div className="player-overlay"></div>
                                    </div>
                                ) : <span></span>
                            }
                        </div>
                    ) )
                }
            </Carousel>
            {
                _arrows &&
                <>
                  <div className="absolute left-2 top-[75px] bg-white  flex justify-center items-center p-2 rounded-full"
                       onClick={ (e) => previous(e) }>
                    <ChevronLeftFilledIcon className="text-black" />
                  </div>

                  <div className="absolute right-2 top-[75px] bg-white  flex justify-center items-center p-2 rounded-full"
                       onClick={ (e) => next(e) }>
                    <ChevronRightFilledIcon className="text-black" />
                  </div>
                </>
            }
        </>
    );

    return (
        <div className="relative bg-black"
             style={ { height } }>
            {
                videos?.length > 0 ? carouselJSX : noPortfolioJSX
            }
        </div>
    );
}
