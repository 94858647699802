import { useTranslation } from 'react-i18next';
import { CloseOutlinedIcon } from '@/assets/icons/CustomIcons';
import { App, Button, Divider } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useIntercomHook } from '@/plugins/intercom/useIntercomHook';
import { OrderTypeEnum } from '@/gql/graphql';
import * as Sentry from '@sentry/react';
import { CreateOrderGQL } from '@/gql/global-queries/createOrderGQL';
import { useMutation } from '@apollo/client';

export default function ClientOrderPaymentCancelled() {

    const { t } = useTranslation();

    const location = useLocation();
    const { toggleIntercomVisibility } = useIntercomHook();
    const queryParams = new URLSearchParams(location.search);
    
    const jobPostId = queryParams.get('jobPostId');
    const { message } = App.useApp();

    const createOrderMutation = useMutation( CreateOrderGQL );

    const createOrder = async() => {
        try {
            let args;

            if( jobPostId ) {
                args = { 
                    type: OrderTypeEnum.PREMIUM_JOB_PASS,
                    jobPostId: 'c0d0abcf-9094-49b2-b2e8-25437cef358a'
                }
            } else {
                args = { type: OrderTypeEnum.PREMIUM_ANNUAL_PASS }
            }

            const { data } = await createOrderMutation[0]({ variables: { args } });            

            if( data?.createOrder?.stripePaymentUrl ) {
                window.location.href = data.createOrder.stripePaymentUrl;
            }
        } catch( err ) {
            Sentry.captureException( 'An error occur when trying to create url', err );
            message.error( t( 'common:form.submitError' ) );
        }
    };
 
    return (
        <div className='flex flex-col gap-6 lg:w-2/5 w-full self-center'>
            <div className="flex flex-col items-center justify-center gap-6">
                <div className='w-12 h-12 flex justify-center items-center p-3 rounded-full border-2 border-red'>
                    <CloseOutlinedIcon className='text-red text-lg' />
                </div>
                <h1>{ t( 'client:order.orderCancelled.title' ) }</h1>
            </div>
            <Divider className="my-0" />
            <div className='flex flex-col gap-2'>
                <span className='text-[#71767B] text-base'>
                    { t( 'client:order.orderCancelled.retryMessage' ) }
                </span>
                <Button type='primary' className='w-fit' onClick={ createOrder }>{ t( 'client:order.orderCancelled.retryButton' ) }</Button>
            </div>
            <span className='text-[#71767B] text-base'>
                { t( 'client:order.orderCancelled.goBack' ) }
            </span>
            <div className="flex flex-col lg:flex-row gap-4">
                <Button onClick={ () => toggleIntercomVisibility() } type="default" className="flex gap-1 items-center w-fit">
                    {t('client:order.orderCancelled.supportButton')}
                </Button>
                <Link to={ '/client/job-posts/status/ongoing' } type="default" >
                    <Button type="default" className="flex gap-1 items-center w-fit" >
                        { t( 'client:order.orderCancelled.goToJobPostsButton' ) }
                    </Button>
                </Link>
            </div>
        </div>
    );
}