import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import MenuSection, { AccountNavigationItem } from '@/components/MenuSection';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';

export default function ClientAccount() {
    const { t } = useTranslation();

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: false
    } );

    const navigationSections: AccountNavigationItem[] = [
        {
            title: t( 'client:account.navigation.editProfile' ),
            route: '/client/settings/edit-profile'
        },
        {
            title: t( 'client:account.navigation.myYTChannels' ),
            route: '/client/settings/yt-channels'
        },
        {
            title: t( 'client:account.navigation.editPassword' ),
            route: '/client/settings/edit-password'
        },
        {
            title: t( 'client:account.navigation.plansAndBilling' ),
            route: '/client/settings/plans-billing'
        },
    ];

    return (
        <div className="w-full h-full">
            <div className="hidden lg:block text-2xl font-medium font-sharp mb-8">
                { t( 'client:account.navigation.myAccount' ) }
            </div>
            <div className="flex flex-col lg:flex-row">
                <MenuSection routes={ navigationSections }
                             className="flex-none lg:w-[250px]" />

                <div className="mt-6 lg:mt-0 w-full lg:max-w-[977px] h-full lg:ml-[96px] lg:p-6">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

