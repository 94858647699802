export function cuttingTextFormatter( value: string, length: number ): string {
    let maxLength = 18;

    if( length != null ) {
        maxLength = length;
    }

    if( value?.length > maxLength ) {
        return `${ value.substring( 0, maxLength ) }...`;
    }
    else {
        return value;
    }
}