import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeftFilledIcon } from '@/assets/icons/CustomIcons';
import { twMerge } from 'tailwind-merge';

interface BackButtonProps {
    to?: string;
    onClick?: () => void;
    className?: string;
    icon?:  JSX.Element;
}

export default function BackButton( { to, onClick, icon, className }: BackButtonProps ) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleBack = () => {
        if( to ) {
            navigate( to );
        }
        else {
            onClick();
        }
    };

    return (
        <>
            <div onClick={ () => handleBack() }
                 className={ twMerge( 'cursor-pointer', className ) }>
                <ArrowLeftFilledIcon className="lg:hidden text-[24px]" />
            </div>
            <Button type="text"
                    className={ twMerge( 'hidden lg:inline-block text-grey ', className ) }
                    icon={ icon }
                    onClick={ () => handleBack() }>
                <span className="ml-1">{ t( 'common:button.back' ) }</span>
            </Button>
        </>
    );
}