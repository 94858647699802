import { useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { JobPostFormRepository } from '@/pages/client/views/job-post/job-post-form/jobPostFormRepository';
import JobPostFormStepJobInfo from '@/pages/client/views/job-post/job-post-form/job-post-form-steps/JobPostFormStepJobInfo';
import JobPostFormStepYoutubeChannel from '@/pages/client/views/job-post/job-post-form/job-post-form-steps/JobPostFormStepYoutubeChannel';
import JobPostFormStepReview from '@/pages/client/views/job-post/job-post-form/job-post-form-steps/JobPostFormStepReview';
import JobPostFormAdditionalInfo from '@/pages/client/views/job-post/job-post-form/job-post-form-steps/JobPostFormAdditionalInfo';
import channelManagerSettingsHook from './channelManagerSettingsHook';
import { createJobPostGQL } from '@/gql/global-queries';

enum ProgressBarItemEnum {
    REQUIRED_INFO = 'REQUIRED_INFO',
    OPTIONAL_INFO = 'OPTIONAL_INFO',
    YOUTUBE_CHANNEL_PAIRING = 'YOUTUBE_CHANNEL_PAIRING',
    REVIEW = 'REVIEW'
}

export default function jobPostFormHook() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const jobPostFormRepository: JobPostFormRepository = new JobPostFormRepository();
    const [ createJobPost, { data, loading: isLoadingMutation } ] = useMutation( createJobPostGQL );

    const { channels } = channelManagerSettingsHook();

    const [ isLoadingNextStep, setIsLoadingNextStep ] = useState( false );

    const [ currentStep, setCurrentStep ] = useState( 0 );

    const isLoading: boolean = isLoadingNextStep || isLoadingMutation;
    
    const refStep1 = useRef( null );
    const refStep2 = useRef( null );
    const refStep3 = useRef( null );

    const progressBar = [
        {
            id: ProgressBarItemEnum.REQUIRED_INFO,
            label: t( 'client:jobPost.form.steps.requiredInfo' )
        },
        {
            id: ProgressBarItemEnum.OPTIONAL_INFO,
            label: t( 'client:jobPost.form.steps.optionalInfo' )
        },
        {
            id: ProgressBarItemEnum.YOUTUBE_CHANNEL_PAIRING,
            label: t( 'client:jobPost.form.steps.youtubeChannelPairing' )
        },
        {
            id: ProgressBarItemEnum.REVIEW,
            label: t( 'client:jobPost.form.steps.review' )
        }
    ];

    useEffect( () => {
        const subscription = jobPostFormRepository.isLoadingNextStep$.subscribe( ( isLoading ) => {
            setIsLoadingNextStep( isLoading );
        } );

        return () => {
            jobPostFormRepository.clearStore();
            subscription.unsubscribe()
        };
    }, [] );

    useEffect( () => {
        if( channels?.length ) {
            jobPostFormRepository.channelId = channels[0]?.id;
        }
    }, [ channels ] );

    useEffect( () => {
        const observable = jobPostFormRepository.step$.subscribe( ( step ) => {
            if( step != currentStep ) {
                setCurrentStep( step );
            }
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [ currentStep ] );

    useEffect( () => {
        if( channels.length ) {
            jobPostFormRepository.channelId = channels[0].id
        }
    }, [ channels ] );

    const nextStep = async() => {        
        switch( currentStep ) {
        case 0:
            const step1IsValid = await refStep1?.current?.validateFields();
            if( step1IsValid ) {
                jobPostFormRepository.nextStep();
            } else {
                message.warning( t( 'client:jobPost.form.fieldRequired' ) );
            }
            break;
        case 1:
            const step2IsValid = await refStep2?.current?.validateFields();
            if( step2IsValid ) {
                jobPostFormRepository.nextStep();
            }
            break;
        case 2:
            const step3IsValid = await refStep3?.current?.validateFields();
            if( step3IsValid ) {
                jobPostFormRepository.nextStep();
            } else {
                message.warning( t( 'client:jobPost.form.youTubeChannelRequired' ) );
            }
            break;
        case 3:
            createNewJobPost();
            break;
        default:
            return;
        }
    };

    const previousStep = () => {
        jobPostFormRepository.previousStep();
    };

    const createNewJobPost = async() => {
        try {
            const args = {
                channelId: jobPostFormRepository.channelId,
                description: jobPostFormRepository.description,
                name: jobPostFormRepository.title,
                expertises: jobPostFormRepository.expertiseIds,
                experiences: jobPostFormRepository.experiences,
                type: jobPostFormRepository.type,
                formatType: jobPostFormRepository.contentType,
                languages: jobPostFormRepository.languageIds,
                priceMin: parseInt( jobPostFormRepository.budgetMin as string ),
                priceMax: parseInt( jobPostFormRepository.budgetMax as string ),
                skills: jobPostFormRepository.skillIds,
                role: jobPostFormRepository.role,
                youtubeInspirationVideos: jobPostFormRepository.portfolioVideos.map(video => video.id)
            };

            const response = await createJobPost( {
                variables: {
                    args
                }
            } );

            if( response?.data ) {
                message.success( t( 'client:jobPost.form.success.createSuccess' ));
                jobPostFormRepository.clearStore();
                navigate( '/client/job-posts/status/ongoing', { replace: true } );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to create a new freelancer account', e );
            message.error( {
                content: t( 'client:jobPost.form.errors.submitError' ),
                duration: 4
            } );
        }
    };

    const items = [
        {
            progressBarId: ProgressBarItemEnum.REQUIRED_INFO,
            titleRight: t( 'client:jobPost.form.jobMainInfoPage.title' ),
            subtitle: t( 'client:jobPost.form.jobMainInfoPage.subtitle' ),
            content: <JobPostFormStepJobInfo ref={ refStep1 } />
        },
        {
            progressBarId: ProgressBarItemEnum.OPTIONAL_INFO,
            titleRight: t( 'client:jobPost.form.jobAdditionalInfoPage.title' ),
            subtitle: t( 'client:jobPost.form.jobAdditionalInfoPage.subtitle' ),
            content: <JobPostFormAdditionalInfo ref={ refStep2 } />
        },
        {
            progressBarId: ProgressBarItemEnum.YOUTUBE_CHANNEL_PAIRING,
            titleRight: t( 'client:jobPost.form.channelPage.pageTitle' ),
            subtitle: t( 'client:jobPost.form.channelPage.pageSubtitle' ),
            content: <JobPostFormStepYoutubeChannel ref={ refStep3 } />
        },
        {
            progressBarId: ProgressBarItemEnum.REVIEW,
            titleRight: t( 'client:jobPost.form.reviewPage.pageTitle' ),
            subtitle: t( 'client:jobPost.form.reviewPage.pageSubtitle' ),
            content: <JobPostFormStepReview />
        },
    ];

    return {
        items,
        currentStep,
        totalStep: items.length,
        nextStep,
        previousStep,
        isLoading,
        progressBar,
        setCurrentStep: jobPostFormRepository.updateStep
    };
}