import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { JobPostFormRepository } from '@/pages/client/views/job-post/job-post-form/jobPostFormRepository';
import { Divider } from 'antd';
import TextAreaComponent from '@/components/custom-element-form/TextAreaComponent';
import MultiSelectComponent from '@/components/custom-element-form/MultiSelectComponent';
import JobPostFormReference from '../component/JobPostFormReference';
import JobPostFormBudget from '../component/JobPostFormBudget';
import { ExpertiseEnum, SkillEnum } from '@/gql/graphql';


const JobPostFormAdditionalInfo = forwardRef( function( props, ref ) {
    const { t } = useTranslation();
    const { control, watch, getValues, setValue, trigger, formState: { errors } } = useForm();
    const repository = new JobPostFormRepository();

    const subscription = watch( ( value ) => {
        repository.updateData( value );
    } );

    useImperativeHandle( ref, () => ( {
        async validateFields(): Promise<boolean> {
            return trigger();
        }
    } ) );
    
    useEffect( () => {
        return () => subscription.unsubscribe();
    }, [] );

    return (
        <form className="flex w-full flex-col gap-y-4">
            <JobPostFormBudget errors={ errors } control={ control } getValues={ getValues } />
                
            <Divider type="horizontal"
                className="my-2 lg:my-6" />

            <div className='flex flex-col'>
                <div className='flex items-center gap-1.5'>
                    <span className='font-semibold text-lg'>{t('client:jobPost.form.jobAdditionalInfoPage.genre.title')}</span>
                    <div className="rounded-3xl px-2 py-1 border-primary-100 border text-grey text-xs">
                        { t( 'client:jobPost.form.optional' ) }
                    </div>
                </div>
                <span className='block pb-2 text-grey'>{t('client:jobPost.form.jobAdditionalInfoPage.genre.subtitle')}</span>
                <MultiSelectComponent options={ Object.values( ExpertiseEnum ).map( ( expertise ) => ( {
                    value: expertise,
                    label: t( 'translation:enum.expertises.' + expertise )
                } ) ) }
                control={ control }
                name="expertiseIds"
                className="w-full"
                defaultValue={ repository.expertiseIds }
                errors={ errors }
                filterOption={ ( input, option ) =>
                    ( option?.label ?? '' ).toLowerCase().includes( input.toLowerCase() )
                }
                />
            </div>

            <Divider type="horizontal"
                className="my-2 lg:my-6" />

            <div className='flex flex-col'>
                <div className='flex items-center gap-1.5'>
                    <span className='font-semibold text-lg'>{t('client:jobPost.form.jobAdditionalInfoPage.skills.title')}</span>
                    <div className="rounded-3xl px-2 py-1 border-primary-100 border text-grey text-xs">
                        { t( 'client:jobPost.form.optional' ) }
                    </div>
                </div>
                <span className='block pb-2 text-grey'>{t('client:jobPost.form.jobAdditionalInfoPage.skills.subtitle')}</span>
                <MultiSelectComponent options={ Object.values( SkillEnum ).map( ( skill ) => ( {
                    value: skill,
                    label: t( 'translation:enum.skills.' + skill )
                } ) ) }
                control={ control }
                name="skillIds"
                className="w-full"
                defaultValue={ repository.skillIds }
                errors={ errors }
                filterOption={ ( input, option ) =>
                    ( option?.label ?? '' ).toLowerCase().includes( input.toLowerCase() )
                }
                />
            </div>

            <Divider type="horizontal"
                className="my-2 lg:my-6" />

            <JobPostFormReference />
        </form>
    );
} );

export default JobPostFormAdditionalInfo;