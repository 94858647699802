import { PortfolioElement, PortfolioElementTypeEnum } from '@/gql/graphql';
import ReactPlayer from 'react-player';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { Button } from 'antd';
import { CloseOutlinedIcon } from '@/assets/icons/CustomIcons';
import handleResizeHook from '@/hooks/handleResizeHook';

interface FreelancerProfilePortfolioListItemProps {
    portfolioElement: PortfolioElement;
    onRemove?: (string) => void;
}

export default function FreelancerProfilePortfolioListItem( { portfolioElement, onRemove }: FreelancerProfilePortfolioListItemProps ) {

    const { t } = useTranslation();
    const containerRef = useRef( null );
    const playerHeight = handleResizeHook( { ref: containerRef } );

    return (
        <div className="relative"
             ref={ containerRef }>
            {
                onRemove && 
                <Button type="default"
                        onClick={() => onRemove(portfolioElement.id)}
                        className="absolute z-[1] -right-4 -top-4 flex justify-center items-center border-[#E9D7FE] border-2 bg-primary-100 p-0 w-8 h-8 rounded-full">
                    <CloseOutlinedIcon className='text-primary-500 text-[10px] px-' />
                </Button>
            }
            <div className="w-full rounded-2xl overflow-hidden relative">
                {
                    portfolioElement.type == PortfolioElementTypeEnum.VIDEO &&
                    <ReactPlayer url={ portfolioElement.url }
                                 muted={ true }
                                 volume={ 1 }
                                 width="100%"
                                 height={ playerHeight.toString() + 'px' }
                                 light={ true }
                                 playing={ true } />
                }
                {
                    portfolioElement.type == PortfolioElementTypeEnum.IMAGE &&
                    <img src={ portfolioElement.url }
                         className="object-cover w-full h-full"
                         alt="portfolio element" />
                }
            </div>
            <div className="text-sm font-normal truncate mt-2">
                { portfolioElement.title }
            </div>

            <div className="text-xs text-grey flex gap-1">
                <span className="truncate">
                    { portfolioElement.portfolioChannel?.name }
                </span>
                {

                    portfolioElement.type == PortfolioElementTypeEnum.VIDEO && portfolioElement?.viewCount >= 0  ?
                    <span className="flex-none">
                        { `• ${ abbreviationNumberFormatter( portfolioElement.viewCount ) } ${ t( 'common:freelancerProfile.card.views', { count: portfolioElement?.viewCount } ) }` }
                    </span> : ''
                }
            </div>
        </div>
    );
}