import { forwardRef } from 'react';
import SelectComponent from '@/components/custom-element-form/SelectComponent';
import countries from '@/constants/countries';
import getFlagEmoji from '@/utils/flagEmoji';
import { useTranslation } from 'react-i18next';
import { SelectProps } from 'antd';

interface SelectCountryProps {
    control: any;
    errors: any;
    controlName: string;
    label?: string;
    placeholder?: string;
    className?: string;
}

const SelectCountry = forwardRef( ( props: SelectCountryProps, ref ) => {

    const { t } = useTranslation();

    const { control, errors, controlName, label, placeholder, className } = props;

    const filterOption = ( inputValue, option ) => {
        return option?.label.toLowerCase().includes( inputValue.toLowerCase() );
    };

    const getSelectOptions = (): SelectProps['options'] => {
        return countries?.map( ( { value, label } ) => {
            return {
                value,
                label: `${ getFlagEmoji( value ) } ${ t( label ) }`
            };
        } ).sort( ( a, b ) => a.label.localeCompare( b.label ) );
    };

    const selectOptions = getSelectOptions();

    return (
        <SelectComponent options={ selectOptions }
                         control={ control }
                         filterOption={ filterOption }
                         name={ controlName }
                         className={ className }
                         label={ label }
                         rules={ {
                             required: t( 'common:error.form.required' )
                         } }
                         placeholder={ placeholder }
                         errors={ errors } />
    );
} );

export default SelectCountry;