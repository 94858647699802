import { createStore, select, withProps } from '@ngneat/elf';

interface GettingStartedState {
  steps: {
    discoverOutreachedProfiles: boolean | null;
    reviewInterestedTalents: boolean | null;
  };
  completed: boolean;
}

const initialState: GettingStartedState = {
    steps: {
        discoverOutreachedProfiles: null,
        reviewInterestedTalents: null,
    },
    completed: false,
};

const gettingStartedStore = createStore(
    { name: 'gettingStarted' },
    withProps<GettingStartedState>(initialState)
);

export class GettingStartedRepository {
  steps$ = gettingStartedStore.pipe(select((state) => state.steps));
  completed$ = gettingStartedStore.pipe(select((state) => state.completed));

  get steps() {
      return gettingStartedStore.getValue().steps;
  }

  set steps(steps) {
      gettingStartedStore.update((state) => ({
          ...state,
          steps,
      }));
  }

  get completed() {
      return gettingStartedStore.getValue().completed;
  }

  set completed(completed) {
      gettingStartedStore.update((state) => ({
          ...state,
          completed,
      }));
  }
}
