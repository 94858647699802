import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect } from 'react';
import { excludeGraphQLFetch } from 'apollo-link-sentry';

if( import.meta.env.VITE_ENV != 'development' ) {
    Sentry.init( {
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_ENV,
        release: import.meta.env.VITE_CI_COMMIT_TAG || null,
        integrations: [
            new Sentry.BrowserProfilingIntegration(),
            new Sentry.BrowserTracing( {
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                )
            } ),
            new Sentry.Replay( {
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true
            } )
        ],
        tracePropagationTargets: [
            'localhost',
            'https://creativejobs-api.jellysmack.com',
            'https://creativejobs-api-preprod.jellysmack.com'
        ],
        beforeBreadcrumb: excludeGraphQLFetch,
        // Performance Monitoring
        tracesSampleRate: 3.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0
    } );
}