import { MutableRefObject, useEffect, useState } from 'react';

interface ResizeHookProps {
    ref: MutableRefObject<any>;
}

export default function handleResizeHook( { ref }: ResizeHookProps ) {
    const [ height, setHeight ] = useState( 0 );

    useEffect( () => {
        function handleResize() {
            if( ref.current ) {
                const width = ref.current.offsetWidth; //get width of the container
                setHeight( ( 9 / 16 ) * width ); //set height based on 16:9 aspect ratio
            }
        }

        handleResize();
        window.addEventListener( 'resize', handleResize );
        return () => window.removeEventListener( 'resize', handleResize );
    }, [] );

    return height;
}