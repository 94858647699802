import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { PlusFilledIcon, ProjectFolderOutlinedIcon } from '@/assets/icons/CustomIcons';

export default function ClientNavBarTouch() {

    const { t } = useTranslation();

    const navs = [
        {
            to: '/client/job-posts',
            text: t( 'client:header.touch.jobPosts' ),
            icon: <ProjectFolderOutlinedIcon className="text-[22px]" />
        },
        {
            to: '/client/create-job-post',
            text: t( 'client:header.touch.newJobPost' ),
            icon: <PlusFilledIcon className="text-[22px]" />
        }
    ];

    return (
        <div className="flex-none w-full h-[60px] fixed bottom-0 left-0 right-0 bg-white z-50 flex items-center justify-around border-t border-grey-light">
            {
                navs.map( ( nav, index ) => (
                    <NavLink to={ nav.to }
                             key={ index }
                             className={ ( { isActive, isPending } ) => {
                                 const state = isPending ? 'pending' : isActive ? 'text-primary-400' : '';
                                 return twMerge( 'w-[105px] h-[52px] flex flex-col items-center justify-center flex-none text-grey gap-y-1', state );
                             } }>
                        {
                            ( { isActive, isPending } ) => (
                                <div className="flex flex-col items-center w-fit">
                                    { nav.icon }
                                    <span className="mt-px text-xs uppercase text-[9px] font-semibold">{ nav.text }</span>
                                </div>
                            )
                        }
                    </NavLink>
                ) )
            }
        </div>
    );
}