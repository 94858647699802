import { gql } from '@apollo/client';

const updatePortfolioChannelsOrderGQL = gql`
    mutation UpdatePortfolioChannelsOrderGQL($args: [UpdateOrderPortfolioChannelInput!]!) {
        updatePortfolioChannelsOrder(args: $args) {
            id
        }
    }
`;

export default updatePortfolioChannelsOrderGQL;