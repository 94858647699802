import { gql } from '@apollo/client';

const userProfilePictureGQL = gql`
    query me {
        me {
            id
            profilePicture
        }
    }`;

export default userProfilePictureGQL;