import { Button, Dropdown } from 'antd';
import { SortFilledIcon } from '@/assets/icons/CustomIcons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

type SortByProps = {
    items: any[];
    selectedItem: any | null;
    onItemSelect: ( string ) => void;
    className?: string;
};

export default function SortBy( {
                                    items,
                                    selectedItem,
                                    onItemSelect,
                                    className
                                }: SortByProps ) {

    const { t } = useTranslation( 'translation' );

    const [ itemLabel, setItemLabel ] = useState( null );

    if( !items || items.length === 0 ) {
        return null;
    }

    useEffect( () => {
        if( selectedItem ) {
            const selectedItemLabel = items.find( ( item ) => item?.key === selectedItem )?.label;
            setItemLabel( selectedItemLabel );
        }
    }, [ selectedItem ] );

    return (
        <Dropdown className={ className }
                  menu={ {
                      items,
                      selectable: true,
                      onClick: ( value ) => {
                          onItemSelect( value );
                      }
                  } }>
            <Button type="text"
                    className="text-grey font-semibold"
                    icon={ <SortFilledIcon className="text-lg" /> }>
                <span>{ selectedItem ? t( selectedItem.label || itemLabel ) : t( 'common:sort.title' ) }</span>
            </Button>
        </Dropdown>
    );
};

