import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './i18n/en/translation.json';
import enClient from './i18n/en/client.json';
import enFreelancer from './i18n/en/freelancer.json';
import enCommon from './i18n/en/common.json';
import enAuth from './i18n/en/auth.json';
import enTransverse from './i18n/en/transverse.json';

i18n.use( initReactI18next )
    .use( LanguageDetector )
    .init( {
        resources: {
            en: {
                translation: enTranslation,
                client: enClient,
                freelancer: enFreelancer,
                common: enCommon,
                auth: enAuth,
                transverse: enTransverse
            }
        },
        lng: 'en',
        fallbackLng: 'en',
        supportedLngs: [ 'en' ],
        debug: import.meta.env.DEV
    } );

export default i18n;