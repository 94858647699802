import { gql } from '@apollo/client';

const createPortfolioElementGQL = gql`
    mutation CreatePortfolioElementGQL($url: String!) {
        createPortfolioElement(url: $url) {
            portfolioElements {
                id
                type
                title
                thumbnailUrl
                viewCount
                portfolioChannel {
                    id
                    name
                    subscriberCount
                    viewCount
                    videoCount
                }
            }
            failed 
        }
    }
`;

export default createPortfolioElementGQL;