import FreelancerProfileLoaderSelectedPart from '@/components/freelance-profile/components/loader/FreelancerProfileLoaderSelectedPart';
import FreelancerProfilePortfolioList from '@/components/freelance-profile/components/FreelancerProfilePortfolioList';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlinedIcon } from '@/assets/icons/CustomIcons';
import { useTranslation } from 'react-i18next';

interface FreelancerPortfolioSelectedSectionProps {
    portfolioElement: any;
    isLoading: boolean;
    editable?: boolean;
}

export default function FreelancerPortfolioSelectedSection( {
                                                                portfolioElement,
                                                                isLoading,
                                                                editable
                                                            }: FreelancerPortfolioSelectedSectionProps ) {

    const { t } = useTranslation();

    if( isLoading ) {
        return <FreelancerProfileLoaderSelectedPart />;
    }

    if( portfolioElement === undefined || portfolioElement.length === 0 ) {
        if( editable ) {
            return (
                <Link to="/freelancer/profile/edit-portfolio">
                    <div className="w-full md:w-[390px] h-[219px] flex flex-col items-center justify-center rounded-2xl bg-[#F9F9FB] cursor-pointer">
                        <Button type="default"
                                shape="circle"
                                className="flex-none border-primary-200 bg-[#F9F9FB]"
                                icon={
                                    <PlusOutlinedIcon className="text-xl text-primary-400" /> } />
                        <span className="text-primary-500 mt-4 font-semibold	">
                                        { t( 'freelancer:account.edit-portfolio.addVideo' ) }
                                    </span>
                    </div>
                </Link>
            );
        }
        else {
            return (
                <div className="w-full md:w-[390px] h-[219px] flex flex-col items-center justify-center rounded-2xl bg-[#F9F9FB]">
                    <span>{ t( 'freelancer:account.edit-portfolio.noVideo' ) }</span>
                </div>
            );
        }
    }

    return (
        <section>
            <h2>{ t( 'common:freelancerProfile.card.portfolioTitle' ) }</h2>
            <div className="mt-4">
                { <FreelancerProfilePortfolioList portfolio={ portfolioElement } /> }
            </div>
        </section>
    );
}