import { Navigate } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { UserTypeEnum } from '@/gql/graphql';

interface RoleGuardProps {
    allowedUserType: UserTypeEnum;
    children: ReactNode;
    redirectPath: string;
}

export const RoleGuard = ( { allowedUserType, children, redirectPath }: RoleGuardProps ) => {
    const sessionRepository = new SessionRepository();
    const [ userType, setUserType ] = useState<UserTypeEnum>( null );

    useEffect( () => {

        const observable = sessionRepository.userType$.subscribe( ( userType: UserTypeEnum ) => {
            setUserType( () => userType );
        } );

        return () => {
            observable.unsubscribe();
        };
    }, [] );

    return (
        <>
            {
                userType != null && ( userType == allowedUserType ? children : <Navigate to={ redirectPath } /> )
            }
        </>
    );
};
