import React from 'react';
import { PortfolioChannel } from '@/gql/graphql';
import { useTranslation } from 'react-i18next';
import FreelancerProfileEditPortfolioChannelItem from '@/pages/freelancer/views/profile/profile-edit-portfolio/components/FreelancerProfileEditPortfolioChannelListItem';
import ScrollableContainer from '@/components/scrollable-container/ScrollableContainer';
import SkeletonAvatar from 'antd/es/skeleton/Avatar';
import { twMerge } from 'tailwind-merge';

interface FreelancerAccountEditOrderedListProps {
    portfolioChannels: PortfolioChannel[];
    isLoading?: boolean;
    className?: string;
}

export default function FreelancerProfileEditPortfolioChannelOrderedList( {
                                                                              portfolioChannels,
                                                                              isLoading,
                                                                              className
                                                                          }: FreelancerAccountEditOrderedListProps ) {

    const { t } = useTranslation();

    if( isLoading ) {
        return (
            <div className={ twMerge( 'flex gap-x-6', className ) }>
                {
                    [ ...Array( 5 ).keys() ].map( ( index ) => {
                        return <SkeletonAvatar active={ true }
                                               style={ { height: '64px', width: '64px' } }
                                               key={ index } />;
                    } )
                }

            </div>
        );
    }

    if( portfolioChannels?.length > 0 ) {
        return (
            <ScrollableContainer className={ twMerge( 'gap-x-4', className ) }>
                {
                    portfolioChannels.map( ( item, index ) => (
                        <FreelancerProfileEditPortfolioChannelItem portfolioChannel={ item }
                                                                   key={ index } />
                    ) )
                }
            </ScrollableContainer>
        );
    }

    return <p className="text-sm font-normal text-grey">
        { t( 'freelancer:account.edit-portfolio.noDataCreators' ) }
    </p>
}
