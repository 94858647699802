import { gql } from '@apollo/client';

const updateProfilePictureGQL = gql`
    mutation UpdateProfilePictureGQL($profilePictureUrl: String!) {
        updateProfilePicture(profilePictureUrl: $profilePictureUrl) {
            id
            type
            profilePicture
        }
    }`;

export default updateProfilePictureGQL;