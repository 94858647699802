import BackButton from '@/components/BackButton';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import AnimationCards from '@/components/AnimationCards';

interface SignUpLayoutProps {
    rightSide: JSX.Element;
    hideNextButton?: boolean;
    onNext?: () => void;
    onBack?: () => void;
    loadingNext?: boolean;
    isLastStep?: boolean;
}

export default function SignUpLayout( {
                                          rightSide,
                                          onNext,
                                          onBack,
                                          loadingNext,
                                          hideNextButton = false
                                      }: SignUpLayoutProps ) {

    const navigate = useNavigate();

    const handleNext = () => onNext?.();

    const handleBack = () => {
        if( onBack ) {
            onBack();
        }
        else {
            navigate( -1 );
        }
    };

    const { t } = useTranslation();

    return (
        <div className="main-container w-full max-h-screen flex justify-center">
            <div className="w-full lg:w-[600px] py-16 flex flex-col justify-between">
                <div className="w-full lg:hidden">
                    <BackButton onClick={ () => handleBack() } />
                </div>
                <div className="flex-grow pt-6 lg:pt-[10%]">
                    { rightSide }
                </div>
                <div className="w-full flex justify-between mt-8">
                    <Button type="primary"
                            loading={ loadingNext }
                            disabled={ loadingNext }
                            onClick={ () => handleNext() }
                            className={ twMerge( 'w-full', hideNextButton ? 'hidden' : '' ) }>
                        { t( 'common:button.next' ) }
                    </Button>
                </div>
            </div>
        </div>
    );
}