import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import ResponsiveModal from '@/components/ResponsiveModal';
import { useForm } from 'react-hook-form';
import { Form } from 'react-router-dom';
import TextAreaComponent from '../custom-element-form/TextAreaComponent';


interface ApplyJobPostModalProps {
    onJobPostApply?: ( message: string ) => void;
    isLoading: boolean;
}

const ApplyJobPostModal = forwardRef( ( props: ApplyJobPostModalProps, ref ) => {
    const { t } = useTranslation();
    const { onJobPostApply, isLoading } = props;
    const modalRef = useRef( null );

    useImperativeHandle( ref, () => ( {
        open() {
            modalRef.current.open();
        },
        close() {
            modalRef.current.close();
        }
    } ) );

    const { control, handleSubmit } = useForm();

    const handleConfirm = async( data: { message: string } ) => {
        onJobPostApply( data.message );
    };

    return (
        <ResponsiveModal ref={ modalRef }
                         rootClassName="custom-modal"
                         mobileHeight="h-fit"
                         destroyOnClose={ true }
                         title={
                            <div className="flex flex-col md:px-6 md:pt-5">
                                <span className="font-semibold text-lg">{ t( 'freelancer:jobPost.applyModal.title' ) }</span>
                            </div>
                         }
                         desktopWidth="454px">
            <>
            <Divider className="mt-0 mb-4" />
            <div className="flex gap-28 md:px-6 md:pb-6">
                <div>
                    <div className="flex flex-col gap-6 mb-2">
                        <span className="text-grey">
                            { t( 'freelancer:jobPost.applyModal.description' ) }
                        </span>
                    </div>

                    <Form className="flex flex-col gap-2 w-full"
                          onSubmit={ handleSubmit( handleConfirm ) }>
                        <TextAreaComponent control={ control }
                                           rows={ 3 }
                                           className="bg-white flex-1"
                                           name="message"
                                           placeholder={ t( 'freelancer:jobPost.applyModal.sendAMessagePlaceholder' ) } />

                        <Button loading={ isLoading }
                                htmlType="submit"
                                type="primary"
                                className="w-full text-sm mt-4">
                            { t( 'common:button.confirm' ) }
                        </Button>
                    </Form>
                </div>
            </div>
            </>
        </ResponsiveModal>
    );
} );

export default ApplyJobPostModal;
