import { gql } from '@apollo/client';

export const activeSubscriptionOrderGQL = gql`
    query ActiveSubscriptionOrder {
        activeSubscriptionOrder  {
            id
            isActive
            isSubscription
            startAt
        }
    }`;
