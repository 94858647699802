import { gql } from '@apollo/client';

const deletePortfolioElementGQL = gql`
    mutation DeletePortfolioElementGQL($id: String!) {
        deletePortfolioElement(id: $id) {
            id
        }
    }
`;

export default deletePortfolioElementGQL;