import { AuthGuard } from '@/core/auth/AuthGuard';
import { RoleGuard } from '@/core/auth/RoleGuard';
import { Navigate } from 'react-router-dom';
import { UserTypeEnum } from '@/gql/graphql';
import FreelancerProfileEditProfile from '@/pages/freelancer/views/account/freelancer-account-edit-profile/FreelancerProfileEditProfile';
import FreelancerAccountConnectedAccount from '@/pages/freelancer/views/account/freelancer-account-edit-connected-account/FreelancerAccountConnectedAccount';
import FreelancerAccountEditPassword from '@/pages/freelancer/views/account/freelancer-account-edit-password/FreelancerAccountEditPassword';
import ErrorBoundary from '@/pages/errors/ErrorBoundary';
import FreelancerRoot from '@/pages/freelancer/views/FreelancerRoot';
import FreelancerSettings from '@/pages/freelancer/views/account/FreelancerSettings';
import FreelancerJobPosts from '@/pages/freelancer/views/job-posts/FreelancerJobPosts';
import FreelancerJobPostDetail from '@/pages/freelancer/views/job-post/FreelancerJobPostDetail';
import FreelancerProfileEditPortfolio from '@/pages/freelancer/views/profile/profile-edit-portfolio/FreelancerProfileEditPortfolio';
import FreelancerFreelancerProfile from '@/pages/freelancer/views/profile/FreelancerFreelancerProfile';
import FreelancerGettingStarted from '@/pages/freelancer/views/getting-started/FreelancerGettingStarted';

export const FreelancerRouting = {
    path: 'freelancer',
    errorElement: <ErrorBoundary />,
    element:
        <AuthGuard redirectPath="/">
            <RoleGuard allowedUserType={ UserTypeEnum.FREELANCER }
                       redirectPath="/client">
                <FreelancerRoot />
            </RoleGuard>
        </AuthGuard>,
    children: [
        {
            path: '',
            element: <Navigate to="/freelancer/job-posts"
                               replace={ true } />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'profile',
            children: [
                {
                   path: '',
                    element: <FreelancerFreelancerProfile />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-portfolio',
                    element: <FreelancerProfileEditPortfolio />,
                    errorElement: <ErrorBoundary />
                }
            ]
        },
        {
            path: 'getting-started',
            element: <FreelancerGettingStarted />,
        },
        {
            path: 'job-posts',
            children: [
                {
                    path: '',
                    element: <Navigate to={ '/freelancer/job-posts/all' } replace />
                },
                {
                    path: ':status',
                    element: <FreelancerJobPosts />
                },
                {
                    path: ':status/:jobPostId',
                    element: <FreelancerJobPostDetail />
                }
            ]
        },
        {
            path: 'job-post',
            children: [
                {
                    path: '',
                    element: <Navigate to={ '/freelancer/job-posts/all' }
                                       replace />
                },
                {
                    path: ':jobPostId',
                    element: <FreelancerJobPostDetail />
                }
            ]
        },
        {
            path: 'settings',
            element: <FreelancerSettings />,
            children: [
                {
                    path: '',
                    element: <Navigate to="/freelancer/settings/edit-profile" />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-profile',
                    element: <FreelancerProfileEditProfile />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'connected-account',
                    element: <FreelancerAccountConnectedAccount />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-password',
                    element: <FreelancerAccountEditPassword />,
                    errorElement: <ErrorBoundary />
                }
            ]
        }
    ]
};