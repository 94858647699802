interface SignUpOption {
    data: {
        name: string;
        description: string;
        icon: JSX.Element;
    };
}

export default function SignUpOptions( { data }: SignUpOption ) {

    const { name, description, icon } = data;

    return (
        <div className="group flex items-center justify-between gap-x-6 rounded-[10px] h-[117px] w-full px-6 border border-grey-light hover:border-primary-500 hover:bg-primary-100 cursor-pointer shadow">
            <div className="flex flex-col">
                <div className="group-hover:text-primary-400 text-lg font-semibold">
                    { name }
                </div>
                <div className="text-sm text-grey mt-2 group-hover:text-primary-500">
                    { description }
                </div>
            </div>
            <div className="text-[32px] group-hover:text-primary-400">
                { icon }
            </div>
        </div>
    );
}