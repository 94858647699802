import { Trans, useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { CheckOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import CheckIfProfileIsCompletedGQL from '@/gql/global-queries/checkIfProfileIsCompletedGQL';
import { useEffect, useState } from 'react';
import ListSkeleton from '@/components/ListSkeleton';

export default function FreelancerGettingStarted() {

    const { t } = useTranslation();

    const { data, loading, error } = useQuery( CheckIfProfileIsCompletedGQL );

    const [ steps, setSteps ] = useState( null );

    useEffect( () => {
        if( data ) {
            setSteps( [
                    {
                        isDone: data.checkIfProfileIsCompleted.portfolio,
                        href: '/freelancer/profile/edit-portfolio',
                        title: t( 'freelancer:gettingStarted.step1.title' ),
                        description: <Trans i18nKey="freelancer:gettingStarted.step1.description">
                            <br />
                            <span className="block"></span>
                            <span className="block font-bold whitespace-pre-line"></span>
                        </Trans>,
                        tip: <Trans i18nKey="freelancer:gettingStarted.step1.tip">
                            <span className="font-bold"></span>
                        </Trans>
                    },
                    {
                        isDone: data.checkIfProfileIsCompleted.contacts,
                        href: '/freelancer/settings/connected-account',
                        title: t( 'freelancer:gettingStarted.step2.title' ),
                        description: t( 'freelancer:gettingStarted.step2.description' )
                    },
                    {
                        isDone: data.checkIfProfileIsCompleted.profile,
                        href: '/freelancer/profile?edit=open',
                        title: t( 'freelancer:gettingStarted.step3.title' ),
                        description: <Trans i18nKey="freelancer:gettingStarted.step3.description">
                            <br />
                        </Trans>
                    }
                ]
            );
        }

    }, [ data ] );

    return (
        <>
            <h1 className="hidden md:block">{ t( 'freelancer:gettingStarted.title' ) }</h1>
            <p className="text-sm font-normal text-grey">
                <Trans i18nKey="freelancer:gettingStarted.description">
                    <span className="font-bold"></span>
                </Trans>
            </p>

            <div className="mt-6 flex flex-col gap-y-4">
                {
                    loading || steps == null ?
                    <ListSkeleton isLoading={ true }
                                  count={ 3 }
                                  height={ 115 } /> :
                    <>
                        {
                            steps?.map( ( step: any, index: number ) => (
                                <Link to={ step.href }
                                      key={ index }
                                      className="flex p-6 border border-grey-light rounded-[10px]">
                                    <div className={ twMerge( 'flex flex-none items-center justify-center w-8 h-8 rounded-full text-white text-sm font-bold', step.isDone
                                                                                                                                                              ? 'bg-green-200'
                                                                                                                                                              : 'bg-primary-500' ) }>
                                        { step.isDone ? <CheckOutlinedIcon /> : index + 1 }
                                    </div>
                                    <div className="flex flex-col ml-4">
                                        <h2 className={ twMerge( 'text-lg font-bold', step.isDone
                                                                                      ? 'text-grey'
                                                                                      : 'text-black' ) }>
                                            { step.title }
                                        </h2>
                                        <p className="text-sm font-normal text-grey mt-2">
                                            { step.description }
                                        </p>
                                        {
                                            step.tip &&
                                            <div className="text-sm rounded-md bg-primary-100 font-normal text-black p-3 mt-4">
                                                { step.tip }
                                            </div>
                                        }
                                    </div>
                                </Link>
                            ) )
                        }
                    </>
                }
            </div>
        </>
    );
}