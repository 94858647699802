import React from 'react';
import { Input } from 'antd';
import { SearchOutlinedIcon } from '@/assets/icons/CustomIcons';

interface SearchBarProps {
  placeholder: string,
  onChange: (filterCriteria: string) => void
}

const SearchBar = (props: SearchBarProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value)
    }

    return (
        <Input
            prefix={
                <div className='flex justify-center items-center my-px mr-1 p-2 bg-primary-500 rounded-full'>
                    <SearchOutlinedIcon className='text-white' />
                </div>
            }
            className='p-1'
            placeholder={props.placeholder}
            onChange={handleChange}
        />
    )
}

export default SearchBar