import SignUpLayout from '@/pages/auth/sign-up/components/SignUpLayout';
import SignUpFreelancerStepMain from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepMain';
import signUpFreelancerHook from '@/hooks/signUpFreelancerHook';
import { useEffect } from 'react';
import { FreelancerSignupStep, PageName, trackPageView } from '@/utils/analytics';

export default function SignUpFreelancer() {

    const {
              items,
              progressBar,
              isLoading,
              previousStep,
              currentStep,
              nextStep,
              currentProgressBarIndex
          } = signUpFreelancerHook();

    const handleNext = async() => {
        await nextStep();
    };

    useEffect( () => {
        trackPageView( PageName.SignUp, { step: FreelancerSignupStep[ items[ currentStep ]?.step ] } );
    }, [ currentStep ] );

    return (
        <SignUpLayout loadingNext={ isLoading }
                      rightSide={
                          <SignUpFreelancerStepMain steps={ items as any }
                                                    currentStep={ currentStep } />
                      }
                      onNext={ () => handleNext() }
                      onBack={ () => previousStep() } />
    );
}