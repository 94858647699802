import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { RoundedPlusFilledIcon } from '@/assets/icons/CustomIcons';
import { NavLink } from 'react-router-dom';

export default function ClientNoJobPostCard() {
    const { t } = useTranslation( 'translation' );

    return (
        <div className="w-full rounded-2xl bg-purple-50 p-4 flex flex-col lg:flex-row">
            <div className="w-full flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <h4 className="text-lg font-medium font-sharp text-black">
                            { t( 'client:jobPosts.noJobPostCard.title' ) }
                        </h4>
                        <span className="text-sm text-grey">
                            { t( 'client:jobPosts.noJobPostCard.description' ) }
                        </span>
                    </div>
                    <div className="flex justify-start lg:justify-between items-center">
                        <NavLink to="/client/create-job-post">
                            <Button type="primary"
                                    icon={ <RoundedPlusFilledIcon className="text-md flex text-white" /> }
                                    className="w-full text-sm lg:w-fit mt-0 flex items-center">
                                { t( 'client:jobPosts.noJobPostCard.postJobPostButton' ) }
                            </Button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}