import FormLayout from './layout/FormLayout';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import JobPostFormStepMain from '@/pages/client/views/job-post/job-post-form/job-post-form-steps/JobPostFormStepMain';
import { useNavigate } from 'react-router-dom';
import jobPostFormHook from '@/hooks/jobOfferFormHook';

export default function JobPostForm() {

    const {
              items,
              currentStep,
              isLoading,
              nextStep,
              previousStep,
              totalStep,
              progressBar,
              setCurrentStep
          } = jobPostFormHook();

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: false
    } );

    const navigate = useNavigate();

    /* const {
     data: isAllowedToCreateJobPostData,
     loading: isAllowedToCreateJobPostLoading
     } = useQuery( isAllowedToCreateJobPostGQL );*/


    /*  useEffect( () => {
     if( !isAllowedToCreateJobPostData ) {
     navigate( '/client' );
     }
     }, [ isAllowedToCreateJobPostData ] );

     if( isAllowedToCreateJobPostLoading ) {
     return <Skeleton active={ true } />;
     }*/

    return (
        <FormLayout loadingNext={ isLoading }
                    currentStep={ currentStep }
                    progressBar={ progressBar }
                    totalStep={ totalStep }
                    onNext={ () => nextStep() }
                    onBack={ () => previousStep() }
                    setCurrentStep={ setCurrentStep }
                    content={
                        <JobPostFormStepMain steps={ items as any }
                                             currentStep={ currentStep } /> }/>
    );
}