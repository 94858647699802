import { ReactNode, useState } from 'react';
import CheckableTag from 'antd/es/tag/CheckableTag';
import { Space } from 'antd';
import { Controller } from 'react-hook-form';

interface CustomCheckboxComponentProps {
    options: any[];
    defaultSelectedIds?: string[];
    limit?: number;
    name?: string;
    control: any;
    rules?: any;
    direction?: "vertical" | "horizontal";
    renderOptionContent: (option: any) => ReactNode;
}

export default function CustomCheckboxComponent( props: CustomCheckboxComponentProps ) {

    const {
            options,
            defaultSelectedIds,
            limit,
            renderOptionContent,
            control,
            rules,
            name,
            direction
          } = props;

    const [ elementsSelected, setElementsSelected ] = useState<string[]>( defaultSelectedIds || [] );

    const handleChange = ( option, checked: boolean ) => {
        
        if( ( elementsSelected.length < limit || limit == null ) ) {
            const nextSelectedTags: string[] = checked
                ? [ ...elementsSelected, option.id ]
                : elementsSelected.filter( ( t ) => t !== option.id );
            setElementsSelected( nextSelectedTags );
        }
        else if( elementsSelected.length >= limit && limit == 1 && checked && !elementsSelected.includes( option.id ) ) {
            const nextSelectedTags: string[] = [ option.id ];
            setElementsSelected( nextSelectedTags );
        }
        else if( !checked ) {
            const nextSelectedTags = elementsSelected.filter( ( t ) => t !== option.id );
            setElementsSelected( nextSelectedTags );
        }
    };

    return (
        <div className="flex gap-2 flex-wrap">
            <Controller
                control={ control }
                name={ name}
                rules={ rules }
                render={ ( { fieldState: { error }, field: { onChange, onBlur, value, ref } } ) => (
                    <Space direction={ direction } className='w-full'>
                        {
                            options?.map((option, index) => (
                                <CheckableTag key={ index }
                                    className="rounded-xl py-0"
                                    checked={ elementsSelected.includes( option.id ) }
                                    onChange={ ( checked ) => { handleChange( option, checked ) ; onChange(checked ? option.id : null) } }>
                                    <div className='flex items-center gap-3 w-full'>
                                        <div className='w-full'>
                                            {renderOptionContent(option)}
                                        </div>
                                    </div>
                                    
                                </CheckableTag>
                            ))
                        }
                    </Space>
                )}
            />
        </div>
    );
};