export function Logo( { ...props }: React.SVGProps<SVGSVGElement> ) {
    return (
        <svg viewBox="0 0 177 39"
             fill="none"
             xmlns="http://www.w3.org/2000/svg" { ...props }>
            <path d="M0.701876 13.5957H5.83676L4.33152 20.8216C4.28316 21.0541 4.59334 21.1873 4.73664 20.9959L12.364 10.8136C12.702 10.3623 12.3712 9.72809 11.798 9.72809H6.7497L7.78682 2.33901C7.81937 2.10801 7.51008 1.99193 7.37514 2.18453L0.123306 12.5277C-0.194341 12.981 0.138531 13.5957 0.701876 13.5957Z"
                  fill="currentColor" />
            <path d="M37.2495 13.7758C37.1224 15.2754 36.7412 16.5462 36.1566 17.6391C34.9874 19.7741 32.8779 20.9687 29.3958 20.9687C24.3887 20.9687 21.6183 17.9695 21.6183 12.4287V10.802C21.6183 5.2104 24.5158 2.13499 29.5229 2.13499C34.047 2.13499 36.6649 4.39707 37.1479 8.97205H33.6658C33.437 6.48123 32.1408 5.13415 29.5229 5.13415C26.5491 5.13415 25.0242 7.11664 25.0242 11.0054V12.2508C25.0242 15.7837 26.5746 17.7916 29.5483 17.7916C32.2679 17.7916 33.4625 16.3683 33.6912 13.7758H37.2495Z"
                  fill="currentColor" />
            <path d="M38.8871 20.5874V5.9729H41.5813L42.0133 9.40413C42.5979 6.98956 44.0975 5.84581 46.8679 5.84581H47.8592V9.22622H46.4104C45.2413 9.22622 44.3771 9.37872 43.7671 9.68372C42.5471 10.2683 42.1658 11.5391 42.1658 13.8266V20.5874H38.8871Z"
                  fill="currentColor" />
            <path d="M62.5474 16.2666C61.7086 19.2404 59.3449 20.9687 55.507 20.9687C50.5762 20.9687 48.1616 18.122 48.1616 13.5979V13.242C48.1616 11.6662 48.4666 10.37 49.0766 9.2008C50.2966 6.91331 52.5332 5.61706 55.6086 5.61706C58.1249 5.61706 59.904 6.48123 61.0732 7.87914C62.2424 9.27705 62.7507 11.0816 62.7507 12.9625V14.5129H51.4657C51.7453 16.902 53.2195 18.2237 55.6341 18.2237C57.4132 18.2237 58.4553 17.5374 58.9636 16.2666H62.5474ZM55.6341 8.26039C53.3211 8.26039 51.8724 9.58205 51.4911 11.895H59.4211C59.1924 9.6583 57.9724 8.26039 55.6341 8.26039Z"
                  fill="currentColor" />
            <path d="M74.4812 17.6645C73.49 19.5708 71.5329 20.9687 68.5083 20.9687C65.3313 20.9687 63.73 19.4437 63.73 17.0545C63.73 15.4279 64.4417 14.2079 65.6363 13.4708C66.0429 13.1912 66.475 12.9879 66.8817 12.8608C67.7458 12.5558 68.8388 12.3525 70.6688 12.0729C73.2867 11.7425 74.3287 11.3866 74.3287 10.192C74.3287 8.97205 73.5408 8.20956 71.2533 8.20956C68.8388 8.20956 67.7458 9.25164 67.6442 11.1325H64.2129C64.4417 7.80289 66.8817 5.61706 71.0754 5.61706C72.6767 5.61706 73.8967 5.8204 74.8625 6.25248C76.7687 7.06581 77.5312 8.61622 77.5312 10.7258V20.5874H74.8371L74.4812 17.6645ZM69.6521 18.4016C70.6179 18.4016 71.4312 18.1729 72.1429 17.7408C73.5662 16.8512 74.3542 15.377 74.3542 13.9791V13.1912C73.7442 13.6233 72.7275 13.9283 71.4058 14.1316C69.6267 14.4112 68.915 14.6145 68.2288 15.0466C67.6442 15.4279 67.3646 15.9362 67.3646 16.5716C67.3646 17.7916 68.1271 18.4016 69.6521 18.4016Z"
                  fill="currentColor" />
            <path d="M88.0998 20.5874H86.2189C84.8464 20.5874 83.7789 20.3841 83.0673 20.0537C81.5931 19.3166 81.1356 17.7154 81.1356 14.7416V8.92122H78.289V6.09998H79.5852C80.0681 6.09998 80.3985 6.04915 80.6273 5.94748C81.1102 5.71873 81.2627 5.15957 81.339 3.96499C81.3644 3.32957 81.3898 2.46541 81.3898 2.03333H84.2364V5.9729H88.0998V8.92122H84.2364V14.1825C84.2364 15.1483 84.3127 15.8599 84.4398 16.3429C84.6685 17.3087 85.431 17.4866 87.0577 17.4866H88.0998V20.5874Z"
                  fill="currentColor" />
            <path d="M91.4776 4.39707C89.8763 4.39707 89.2663 3.86332 89.2663 2.18583C89.2663 0.559165 90.0034 0 91.4776 0C92.9517 0 93.6888 0.559165 93.6888 2.18583C93.6888 3.76165 92.9772 4.39707 91.4776 4.39707ZM89.7493 20.5874V5.9729H93.1297V20.5874H89.7493Z"
                  fill="currentColor" />
            <path d="M99.7846 20.5874L94.0405 5.9729H97.5226L101.31 16.4445L105.249 5.9729H108.757L102.758 20.5874H99.7846Z"
                  fill="currentColor" />
            <path d="M122.723 16.2666C121.884 19.2404 119.521 20.9687 115.683 20.9687C110.752 20.9687 108.337 18.122 108.337 13.5979V13.242C108.337 11.6662 108.642 10.37 109.252 9.2008C110.472 6.91331 112.709 5.61706 115.784 5.61706C118.301 5.61706 120.08 6.48123 121.249 7.87914C122.418 9.27705 122.926 11.0816 122.926 12.9625V14.5129H111.641C111.921 16.902 113.395 18.2237 115.81 18.2237C117.589 18.2237 118.631 17.5374 119.139 16.2666H122.723ZM115.81 8.26039C113.497 8.26039 112.048 9.58205 111.667 11.895H119.597C119.368 9.6583 118.148 8.26039 115.81 8.26039Z"
                  fill="currentColor" />
            <path d="M132.55 2.10265H134.237V15.4337C134.231 16.6371 134.015 17.642 133.588 18.4483C133.16 19.2486 132.571 19.8473 131.819 20.2445C131.066 20.6416 130.203 20.8402 129.228 20.8402C128.271 20.8402 127.417 20.6626 126.665 20.3076C125.913 19.9526 125.32 19.4532 124.887 18.8094C124.46 18.1655 124.246 17.4104 124.246 16.5439H125.907C125.907 17.0914 126.051 17.5758 126.34 17.997C126.629 18.4122 127.023 18.7371 127.522 18.9718C128.028 19.2065 128.596 19.3238 129.228 19.3238C129.884 19.3238 130.459 19.1854 130.952 18.9086C131.452 18.6318 131.843 18.2076 132.125 17.636C132.408 17.0584 132.55 16.3243 132.55 15.4337V2.10265Z"
                  fill="currentColor" />
            <path d="M143.439 20.8763C142.242 20.8763 141.183 20.5724 140.262 19.9647C139.348 19.3569 138.632 18.5175 138.114 17.4465C137.597 16.3694 137.338 15.1268 137.338 13.7188C137.338 12.2988 137.597 11.0502 138.114 9.97312C138.632 8.89003 139.348 8.04762 140.262 7.4459C141.183 6.83817 142.242 6.5343 143.439 6.5343C144.637 6.5343 145.693 6.83817 146.607 7.4459C147.522 8.05364 148.238 8.89604 148.756 9.97312C149.279 11.0502 149.541 12.2988 149.541 13.7188C149.541 15.1268 149.282 16.3694 148.765 17.4465C148.247 18.5175 147.528 19.3569 146.607 19.9647C145.693 20.5724 144.637 20.8763 143.439 20.8763ZM143.439 19.4051C144.402 19.4051 145.217 19.1463 145.885 18.6288C146.553 18.1114 147.059 17.4224 147.402 16.5619C147.751 15.7015 147.925 14.7538 147.925 13.7188C147.925 12.6839 147.751 11.7331 147.402 10.8667C147.059 10.0002 146.553 9.30521 145.885 8.78172C145.217 8.25822 144.402 7.99648 143.439 7.99648C142.483 7.99648 141.667 8.25822 140.993 8.78172C140.326 9.30521 139.817 10.0002 139.468 10.8667C139.125 11.7331 138.954 12.6839 138.954 13.7188C138.954 14.7538 139.125 15.7015 139.468 16.5619C139.817 17.4224 140.326 18.1114 140.993 18.6288C141.661 19.1463 142.477 19.4051 143.439 19.4051Z"
                  fill="currentColor" />
            <path d="M152.639 20.5874V2.10265H154.255V9.36839H154.399C154.58 8.95321 154.838 8.52599 155.175 8.08673C155.518 7.64748 155.967 7.28043 156.52 6.98559C157.08 6.68473 157.784 6.5343 158.632 6.5343C159.769 6.5343 160.768 6.83516 161.629 7.43688C162.489 8.03258 163.16 8.86897 163.642 9.94604C164.123 11.0171 164.364 12.2657 164.364 13.6917C164.364 15.1238 164.123 16.3784 163.642 17.4555C163.166 18.5326 162.498 19.372 161.638 19.9737C160.783 20.5754 159.791 20.8763 158.659 20.8763C157.817 20.8763 157.113 20.7258 156.547 20.425C155.988 20.1241 155.536 19.7541 155.193 19.3148C154.85 18.8695 154.586 18.4363 154.399 18.0151H154.201V20.5874H152.639ZM154.228 13.6647C154.228 14.7839 154.393 15.7767 154.724 16.6432C155.061 17.5036 155.542 18.1806 156.168 18.674C156.8 19.1614 157.564 19.4051 158.461 19.4051C159.381 19.4051 160.161 19.1523 160.798 18.6469C161.436 18.1354 161.921 17.4465 162.252 16.58C162.583 15.7135 162.748 14.7417 162.748 13.6647C162.748 12.5996 162.583 11.6399 162.252 10.7854C161.927 9.931 161.445 9.25407 160.807 8.75464C160.17 8.2492 159.387 7.99648 158.461 7.99648C157.558 7.99648 156.791 8.24017 156.159 8.72756C155.533 9.20894 155.055 9.87684 154.724 10.7313C154.393 11.5797 154.228 12.5575 154.228 13.6647Z"
                  fill="currentColor" />
            <path d="M176.445 9.76553L174.974 10.1807C174.829 9.76553 174.625 9.38945 174.36 9.05249C174.095 8.71553 173.749 8.44776 173.322 8.2492C172.901 8.05063 172.377 7.95135 171.751 7.95135C170.813 7.95135 170.043 8.17398 169.441 8.61925C168.839 9.06453 168.538 9.63917 168.538 10.3432C168.538 10.9389 168.743 11.4233 169.152 11.7963C169.567 12.1634 170.205 12.4552 171.065 12.6718L173.159 13.1863C174.321 13.4691 175.19 13.9174 175.768 14.5311C176.352 15.1449 176.643 15.9151 176.643 16.8417C176.643 17.624 176.427 18.319 175.993 18.9267C175.56 19.5344 174.956 20.0128 174.179 20.3618C173.409 20.7048 172.516 20.8763 171.499 20.8763C170.145 20.8763 169.029 20.5724 168.15 19.9647C167.272 19.3509 166.709 18.4664 166.462 17.3111L168.006 16.932C168.204 17.7563 168.592 18.3791 169.17 18.8003C169.754 19.2215 170.521 19.4321 171.472 19.4321C172.537 19.4321 173.388 19.1945 174.026 18.7191C174.664 18.2377 174.983 17.636 174.983 16.9139C174.983 16.3543 174.796 15.885 174.423 15.5059C174.05 15.1208 173.484 14.838 172.726 14.6575L170.461 14.1159C169.257 13.8271 168.367 13.3698 167.789 12.744C167.211 12.1182 166.923 11.342 166.923 10.4154C166.923 9.6512 167.13 8.98028 167.545 8.40263C167.961 7.81897 168.532 7.36166 169.26 7.03072C169.988 6.69977 170.819 6.5343 171.751 6.5343C173.021 6.5343 174.035 6.82313 174.793 7.40077C175.557 7.97241 176.108 8.76066 176.445 9.76553Z"
                  fill="currentColor" />
            <path d="M74.2703 26.9694C73.5105 26.5653 72.6217 26.4783 71.7979 26.7272C70.9742 26.9761 70.2823 27.5408 69.8734 28.2979C69.872 28.3004 69.8712 28.3031 69.8709 28.3059C69.8706 28.3087 69.8708 28.3115 69.8717 28.3142C69.8725 28.3169 69.8738 28.3194 69.8756 28.3215C69.8774 28.3237 69.8796 28.3255 69.882 28.3268L71.379 29.1227L69.7095 29.6331L69.9667 30.4748L72.2877 29.7652L70.8238 32.518L71.6009 32.931L73.0629 30.1815L73.7708 32.4972L74.6124 32.2398L74.1019 30.5703L75.599 31.3663C75.6015 31.3676 75.6042 31.3684 75.607 31.3687C75.6098 31.369 75.6126 31.3687 75.6153 31.3679C75.618 31.3671 75.6205 31.3657 75.6226 31.3639C75.6248 31.3621 75.6266 31.3599 75.6279 31.3575C76.0269 30.595 76.108 29.7056 75.8537 28.8835C75.5994 28.0614 75.0301 27.3733 74.2703 26.9694Z"
                  fill="currentColor" />
            <path d="M66.5846 33.2247L68.9981 30.4765H67.2061L65.2245 32.8754V30.4765H63.8107V36.7418H65.2245V34.7814L65.6877 34.2554L67.4695 36.7199L67.4854 36.7411H69.1618L66.5846 33.2247Z"
                  fill="currentColor" />
            <path d="M16.9912 36.7419H21.377V35.4853H18.4225V34.1725H21.1044V33.0039H18.4225V31.7343H21.377V30.4777H16.9912V36.7419Z"
                  fill="currentColor" />
            <path d="M34.8405 32.9153L33.2391 30.4771H31.4239L34.0743 34.2731L32.7737 36.7417H34.4039L37.7032 30.4771H36.0638L34.8405 32.9153Z"
                  fill="currentColor" />
            <path d="M47.1527 35.2745L45.5634 30.4765H43.6541V36.7418H45.0153V32.525L46.4706 36.7066L46.4828 36.7411H47.8141L49.2905 32.4996V36.7411H50.6424V30.4765H48.7419L47.1527 35.2745Z"
                  fill="currentColor" />
            <path d="M55.0826 30.4765H53.4274L51.157 36.7418H52.6401L53.097 35.3952H55.4234L55.8806 36.7418H57.3636L55.0956 30.5111L55.0826 30.4765ZM53.492 34.2331L54.2633 31.9902L55.0268 34.2331H53.492Z"
                  fill="currentColor" />
            <path d="M23.6787 30.4765H22.2296V36.7418H26.6858V35.4498H23.6787V30.4765Z"
                  fill="currentColor" />
            <path d="M28.9267 30.4765H27.4778V36.7418H31.9338V35.4498H28.9267V30.4765Z"
                  fill="currentColor" />
            <path d="M60.3748 31.5926C61.1029 31.5926 61.5617 31.9626 61.7387 32.6933L61.7494 32.7376L63.1908 32.5857L63.1801 32.5303C62.922 31.1691 61.8961 30.3883 60.366 30.3883C58.4522 30.3883 57.263 31.6121 57.263 33.5821V33.6354C57.263 35.6054 58.4522 36.8292 60.366 36.8292C61.8962 36.8292 62.922 36.0486 63.1801 34.6872L63.1908 34.6318L61.7494 34.4799L61.7387 34.5242C61.5617 35.2549 61.1029 35.6248 60.3748 35.6248C59.3181 35.6248 58.7125 34.8932 58.7125 33.6176V33.5999C58.7125 32.3242 59.3181 31.5926 60.3748 31.5926Z"
                  fill="currentColor" />
            <path d="M14.6239 34.2947C14.6239 35.1839 14.2909 35.5983 13.5765 35.5983C12.8621 35.5983 12.5034 35.1388 12.5034 34.2326V34.0602L11.0544 33.9079V34.2326C11.0544 35.9061 11.9535 36.8285 13.5857 36.8285C15.1667 36.8285 16.0732 35.9174 16.0732 34.3297V30.4747H14.6239V34.2947Z"
                  fill="currentColor" />
            <path d="M40.1876 31.6455C40.9319 31.6455 41.3573 31.9304 41.4883 32.5166L41.4986 32.5609L42.9 32.4102L42.8903 32.3553C42.7288 31.4576 42.1377 30.3874 40.2404 30.3874C39.6449 30.3784 39.0591 30.5385 38.551 30.8491C38.3027 30.9998 38.0979 31.2124 37.9564 31.4661C37.815 31.7198 37.7418 32.0058 37.7441 32.2963C37.7441 33.381 38.3933 33.98 39.9132 34.3016C40.8544 34.5254 41.1828 34.6422 41.1828 35.0784C41.1828 35.5537 40.7308 35.6247 40.4614 35.6247C39.8102 35.6247 39.4361 35.3122 39.3787 34.7211L39.3742 34.6743H37.9527L37.9558 34.7292C38.0311 36.0243 38.9674 36.8291 40.3994 36.8291C41.808 36.8291 42.65 36.1711 42.65 35.069C42.65 33.9037 41.8066 33.492 40.4458 33.2053C39.3966 32.9837 39.1859 32.7024 39.1859 32.3231C39.1845 31.8847 39.7014 31.6455 40.1876 31.6455Z"
                  fill="currentColor" />
            <path d="M0.578954 36.9197H0V31.1264H0.762897V33.747C0.873893 33.4571 1.07266 33.209 1.33145 33.0374C1.59025 32.8659 1.89615 32.7794 2.20646 32.7901C3.39322 32.7901 4.04618 33.7287 4.04618 34.9146C4.04618 36.1005 3.39322 37.0303 2.1978 37.0303C1.88738 37.0421 1.58095 36.9573 1.32071 36.7876C1.06048 36.618 0.859245 36.3718 0.744719 36.0831L0.578954 36.9197ZM0.745296 35.0251C0.745296 35.9081 1.30593 36.4235 2.03204 36.4235C2.8235 36.4235 3.2553 35.8349 3.2553 34.9146C3.2553 33.9943 2.8235 33.4066 2.03204 33.4066C1.30593 33.4066 0.745296 33.922 0.745296 34.7953V35.0251Z"
                  fill="currentColor" />
            <path d="M5.36452 38.4641L6.01763 36.9099L4.32578 32.9005H5.12605L6.40428 35.99H6.44092L7.67385 32.9005H8.4278L6.10982 38.4641H5.36452Z"
                  fill="currentColor" />
        </svg>
    );
}