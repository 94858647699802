import SkeletonInput from 'antd/es/skeleton/Input';
import { useRef } from 'react';
import handleResizeHook from '@/hooks/handleResizeHook';

export default function FreelancerProfileLoaderFeaturedPart() {
    const containerRef = useRef( null );
    const playerHeight: number = handleResizeHook( { ref: containerRef } );

    return <div className="w-full"
                ref={ containerRef }>
        <SkeletonInput active={ true }
                       className="w-full rounded-2xl"
                       style={ { height: playerHeight + 'px' } } />
    </div>;
}