import { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GettingStartedStepsGQL } from '@/pages/client/views/job-post/job-post-management/queries/gettingStartedStepsGQL';
import { UpdateGettingStartedStepsGQL } from '@/pages/client/views/job-post/job-post-management/queries/updateGettingStartedStepsGQL';
import { GettingStartedRepository } from '@/pages/client/views/job-post/job-post-management/ClientJobPostGettingStartedRepository';
import { App } from 'antd';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

const gettingStartedRepository = new GettingStartedRepository();

const gettingStartedHook = () => {
    const [fetchSteps, { data, loading, error }] = useLazyQuery(GettingStartedStepsGQL);
    const [updateStepsMutation] = useMutation(UpdateGettingStartedStepsGQL);

    const { t } = useTranslation();

    const { message } = App.useApp();

    useEffect(() => {
        if ( gettingStartedRepository.steps?.discoverOutreachedProfiles === null && gettingStartedRepository.steps?.reviewInterestedTalents === null ) {
            fetchSteps();
        }
    }, [ fetchSteps ]);

    useEffect(() => {
        if ( data && data.meClient ) {
            const steps = {
                discoverOutreachedProfiles: data.meClient?.gettingStartedSteps?.discoverOutreachedProfiles || false,
                reviewInterestedTalents: data.meClient?.gettingStartedSteps?.reviewInterestedTalents || false,
            };
            
            gettingStartedRepository.steps = steps;
            gettingStartedRepository.completed = data.meClient.gettingStartedCompleted;
        }
    }, [ data ]);

    const updateSteps = async (steps) => {
        try {
            const response = await updateStepsMutation({
                variables: { args: steps },
            });
            if (response.data?.updateGettingStartedSteps) {
                gettingStartedRepository.steps = steps;
            }
        } catch (e) {
            Sentry.captureException( 'An error occur when trying to apply to a job offer', e );
            message.error( t( 'common:form.submitError' ) );
        }
    };

    return {
        gettingStartedSteps: gettingStartedRepository.steps,
        gettingStartedCompleted: gettingStartedRepository.completed,
        state$: gettingStartedRepository.steps$,
        loading,
        error,
        updateSteps,
        refetchSteps: fetchSteps,
    };
};

export default gettingStartedHook;
