import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';

interface FreelancerProfilePortfolioListItemProps {
    stats: {
        countViews: number;
        countElements: number;
        countLikes?: number;
    };
}

export default function FreelancerPortfolioStats( { stats }: FreelancerProfilePortfolioListItemProps ) {

    const { t } = useTranslation();

    const [ _stats, setStats ] = useState( [] );

    useEffect( () => {
        if( stats ) {
            const data = [
                {
                    label: t( 'common:freelancerProfile.card.stats.views' ),
                    value: stats.countViews || 0
                },
                {
                    label: t( 'common:freelancerProfile.card.stats.videos' ),
                    value: stats.countElements || 0
                }
            ];
            setStats( data );
        }
    }, [ stats ] );


    return (
        <div className="flex flex-wrap items-center space-x-4">
            {
                _stats.map( ( stat, index ) => (
                    <div key={ index }
                         className="flex items-end gap-x-1">
                        <span className="text-xl font-semibold font-sharp">
                            { abbreviationNumberFormatter(stat.value) }
                        </span>
                        <span className="text-base text-grey">
                            { stat.label.toLocaleLowerCase() }
                        </span>
                    </div>
                ) )
            }
        </div>
    );
}