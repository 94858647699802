import { useEffect } from 'react';
import { Button } from 'antd';
import { ArrowLeftFilledIcon } from '@/assets/icons/CustomIcons';
import { useIntercomHook } from '@/plugins/intercom/useIntercomHook';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import NoDataIcon from '@/components/NoDataIcon';

interface ErrorProps {
    code: 404 | 500;
}

export default function ErrorPage( { code }: ErrorProps ) {
    const { t } = useTranslation();
    const { setIntercomVisibility } = useIntercomHook();

    useEffect( () => {
        if( code ) {
            setIntercomVisibility( true );
        }
    }, [ code ] );

    return (
        <div className="p-4 lg:p-10 h-screen">
            <Link to="/">
                <Button shape="circle"
                        size="small"
                        icon={ <ArrowLeftFilledIcon style={ { fontSize: '14px' } } /> }>
                </Button>
            </Link>
            <div className="flex flex-col items-center mt-8 h-full w-full">
                <NoDataIcon message={ t( `error.${ code }.title` ) }
                            subMessage={ t( `error.${ code }.description` ) }
                            button={ {
                                to: '/',
                                text: t( `error.${ code }.button` )
                            } } />
            </div>
        </div>
    );
}