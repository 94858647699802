import { createStore, select, withProps } from '@ngneat/elf';
import { Channel } from '@/gql/graphql';

interface ChannelManagerStore {
    channels: Channel[];
}

const ChannelManagerStore = createStore( {
        name: 'channelManager'
    },
    withProps<ChannelManagerStore>( {
        channels: []
    } )
);

export class ChannelManagerRepository {

    channels$ = ChannelManagerStore.pipe( select( ( state ) => state.channels ) );

    get channel(): Channel[] {
        return ChannelManagerStore.getValue().channels;
    }

    set channel( channels: Channel[] ) {
        ChannelManagerStore.update( ( state ) => {
            return {
                ...state,
                channels
            };
        } );
    }
}