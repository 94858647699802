import parsePhoneNumber, { CountryCode, getCountryCallingCode, PhoneNumber } from 'libphonenumber-js';

export default function phoneNumberFromForm( countryCode: string, phoneNumber: string ) {
    if( !countryCode || !phoneNumber ) {
        return null;
    }

    const countryCallingCode = getCountryCallingCode( countryCode as CountryCode );
    const fullPhoneNumber = `+${ countryCallingCode }${ phoneNumber }`;
    const phoneNumberParsed: PhoneNumber = parsePhoneNumber( fullPhoneNumber );

    if( !phoneNumberParsed ) {
        return null;
    }

    return phoneNumberParsed.format( 'E.164' );
}