import { PortfolioChannel } from '@/gql/graphql';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { cuttingTextFormatter } from '@/utils/cuttingTextFormatter';

interface FreelancerAccountEditPortfolioListItemProps {
    portfolioChannel: PortfolioChannel;
}

export default function FreelancerProfileEditPortfolioChannelListItem( {
                                                                           portfolioChannel
                                                                       }: FreelancerAccountEditPortfolioListItemProps ) {
    return (
        <div className="flex-none relative group bg-white flex flex-col items-center justify-center gap-y-1 cursor-grab w-[98px]">
            <Avatar size={ 64 }
                    icon={ <UserOutlined /> }
                    className="flex-none"
                    src={ portfolioChannel?.thumbnailUrl } />
            <p className="font-medium text-sm">
                { cuttingTextFormatter( portfolioChannel?.name, 8 ) }
            </p>
            <p className="font-normal text-grey text-xs">
                { abbreviationNumberFormatter( portfolioChannel?.subscriberCount ) }
            </p>
        </div>
    );
}