import { useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { twMerge } from 'tailwind-merge';

interface PhoneNumberFormProps {
    onCodeCompleted: ( code: string ) => void;
}

const VerificationInput = ( { onChange, value, className = null, inputRef, onKeyDown } ) => (
    <Input className={ twMerge( 'text-center font-medium text-5xl p-0', className ) }
           maxLength={ 1 }
           bordered={ false }
           onKeyDown={ onKeyDown }
           placeholder="-"
           inputMode="numeric"
           onChange={ ( e ) => {
               const val = Number( e.target.value );
               if( !isNaN( val ) ) {
                   onChange( e );
               }
           } }
           value={ value }
           ref={ inputRef } />
);

export default function VerificationCode( { onCodeCompleted }: PhoneNumberFormProps ) {

    const [ code, setCode ] = useState( [ '', '', '', '', '', '' ] );
    const inputsRef = useRef( [] );

    const updateCodeAndFocus = ( index, value ) => {
        const newCode = [ ...code ];
        newCode[ index ] = value;
        setCode( newCode );

        if( value === '' && index > 0 ) {
            inputsRef.current[ index - 1 ].focus();
        }
        else if( index < code.length - 1 ) {
            inputsRef.current[ index + 1 ].focus();
        }

        const isFilled: boolean = newCode.every( ( digit ) => digit !== '' );
        if( isFilled ) {
            const verificationCode: string = newCode.join( '' );
            onCodeCompleted( verificationCode );
        }
    };

    const handleChange = ( index ) => ( e ) => {
        const val = Number( e.target.value );
        if( !isNaN( val ) ) {
            updateCodeAndFocus( index, e.target.value );
        }
    };

    const handleKeyDown = ( index ) => ( e ) => {
        if( e.key === 'Backspace' && e.target.value === '' ) {
            updateCodeAndFocus( index - 1, '' );
        }
    };

    return (
        <Form className="flex items-center justify-center  border-2 border-purple-600 rounded-[100px] p-2.5">
            {
                code.map( ( digit, index ) => (
                    <VerificationInput key={ index }
                                       value={ digit }
                                       onChange={ handleChange( index ) }
                                       onKeyDown={ handleKeyDown( index ) }
                                       inputRef={ el => inputsRef.current[ index ] = el } />
                ) )
            }
        </Form>
    );
};