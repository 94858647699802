import { useEffect, useRef, useState } from 'react';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import ListSkeleton from '@/components/ListSkeleton';
import JobPostTalentCard from './JobPostTalentCard';
import ClientNoJobPostCard from '@/pages/client/views/job-posts/components/ClientNoJobPostCard';
import UpgradePlanModal from '../../account/client-account-plans-billing/UpgradePlanModal';
import { Application, ApplicationTypeEnum } from '@/gql/graphql';
import { LockOutlinedIcon } from '@/assets/icons/CustomIcons';
import { DateTime } from 'luxon';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import nextUnlockedApplicationGQL from '@/gql/global-queries/clientNextApplicationUnlockGQL';
import { Link } from 'react-router-dom';
import { applicationTypeStatus, paywallOriginTypeName, SegmentEvent, trackSegmentEvent } from '@/utils/analytics';

interface JobPostTalentListRestrictedProps {
    applications: Application[];
    isLoading: boolean;
    applicationsStatus: ApplicationTypeEnum;
    jobPostId: string;
    onFollowUpMessage?: ( application: Application ) => void;
    onUpdateFavoriteStatus?: ( status, applicationId ) => void;
    onShowContactDetails?: ( application: Application ) => void;
}

export default function JobPostTalentListRestricted( {
                                                          applications,
                                                          isLoading,
                                                          applicationsStatus,
                                                          jobPostId,
                                                          onFollowUpMessage,
                                                          onUpdateFavoriteStatus,
                                                          onShowContactDetails
                                                      }: JobPostTalentListRestrictedProps ) {

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: false
    } );

    const { t } = useTranslation();

    const upgradePlanModalRef = useRef( null );

    const handleFollowUp = ( application: Application ) => onFollowUpMessage?.( application );

    const handleUpdateFavoriteStatus = ( status, applicationId ) => onUpdateFavoriteStatus?.( status, applicationId );

    const handleShowContactDetails = ( application: Application ) => onShowContactDetails?.( application );

    const { data: nextUnlockedApplication, loading: nextUnlockedLoading } = useQuery(
        nextUnlockedApplicationGQL, { variables: { jobPostId } }
    );

    const [ timeRemaining, setTimeRemaining ] = useState( undefined );

    useEffect( () => {
        const unlockDate = nextUnlockedApplication?.getNextUnlockedApplication?.isUnlockAt;
        if( unlockDate ) {
            const unlockTimestamp = DateTime.fromISO( unlockDate ).toMillis();
            const calculateTimeRemaining = ( timestamp ) => {
                if( !timestamp || isNaN( timestamp ) ) {
                    return {
                        hours: '00',
                        minutes: '00',
                        seconds: '00'
                    };
                }

                const now = DateTime.now();
                const targetDateTime = DateTime.fromMillis( timestamp );
                const diff = targetDateTime.diff( now, [ 'hours', 'minutes', 'seconds' ] );

                return {
                    hours: diff.hours.toFixed().padStart( 2, '0' ),
                    minutes: diff.minutes.toFixed().padStart( 2, '0' ),
                    seconds: diff.seconds.toFixed().padStart( 2, '0' )
                };
            };

            const interval = setInterval( () => {
                setTimeRemaining( calculateTimeRemaining( unlockTimestamp ) );
            }, 1000 );

            return () => clearInterval( interval );
        }
    }, [ nextUnlockedApplication ] );

    const onTalentClicked = ( id: string ) => {
        trackSegmentEvent( SegmentEvent.ProfileClicked, {
            profile_clicked_id: id,
            profile_clicked_origin: applicationTypeStatus[ applicationsStatus ]
        } );
    };

    const onPaywallClicked = () => {
        upgradePlanModalRef.current.open();
        trackSegmentEvent( SegmentEvent.PaywallClicked, { paywall_origin: paywallOriginTypeName.Interested } );
    };

    const skeleton = ( limit: number ) => {
        return (
            <div className="flex flex-col gap-y-4">
                <ListSkeleton isLoading={ true }
                              height={ 130 }
                              count={ limit } />
            </div>
        );
    };

    if( isLoading || nextUnlockedLoading ) {
        return skeleton( 8 );
    }

    if( applications?.length > 0 ) {
        const unlockedApplications = applications.filter( app => app.isUnlock );
        const lockedApplications = applications.filter( app => !app.isUnlock );

        return (
            <>
                <div className="flex flex-col gap-4 w-full">
                    {
                        unlockedApplications.map( ( application, index ) => (
                            <Link key={ index }
                                  onClick={ () => onTalentClicked( application.freelancer.id ) }
                                  className="text-current"
                                  to={ `../p/${ application.freelancer.id }` }>
                                <JobPostTalentCard key={ application.id }
                                                   talent={ application.freelancer }
                                                   canSendSuperLike={ false }
                                                   onFollowUp={ () => handleFollowUp( application ) }
                                                   onUpdateFavorite={ () => handleUpdateFavoriteStatus( !application.isFavoriteAt, application.id ) }
                                                   onShowContactDetails={ () => handleShowContactDetails( application ) }
                                                   applicationsStatus={ applicationsStatus }
                                                   applyAt={ application.appliedAt }
                                                   isFavorite={ !!application.isFavoriteAt }
                                                   freelancerMessage={ application.message }
                                                   creatorHasSentSuperLikeMessage={ !!application.superLikeMessage }
                                />
                            </Link>
                        ) )
                    }
                    {
                        lockedApplications.length > 0 && timeRemaining &&
                        <div className="px-4 py-2 rounded-2xl w-full text-white flex flex-col lg:flex-row gap-4 items-center justify-between"
                             style={ { background: 'linear-gradient(89.25deg, #8C96F2 -33.77%, #9610FF 44.77%, #2C4992 99.36%)' } }>
                          <div className="flex gap-4 items-center">
                            <span className="text-xl">{ t( 'client:jobPosts.interested.nextTalentAvailable' ) }</span>
                            <div className="min-w-32 font-sharp text-lg rounded-lg px-2.5 py-1 bg-white/30 border-grey-light border">
                              <span>{ timeRemaining?.hours }:</span>
                              <span>{ timeRemaining?.minutes }:</span>
                              <span>{ timeRemaining?.seconds }</span>
                            </div>
                          </div>
                          <Button type="text"
                                  onClick={ () => onPaywallClicked() }
                                  className="bg-white font-semibold text-black rounded-4xl h-8 px-2.5 py-0">
                            <LockOutlinedIcon />
                            <span>{ t( 'client:jobPosts.interested.unlockTalentButton' ) }</span>
                          </Button>
                        </div>
                    }

                    {
                        lockedApplications.map( ( application ) => (
                            <JobPostTalentCard key={ application.id }
                                               talent={ application.freelancer }
                                               onFollowUp={ () => handleFollowUp( application ) }
                                               onUpdateFavorite={ () => handleUpdateFavoriteStatus( !application.isFavoriteAt, application.id ) }
                                               applicationsStatus={ applicationsStatus }
                                               applyAt={ application.appliedAt }
                                               isFavorite={ !!application.isFavoriteAt }
                                               freelancerMessage={ application.message }
                                               isRestricted={ true }
                                               creatorHasSentSuperLikeMessage={ !!application.superLikeMessage } />
                        ) )
                    }
                </div>
                <UpgradePlanModal ref={ upgradePlanModalRef } />
            </>
        );
    }

    return (
        <div className="flex justify-center items-center w-full">
            <ClientNoJobPostCard />
        </div>
    );
}
