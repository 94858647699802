import { forwardRef, useImperativeHandle, useState } from 'react';
import CheckableTag from 'antd/es/tag/CheckableTag';
import SkeletonInput from 'antd/es/skeleton/Input';

export interface TagListForm {
    id: string;
    label: string | JSX.Element;
}

interface SelectMultiTagsProps {
    tags: TagListForm[];
    defaultMainTagId?: string;
    enableMainTag?: boolean;
    defaultSelectedIds?: string[];
    onTagsSelected?: ( tagIds: string[] ) => void;
    onChangeMainTag?: ( tagId: string ) => void;
    limit?: number;
    loading?: boolean;
}

const SelectMultiTags = forwardRef( ( props: SelectMultiTagsProps, ref ) => {

    const {
              tags,
              enableMainTag = false,
              defaultMainTagId,
              defaultSelectedIds,
              limit = 100,
              onTagsSelected,
              onChangeMainTag,
              loading = false
          } = props;

    const [ elementsSelected, setElementsSelected ] = useState<string[]>( defaultSelectedIds || [] );
    const [ mainTagId, setMainTagId ] = useState<string>( defaultMainTagId );

    useImperativeHandle( ref, () => ( {
            getSelectedIds(): string[] {
                return elementsSelected;
            },
            getMainTagId(): string {
                return mainTagId;
            }
        } )
    );

    const handleChange = ( tag: TagListForm, checked: boolean ) => {
        if( !checked ) {
            const nextSelectedTags = elementsSelected.filter( ( t ) => t !== tag.id );
            setElementsSelected( nextSelectedTags );
            if( tag.id === mainTagId ) {
                setMainTagId( null );
                onChangeMainTag?.( null );
            }
            onTagsSelected?.( nextSelectedTags );
        }
        else if( ( elementsSelected.length < limit || limit == null ) ) {
            if( elementsSelected.length === 0 ) {
                setMainTagId( tag.id );
                onChangeMainTag?.( tag.id );
            }
            else if( elementsSelected.length >= 1 && tag.id === mainTagId ) {
                setMainTagId( null );
                setElementsSelected( [] );
                onChangeMainTag?.( null );
                onTagsSelected?.( [] );
                return;
            }

            const nextSelectedTags: string[] = checked
                                               ? [ ...elementsSelected, tag.id ]
                                               : elementsSelected.filter( ( t ) => t !== tag.id );
            setElementsSelected( nextSelectedTags );
            onTagsSelected?.( nextSelectedTags );
        }
        else if( elementsSelected.length >= limit && limit == 1 && checked && !elementsSelected.includes( tag.id ) ) {
            const nextSelectedTags: string[] = [ tag.id ];
            setElementsSelected( nextSelectedTags );
            onTagsSelected?.( nextSelectedTags );
            setMainTagId( tag.id );
            onChangeMainTag?.( tag.id );
        }
    };

    if( loading ) {
        return (
            <div className="flex flex-wrap gap-2">
                {
                    [ ...Array( 3 ).keys() ].map( ( index ) => {
                        return <SkeletonInput active={ true }
                                              style={ { width: '80px', height: '28.33px' } }
                                              className="rounded-full"
                                              key={ index } />;
                    } )
                }
            </div>
        );
    }

    return (
        <div className="flex gap-2 flex-wrap">
            {
                tags?.map( ( tag, index ) => (
                    <CheckableTag key={ index }
                                  className={ elementsSelected.length >= limit && !elementsSelected.includes( tag.id ) && limit > 1
                                              ? 'hover-disabled'
                                              : '' }
                                  checked={ elementsSelected.includes( tag.id ) || mainTagId === tag.id }
                                  onChange={ ( checked ) => handleChange( tag, checked ) }>
                        { tag.label }
                        {
                            enableMainTag &&
                            tag.id === mainTagId &&
                            <div className="ml-2 h-[18px] w-[18px] text-white bg-primary-400 text-sm rounded-full flex justify-center items-center">1</div>
                        }
                    </CheckableTag>
                ) )
            }
        </div>
    );
} );

export default SelectMultiTags;