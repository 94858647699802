import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ResponsiveModal from '@/components/ResponsiveModal';
import { LockIcon } from '@/assets/icons/CustomIcons';
import PremiumJobPass from './components/PremiumJobPass';
import PremiumAnnualPass from './components/PremiumAnnualPass';
import { ordersHook } from '@/hooks/ordersHook';
import { OrderStatusEnum } from '@/gql/graphql';
import { ordersGQL } from '@/gql/global-queries/ordersGQL';
import { useQuery } from '@apollo/client';

const UpgradePlanModal = forwardRef((props, ref) => {
    const { t } = useTranslation()

    const modalRef = useRef(null)

    const createOrder = ordersHook();

    useImperativeHandle(ref, () => ({
        open() {
            modalRef.current.open()
        },
        close() {
            modalRef.current.close()
        },
    }))

    const { loading, data: orders } = useQuery(ordersGQL, {
        variables: {
            status: OrderStatusEnum.PAID,
            page: 1,
            limit: 10
        },
    });

    const jobPassOrders = orders?.orders?.data?.filter(order => !order.isSubscription);

    return (
        <ResponsiveModal ref={modalRef} destroyOnClose={true} desktopWidth="938px" rootClassName="upgrade-plan-modal">
            <div className="header text-white flex flex-col gap-6">
                <LockIcon className='text-5xl' />
                <div className="flex flex-col gap-2">
                    <h4 className="font-sharp text-2xl"> { t( 'client:upgradePlanModal.title' ) }</h4>
                    <span className='font-sharp'>{ t( 'client:upgradePlanModal.description' ) }</span>
                </div>
            </div>

            <div className="content flex flex-col lg:flex-row gap-4">
                <div className='w-full flex flex-col gap-4  overflow-hidden'>
                    <span className='font-semibold text-base'>{ t( 'client:upgradePlanModal.subscription' ) }</span>
                    <PremiumAnnualPass createOrder={ createOrder } />
                </div>
                <div className='w-full flex flex-col gap-4  overflow-hidden'>
                    <span className='font-semibold text-base'>{ t( 'client:upgradePlanModal.jobPass' ) }</span>
                    <PremiumJobPass jobPassOrders={ jobPassOrders } createOrder={ createOrder } />
                </div>
            </div>
        </ResponsiveModal>
    )
})

export default UpgradePlanModal
