import { useTranslation } from 'react-i18next';
import { JobPostFormRepository } from '@/pages/client/views/job-post/job-post-form/jobPostFormRepository';
import SelectMultiTags from '@/components/SelectMultiTags';
import { Controller } from 'react-hook-form';
import { LanguageEnum } from '@/gql/graphql';

interface InputProps {
    control: any;
    errors?: any;
    onChange?: (title: string, value: any) => void,
}

export default function JobPostFormLanguage( { control, errors, onChange: onChangeMethod }: InputProps ) {

    const { t } = useTranslation();
    const repository: JobPostFormRepository = new JobPostFormRepository();

    const handleOnChangeLanguageSelected = ( languageIds: LanguageEnum[] ) => {
        onChangeMethod( 'languageIds', languageIds );
    };


    return (
        <>
            <Controller
                name='languageIds'
                control={ control }
                rules={{ required: true }}
                render={() => (
                    <SelectMultiTags
                        onTagsSelected={ ( value ) => handleOnChangeLanguageSelected( value as LanguageEnum[] ) }
                        tags={ Object.values( LanguageEnum ).map( ( language ) => ( {
                            id: language,
                            label: t( 'translation:enum.languages.' + language )
                        } ) ) }
                        defaultSelectedIds={ repository.languageIds }/>
                )}
            />
            { errors.languageIds &&
                <div className="w-fit text-sm font-normal text-red mt-1.5">
                    {t('common:error.form.required')}
                </div>
            }
        </>
            
    );
};