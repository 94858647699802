import { gql } from '@apollo/client';

const sendSuperLikeGQL = gql`
    mutation SendSuperLike($applicationId: String!, $message: String!) {
        sendSuperLike(applicationId: $applicationId, message: $message) {
            id
            jobPostId
        }
    }
`;

export default sendSuperLikeGQL;