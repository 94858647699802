import { Outlet } from 'react-router-dom';
import ClientNavBarTouch from '@/pages/client/layout/nav-bar/ClientNavBarTouch';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import ClientHeader from '@/pages/client/layout/header/ClientHeader';

export default function RootClient() {

    const { isTabletOrMobile, isHeaderVisible, isBottomNavBarVisible } = LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    return (
        <div className="min-h-screen flex flex-col h-full relative">
            {
                isHeaderVisible &&
                <ClientHeader />
            }

            <div className={ `main-container flex-grow flex flex-col pt-[84px] lg:pt-6 lg:pb-[72px] h-full ${ isTabletOrMobile && isBottomNavBarVisible
                                                                                        ? 'pb-[72px]'
                                                                                        : '' }` }>
                <Outlet />
            </div>
            {
                isTabletOrMobile &&
                isBottomNavBarVisible &&
                <ClientNavBarTouch />
            }
        </div>
    );
}