import { useRef, useState } from 'react';

interface FreelancerCreatorsIWorkWithProps {
    timer: number;
}

export default function MouseInactivityDetector( { timer }: FreelancerCreatorsIWorkWithProps ) {
    const [ isMouseActive, setIsMouseActive ] = useState( false );
    const timerRef = useRef( null );

    const handleMouseMove = () => {
        if( !isMouseActive ) {
            setIsMouseActive( true );
        }

        if( timerRef.current ) {
            clearTimeout( timerRef.current );
        }

        timerRef.current = setTimeout( () => {
            setIsMouseActive( false );
        }, timer );
    };

    const startWatcher = () => {
        window.addEventListener( 'mousemove', handleMouseMove );
    };

    const destroyWatcher = () => {
        window.removeEventListener( 'mousemove', handleMouseMove );
        if( timerRef.current ) {
            clearTimeout( timerRef.current );
        }
    };

    return {
        isMouseActive,
        startWatcher,
        destroyWatcher
    };
}
