import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import MenuSection from '@/components/MenuSection';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import { CheckOutlinedIcon, DiscordLogoIcon, QuestionMarkFilledIcon } from '@/assets/icons/CustomIcons';
import { useEffect, useRef } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { App, Button } from 'antd';
import * as Sentry from '@sentry/react';
import { useIntercomHook } from '@/plugins/intercom/useIntercomHook';
import gettingStartedHook from '@/hooks/gettingStartedHook';
import CloseJobPostModal from '../CloseJobPostModal';
import { JobPostStatusEnum } from '@/gql/graphql';
import { privateJobPostGQL, closeJobPostGQL } from '@/gql/global-queries';
import UpgradePlanBanner from '@/components/UpgradePlanBanner';
import countApplicationsByStatusGQL from '@/gql/global-queries/countApplicationsByStatusGQL';

export default function ClientJobPostManagement() {

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    const { jobPostId } = useParams();
    const { t } = useTranslation();
    const { gettingStartedCompleted } = gettingStartedHook();

    const isGettingStarted = useMatch( `/client/job-posts/${ jobPostId }/getting-started` );

    const closeJobPostModalRef = useRef( null );

    const jobPostQuery = useLazyQuery( privateJobPostGQL );
    const countApplicationByStatusQuery = useQuery( countApplicationsByStatusGQL, {
        variables: {
            jobPostId
        }
    } );

    const nav = [
        {
            title: t( 'client:jobPost.menu.gettingStarted' ),
            route: `/client/job-posts/${ jobPostId }/getting-started`,
            hidden: gettingStartedCompleted
        },
        {
            title: t( 'client:jobPost.menu.manageTalents.title' ),
            route: `/client/job-posts/${ jobPostId }/notified`,
            children: [
                {
                    title: t( 'client:jobPost.menu.manageTalents.outreached', {
                        count: countApplicationByStatusQuery?.data?.countApplicationsByStatus?.countOutreached
                    } ),
                    route: `/client/job-posts/${ jobPostId }/notified`
                },
                {
                    title: t( 'client:jobPost.menu.manageTalents.interested', {
                        count: countApplicationByStatusQuery?.data?.countApplicationsByStatus?.countInterested
                    } ),
                    route: `/client/job-posts/${ jobPostId }/interested`
                },
                {
                    title: t( 'client:jobPost.menu.manageTalents.favorites', {
                        count: countApplicationByStatusQuery?.data?.countApplicationsByStatus?.countInFavorite
                    } ),
                    route: `/client/job-posts/${ jobPostId }/favorites`
                }
            ]
        },
        {
            title: t( 'client:jobPost.menu.jobDetails' ),
            route: `/client/job-posts/${ jobPostId }/details`
        }
    ];

    const loadJobPost = (): void => {
        jobPostQuery[ 0 ]( {
            variables: {
                jobPostId
            }
        } );
    };

    const navigate = useNavigate();
    const { message } = App.useApp();


    const [ closeJobPost, { data, loading: isLoadingMutation } ] = useMutation( closeJobPostGQL );

    const handleCloseJobPost = async() => {
        try {

            const response = await closeJobPost( {
                variables: { jobPostId }
            } );

            if( response?.data ) {
                message.success( t( 'client:jobPost.form.success.closeSuccess' ) );
                navigate( '/client/job-posts/status/ongoing', { replace: true } );
            }
            else {
                message.error( {
                    content: t( 'client:jobPost.form.errors.submitError' ),
                    duration: 4
                } );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to create a new freelancer account', e );
            message.error( {
                content: t( 'client:jobPost.form.errors.submitError' ),
                duration: 4
            } );
        }
    };

    const goToDiscord = () => {
        window.open( 'https://discord.com/invite/s3uMYNPtwV', '_blank', 'noreferrer' );
    };

    const { toggleIntercomVisibility } = useIntercomHook();

    useEffect( () => {
        if( jobPostId != null ) {
            loadJobPost();
        }
    }, [ jobPostId ] );

    const leftSideBottomActionsJSX = (
        <>
            {
                isGettingStarted &&
                <div className="flex flex-col gap-10 border border-grey-light rounded-xl p-4">
                  <div className="flex flex-col gap-2">
                    <span className="font-semibold">
                        { t( 'client:jobPost.gettingStarted.help' ) }
                    </span>
                    <span className="text-grey text-sm">
                        { t( 'client:jobPost.gettingStarted.supportDescription' ) }
                    </span>
                    <Button onClick={ () => goToDiscord() }
                            type="default"
                            className="flex gap-1 items-center w-fit">
                      <DiscordLogoIcon className="text-sm leading-3" />
                      <span className="text-sm">
                          { t( 'client:jobPost.gettingStarted.dicordButton' ) }
                      </span>
                    </Button>
                    <Button onClick={ () => toggleIntercomVisibility() }
                            type="default"
                            className="flex gap-1 items-center w-fit">
                      <QuestionMarkFilledIcon className="text-sm leading-3" />
                      <span className="text-sm">
                          { t( 'client:jobPost.gettingStarted.supportButton' ) }
                      </span>
                    </Button>
                  </div>
                </div>
            }
            {
                jobPostQuery[ 1 ]?.data?.privateJobPost.status != JobPostStatusEnum.CLOSED &&
                <Button type="default"
                        className="w-full text-sm lg:w-fit mt-4 lg:mt-0"
                        onClick={ () => closeJobPostModalRef.current?.open() }>
                  <CheckOutlinedIcon className="text-sm text-primary" />
                    { t( 'client:jobPost.menu.endJobPost' ) }
                </Button>
            }
        </>
    );

    return (
        <div className="w-full h-full  flex flex-1 flex-col">
            { (!jobPostQuery[ 1 ]?.loading  && !jobPostQuery[ 1 ].data?.privateJobPost?.isPaidOffer) && <UpgradePlanBanner className="mb-6" /> }
            <div className="w-full h-full flex flex-1 flex-col lg:flex-row">
                <div className="flex flex-col justify-between">
                    <MenuSection routes={ nav }
                                 className="md:w-[180px]" />
                    <div className="hidden sticky bottom-12 md:flex flex-col gap-4">
                        { leftSideBottomActionsJSX }
                    </div>
                </div>
                <div className="mt-6 lg:mt-0 w-full flex flex-col gap-6 lg:ml-20 mb-5 overflow-hidden">
                    <Outlet context={ {
                        jobPost: jobPostQuery[ 1 ]?.data?.privateJobPost,
                        jobPostRefetch: jobPostQuery[ 1 ]?.refetch
                    } } />
                </div>
                <div className="md:hidden">
                    { leftSideBottomActionsJSX }
                </div>
            </div>
            <CloseJobPostModal ref={ closeJobPostModalRef }
                               isLoading={ isLoadingMutation }
                               onJobPostClose={ () => handleCloseJobPost() } />
        </div>
    );
}