import { useTranslation } from 'react-i18next';
import channelsHook from '@/hooks/channelsHook';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { GoogleLogoIcon, PlusOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Button } from 'antd';
import ChannelItem from '@/components/ChannelItem';
import { JobPostFormRepository } from '../jobPostFormRepository';
import { useForm } from 'react-hook-form';
import ListSkeleton from '@/components/ListSkeleton';
import CustomCheckboxComponent from '@/components/CustomCheckboxComponent';


const JobPostFormStepYoutubeChannel = forwardRef( function( props, ref ) {

    const { t } = useTranslation();
    const { loading, getChannels, channels, handleAddChannel } = channelsHook();

    const { control, watch } = useForm();
    const repository = new JobPostFormRepository();

    useImperativeHandle( ref, () => ( {
        async validateFields(): Promise<boolean> {
            return repository.channelId != null && repository.channelId !== '';
        }
    } ) );

    const subscription = watch( ( value ) => {
        repository.updateData( value );
    } );

    const addChannel = () => {
        handleAddChannel();
    };

    useEffect( () => {
        getChannels();
        return () => subscription.unsubscribe();
    }, [] );

    const loadingChannelListJSX = (
        <div className="flex flex-col space-y-4">
            <ListSkeleton isLoading={ true }
                          count={ 3 }
                          height={ 70 } />
        </div>
    );

    return (
        <>
            <div className="mt-6">
                <div className="flex flex-col gap-1.5 mb-6">
                    <span className="font-semibold text-lg">
                        { t( 'client:jobPost.form.channelPage.myChannels' ) }
                    </span>
                    <span className="text-grey">
                        {
                            channels?.length !== 0 ?
                            t( 'client:jobPost.form.channelPage.myChannelsInstruction' ) :
                            t( 'client:jobPost.form.channelPage.myChannelsNotPairedInstruction' )
                        }
                    </span>
                </div>
                {
                    loading ?
                    loadingChannelListJSX
                            :
                    (
                        channels?.length !== 0 ?
                        <>
                            <CustomCheckboxComponent
                                control={ control }
                                renderOptionContent={ ( channel ) => (
                                    <ChannelItem channel={ channel }
                                                 avatarSize={ 50 }
                                                 className="rounded-2xl py-3 px-2"
                                                 titleClass={ `hover:text-black group-hover:text-black ${ channel.id === repository.channelId && 'text-primary-500' }` }
                                    />
                                ) }
                                options={ channels }
                                limit={ 1 }
                                name="channelId"
                                direction="vertical"
                                defaultSelectedIds={ [ repository.channelId ] }
                            />

                            <Button className="flex items-center justify-center mt-4"
                                    loading={ loading }
                                    onClick={ addChannel }>
                                    <span className="font-semibold text-sm">
                                        { t( 'client:jobPost.form.channelPage.addNewChannelButton' ) }
                                    </span>
                                <PlusOutlinedIcon className="text-xs h-3" />
                            </Button>
                        </> :
                        <Button className="flex items-center justify-center"
                                loading={ loading }
                                onClick={ addChannel }>
                            <GoogleLogoIcon className="text-xl h-5 w-5" />
                            <span className="font-semibold">
                                    { t( 'client:jobPost.form.channelPage.googleAuthButton' ) }
                                </span>
                        </Button>
                    )
                }
            </div>
        </>
    );
});

export default JobPostFormStepYoutubeChannel;

