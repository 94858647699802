import { Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { SessionRepository } from '@/core/auth/sessionRepository';

interface AuthGuardProps {
    children: React.ReactNode;
    redirectPath: string;
}

export const AuthGuard = ( { redirectPath, children }: AuthGuardProps ) => {
    const sessionRepository = new SessionRepository();
    const [ isLoggedIn, setIsLoggedIn ] = useState<boolean>( null );

    useEffect( () => {

        const observable = sessionRepository.isLoggedIn$.subscribe( ( isLoggedIn ) => {
            setIsLoggedIn( () => isLoggedIn );
        } );

        return () => {
            observable.unsubscribe();
        };
    }, [] );

    return (
        <>
            {
                isLoggedIn != null && ( isLoggedIn ? children : <Navigate to={ redirectPath } /> )
            }
        </>
    );
};
