import { CloseOutlinedIcon } from '@/assets/icons/CustomIcons';
import { useNavigate } from 'react-router-dom';

interface HeaderTouchProps {
    closeButton?: boolean;
    left?: JSX.Element;
    right?: JSX.Element;
}

export default function HeaderTouch( { closeButton = false, left, right }: HeaderTouchProps ) {

    const navigate = useNavigate();

    const goBack = () => {
        navigate( '/client' );
    };

    return (
        <div className="fixed w-full bg-white px-4 md:px-6 py-4 h-[72px] z-50 flex items-center">
            {
                closeButton &&
                <div onClick={ () => goBack() }>
                  <CloseOutlinedIcon className="text-[16px] text-black mb-0 lg:mb-10" />
                </div>
            }
            <header className="w-full flex items-center justify-between">
                <div className="flex items-center">
                    { left }
                </div>

                <div className="h-full flex items-center">
                    { right }
                </div>
            </header>
        </div>
    );
}