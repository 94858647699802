import { gql } from '@apollo/client';

const channelGQL = gql`
    query Channel($id: String!) {
        channel(id: $id) {
            id
            name
            subscriberCount
            videoCount
            thumbnailUrl
        }
    }`;

export default channelGQL;

