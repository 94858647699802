const antThemeDefault = {
    token: {
        'colorPrimary': '#7f4eec',
        'colorInfo': '#7f4eec',
        'colorSuccess': '#17b26a',
        'colorSuccessText': '#067647',
        'colorSuccessBorder': '#abefc6',
        'colorSuccessBg': '#f0fdf4',
        'colorSuccessBgHover': '#17b26a',
        'colorSuccessBorderHover': '#067647',
        'colorSuccessTextActive': '#067647',
        'colorSuccessActive': '#067647',
        'colorSuccessTextHover': '#067647',
        'colorError': '#d92d20',
        'colorPrimaryTextHover': '#6a3bd9',
        'colorPrimaryBgHover': '#6a3bd9',
        'colorPrimaryBg': '#fbf8ff',
        'colorPrimaryBorder': '#7f4eec',
        'colorPrimaryText': '#7f4eec',
        'colorPrimaryHover': '#6a3bd9',
        'colorPrimaryBorderHover': '#6a3bd9',
        'borderRadius': 10,
        'wireframe': false,
        'fontSize': 14,
        'fontSizeLG': 16
    },
    'components': {
        'Switch': {},
        'Dropdown': {
            'controlPaddingHorizontal': 16
        },
        'Rate': {
            'colorFillContent': '#E6E9F0',
            'yellow6': '#000000',
            'marginXS': 4
        },
        'TextArea': {
            'borderRadius': 0
        },
        'Modal': {
            'colorIcon': 'rgb(113, 118, 123)',
            'colorIconHover': 'rgb(0, 0, 0)',
            'titleFontSize': 18
        },
        'Button': {
            'contentFontSizeSM': 16,
            'contentFontSizeLG': 18,
            'contentFontSize': 16,
            'borderRadius': 100,
            'borderRadiusLG': 100,
            'borderRadiusSM': 100,
            'fontWeight': 600,
            'paddingInline': 14,
            'paddingInlineSM': 12,
            'paddingInlineLG': 14,
            'onlyIconSizeSM': 16,
            'controlHeightSM': 40,
            'controlHeight': 44,
            'controlHeightLG': 48,
            'colorLink': 'rgb(0, 0, 0)',
            'colorLinkHover': 'rgb(106, 59, 217)'
        },
        'Select': {
            'optionFontSize': 16,
            'fontSize': 16,
            'optionPadding': '6px 14px'
        },
        'InputNumber': {
            'controlHeight': 56,
            'controlHeightLG': 56,
            'fontSize': 14,
            'fontSizeLG': 16,
            'borderRadius': 32
        },
        'Input': {
            'controlHeightSM': 48,
            'controlHeight': 48,
            'controlHeightLG': 56,
            'borderRadius': 32,
            'fontSize': 16,
            'fontSizeLG': 16
        },
        'Checkbox': {
            borderRadius: 4,
            borderRadiusSM: 4,
            borderRadiusLG: 6
        },
        'Tags': {
            'borderRadiusSM': 32,
            'lineWidth': 1.5,
            'marginXS': 0,
            'fontSize': 14,
            'fontSizeSM': 14
        }
    }
};

const antThemeLight = {
    ...antThemeDefault,
    components: {
        ...antThemeDefault.components,
        'Switch': {
            ...antThemeDefault.components.Switch,
            'colorTextQuaternary': 'rgb(230, 233, 240)'
        },
        'Avatar': {
            'groupBorderColor': '#E6E9F0',
            'groupSpace': 6,
            'groupOverlapping': -12
        },
        'Dropdown': {
            'controlItemBgHover': '#FBF8FF'
        },
        'Modal': {
            ...antThemeDefault.components.Modal
        },
        'Tabs': {
            'colorPrimary': '#7F4EEC',
            'colorText': '#000000',
            'colorSplit': '#808080'
        },
        'Select': {
            ...antThemeDefault.components.Select
        },
        'Tag': {
            ...antThemeDefault.components.Tags,
            'colorBorder': 'rgb(234, 236, 240)',
            'defaultColor': 'rgb(113, 118, 123)',
            'colorText': 'rgb(113, 118, 123)',
            'defaultBg': 'rgba(0, 0, 0, 0)'
        },
        'InputNumber': {
            'colorBorder': '#000000'
        },
        'Button': {
            ...antThemeDefault.components.Button,
            'defaultBorderColor': '#E6E9F0',
            'defaultColor': '#000000'
        },
        'Input': {
            ...antThemeDefault.components.Input,
            'colorBg': '#FFFFFF'
        },
        'Radio': {
            'buttonColor': 'rgba(0, 0, 0, 0.88)',
            'buttonCheckedBg': '#ffffff',
            'buttonSolidCheckedColor': '#fff',
            'buttonBg': '#ffffff',
            'colorPrimary': '#000000',
            'colorBorder': '#d9d9d9',
            'colorPrimaryActive': '#000000',
            'colorText': 'inherit',
            'colorPrimaryHover': '#1F1F1F'
        }
    }
};

const antThemeDark = {
    ...antThemeDefault,
    ...antThemeLight // TODO update it when dark mode will be restored
};

export { antThemeLight, antThemeDark };