export default [
    {
        value: 'US',
        label: 'common:countries.US'
    },
    {
        value: 'AE',
        label: 'common:countries.AE'
    },
    {
        value: 'AG',
        label: 'common:countries.AG'
    },
    {
        value: 'AL',
        label: 'common:countries.AL'
    },
    {
        value: 'AM',
        label: 'common:countries.AM'
    },
    {
        value: 'AR',
        label: 'common:countries.AR'
    },
    {
        value: 'AT',
        label: 'common:countries.AT'
    },
    {
        value: 'AU',
        label: 'common:countries.AU'
    },
    {
        value: 'BA',
        label: 'common:countries.BA'
    },
    {
        value: 'BE',
        label: 'common:countries.BE'
    },
    {
        value: 'BG',
        label: 'common:countries.BG'
    },
    {
        value: 'BH',
        label: 'common:countries.BH'
    },
    {
        value: 'BO',
        label: 'common:countries.BO'
    },
    {
        value: 'CA',
        label: 'common:countries.CA'
    },
    {
        value: 'CH',
        label: 'common:countries.CH'
    },
    {
        value: 'CI',
        label: 'common:countries.CI'
    },
    {
        value: 'CL',
        label: 'common:countries.CL'
    },
    {
        value: 'CO',
        label: 'common:countries.CO'
    },
    {
        value: 'CR',
        label: 'common:countries.CR'
    },
    {
        value: 'CY',
        label: 'common:countries.CY'
    },
    {
        value: 'CZ',
        label: 'common:countries.CZ'
    },
    {
        value: 'DE',
        label: 'common:countries.DE'
    },
    {
        value: 'DK',
        label: 'common:countries.DK'
    },
    {
        value: 'DO',
        label: 'common:countries.DO'
    },
    {
        value: 'EC',
        label: 'common:countries.EC'
    },
    {
        value: 'EE',
        label: 'common:countries.EE'
    },
    {
        value: 'EG',
        label: 'common:countries.EG'
    },
    {
        value: 'ES',
        label: 'common:countries.ES'
    },
    {
        value: 'ET',
        label: 'common:countries.ET'
    },
    {
        value: 'FI',
        label: 'common:countries.FI'
    },
    {
        value: 'FR',
        label: 'common:countries.FR'
    },
    {
        value: 'GB',
        label: 'common:countries.GB'
    },
    {
        value: 'GH',
        label: 'common:countries.GH'
    },
    {
        value: 'GM',
        label: 'common:countries.GM'
    },
    {
        value: 'GR',
        label: 'common:countries.GR'
    },
    {
        value: 'GT',
        label: 'common:countries.GT'
    },
    {
        value: 'GY',
        label: 'common:countries.GY'
    },
    {
        value: 'HK',
        label: 'common:countries.HK'
    },
    {
        value: 'HR',
        label: 'common:countries.HR'
    },
    {
        value: 'HU',
        label: 'common:countries.HU'
    },
    {
        value: 'ID',
        label: 'common:countries.ID'
    },
    {
        value: 'IE',
        label: 'common:countries.IE'
    },
    {
        value: 'IL',
        label: 'common:countries.IL'
    },
    {
        value: 'IS',
        label: 'common:countries.IS'
    },
    {
        value: 'IT',
        label: 'common:countries.IT'
    },
    {
        value: 'JM',
        label: 'common:countries.JM'
    },
    {
        value: 'JO',
        label: 'common:countries.JO'
    },
    {
        value: 'JP',
        label: 'common:countries.JP'
    },
    {
        value: 'KE',
        label: 'common:countries.KE'
    },
    {
        value: 'KH',
        label: 'common:countries.KH'
    },
    {
        value: 'KR',
        label: 'common:countries.KR'
    },
    {
        value: 'KW',
        label: 'common:countries.KW'
    },
    {
        value: 'LC',
        label: 'common:countries.LC'
    },
    {
        value: 'LI',
        label: 'common:countries.LI'
    },
    {
        value: 'LK',
        label: 'common:countries.LK'
    },
    {
        value: 'LT',
        label: 'common:countries.LT'
    },
    {
        value: 'LU',
        label: 'common:countries.LU'
    },
    {
        value: 'LV',
        label: 'common:countries.LV'
    },
    {
        value: 'MA',
        label: 'common:countries.MA'
    },
    {
        value: 'MD',
        label: 'common:countries.MD'
    },
    {
        value: 'MG',
        label: 'common:countries.MG'
    },
    {
        value: 'MK',
        label: 'common:countries.MK'
    },
    {
        value: 'MN',
        label: 'common:countries.MN'
    },
    {
        value: 'MO',
        label: 'common:countries.MO'
    },
    {
        value: 'MT',
        label: 'common:countries.MT'
    },
    {
        value: 'MU',
        label: 'common:countries.MU'
    },
    {
        value: 'MX',
        label: 'common:countries.MX'
    },
    {
        value: 'MY',
        label: 'common:countries.MY'
    },
    {
        value: 'NA',
        label: 'common:countries.NA'
    },
    {
        value: 'NG',
        label: 'common:countries.NG'
    },
    {
        value: 'NL',
        label: 'common:countries.NL'
    },
    {
        value: 'NO',
        label: 'common:countries.NO'
    },
    {
        value: 'NZ',
        label: 'common:countries.NZ'
    },
    {
        value: 'OM',
        label: 'common:countries.OM'
    },
    {
        value: 'PA',
        label: 'common:countries.PA'
    },
    {
        value: 'PE',
        label: 'common:countries.PE'
    },
    {
        value: 'PH',
        label: 'common:countries.PH'
    },
    {
        value: 'PL',
        label: 'common:countries.PL'
    },
    {
        value: 'PT',
        label: 'common:countries.PT'
    },
    {
        value: 'PY',
        label: 'common:countries.PY'
    },
    {
        value: 'QA',
        label: 'common:countries.QA'
    },
    {
        value: 'RO',
        label: 'common:countries.RO'
    },
    {
        value: 'RS',
        label: 'common:countries.RS'
    },
    {
        value: 'RW',
        label: 'common:countries.RW'
    },
    {
        value: 'SA',
        label: 'common:countries.SA'
    },
    {
        value: 'SE',
        label: 'common:countries.SE'
    },
    {
        value: 'SG',
        label: 'common:countries.SG'
    },
    {
        value: 'SI',
        label: 'common:countries.SI'
    },
    {
        value: 'SK',
        label: 'common:countries.SK'
    },
    {
        value: 'SN',
        label: 'common:countries.SN'
    },
    {
        value: 'SV',
        label: 'common:countries.SV'
    },
    {
        value: 'TH',
        label: 'common:countries.TH'
    },
    {
        value: 'TN',
        label: 'common:countries.TN'
    },
    {
        value: 'TR',
        label: 'common:countries.TR'
    },
    {
        value: 'TT',
        label: 'common:countries.TT'
    },
    {
        value: 'TW',
        label: 'common:countries.TW'
    },
    {
        value: 'TZ',
        label: 'common:countries.TZ'
    }
];