import { Link, useOutletContext, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { JobPost } from '@/gql/graphql';
import InfoMessage from '@/components/InfoMessage';
import { App, Button } from 'antd';
import { ChainLinkFilledIcon, CheckOutlinedIcon } from '@/assets/icons/CustomIcons';
import PairChannelModal from '../PairChannelModal';
import { useRef } from 'react';
import gettingStartedHook from '@/hooks/gettingStartedHook';
import ListSkeleton from '@/components/ListSkeleton';
import attachChannelToJobPostGQL from '@/gql/global-queries/attachChannelToJobPostGQL';
import { SegmentEvent, trackSegmentEvent } from '@/utils/analytics';

const stepsEnum = {
    DISCOVER: 'discoverOutreachedProfiles',
    INTERESTED: 'reviewInterestedTalents',
}

const ClientJobPostGettingStated = () => {
    const { jobPostId } = useParams();
    const { jobPost } = useOutletContext<{ jobPost: JobPost,  jobPostRefetch: any }>();

    const { t } = useTranslation();
    const { message } = App.useApp();

    const pairChannelModalRef = useRef( null );

    const { gettingStartedSteps, loading: isLoadingGettingStarted, updateSteps } = gettingStartedHook();

    const attachChannelToJobPostMutation = useMutation( attachChannelToJobPostGQL );

    const handleStepClick = async( step ): Promise<void> => {
        const areAllStepsTrue = Object.values( gettingStartedSteps ).map((value, index) => {
            if (index === Object.keys( gettingStartedSteps ).indexOf( step )) {
                value = true;
            }
            return value;
        }).every(value => value === true);

        if( areAllStepsTrue ) {            
            trackSegmentEvent( SegmentEvent.GettingStartedFinishedCreator );
        }
        
        const updatedSteps = { ...gettingStartedSteps, [ step ]: true };
        updateSteps( updatedSteps );
    };

    const handlePairChannel = () => {
        pairChannelModalRef.current.open();
    };

    const onPairChannel = async ( channelId ) => {
        try {            
            const result = await attachChannelToJobPostMutation[ 0 ]( {
                variables: {
                    channelId,
                    jobPostId: jobPost?.id,
                }
            } );

            if( result.data ) {
                pairChannelModalRef.current.close();
                message.success( t( 'client:jobPost.pairChannel.success' ));
            } else {
                message.error( t( 'translation:error.default' ) );
            }
        } catch( e ) {            
            message.error( t( 'translation:error.default' ) );
        }
    }

    const steps = [
        {
            id: stepsEnum.DISCOVER,
            title: t('client:jobPost.gettingStarted.outreachedTitle'),
            description: t('client:jobPost.gettingStarted.outreachedDescription'),
            link: `/client/job-posts/${ jobPostId }/notified`
        },
        {
            id: stepsEnum.INTERESTED,
            title: t('client:jobPost.gettingStarted.interestedTitle'),
            description: t('client:jobPost.gettingStarted.interestedDescription'),
            link: `/client/job-posts/${ jobPostId }/interested`
        },
    ]

    const completedStep = 
        <div className="w-10 h-10 rounded-full bg-green-500 flex justify-center items-center text-white font-sharp">
            <CheckOutlinedIcon />
        </div>

    const skeleton = ( limit: number ) => {
        return (
            <div className="flex flex-col gap-y-4">
                <ListSkeleton isLoading={ true }
                    height={ 130 }
                    count={ limit } />
            </div>
        );
    };

    if( isLoadingGettingStarted ) {
        return skeleton( 4 );
    }
    
    return (
        <div className="flex flex-col gap-10">
            <h4 className="font-sharp text-2xl">{t('client:jobPost.gettingStarted.title')}</h4>
            {
                !jobPost?.channel &&
                <InfoMessage>
                    <div className='flex flex-col gap-2'>
                        <span>
                            <Trans 
                                i18nKey="client:jobPost.pairChannel.description" 
                                components={[<span/>,<strong />]}
                            />
                        </span>
                        <Button onClick={ () => handlePairChannel() } className='px-4 py-0 h-8 text-sm w-fit' type="default">
                            <ChainLinkFilledIcon />
                            {t('client:jobPost.pairChannel.button')}
                        </Button>
                    </div>
                </InfoMessage>
            }

            <div className="flex flex-col gap-4">
                {
                    steps.map(( step, index ) => (
                        <Link key={ step.id } to={ step.link } onClick={ () => handleStepClick( step.id ) } className="flex gap-8 items-center border p-6 border-grey-light rounded-xl group hover:border-primary-500 hover:bg-primary-100 transition-all duration-300">
                            {
                                gettingStartedSteps?.[ step?.id ] ?
                                    completedStep
                                    :
                                    <div className="w-10 h-10 rounded-full bg-primary-500 flex justify-center items-center text-white font-sharp">
                                        { index + 1 }
                                    </div>
                            }
                            
                            <div className="flex flex-col gap-2 flex-1">
                                <span className="font-sharp transition-all duration-300 group-hover:text-primary-500">
                                    { step.title }
                                </span>
                                <span className="text-grey transition-all duration-300 group-hover:text-primary-500">
                                    { step.description }
                                </span>
                            </div>
                        </Link>
                    ))
                }
            </div>
            <PairChannelModal ref={ pairChannelModalRef } onChannelIdSumbitted={( channelId: string ) => onPairChannel( channelId )} />
        </div>
    )
};

export default ClientJobPostGettingStated
