import { gql } from '@apollo/client';

const loginWithPasswordGQL = gql`
    mutation LoginWithPasswordGQL($args: LoginInput!){
        loginWithPassword(args: $args){
            accessToken
            refreshToken
            email
            user {
                id
                type
                firstName
                lastName
                phone
                userName
                profilePicture
            }
        }
    }
`;

export default loginWithPasswordGQL;