import { forwardRef, useImperativeHandle, useRef } from 'react';
import { App, Button } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ResponsiveModal from '@/components/ResponsiveModal';
import { Application, JobPost } from '@/gql/graphql';
import { FlashFilledIcon, SuperLikeFilledIcon } from '@/assets/icons/CustomIcons';
import TextAreaComponent from '@/components/custom-element-form/TextAreaComponent';
import { Form } from 'react-router-dom';
import JobPostCard from '@/components/job-post/JobPostCard';
import { SegmentEvent, trackSegmentEvent } from '@/utils/analytics';
import { useMutation } from '@apollo/client';
import { sendSuperLikeGQL } from '@/gql/global-queries';

interface ApplicationFollowUpModalProps {
    application: Application;
    jobPost?: JobPost;
    onFollowUpMessageSubmitted?: () => void;
}

const ApplicationFollowUpModal = forwardRef( ( props: ApplicationFollowUpModalProps, ref ) => {

    const { message } = App.useApp();

    const INPUT_MAXLENGTH: number = 1000;
    const { application, jobPost, onFollowUpMessageSubmitted } = props;
    const { t } = useTranslation();

    const projectRef = useRef( null );
    const sendSuperLikeMutation = useMutation( sendSuperLikeGQL );

    const { trigger, control, handleSubmit, reset, formState: { errors } } = useForm<{
        message: string
    }>( { defaultValues: { message: '' } } );

    useImperativeHandle( ref, () => ( {
        open() {
            projectRef.current.open();
        },
        close() {
            projectRef.current.close();
        }
    } ) );

    const onMessageSent = async( data: any ) => {

        const message = data.message;

        if( !( await trigger() ) ) {
            return;
        }

        try {
            const response = await sendSuperLikeMutation[ 0 ]( {
                variables: {
                    applicationId: application.id,
                    message
                }
            } );

            if( response.data.sendSuperLike ) {
                trackSegmentEvent(
                    SegmentEvent.SuperlikeSent,
                    {
                        message_added: message,
                        job_post_id: application.jobPostId
                    }
                );
            }
        } catch( e ) {
            message.error( t( 'translation:error.default' ) );
        }

        await reset( { message: '' } );
        projectRef.current.close();
    };

    return (
        <ResponsiveModal ref={ projectRef }
                         destroyOnClose={ true }
                         desktopWidth="700px"
                         rootClassName="follow-up-modal">
            <div className="flex gap-28 py-20 px-10">
                <div className="flex flex-col gap-6">
                    <h4 className="font-sharp text-2xl">{ t( 'client:jobPost.superLikeModal.title' ) }</h4>
                    <span className="text-grey text-base">
                        <Trans i18nKey="client:jobPost.superLikeModal.description"
                               values={ {
                                   firstName: application?.freelancer?.firstName,
                                   lastName: application?.freelancer?.lastName
                               } }>
                            <strong className="font-semibold"></strong>
                        </Trans>
                    </span>
                    <Form onSubmit={ handleSubmit( onMessageSent ) }>
                        <TextAreaComponent control={ control }
                                           rows={ 2 }
                                           className="w-full"
                                           name="message"
                                           label="Your message"
                                           rules={ {
                                               required: t( 'common:error.form.required' ),
                                               maxLength: {
                                                   value: INPUT_MAXLENGTH,
                                                   message: t( 'common:error.form.maxLength', { maxLength: INPUT_MAXLENGTH } )
                                               }
                                           } }
                                           maxLength={ INPUT_MAXLENGTH }
                                           errors={ errors }
                        />
                        <Button type="primary"
                                loading={sendSuperLikeMutation[1].loading }
                                className="w-full text-sm mt-6"
                                htmlType="submit">
                            <SuperLikeFilledIcon className="text-sm text-white" />
                            { t( 'client:jobPost.superLikeModal.sendButton', { name: application?.freelancer?.firstName } ) }
                        </Button>
                    </Form>
                </div>
            </div>
        </ResponsiveModal>
    );
} );

export default ApplicationFollowUpModal;
