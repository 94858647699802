import * as Sentry from '@sentry/react';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { useTranslation } from 'react-i18next';
import { Freelancer } from '@/gql/graphql';
import { App } from 'antd';

export function freelancerProfileLinkHook() {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const sessionRepository: SessionRepository = new SessionRepository();

    const getUrl = ( id?: string | null ): string => {
        const user: Freelancer = sessionRepository.user as Freelancer;

        if( id == null || id == '' ) {
            id = user.customUrl ? user.customUrl : user.id;
        }

        return `${ import.meta.env.VITE_ENDPOINT }/p/${ id }`;
    };

    const getProfileLink = async( id?: string | null ): Promise<void> => {
        const profileUrl: string = getUrl( id );
        try {
            await navigator.clipboard.writeText( profileUrl );
            message.success( t( 'freelancer:account.successCopyInClipboard' ) );
        } catch( e ) {
            Sentry.captureException( e );
            message.error( t( 'freelancer:account.errorCopyInClipboard' ) );
        }
    };

    return {
        getProfileLink,
        getUrl
    };
}