import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import { JobPostFormRepository } from '@/pages/client/views/job-post/job-post-form/jobPostFormRepository';

import InputComponent from '@/components/custom-element-form/InputComponent';
import { Divider } from 'antd';
import JobPostFormJob from '../component/JobPostFormJob';
import JobPostContentType from '../component/JobPostContentType';
import JobPostFormExperiences from '../component/JobPostFormExperiences';
import JobPostJobType from '../component/JobPostJobType';
import JobPostFormLanguages from '../component/JobPostFormLanguage';
import { useForm } from 'react-hook-form';
import TextAreaComponent from '@/components/custom-element-form/TextAreaComponent';

const JobPostFormStepJobInfo = forwardRef( function( props, ref ) {
    const INPUT_MINLENGTH: number = 250;
    const { t } = useTranslation();
    const { trigger, control, watch, setValue, reset, formState: { errors } } = useForm();
    const repository = new JobPostFormRepository();

    const subscription = watch( ( value ) => {
        repository.updateData( value );
    } );

    useImperativeHandle( ref, () => ( {
        async validateFields(): Promise<boolean> {
            return trigger();
        }
    } ) );

    useEffect( () => {
        initForm()
        return () => subscription.unsubscribe();
    }, [] );

    const initForm = () => {
        reset({
            languageIds: repository.languageIds,
            type: repository.type,
            title: repository.title,
            description: repository.description,
            role: repository.role,
            experiences: repository.experiences,
            contentType: repository.contentType,
        });
    }

    const handleSelectChanged = ( title: string, value: any ) => {
        setValue( title, value );
        repository[ title ] = value;
    };

    return (
        <form className="flex w-full flex-col gap-y-4">
            <div className="flex flex-col">
                <span className="font-semibold text-lg">{ t( 'client:jobPost.form.jobMainInfoPage.jobTitle.title' ) }</span>
                <span className="block pb-2 text-grey">{ t( 'client:jobPost.form.jobMainInfoPage.jobTitle.subtitle' ) }</span>
                <InputComponent control={ control }
                                name="title"
                                defaultValue={ repository.title }
                                className="w-full text-black"
                                inputClassName="font-sharp"
                                label={ t( 'client:jobPost.form.jobMainInfoPage.jobTitle.titleLabel' ) }
                                placeholder={ t( 'client:jobPost.form.jobMainInfoPage.jobTitle.titlePlaceholder' ) }
                                errors={ errors }
                                rules={ {
                                    required: t( 'common:error.form.required' ),
                                    validate: value => value.trim() !== '' || t( 'common:error.form.required' )
                                } } />
            </div>

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <div className="flex flex-col">
                <span className="font-semibold text-lg">{ t( 'client:jobPost.form.jobAdditionalInfoPage.description.title' ) }</span>
                <span className="block pb-2 text-grey">{ t( 'client:jobPost.form.jobAdditionalInfoPage.description.subtitle' ) }</span>
                <TextAreaComponent control={ control }
                                   rows={ 6 }
                                   className="w-full text-black"
                                   defaultValue={ repository.description }
                                   name="description"
                                   label={ t( 'client:jobPost.form.jobAdditionalInfoPage.description.descriptionLabel' ) }
                                   errors={ errors }
                                   rules={ {
                                        required: t( 'common:error.form.required' ),
                                        minLength: {
                                            value: INPUT_MINLENGTH,
                                            message: t( 'common:error.form.descriptionMinLength', { minLength: INPUT_MINLENGTH } )
                                        }
                                   } }
                />
            </div>

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <div className="flex flex-col gap-1.5">
                <span className="font-semibold text-lg">{ t( 'client:jobPost.form.jobMainInfoPage.role.title' ) }</span>
                <span className="block pb-2 text-grey">{ t( 'client:jobPost.form.jobMainInfoPage.role.subtitle' ) }</span>
                <JobPostFormJob errors={ errors }
                                control={ control }
                                onChange={ handleSelectChanged } />
            </div>

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <div className="flex flex-col gap-1.5">
                <span className="font-semibold text-lg">{ t( 'client:jobPost.form.jobMainInfoPage.experience.title' ) }</span>
                <span className="block pb-2 text-grey">{ t( 'client:jobPost.form.jobMainInfoPage.experience.subtitle' ) }</span>
                <JobPostFormExperiences errors={ errors }
                                        control={ control }
                                        onChange={ handleSelectChanged } />
            </div>

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <div className="flex flex-col gap-1.5">
                <span className="font-semibold text-lg">{ t( 'client:jobPost.form.jobMainInfoPage.language.title' ) }</span>
                <span className="block pb-2 text-grey">{ t( 'client:jobPost.form.jobMainInfoPage.language.subtitle' ) }</span>
                <JobPostFormLanguages errors={ errors }
                                      control={ control }
                                      onChange={ handleSelectChanged } />
            </div>

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <div className="flex flex-col gap-1.5">
                <span className="font-semibold text-lg">{ t( 'client:jobPost.form.jobMainInfoPage.formatType.title' ) }</span>
                <span className="block pb-2 text-grey">{ t( 'client:jobPost.form.jobMainInfoPage.formatType.subtitle' ) }</span>
                <JobPostContentType errors={ errors }
                                    control={ control }
                                    onChange={ handleSelectChanged } />
            </div>

            <Divider type="horizontal"
                     className="my-2 lg:my-6" />

            <div className="flex flex-col gap-1.5">
                <span className="font-semibold text-lg">{ t( 'client:jobPost.form.jobMainInfoPage.type.title' ) }</span>
                <span className="block pb-2 text-grey">{ t( 'client:jobPost.form.jobMainInfoPage.type.subtitle' ) }</span>
                <JobPostJobType errors={ errors }
                                control={ control }
                                onChange={ handleSelectChanged } />
            </div>
        </form>
    );
} );

export default JobPostFormStepJobInfo;