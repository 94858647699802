import { gql } from '@apollo/client';

export const ordersGQL = gql`
    query Orders($status: OrderStatusEnum!, $limit: Int, $page: Int)  {
        orders(status: $status, limit: $limit, page: $page)  {
            data {
                id
                isActive
                isSubscription
                startAt
                jobPostId
            }
            total
            page
            limit
            lastPage
        }
    }`;
