import { useLazyQuery } from '@apollo/client';
import { clientApplicationsGQL } from '@/gql/global-queries';

interface clientApplicationsHookProps {
    limit?: number;
}

export default function clientApplicationsHook( { limit }: clientApplicationsHookProps = {}  ) {
  
    const [ applicationsQuery, { data, loading, error, refetch } ] = useLazyQuery( clientApplicationsGQL );

    const loadJobPosts = ( {
        jobPostId = null,
        applicationStatus = 'OUTREACHED',
        orderBy = 'DESC',
        page = 1,
        youtubeIds = [],
        onlyPerfectMatch = false
    } = {}) => {
        
        applicationsQuery( {
            variables: {
                args: {
                    applicationStatus,
                    jobPostId,
                    onlyPerfectMatch,
                    orderBy,
                    youtubeIds
                },
                limit,
                page
            },
        } );
    };

    return {
        loadJobPosts,
        refetch,
        applications: data?.applications?.data,
        loading,
        total: data?.applications?.total,
        page: data?.applications?.page,
    };
}