import { gql } from '@apollo/client';

const channelsGQL = gql`
    query Channels {
        channels {
            id
            name
            subscriberCount
            videoCount
            thumbnailUrl
        }
    }`;

export default channelsGQL;