import ListSkeleton from '@/components/ListSkeleton';
import JobPostTalentCard from './JobPostTalentCard';
import ClientNoJobPostCard from '@/pages/client/views/job-posts/components/ClientNoJobPostCard';
import { Application, ApplicationTypeEnum } from '@/gql/graphql';
import { Link } from 'react-router-dom';
import { applicationTypeStatus, SegmentEvent, trackSegmentEvent } from '@/utils/analytics';

interface JobPostTalentListProps {
    applications: Application[];
    isLoading: boolean;
    applicationsStatus: ApplicationTypeEnum;
    onFollowUpMessage?: ( application: Application ) => void;
    onUpdateFavoriteStatus?: ( status, applicationId ) => void;
    onShowContactDetails?: ( application: Application ) => void;
}

export default function JobPostTalentList( {
                                                applications,
                                                isLoading,
                                                applicationsStatus,
                                                onFollowUpMessage,
                                                onUpdateFavoriteStatus,
                                                onShowContactDetails
                                            }: JobPostTalentListProps ) {

    const handleApplicationSelected = ( application: Application ) => onFollowUpMessage?.( application );

    const handleUpdateFavoriteStatus = ( status, applicationId ) => onUpdateFavoriteStatus?.( status, applicationId );

    const handleShowContactDetails = ( application: Application ) => onShowContactDetails?.( application );

    const onTalentClicked = ( id: string ) => {
        trackSegmentEvent( SegmentEvent.ProfileClicked, {
            profile_clicked_id: id,
            profile_clicked_origin: applicationTypeStatus[ applicationsStatus ]
        } );
    };

    if( isLoading ) {
        return (
            <div className="flex flex-col gap-y-4">
                <ListSkeleton isLoading={ true }
                              height={ 130 }
                              count={ 8 } />
            </div>
        );
    }

    if( !isLoading && applications?.length > 0 ) {
        return (
            <div className="flex flex-col gap-4 w-full">
                {
                    applications?.map( ( application, index ) => (
                        <Link key={ index }
                              onClick={ () => onTalentClicked( application.freelancer.id ) }
                              className="text-current"
                              to={ `../p/${ application.freelancer.id }` }>
                            <JobPostTalentCard key={ application.id }
                                               canSendSuperLike={ applicationsStatus == ApplicationTypeEnum.OUTREACHED && application.isUnlock }
                                               talent={ application.freelancer }
                                               onFollowUp={ () => handleApplicationSelected( application ) }
                                               onUpdateFavorite={ () => handleUpdateFavoriteStatus( !application.isFavoriteAt, application.id ) }
                                               onShowContactDetails={ () => handleShowContactDetails( application ) }
                                               applicationsStatus={ applicationsStatus }
                                               applyAt={ application.appliedAt }
                                               isFavorite={ !!application.isFavoriteAt }
                                               freelancerMessage={ application.message }
                                               creatorHasSentSuperLikeMessage={ !!application.superLikeMessage } />
                        </Link>
                    ) )
                }
            </div>
        );
    }

    return (
        <div className="flex justify-center items-center w-full">
            <ClientNoJobPostCard />
        </div>
    );
}