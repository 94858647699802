import { gql } from '@apollo/client';

const publicCreatePortfolioElementGQL = gql`
    mutation PublicCreatePortfolioElement($url: String!) {
        publicCreatePortfolioElement(url: $url) {
            portfolioElements {
                id
                type
                title
                thumbnailUrl
                viewCount
                url
                portfolioChannel {
                    id
                    name
                    subscriberCount
                    viewCount
                    videoCount
                }
            }
            failed 
        }
    }
`;

export default publicCreatePortfolioElementGQL;