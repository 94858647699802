/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. BigInt can represent values between -(2^53) + 1 and 2^53 - 1.  */
  BigInt: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: any;
};

export type Action = {
  __typename?: 'Action';
  /** The date when the entity was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The date when the entity was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  /** The date when the entity was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** If this action is related to a user, this field will be populated */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type ActionByUserInput = {
  type?: InputMaybe<Scalars['String']>;
};

export type ActionPaginated = {
  __typename?: 'ActionPaginated';
  data?: Maybe<Array<Maybe<Action>>>;
  lastPage: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  total: Scalars['Int'];
};

/** The possible values for the order by enum */
export enum ActionTypeEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Application = {
  __typename?: 'Application';
  /** When the freelancer has officially applied to the job post */
  appliedAt?: Maybe<Scalars['DateTime']>;
  /** The date when the entity was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** When the freelancer has officially declined the job post */
  declinedAt?: Maybe<Scalars['DateTime']>;
  /** The date when the entity was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The id of the freelancer who sent the application */
  freelancer: Freelancer;
  /** The id of the freelancer's application */
  freelancerId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  /** When the client has start the discussion process */
  isFavoriteAt?: Maybe<Scalars['DateTime']>;
  /** If the freelancer has been notified or not */
  isNotified?: Maybe<Scalars['Boolean']>;
  isUnlock: Scalars['Boolean'];
  /** If the client has unlocked the application or not. If true, the client has unlocked */
  isUnlockAt?: Maybe<Scalars['DateTime']>;
  /** The id of the job post */
  jobPostId: Scalars['String'];
  /** The message sent by freelancer to the client */
  message?: Maybe<Scalars['String']>;
  /** The percentage of matching score between the job post and the freelancer */
  score: Scalars['Int'];
  /** If the freelancer has seen the application or not, if true, the date of the application is considered as seen by the freelancer, if null, the freelancer has not seen the application yet. */
  seenAt?: Maybe<Scalars['DateTime']>;
  /** The freelancer who sent the application */
  sentBy: ApplicationSentByEnum;
  /** The id of the job post */
  status: ApplicationTypeEnum;
  /** The text made by the client when he super liked the freelancer. This text is used to send a special message to the freelancer. */
  superLikeMessage?: Maybe<Scalars['String']>;
  /** If the client has super liked the freelancer the date of the super like is stored here. If null, the client has not super liked the freelancer yet. */
  superLikeSentAt?: Maybe<Scalars['DateTime']>;
  /** The date when the entity was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApplicationCountsOutput = {
  __typename?: 'ApplicationCountsOutput';
  countInFavorite: Scalars['Int'];
  countInterested: Scalars['Int'];
  countOutreached: Scalars['Int'];
  countPerfectMatchInterested: Scalars['Int'];
  countPerfectMatchOutreached: Scalars['Int'];
};

export type ApplicationPaginated = {
  __typename?: 'ApplicationPaginated';
  data?: Maybe<Array<Maybe<Application>>>;
  lastPage: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  total: Scalars['Int'];
};

/** The entity who sent the application */
export enum ApplicationSentByEnum {
  CLIENT = 'CLIENT',
  FREELANCER = 'FREELANCER',
  SYSTEM = 'SYSTEM'
}

/** The status of the application between a client and a freelancer */
export enum ApplicationTypeEnum {
  DECLINED = 'DECLINED',
  FAVORITE = 'FAVORITE',
  INTERESTED = 'INTERESTED',
  OUTREACHED = 'OUTREACHED'
}

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['JWT'];
  /** CometChatAuthToken */
  cometchatAuthToken?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** JWT refresh token */
  refreshToken: Scalars['JWT'];
  user: User;
};

export type AverageDailyRateRangeInput = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type Channel = {
  __typename?: 'Channel';
  acquisitionDate?: Maybe<Scalars['DateTime']>;
  /** The status of the acquisition of the channel */
  acquisitionStatus: ChannelStatusAcquisition;
  averageRate: Scalars['Float'];
  /** The average rate of the channel */
  avgRate?: Maybe<Scalars['Int']>;
  /** The image banner of the channel */
  bannerImageUrl?: Maybe<Scalars['String']>;
  /** All users how can use the channel to make projects */
  clients?: Maybe<Array<Maybe<User>>>;
  /** The number of projects of the channel */
  countProjects?: Maybe<Scalars['Int']>;
  /** The date when the entity was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The Youtube custom url of the channel */
  customUrl?: Maybe<Scalars['String']>;
  /** The date when the entity was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The channel's description */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** All job posts published on the channel */
  jobPosts?: Maybe<Array<Maybe<JobPost>>>;
  /** The channel's name */
  name?: Maybe<Scalars['String']>;
  /** The number of subscribers of the channel */
  subscriberCount?: Maybe<Scalars['BigInt']>;
  /** The thumbnail of youtube channel */
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** The token id of the channel */
  tokenId?: Maybe<Scalars['String']>;
  /** The date when the entity was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The Youtube url of the channel */
  url?: Maybe<Scalars['String']>;
  /** The number of videos of the channel */
  videoCount?: Maybe<Scalars['Int']>;
  /** The number of views of the channel */
  viewCount?: Maybe<Scalars['BigInt']>;
};

/** The status of the acquisition of the channel */
export enum ChannelStatusAcquisition {
  ACQUIRED = 'ACQUIRED',
  PENDING = 'PENDING',
  WAITING = 'WAITING'
}

export type ChecklistVerifiedProfile = {
  __typename?: 'ChecklistVerifiedProfile';
  contacts?: Maybe<Scalars['Boolean']>;
  portfolio?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  /** The user's address city */
  addressCity?: Maybe<Scalars['String']>;
  /** The user's address country */
  addressCountry?: Maybe<Scalars['String']>;
  /** The user's address name */
  addressName?: Maybe<Scalars['String']>;
  /** The user's address postalCode */
  addressPostalCode?: Maybe<Scalars['String']>;
  /** The user's address state */
  addressState?: Maybe<Scalars['String']>;
  /** The user's address street1 */
  addressStreet1?: Maybe<Scalars['String']>;
  /** The user's address street2 */
  addressStreet2?: Maybe<Scalars['String']>;
  /** The user's average rate */
  averageRate?: Maybe<Scalars['Int']>;
  /** Billing name display on invoice */
  billingName?: Maybe<Scalars['String']>;
  /** All the channel owned or managed by a client */
  channels?: Maybe<Array<Maybe<Channel>>>;
  /** The number of projects the user has done */
  countProjects?: Maybe<Scalars['Int']>;
  /** How many time the freelancer profile has been rejected by the admin */
  countRejected?: Maybe<Scalars['Int']>;
  /** The date when the entity was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The size of the creator based on subscribers */
  creatorSize?: Maybe<CreatorSizeEnum>;
  /** The currency used by the user */
  currency?: Maybe<CurrencyEnum>;
  /** The date when the entity was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The user's email */
  email?: Maybe<Scalars['String']>;
  /** The user's first name */
  firstName?: Maybe<Scalars['String']>;
  /** boolean to know if the user has completed the getting started steps */
  gettingStartedCompleted?: Maybe<Scalars['Boolean']>;
  /** The information about the getting started steps of the client */
  gettingStartedSteps?: Maybe<Scalars['JSON']>;
  /** The unique ID of the user when he used google auth */
  googleId?: Maybe<Scalars['String']>;
  /** The user's id */
  id: Scalars['String'];
  /** Indicates if the user has agreed to receive the newsletter */
  isAgreeForNewsletter?: Maybe<Scalars['Boolean']>;
  /** If the user has verified is identity (email or phone) */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** All job posts created by the client */
  jobPostsCreatedByClient?: Maybe<Array<Maybe<JobPost>>>;
  /** The user's last name */
  lastName?: Maybe<Scalars['String']>;
  /** All orders made by the client */
  orders?: Maybe<Array<Maybe<Order>>>;
  /** The user's phone number */
  phone?: Maybe<Scalars['String']>;
  /** The user's profile picture url */
  profilePicture?: Maybe<Scalars['String']>;
  /** When the freelancer profile has been rejected by the admin */
  rejectedAt?: Maybe<Scalars['DateTime']>;
  /** Why the freelancer profile has been rejected by the admin */
  rejectedReason?: Maybe<Scalars['String']>;
  /** The stripe connect id, used to manage every payment transaction with Stripe */
  stripeAccountId?: Maybe<Scalars['String']>;
  /** When the user has submitted his freelancer profile */
  submittedAt?: Maybe<Scalars['DateTime']>;
  /** The type of the user */
  type?: Maybe<UserTypeEnum>;
  /** The date when the entity was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The username of the user (used for the chat and with clients only) */
  userName?: Maybe<Scalars['String']>;
};

export type ClientCreatedOutput = {
  __typename?: 'ClientCreatedOutput';
  client: Client;
  token: Token;
};

/** The edit format type enum */
export enum ContentTypeEnum {
  LONG_FORMAT = 'LONG_FORMAT',
  SHORT_FORMAT = 'SHORT_FORMAT'
}

/** Contract type enum */
export enum ContractTypeEnum {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  PROJECT = 'PROJECT'
}

export type CreateApplicationInput = {
  jobPostId: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
};

export type CreateClientWithGoogleInput = {
  code?: InputMaybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type CreateClientWithPasswordInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateFreelancerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  isAgreeForNewsletter: Scalars['Boolean'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateJobPostInput = {
  channelId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  experiences?: InputMaybe<Array<InputMaybe<ExperienceTypeEnum>>>;
  expertises?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  formatType: ContentTypeEnum;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  priceMax?: InputMaybe<Scalars['Float']>;
  priceMin?: InputMaybe<Scalars['Float']>;
  role: Scalars['String'];
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  type: ContractTypeEnum;
  youtubeInspirationVideos?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateOrderInput = {
  jobPostId?: InputMaybe<Scalars['String']>;
  type: OrderTypeEnum;
};

/** The different size of creators by number of subscribers */
export enum CreatorSizeEnum {
  ASPIRING = 'ASPIRING',
  HOBBYIST = 'HOBBYIST',
  MOGULS = 'MOGULS',
  PROS = 'PROS',
  STARS = 'STARS'
}

/** All currency available. */
export enum CurrencyEnum {
  EUR = 'EUR',
  USD = 'USD'
}

/** The different levels of experience a freelancer can have */
export enum ExperienceTypeEnum {
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  SENIOR = 'SENIOR'
}

/** Expertise enum type */
export enum ExpertiseEnum {
  ARTS_CRAFT = 'ARTS_CRAFT',
  BEAUTY = 'BEAUTY',
  EDUCATIONAL = 'EDUCATIONAL',
  FOOD = 'FOOD',
  GAMING = 'GAMING',
  LIVESTREAMING_REPLAYS = 'LIVESTREAMING_REPLAYS',
  PRANKS = 'PRANKS',
  REACT = 'REACT',
  SHOW = 'SHOW',
  SPORT = 'SPORT',
  TWITCH = 'TWITCH',
  VLOG = 'VLOG'
}

export type FindAllApplicationInput = {
  applicationStatus?: InputMaybe<ApplicationTypeEnum>;
  jobPostId: Scalars['String'];
  onlyPerfectMatch?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<ActionTypeEnum>;
  youtubeIds?: InputMaybe<Array<Scalars['String']>>;
};

export type FindAllPortfolioElementFiltersInput = {
  freelancerId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  segment?: InputMaybe<SegmentPortfolioElementEnum>;
};

export type Freelancer = {
  __typename?: 'Freelancer';
  /** The status of the freelancer's account */
  accountStatus?: Maybe<FreelancerAccountStatusEnum>;
  /** The user's address city */
  addressCity?: Maybe<Scalars['String']>;
  /** The user's address country */
  addressCountry?: Maybe<Scalars['String']>;
  /** The user's address name */
  addressName?: Maybe<Scalars['String']>;
  /** The user's address postalCode */
  addressPostalCode?: Maybe<Scalars['String']>;
  /** The user's address state */
  addressState?: Maybe<Scalars['String']>;
  /** The user's address street1 */
  addressStreet1?: Maybe<Scalars['String']>;
  /** The user's address street2 */
  addressStreet2?: Maybe<Scalars['String']>;
  /** All expertises mastered by the freelancer */
  applications?: Maybe<Array<Maybe<Application>>>;
  /** The average daily rate of the freelancer */
  averageDailyRate?: Maybe<Scalars['Float']>;
  /** The user's average rate */
  averageRate?: Maybe<Scalars['Int']>;
  /** Billing name display on invoice */
  billingName?: Maybe<Scalars['String']>;
  /** A short description of him and is work */
  bio?: Maybe<Scalars['String']>;
  /** If the freelancer has accepted the charter or not */
  charterAccepted?: Maybe<Scalars['Boolean']>;
  /** The type of format the freelancer practise */
  contentTypes?: Maybe<Array<ContentTypeEnum>>;
  /** The type of the job post */
  contractTypes?: Maybe<Array<ContractTypeEnum>>;
  /** The number of projects the user has done */
  countProjects?: Maybe<Scalars['Int']>;
  /** How many time the freelancer profile has been rejected by the admin */
  countRejected?: Maybe<Scalars['Int']>;
  /** Country based of the freelancer */
  countryBased?: Maybe<Scalars['String']>;
  /** The date when the entity was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All creators freelancer worked with */
  creatorsIWorkedWith?: Maybe<Array<Maybe<PortfolioChannel>>>;
  /** The currency used by the user */
  currency?: Maybe<CurrencyEnum>;
  /**
   * The custom url (always in lowercase) of the freelancer with these rules:
   * 1. Length: The custom name should be between 3 and 50 characters.
   * 2. URL-safe characters: Only use lowercase letters, numbers, hyphens, and underscores.
   * 3. Reserved Words: Certain words (e.g., "admin", "login") are reserved and cannot be used.
   */
  customUrl?: Maybe<Scalars['String']>;
  /** The date when the entity was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Discord account username of the freelancer */
  discordUserTag?: Maybe<Scalars['String']>;
  /** The user's email */
  email?: Maybe<Scalars['String']>;
  /** The different levels of experience a freelancer can have */
  experience?: Maybe<ExperienceTypeEnum>;
  /** The expertises mastered by the freelancer */
  expertises?: Maybe<Array<Maybe<ExpertiseEnum>>>;
  /** The user's first name */
  firstName?: Maybe<Scalars['String']>;
  /** All creators of the freelancer he have worked with */
  freelancerToPortfolioChannels?: Maybe<Array<FreelancerToPortfolioChannel>>;
  /** The freelancer's full name */
  fullName?: Maybe<Scalars['String']>;
  /** boolean to know if the user has completed the getting started steps */
  gettingStartedCompleted?: Maybe<Scalars['Boolean']>;
  /** The unique ID of the user when he used google auth */
  googleId?: Maybe<Scalars['String']>;
  /** The user's id */
  id: Scalars['String'];
  /** Instagram account username of the freelancer */
  instagramUserTag?: Maybe<Scalars['String']>;
  /** Indicates if the user has agreed to receive the newsletter */
  isAgreeForNewsletter?: Maybe<Scalars['Boolean']>;
  /** Give the information if the freelancer is currently available or not */
  isAvailable?: Maybe<Scalars['Boolean']>;
  isCertified?: Maybe<Scalars['Boolean']>;
  /** If the freelancer is unavailable, this date informs until when he is */
  isNotAvailableUntil?: Maybe<Scalars['DateTime']>;
  /** If the user has verified is identity (email or phone) */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** All job posts done by the freelancer */
  jobPostsDoneByFreelancer?: Maybe<Array<Maybe<JobPost>>>;
  /** The jobs the freelancer is able to do */
  jobs?: Maybe<Array<Maybe<JobEnum>>>;
  /** The languages of the freelancer */
  languages?: Maybe<Array<Maybe<LanguageEnum>>>;
  /** The user's last name */
  lastName?: Maybe<Scalars['String']>;
  /** The main job of the freelancer */
  mainJob?: Maybe<JobEnum>;
  /** All portfolio elements of the freelancer */
  mainPortfolioElements?: Maybe<Array<PortfolioElement>>;
  nativeLanguages?: Maybe<Array<Maybe<LanguageEnum>>>;
  /** The user's phone number */
  phone?: Maybe<Scalars['String']>;
  /** The total number of view of the portfolio */
  portfolioElementStats?: Maybe<PortfolioElementStatsOutput>;
  /** All portfolioElements of the freelancer */
  portfolioElements?: Maybe<Array<Maybe<PortfolioElement>>>;
  /** The currency of his hourly rate */
  priceCurrency?: Maybe<CurrencyEnum>;
  /** The user's profile picture url */
  profilePicture?: Maybe<Scalars['String']>;
  /** When the freelancer profile has been rejected by the admin */
  rejectedAt?: Maybe<Scalars['DateTime']>;
  /** Why the freelancer profile has been rejected by the admin */
  rejectedReason?: Maybe<Scalars['String']>;
  secondaryLanguages?: Maybe<Array<Maybe<LanguageEnum>>>;
  /** The skills mastered by the freelancer */
  skills?: Maybe<Array<Maybe<SkillEnum>>>;
  /** The stripe connect id, used to manage every payment transaction with Stripe */
  stripeAccountId?: Maybe<Scalars['String']>;
  /** When the user has submitted his freelancer profile */
  submittedAt?: Maybe<Scalars['DateTime']>;
  /** The trainer's appreciation when a freelancer has made the the creative academy training */
  trainerAppreciation?: Maybe<Scalars['String']>;
  /** Twitter account username of the freelancer */
  twitterUserTag?: Maybe<Scalars['String']>;
  /** The type of the user */
  type?: Maybe<UserTypeEnum>;
  /** When the freelancer has update for the last time is availability */
  updateAvailabilityAt?: Maybe<Scalars['DateTime']>;
  /** The date when the entity was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The username of the user (used for the chat and with clients only) */
  userName?: Maybe<Scalars['String']>;
  /** The TVA percentage of the freelancer (manually input by himself) */
  vatRate?: Maybe<Scalars['Float']>;
  /** The freelancer's youtube playlist */
  youtubePlaylist?: Maybe<Scalars['String']>;
};


export type FreelancerPortfolioElementsArgs = {
  args?: InputMaybe<FindAllPortfolioElementFiltersInput>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

/** The status of the freelancer account */
export enum FreelancerAccountStatusEnum {
  APPROVED = 'APPROVED',
  PENDING_APPROBATION = 'PENDING_APPROBATION',
  REJECTED = 'REJECTED'
}

export type FreelancerContactDetailsOutput = {
  __typename?: 'FreelancerContactDetailsOutput';
  discordUserTag?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  instagramUserTag?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  twitterUserTag?: Maybe<Scalars['String']>;
};

export type FreelancerPaginated = {
  __typename?: 'FreelancerPaginated';
  data?: Maybe<Array<Maybe<Freelancer>>>;
  lastPage: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  total: Scalars['Int'];
};

export type FreelancerToPortfolioChannel = {
  __typename?: 'FreelancerToPortfolioChannel';
  freelancer?: Maybe<Freelancer>;
  freelancerId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  portfolioChannel?: Maybe<PortfolioChannel>;
  portfolioChannelId?: Maybe<Scalars['String']>;
};

export type FreelancersFiltersInput = {
  averageDailyRate?: InputMaybe<AverageDailyRateRangeInput>;
  experiences?: InputMaybe<Array<InputMaybe<ExperienceTypeEnum>>>;
  expertises?: InputMaybe<Array<InputMaybe<ExpertiseEnum>>>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isCertified?: InputMaybe<Scalars['Boolean']>;
  jobs?: InputMaybe<Array<InputMaybe<JobEnum>>>;
  languages?: InputMaybe<Array<InputMaybe<LanguageEnum>>>;
  skills?: InputMaybe<Array<InputMaybe<SkillEnum>>>;
};

export type GetFreelancerPortfolioChannelByJobPostInput = {
  applicationStatus?: InputMaybe<ApplicationTypeEnum>;
  jobPostId: Scalars['String'];
  onlyPerfectMatch?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};

/** The jobs that can be offered to freelancers */
export enum JobEnum {
  CREATIVE_DIRECTOR = 'CREATIVE_DIRECTOR',
  EDITOR_ASSISTANT = 'EDITOR_ASSISTANT',
  PUBLISHING_MANAGER = 'PUBLISHING_MANAGER',
  SCRIPT_WRITER = 'SCRIPT_WRITER',
  STRATEGIST_MANAGER = 'STRATEGIST_MANAGER',
  THUMBNAIL_DESIGNER = 'THUMBNAIL_DESIGNER',
  VIDEO_EDITOR = 'VIDEO_EDITOR',
  VOICE_OVER = 'VOICE_OVER'
}

export type JobPost = {
  __typename?: 'JobPost';
  /** The channel where the job post is published */
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['String']>;
  /** All freelancers who have been selected for this job post */
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  /** The date when the entity was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The date when the entity was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The description of the job post */
  description?: Maybe<Scalars['String']>;
  /** When the job post ends */
  endAt?: Maybe<Scalars['DateTime']>;
  /** The experience required for this job post, ex: beginner, expert.. */
  experiences?: Maybe<Array<ExperienceTypeEnum>>;
  /** The expertises required for this job post, ex: adventure, food, gaming.. */
  expertises?: Maybe<Array<Scalars['String']>>;
  /** The type of the job post */
  formatType?: Maybe<ContentTypeEnum>;
  /** This field is only available when you retrieve the job posts list for a specific freelancer. It indicates if the freelancer has sent a message to the client. */
  freelancerHasSentMessage?: Maybe<Scalars['Boolean']>;
  /** This field is only available when you retrieve the job posts list for a specific freelancer. It indicates if the freelancer has sent a message to the client. */
  freelancerStatusForLoggedFreelancer?: Maybe<ApplicationTypeEnum>;
  /** The id of the job post */
  id?: Maybe<Scalars['String']>;
  /** If true, this job post is a paid offer and should unlock advantages for the client */
  isPaidOffer?: Maybe<Scalars['Boolean']>;
  /** The language required for this job post */
  languages?: Maybe<Array<Scalars['String']>>;
  /** The urls useful for this job post, ex: google drive, website.. */
  links?: Maybe<Array<Scalars['String']>>;
  /** The minimum matching score for this job post on 100 */
  minMatchingScore?: Maybe<Scalars['Int']>;
  /** The name of the job post */
  name?: Maybe<Scalars['String']>;
  /** The next date for unlock new applications */
  nextUnlockAt?: Maybe<Scalars['DateTime']>;
  /** The orders for this job post */
  order?: Maybe<Order>;
  /** The portfolio elements for this job post */
  portfolioElements?: Maybe<Array<Maybe<PortfolioElement>>>;
  /** The maximum price for this job post */
  priceMax?: Maybe<Scalars['Int']>;
  /** The minimum price for this job post */
  priceMin?: Maybe<Scalars['Int']>;
  /** The role of the freelancer, video editor, thumbnail designer.. */
  role?: Maybe<Scalars['String']>;
  /** The freelancer who has been selected for this job post */
  selectedFreelancer?: Maybe<Freelancer>;
  selectedFreelancerId?: Maybe<Scalars['String']>;
  /** The skills required for this job post, ex: editing, photoshop.. */
  skills?: Maybe<Array<Scalars['String']>>;
  /** When the job post starts */
  startAt?: Maybe<Scalars['DateTime']>;
  /** The status of the job post */
  status?: Maybe<JobPostStatusEnum>;
  /** The total of interested talents for this application. */
  totalInterestedTalents?: Maybe<Scalars['Int']>;
  /** The total of unique view of the job post (by ip address) */
  totalViews?: Maybe<Scalars['Int']>;
  /** The type of the job post */
  type?: Maybe<ContractTypeEnum>;
  /** The date when the entity was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JobPostFiltersByClientInput = {
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ActionTypeEnum>;
  status: JobPostStatusEnum;
};

export type JobPostFreelancerFiltersInput = {
  forMe: Scalars['Boolean'];
  orderBy?: InputMaybe<JobPostOrderByEnum>;
  sortBy?: InputMaybe<ActionTypeEnum>;
  status: JobPostsFreelancerStatusEnum;
};

/** The list of job post order by enum, appliedAt, createdAt, declinedAt */
export enum JobPostOrderByEnum {
  APPLIED_AT = 'APPLIED_AT',
  CREATED_AT = 'CREATED_AT',
  DECLINED_AT = 'DECLINED_AT'
}

export type JobPostPaginated = {
  __typename?: 'JobPostPaginated';
  data?: Maybe<Array<Maybe<JobPost>>>;
  lastPage: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  total: Scalars['Int'];
};

/** The status of the freelancer in the job post */
export enum JobPostsFreelancerStatusEnum {
  ALL = 'ALL',
  APPLIED = 'APPLIED',
  DECLINED = 'DECLINED'
}

/** Languages supported by the application */
export enum LanguageEnum {
  EN = 'EN',
  FR = 'FR',
  GE = 'GE',
  PRT = 'PRT',
  SP = 'SP'
}

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCharter: Freelancer;
  addChannelToUser: Channel;
  applicationSeen: Application;
  /** Attach a Youtube channel to a job post. */
  attachChannelToJobPost: JobPost;
  attachPaymentMethod: PaymentMethod;
  /** Cancel a job post. */
  cancelJobPost: Scalars['Boolean'];
  /** Close a job post. */
  closeJobPost: Scalars['Boolean'];
  /** Create a new application for a job post, or update it if the freelancer has already applied. */
  createApplicationForClient: Application;
  /** Create a new application for a job post, or update it if the freelancer has already applied. */
  createApplicationForFreelancer: Application;
  createClientWithGoogle: ClientCreatedOutput;
  createClientWithPassword: ClientCreatedOutput;
  createCustomUrl: Freelancer;
  createFreelancer: Freelancer;
  /** Create a new job post. */
  createJobPost: JobPost;
  createOrder: Order;
  createPortfolioChannel: PortfolioChannel;
  createPortfolioElement: PortfolioElementsAddedOutput;
  /** Decline a freelancer application for a job post */
  declineApplication: Application;
  deletePortfolioChannel: PortfolioChannel;
  deletePortfolioElement: PortfolioElement;
  detachPaymentMethod: Scalars['Boolean'];
  freelancerUpdate: Freelancer;
  hideAction: Action;
  loginWithGoogle: Auth;
  loginWithPassword: Auth;
  makeDefaultPaymentMethod: Scalars['Boolean'];
  publicCreatePortfolioChannel: PortfolioChannel;
  publicCreatePortfolioElement: PortfolioElementsAddedOutput;
  recoveryPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  /** Send a superlike to a freelancer for a job post */
  sendSuperLike: Application;
  /** Create a new phone number and send a verification code to it */
  sendVerificationCode: Scalars['Boolean'];
  submitFreelancerProfileForVerification: Scalars['Boolean'];
  updateAvailability: Freelancer;
  updateClient: Client;
  updateClientCompanyDetails: Client;
  updateEmail: Scalars['Boolean'];
  updateFavoriteStatus: Application;
  updateFreelancerCompanyDetails: Freelancer;
  updateGettingStartedSteps: Client;
  /** Update a job post. */
  updateJobPost: JobPost;
  updatePassword: Scalars['Boolean'];
  updatePortfolioChannelsOrder: Array<PortfolioChannel>;
  updatePortfolioElementJobs: PortfolioElement;
  updatePortfolioElementSegment: PortfolioElement;
  updatePortfolioElementsOrder: Array<PortfolioElement>;
  updateProfilePicture: User;
};


export type MutationAddChannelToUserArgs = {
  code: Scalars['String'];
};


export type MutationApplicationSeenArgs = {
  applicationId: Scalars['String'];
};


export type MutationAttachChannelToJobPostArgs = {
  channelId: Scalars['String'];
  jobPostId: Scalars['String'];
};


export type MutationAttachPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationCancelJobPostArgs = {
  id: Scalars['String'];
};


export type MutationCloseJobPostArgs = {
  freelancerId?: InputMaybe<Scalars['String']>;
  jobPostId: Scalars['String'];
};


export type MutationCreateApplicationForClientArgs = {
  args: CreateApplicationInput;
};


export type MutationCreateApplicationForFreelancerArgs = {
  args: CreateApplicationInput;
};


export type MutationCreateClientWithGoogleArgs = {
  args: CreateClientWithGoogleInput;
};


export type MutationCreateClientWithPasswordArgs = {
  args: CreateClientWithPasswordInput;
};


export type MutationCreateCustomUrlArgs = {
  name: Scalars['String'];
};


export type MutationCreateFreelancerArgs = {
  args: CreateFreelancerInput;
};


export type MutationCreateJobPostArgs = {
  args: CreateJobPostInput;
};


export type MutationCreateOrderArgs = {
  args: CreateOrderInput;
};


export type MutationCreatePortfolioChannelArgs = {
  url: Scalars['String'];
};


export type MutationCreatePortfolioElementArgs = {
  url: Scalars['String'];
};


export type MutationDeclineApplicationArgs = {
  jobPostId: Scalars['String'];
};


export type MutationDeletePortfolioChannelArgs = {
  id: Scalars['String'];
};


export type MutationDeletePortfolioElementArgs = {
  id: Scalars['String'];
};


export type MutationDetachPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationFreelancerUpdateArgs = {
  args: UpdateFreelancerInput;
};


export type MutationHideActionArgs = {
  id: Scalars['String'];
};


export type MutationLoginWithGoogleArgs = {
  code: Scalars['String'];
};


export type MutationLoginWithPasswordArgs = {
  args: LoginInput;
};


export type MutationMakeDefaultPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationPublicCreatePortfolioChannelArgs = {
  url: Scalars['String'];
};


export type MutationPublicCreatePortfolioElementArgs = {
  url: Scalars['String'];
};


export type MutationRecoveryPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  args: ResetPasswordInput;
};


export type MutationSendSuperLikeArgs = {
  applicationId: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
};


export type MutationSendVerificationCodeArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationUpdateAvailabilityArgs = {
  isAvailable: Scalars['Boolean'];
};


export type MutationUpdateClientArgs = {
  args: UpdateClientInput;
};


export type MutationUpdateClientCompanyDetailsArgs = {
  args: UpdateClientCompanyDetailsInput;
};


export type MutationUpdateEmailArgs = {
  email: Scalars['String'];
};


export type MutationUpdateFavoriteStatusArgs = {
  applicationId: Scalars['String'];
  isFavorite: Scalars['Boolean'];
};


export type MutationUpdateFreelancerCompanyDetailsArgs = {
  args: UpdateFreelancerCompanyDetailsInput;
};


export type MutationUpdateGettingStartedStepsArgs = {
  args: UpdateGettingStartedStepsInput;
};


export type MutationUpdateJobPostArgs = {
  args: UpdateJobPostInput;
  jobPostId: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  args: UpdatePasswordInput;
};


export type MutationUpdatePortfolioChannelsOrderArgs = {
  args: Array<UpdateOrderPortfolioChannelInput>;
};


export type MutationUpdatePortfolioElementJobsArgs = {
  jobs: Array<JobEnum>;
  portfolioElementId: Scalars['String'];
};


export type MutationUpdatePortfolioElementSegmentArgs = {
  portfolioElementId: Scalars['String'];
  segment: SegmentPortfolioElementEnum;
};


export type MutationUpdatePortfolioElementsOrderArgs = {
  args: Array<UpdateOrderPortfolioElementInput>;
};


export type MutationUpdateProfilePictureArgs = {
  profilePictureUrl: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  /** The client who created the order */
  client?: Maybe<Client>;
  /** The client ID who created the order */
  clientId?: Maybe<Scalars['String']>;
  /** The date when the entity was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Currency used for the order */
  currency?: Maybe<Scalars['String']>;
  /** The date when the entity was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The end date of the order or subscription */
  endAt?: Maybe<Scalars['DateTime']>;
  /** The id of the order */
  id?: Maybe<Scalars['String']>;
  /** Whether the order is active or not */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Indicates if the order is a subscription */
  isSubscription?: Maybe<Scalars['Boolean']>;
  /** The job post related to the order */
  jobPost?: Maybe<JobPost>;
  /** The job post ID related to the order */
  jobPostId?: Maybe<Scalars['String']>;
  /** Name of the product or service purchased */
  name?: Maybe<Scalars['String']>;
  /** Total price paid for the order */
  price?: Maybe<Scalars['Int']>;
  /** The start date of the order or subscription */
  startAt?: Maybe<Scalars['DateTime']>;
  /** The status of the order */
  status: OrderStatusEnum;
  /** Stripe checkout session ID associated with the order */
  stripeCheckoutSessionId?: Maybe<Scalars['String']>;
  /** Stripe customer ID for the user */
  stripeCustomerId?: Maybe<Scalars['String']>;
  /** Stripe hosted invoice URL */
  stripeHostedInvoiceUrl?: Maybe<Scalars['String']>;
  /** Stripe invoice ID associated with the order */
  stripeInvoiceId?: Maybe<Scalars['String']>;
  /** URL to the Stripe invoice PDF */
  stripeInvoicePdfUrl?: Maybe<Scalars['String']>;
  /** Stripe payment ID associated with the order */
  stripePaymentId?: Maybe<Scalars['String']>;
  /** The url to the payment page in Stripe */
  stripePaymentUrl?: Maybe<Scalars['String']>;
  /** Stripe product ID associated with the order */
  stripePriceId?: Maybe<Scalars['String']>;
  /** Stripe subscription ID if the order is a subscription */
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  /** The date when the entity was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderPaginated = {
  __typename?: 'OrderPaginated';
  data?: Maybe<Array<Maybe<Order>>>;
  lastPage: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  total: Scalars['Int'];
};

/** Status of the order */
export enum OrderStatusEnum {
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED'
}

/** Order type enum */
export enum OrderTypeEnum {
  FREE_JOB_PASS = 'FREE_JOB_PASS',
  PREMIUM_ANNUAL_PASS = 'PREMIUM_ANNUAL_PASS',
  PREMIUM_JOB_PASS = 'PREMIUM_JOB_PASS'
}

/** Payment method object */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  brand: Scalars['String'];
  id: Scalars['String'];
  last4: Scalars['String'];
  type: Scalars['String'];
};

export type PortfolioChannel = {
  __typename?: 'PortfolioChannel';
  category?: Maybe<Scalars['String']>;
  /** The channel url */
  channelUrl?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** The date when the entity was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The date when the entity was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** All The freelancers who have for work for this channel */
  freelancerToPortfolioChannels?: Maybe<FreelancerToPortfolioChannel>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** All The portfolio elements attached to the channels */
  portfolioElements?: Maybe<Array<Maybe<PortfolioElement>>>;
  subscriberCount?: Maybe<Scalars['Float']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** The date when the entity was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoCount?: Maybe<Scalars['Float']>;
  viewCount?: Maybe<Scalars['Float']>;
  youtubeId?: Maybe<Scalars['String']>;
};

export type PortfolioElement = {
  __typename?: 'PortfolioElement';
  commentCount?: Maybe<Scalars['Float']>;
  /** The date when the entity was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The date when the entity was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  freelancerId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  jobPost?: Maybe<JobPost>;
  jobs?: Maybe<Array<JobEnum>>;
  likeCount?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Int']>;
  portfolioChannel?: Maybe<PortfolioChannel>;
  portfolioChannelId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  segment?: Maybe<SegmentPortfolioElementEnum>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<PortfolioElementTypeEnum>;
  /** The date when the entity was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  videoCategory?: Maybe<Scalars['String']>;
  viewCount?: Maybe<Scalars['Float']>;
};

export type PortfolioElementStatsOutput = {
  __typename?: 'PortfolioElementStatsOutput';
  countElements?: Maybe<Scalars['Int']>;
  countLikes?: Maybe<Scalars['Int']>;
  countViews?: Maybe<Scalars['Float']>;
};

/** The different type a portfolio element can have */
export enum PortfolioElementTypeEnum {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export type PortfolioElementsAddedOutput = {
  __typename?: 'PortfolioElementsAddedOutput';
  failed?: Maybe<Array<Maybe<Scalars['String']>>>;
  portfolioElements?: Maybe<Array<Maybe<PortfolioElement>>>;
};

export type PortfolioElementsPaginatedOutput = {
  __typename?: 'PortfolioElementsPaginatedOutput';
  data?: Maybe<Array<Maybe<PortfolioElement>>>;
  lastPage: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  actions: ActionPaginated;
  /** Get all orders of the current client. Can be used to get invoices by using status = paid */
  activeSubscriptionOrder?: Maybe<Order>;
  application: Application;
  applications: ApplicationPaginated;
  channel: Channel;
  channels: Array<Channel>;
  checkIfCustomUrlExists: Scalars['Boolean'];
  checkIfProfileIsCompleted: ChecklistVerifiedProfile;
  /** Get all total result for each status and include variation with perfectMatch */
  countApplicationsByStatus: ApplicationCountsOutput;
  /** The total number of freelancer who have this expertise */
  countFreelancerByExpertise: Scalars['Float'];
  /** The total number of freelancer who have this job */
  countFreelancerByJob: Scalars['Float'];
  /** The total number of freelancer who speak this language */
  countFreelancerByLanguage: Scalars['Int'];
  /** The total number of freelancer who have this skill */
  countFreelancerBySkill: Scalars['Int'];
  defaultPaymentMethod?: Maybe<PaymentMethod>;
  findChannelByJobPost: Array<PortfolioChannel>;
  findChannelByJobPostPagination: Scalars['Int'];
  freelancer: Freelancer;
  freelancerContactDetails: FreelancerContactDetailsOutput;
  /** Get all job posts for a freelancer. */
  freelancerJobPosts: JobPostPaginated;
  /** Get the next unlocked application for a job post */
  getNextUnlockedApplication?: Maybe<Application>;
  /** Verify if the current user is allowed to create a new job offer. */
  isAllowedToCreateJobPost: Scalars['Boolean'];
  isEmailAvailable: Scalars['Boolean'];
  me: User;
  meClient: Client;
  meFreelancer: Freelancer;
  /** Get all job posts for a client. */
  myJobPosts: JobPostPaginated;
  order: Order;
  /** Get all orders of the current client. Can be used to get invoices by using status = paid */
  orders: OrderPaginated;
  paymentSucceeded: Scalars['Boolean'];
  portfolioChannels: Array<PortfolioChannel>;
  portfolioElementStats: PortfolioElementStatsOutput;
  portfolioElements: PortfolioElementsPaginatedOutput;
  privateFreelancers: FreelancerPaginated;
  privateJobPost: JobPost;
  profilePicturePreSignUrl: Scalars['String'];
  publicJobPost: JobPost;
  refreshToken: Token;
  retrievePaymentMethod?: Maybe<PaymentMethod>;
  retrievePaymentMethods: Array<PaymentMethod>;
  user: User;
  /** Verify a phone number */
  verifyPhoneNumber: Scalars['Boolean'];
};


export type QueryActionsArgs = {
  args?: InputMaybe<ActionByUserInput>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryApplicationArgs = {
  freelancerId: Scalars['String'];
  jobPostId: Scalars['String'];
};


export type QueryApplicationsArgs = {
  args: FindAllApplicationInput;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryChannelArgs = {
  id: Scalars['String'];
};


export type QueryCheckIfCustomUrlExistsArgs = {
  customUrl: Scalars['String'];
};


export type QueryCountApplicationsByStatusArgs = {
  jobPostId: Scalars['String'];
};


export type QueryCountFreelancerByExpertiseArgs = {
  expertise: ExpertiseEnum;
};


export type QueryCountFreelancerByJobArgs = {
  job: JobEnum;
};


export type QueryCountFreelancerByLanguageArgs = {
  language: LanguageEnum;
};


export type QueryCountFreelancerBySkillArgs = {
  skill: SkillEnum;
};


export type QueryFindChannelByJobPostArgs = {
  args: GetFreelancerPortfolioChannelByJobPostInput;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryFindChannelByJobPostPaginationArgs = {
  args: GetFreelancerPortfolioChannelByJobPostInput;
};


export type QueryFreelancerArgs = {
  id: Scalars['String'];
};


export type QueryFreelancerContactDetailsArgs = {
  applicationId: Scalars['String'];
};


export type QueryFreelancerJobPostsArgs = {
  filters?: InputMaybe<JobPostFreelancerFiltersInput>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetNextUnlockedApplicationArgs = {
  jobPostId: Scalars['String'];
};


export type QueryIsEmailAvailableArgs = {
  email: Scalars['String'];
};


export type QueryMyJobPostsArgs = {
  filters?: InputMaybe<JobPostFiltersByClientInput>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  status: OrderStatusEnum;
};


export type QueryPaymentSucceededArgs = {
  sessionId: Scalars['String'];
};


export type QueryPortfolioChannelsArgs = {
  freelancerId?: InputMaybe<Scalars['String']>;
};


export type QueryPortfolioElementStatsArgs = {
  freelancerId: Scalars['String'];
};


export type QueryPortfolioElementsArgs = {
  args?: InputMaybe<FindAllPortfolioElementFiltersInput>;
  freelancerId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPrivateFreelancersArgs = {
  args?: InputMaybe<FreelancersFiltersInput>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPrivateJobPostArgs = {
  id: Scalars['String'];
};


export type QueryProfilePicturePreSignUrlArgs = {
  contentType: Scalars['String'];
};


export type QueryPublicJobPostArgs = {
  id: Scalars['String'];
};


export type QueryRetrievePaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryVerifyPhoneNumberArgs = {
  args?: InputMaybe<VerifyPhoneNumberInput>;
};

export type ResetPasswordInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

/** Segment portfolio element enum */
export enum SegmentPortfolioElementEnum {
  BUCKET = 'BUCKET',
  FEATURED = 'FEATURED',
  SELECTED = 'SELECTED'
}

/** The skills of a freelancer */
export enum SkillEnum {
  ADOBE_PREMIERE = 'ADOBE_PREMIERE',
  AFTER_EFFECTS = 'AFTER_EFFECTS',
  DAVINCI_RESOLVE = 'DAVINCI_RESOLVE',
  FINAL_CUT = 'FINAL_CUT',
  ILLUSTRATOR = 'ILLUSTRATOR',
  LIGHTROOM = 'LIGHTROOM',
  PHOTOSHOP = 'PHOTOSHOP'
}

export type Token = {
  __typename?: 'Token';
  /** JWT access token */
  accessToken: Scalars['JWT'];
  /** CometChatAuthToken */
  cometchatAuthToken?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** JWT refresh token */
  refreshToken: Scalars['JWT'];
};

export type UpdateClientCompanyDetailsInput = {
  addressCity: Scalars['String'];
  addressCountry: Scalars['String'];
  addressName: Scalars['String'];
  addressPostalCode: Scalars['String'];
  addressState?: InputMaybe<Scalars['String']>;
  addressStreet1: Scalars['String'];
  addressStreet2?: InputMaybe<Scalars['String']>;
};

export type UpdateClientInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type UpdateFreelancerCompanyDetailsInput = {
  addressCity: Scalars['String'];
  addressCountry: Scalars['String'];
  addressCountryCode: Scalars['String'];
  addressName: Scalars['String'];
  addressPostalCode: Scalars['String'];
  addressState?: InputMaybe<Scalars['String']>;
  addressStreet1: Scalars['String'];
  addressStreet2?: InputMaybe<Scalars['String']>;
  vatRate: Scalars['Int'];
};

export type UpdateFreelancerInput = {
  averageDailyRate?: InputMaybe<Scalars['Int']>;
  bio?: InputMaybe<Scalars['String']>;
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  contractTypes?: InputMaybe<Array<InputMaybe<ContractTypeEnum>>>;
  countryBased?: InputMaybe<Scalars['String']>;
  discordUserTag?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<ExperienceTypeEnum>;
  expertises?: InputMaybe<Array<InputMaybe<ExpertiseEnum>>>;
  firstName?: InputMaybe<Scalars['String']>;
  instagramUserTag?: InputMaybe<Scalars['String']>;
  jobs?: InputMaybe<Array<InputMaybe<JobEnum>>>;
  lastName?: InputMaybe<Scalars['String']>;
  mainJob?: InputMaybe<JobEnum>;
  nativeLanguages?: InputMaybe<Array<InputMaybe<LanguageEnum>>>;
  phone?: InputMaybe<Scalars['String']>;
  secondaryLanguages?: InputMaybe<Array<InputMaybe<LanguageEnum>>>;
  skills?: InputMaybe<Array<InputMaybe<SkillEnum>>>;
  twitterUserTag?: InputMaybe<Scalars['String']>;
};

export type UpdateGettingStartedStepsInput = {
  discoverOutreachedProfiles: Scalars['Boolean'];
  reviewInterestedTalents: Scalars['Boolean'];
};

export type UpdateJobPostInput = {
  description: Scalars['String'];
};

export type UpdateOrderPortfolioChannelInput = {
  order: Scalars['Int'];
  portfolioChannelId: Scalars['String'];
};

export type UpdateOrderPortfolioElementInput = {
  order: Scalars['Int'];
  portfolioElementId: Scalars['String'];
};

export type UpdatePasswordInput = {
  newPassword: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  /** The user's address city */
  addressCity?: Maybe<Scalars['String']>;
  /** The user's address country */
  addressCountry?: Maybe<Scalars['String']>;
  /** The user's address name */
  addressName?: Maybe<Scalars['String']>;
  /** The user's address postalCode */
  addressPostalCode?: Maybe<Scalars['String']>;
  /** The user's address state */
  addressState?: Maybe<Scalars['String']>;
  /** The user's address street1 */
  addressStreet1?: Maybe<Scalars['String']>;
  /** The user's address street2 */
  addressStreet2?: Maybe<Scalars['String']>;
  /** The user's average rate */
  averageRate?: Maybe<Scalars['Int']>;
  /** Billing name display on invoice */
  billingName?: Maybe<Scalars['String']>;
  /** The number of projects the user has done */
  countProjects?: Maybe<Scalars['Int']>;
  /** How many time the freelancer profile has been rejected by the admin */
  countRejected?: Maybe<Scalars['Int']>;
  /** The date when the entity was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The currency used by the user */
  currency?: Maybe<CurrencyEnum>;
  /** The date when the entity was deleted */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The user's email */
  email?: Maybe<Scalars['String']>;
  /** The user's first name */
  firstName?: Maybe<Scalars['String']>;
  /** boolean to know if the user has completed the getting started steps */
  gettingStartedCompleted?: Maybe<Scalars['Boolean']>;
  /** The unique ID of the user when he used google auth */
  googleId?: Maybe<Scalars['String']>;
  /** The user's id */
  id: Scalars['String'];
  /** Indicates if the user has agreed to receive the newsletter */
  isAgreeForNewsletter?: Maybe<Scalars['Boolean']>;
  /** If the user has verified is identity (email or phone) */
  isVerified?: Maybe<Scalars['Boolean']>;
  /** The user's last name */
  lastName?: Maybe<Scalars['String']>;
  /** The user's phone number */
  phone?: Maybe<Scalars['String']>;
  /** The user's profile picture url */
  profilePicture?: Maybe<Scalars['String']>;
  /** When the freelancer profile has been rejected by the admin */
  rejectedAt?: Maybe<Scalars['DateTime']>;
  /** Why the freelancer profile has been rejected by the admin */
  rejectedReason?: Maybe<Scalars['String']>;
  /** The stripe connect id, used to manage every payment transaction with Stripe */
  stripeAccountId?: Maybe<Scalars['String']>;
  /** When the user has submitted his freelancer profile */
  submittedAt?: Maybe<Scalars['DateTime']>;
  /** The type of the user */
  type?: Maybe<UserTypeEnum>;
  /** The date when the entity was updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The username of the user (used for the chat and with clients only) */
  userName?: Maybe<Scalars['String']>;
};

/** The different type a user can have */
export enum UserTypeEnum {
  CLIENT = 'CLIENT',
  FREELANCER = 'FREELANCER'
}

export type VerifyPhoneNumberInput = {
  code: Scalars['Int'];
  number: Scalars['String'];
};

/** Job post status enum */
export enum JobPostStatusEnum {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS'
}

export type AcceptFreelancerCharterGqlMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptFreelancerCharterGqlMutation = { __typename?: 'Mutation', acceptCharter: { __typename?: 'Freelancer', id: string, charterAccepted?: boolean | null } };

export type FreelancerCharterGqlQueryVariables = Exact<{ [key: string]: never; }>;


export type FreelancerCharterGqlQuery = { __typename?: 'Query', meFreelancer: { __typename?: 'Freelancer', id: string, charterAccepted?: boolean | null } };

export type ClientProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientProfileQuery = { __typename?: 'Query', meClient: { __typename?: 'Client', id: string, firstName?: string | null, lastName?: string | null, userName?: string | null, email?: string | null, phone?: string | null, profilePicture?: string | null, currency?: CurrencyEnum | null, creatorSize?: CreatorSizeEnum | null, averageRate?: number | null, channels?: Array<{ __typename?: 'Channel', id: string, name?: string | null } | null> | null } };

export type ProfilePicturePreSignUrlQueryVariables = Exact<{
  contentType: Scalars['String'];
}>;


export type ProfilePicturePreSignUrlQuery = { __typename?: 'Query', profilePicturePreSignUrl: string };

export type UpdateClientProfileMutationVariables = Exact<{
  args: UpdateClientInput;
}>;


export type UpdateClientProfileMutation = { __typename?: 'Mutation', updateClient: { __typename?: 'Client', id: string, userName?: string | null, email?: string | null, phone?: string | null, profilePicture?: string | null, currency?: CurrencyEnum | null } };

export type GettingStartedStepsGqlQueryVariables = Exact<{ [key: string]: never; }>;


export type GettingStartedStepsGqlQuery = { __typename?: 'Query', meClient: { __typename?: 'Client', gettingStartedCompleted?: boolean | null, gettingStartedSteps?: any | null } };

export type UpdateGettingStartedStepsGqlMutationVariables = Exact<{
  args: UpdateGettingStartedStepsInput;
}>;


export type UpdateGettingStartedStepsGqlMutation = { __typename?: 'Mutation', updateGettingStartedSteps: { __typename?: 'Client', id: string } };

export type FreelancerAvailabilityGqlQueryVariables = Exact<{ [key: string]: never; }>;


export type FreelancerAvailabilityGqlQuery = { __typename?: 'Query', meFreelancer: { __typename?: 'Freelancer', id: string, isAvailable?: boolean | null, isNotAvailableUntil?: any | null, updateAvailabilityAt?: any | null } };

export type UpdateAvailabilityGqlMutationVariables = Exact<{
  isAvailable: Scalars['Boolean'];
}>;


export type UpdateAvailabilityGqlMutation = { __typename?: 'Mutation', updateAvailability: { __typename?: 'Freelancer', id: string, profilePicture?: string | null } };


export const AcceptFreelancerCharterGqlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptFreelancerCharterGQL"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptCharter"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"charterAccepted"}}]}}]}}]} as unknown as DocumentNode<AcceptFreelancerCharterGqlMutation, AcceptFreelancerCharterGqlMutationVariables>;
export const FreelancerCharterGqlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FreelancerCharterGQL"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meFreelancer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"charterAccepted"}}]}}]}}]} as unknown as DocumentNode<FreelancerCharterGqlQuery, FreelancerCharterGqlQueryVariables>;
export const ClientProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ClientProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meClient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"userName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"profilePicture"}},{"kind":"Field","name":{"kind":"Name","value":"currency"}},{"kind":"Field","name":{"kind":"Name","value":"creatorSize"}},{"kind":"Field","name":{"kind":"Name","value":"channels"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"averageRate"}}]}}]}}]} as unknown as DocumentNode<ClientProfileQuery, ClientProfileQueryVariables>;
export const ProfilePicturePreSignUrlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ProfilePicturePreSignUrl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"contentType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"profilePicturePreSignUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"contentType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"contentType"}}}]}]}}]} as unknown as DocumentNode<ProfilePicturePreSignUrlQuery, ProfilePicturePreSignUrlQueryVariables>;
export const UpdateClientProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateClientProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"args"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateClientInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateClient"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"args"},"value":{"kind":"Variable","name":{"kind":"Name","value":"args"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"profilePicture"}},{"kind":"Field","name":{"kind":"Name","value":"currency"}}]}}]}}]} as unknown as DocumentNode<UpdateClientProfileMutation, UpdateClientProfileMutationVariables>;
export const GettingStartedStepsGqlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GettingStartedStepsGQL"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meClient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"gettingStartedCompleted"}},{"kind":"Field","name":{"kind":"Name","value":"gettingStartedSteps"}}]}}]}}]} as unknown as DocumentNode<GettingStartedStepsGqlQuery, GettingStartedStepsGqlQueryVariables>;
export const UpdateGettingStartedStepsGqlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateGettingStartedStepsGQL"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"args"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateGettingStartedStepsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateGettingStartedSteps"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"args"},"value":{"kind":"Variable","name":{"kind":"Name","value":"args"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateGettingStartedStepsGqlMutation, UpdateGettingStartedStepsGqlMutationVariables>;
export const FreelancerAvailabilityGqlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FreelancerAvailabilityGQL"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"meFreelancer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isAvailable"}},{"kind":"Field","name":{"kind":"Name","value":"isNotAvailableUntil"}},{"kind":"Field","name":{"kind":"Name","value":"updateAvailabilityAt"}}]}}]}}]} as unknown as DocumentNode<FreelancerAvailabilityGqlQuery, FreelancerAvailabilityGqlQueryVariables>;
export const UpdateAvailabilityGqlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateAvailabilityGQL"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"isAvailable"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateAvailability"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"isAvailable"},"value":{"kind":"Variable","name":{"kind":"Name","value":"isAvailable"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"profilePicture"}}]}}]}}]} as unknown as DocumentNode<UpdateAvailabilityGqlMutation, UpdateAvailabilityGqlMutationVariables>;