import { gql } from '@apollo/client';

const updateFreelancerProfileGQL = gql`
    mutation UpdateFreelancerProfileGQL($args: UpdateFreelancerInput!) {
        freelancerUpdate(args: $args) {
            id
            type
            firstName
            lastName
            experience
            bio
            profilePicture
        }
    }`;

export default updateFreelancerProfileGQL;