import { SessionRepository } from '@/core/auth/sessionRepository';
import { useEffect, useState } from 'react';
import { UserTypeEnum } from '@/gql/graphql';
import { TransverseHeader } from '@/pages/transverse/layout/header/TransverseHeader';
import { FreelancerHeader } from '@/pages/freelancer/layout/header/FreelancerHeader';

export default function DynamicHeader() {

    const session = new SessionRepository();

    const [ isLoggedIn, setIsLoggedIn ] = useState<boolean>( false );
    const [ userType, setUserType ] = useState<UserTypeEnum>( null );

    useEffect( () => {
        const observable = session.isLoggedIn$.subscribe( ( isLoggedIn ) => {
            setIsLoggedIn( isLoggedIn );
        } );

        return () => {
            observable.unsubscribe();
        };
    }, [] );

    useEffect( () => {
        const observable = session.userType$.subscribe( ( userType ) => {
            setUserType( userType );
        } );

        return () => {
            observable.unsubscribe();
        };
    }, [] );

    return (
        <>
            { !isLoggedIn && <TransverseHeader /> }
            { ( isLoggedIn && userType == UserTypeEnum.FREELANCER ) && <FreelancerHeader /> }
        </>
    );
}