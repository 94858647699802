import { AuthGuard } from '@/core/auth/AuthGuard';
import { RoleGuard } from '@/core/auth/RoleGuard';
import { UserTypeEnum } from '@/gql/graphql';

import ClientAccountEditYTChannels from '@/pages/client/views/account/client-account-edit-yt-channels/ClientAccountEditYTChannels';
import ClientAccountEditPassword from '@/pages/client/views/account/client-account-edit-password/ClientAccountEditPassword';
import ClientFreelancerProfile from '@/pages/client/views/freelancer/ClientFreelancerProfile';
import ErrorBoundary from '@/pages/errors/ErrorBoundary';
import RootClient from '@/pages/client/views/RootClient';
import ClientAccount from '@/pages/client/views/account/ClientAccount';
import ClientAccountEditProfile from '@/pages/client/views/account/client-account-edit-profile/ClientAcountEditProfile';
import { Navigate } from 'react-router-dom';
import JobPostForm from '@/pages/client/views/job-post/job-post-form/JobPostForm';
import ClientJobPosts from '@/pages/client/views/job-posts/ClientJobOffers';
import ClientJobPostManagement from '@/pages/client/views/job-post/job-post-management/ClientJobPostManagement';
import ClientJobPostGettingStated from '@/pages/client/views/job-post/job-post-management/ClientJobPostGettingStated';
import ClientJobPostTalents from '@/pages/client/views/job-post/job-post-management/job-post-talents/ClientJobPostTalents';
import ClientJobPostDetails from '@/pages/client/views/job-post/job-post-management/ClientJobPostDetails';
import ClientAccountPlansBilling from './views/account/client-account-plans-billing/ClientAccountPlansBilling';
import ClientOrderPaymentCancelled from './views/account/client-account-orders/ClientOrderPaymentCancelled';
import ClientOrderPaymentSuccess from './views/account/client-account-orders/ClientOrderPaymentSuccess';

export const ClientRouting = {
    path: 'client',
    element:
        <AuthGuard redirectPath="/">
            <RoleGuard allowedUserType={ UserTypeEnum.CLIENT }
                       redirectPath="/freelancer">
                <RootClient />
            </RoleGuard>
        </AuthGuard>,
    errorElement: <ErrorBoundary />,
    children: [
        {
            path: '',
            element: <Navigate to="/client/job-posts"
                               replace />
        },
        {
            path: 'p/:id',
            element: <ClientFreelancerProfile />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'create-job-post',
            element: <JobPostForm />,
            errorElement: <ErrorBoundary />
        },
        {
            path: 'job-posts',
            children: [
                {
                    path: '',
                    element: <Navigate to={ '/client/job-posts/status/ongoing' }
                                       replace />
                },
                {
                    path: 'status',
                    element: <ClientJobPosts />,
                    children: [
                        {
                            path: ':status',
                            element: <ClientJobPosts />
                        }
                    ]
                },
                {
                    path: ':jobPostId',
                    element: <ClientJobPostManagement />,
                    children: [
                        {
                            path: '',
                            element: <Navigate to="getting-started"
                                               replace />
                        },
                        {
                            path: ':status',
                            element: <ClientJobPostTalents />
                        },
                        {
                            path: 'getting-started',
                            element: <ClientJobPostGettingStated />
                        },
                        {
                            path: 'details',
                            element: <ClientJobPostDetails />
                        }
                    ]
                },
                {
                    path: ':jobPostId/p/:id',
                    element: <ClientFreelancerProfile />,
                    errorElement: <ErrorBoundary />
                }
            ]
        },
        {
            path: 'settings',
            element: <ClientAccount />,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    path: '',
                    element: <Navigate to="/client/settings/edit-profile"
                                       replace />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-profile',
                    element: <ClientAccountEditProfile />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'yt-channels',
                    element: <ClientAccountEditYTChannels />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'edit-password',
                    element: <ClientAccountEditPassword />,
                    errorElement: <ErrorBoundary />
                },
                {
                    path: 'plans-billing',
                    element: <ClientAccountPlansBilling />,
                    errorElement: <ErrorBoundary />
                }
            ]
        },
        {
            path: 'orders',
            children: [
                {
                    path: 'payment-cancelled',
                    element: <ClientOrderPaymentCancelled />
                },
                {
                    path: 'payment-success',
                    element: <ClientOrderPaymentSuccess />
                }
            ]
        }
    ]
};