import { Link, useOutletContext, useParams } from 'react-router-dom';
import ClientJobPostTalentsContent from './ClientJobPostTalentsContent';
import { Trans, useTranslation } from 'react-i18next';
import { App, Button } from 'antd';
import { ChainLinkFilledIcon, ProfileFilledIcon } from '@/assets/icons/CustomIcons';
import { useEffect, useRef } from 'react';
import InfoMessage from '@/components/InfoMessage';
import { JobPost } from '@/gql/graphql';
import { useMutation } from '@apollo/client';
import attachChannelToJobPostGQL from '@/gql/global-queries/attachChannelToJobPostGQL';
import PairChannelModal from '../../PairChannelModal';
import { PageName, trackPageView } from '@/utils/analytics';

const ClientJobPostTalents = () => {
    const { jobPost, jobPostRefetch } = useOutletContext<{ jobPost: JobPost, jobPostRefetch: any }>();
    const { status, jobPostId } = useParams();
    const attachChannelToJobPostMutation = useMutation( attachChannelToJobPostGQL );
    const { t } = useTranslation();
    const { message } = App.useApp();

    const pairChannelModalRef = useRef( null );

    const renderTitleAndDescription = () => {
        switch( status ) {
            case 'notified':
                return (
                    <div className="flex flex-col gap-2">
                        <h1 className="font-sharp text-2xl">
                            { t( 'client:jobPosts.outreached.title' ) }
                        </h1>
                        <p className="text-grey">
                            <Trans i18nKey="client:jobPosts.outreached.description" />
                        </p>
                    </div>
                );
            case 'interested':
                return (
                    <div className="flex flex-col gap-2">
                        <h1 className="font-sharp text-2xl">
                            { t( 'client:jobPosts.interested.title' ) }
                        </h1>
                        <p className="text-grey">
                            <Trans i18nKey="client:jobPosts.interested.description" />
                        </p>
                    </div>
                );
            case 'favorites':
                return (
                    <div className="flex flex-col gap-2">
                        <h1 className="font-sharp text-2xl">{ t( 'client:jobPosts.favorites.title' ) }</h1>
                        <p className="text-grey">
                            <Trans i18nKey="client:jobPosts.favorites.description" />
                        </p>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderEmptyContent = () => {
        switch( status ) {
            case 'outreached':
                return (
                    <div className="flex flex-col gap-8">
                    <span className="font-sharp text-3xl">
                        { t( 'client:jobPosts.outreached.noDataTitle' ) }
                    </span>
                    </div>
                );
            case 'interested':
                return (
                    <div className="flex flex-col gap-8">
                        <span className="font-sharp text-3xl">{ t( 'client:jobPosts.interested.noDataTitle' ) }</span>
                        <span className="text-grey">
                        { t( 'client:jobPosts.interested.noDataDescription' ) }
                    </span>
                        <Link to={ `/client/job-posts/${ jobPostId }/notified` }
                              replace={ true }>
                            <Button type="primary"
                                    size="small"
                                    icon={ <ProfileFilledIcon /> }>
                                { t( 'client:jobPosts.interested.noDataButton' ) }
                            </Button>
                        </Link>
                    </div>
                );
            case 'favorites':
                return (
                    <div className="flex flex-col gap-8">
                    <span className="font-sharp text-3xl">
                        { t( 'client:jobPosts.favorites.noDataTitle' ) }
                    </span>
                        <span className="text-grey">
                        { t( 'client:jobPosts.favorites.noDataDescription' ) }
                    </span>
                        <Link to={ `/client/job-posts/${ jobPostId }/notified` }
                              replace={ true }>
                            <Button type="primary"
                                    size="small"
                                    icon={ <ProfileFilledIcon /> }>
                                { t( 'client:jobPosts.favorites.noDataButton' ) }
                            </Button>
                        </Link>
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect( () => {
        switch( status ) {
            case 'outreached':
                trackPageView( PageName.ClientJobPostNotifiedCandidates );
                break;
            case 'interested':
                trackPageView( PageName.ClientJobPostInterestedCandidates );
                break;
            case 'favorites':
                trackPageView( PageName.ClientJobPostFavoriteCandidates );
                break;
        }
    }, [] );

    const handlePairChannel = () => {
        pairChannelModalRef.current.open();
    };

    const onPairChannel = async( channelId ) => {
        try {
            const result = await attachChannelToJobPostMutation[ 0 ]( {
                variables: {
                    channelId,
                    jobPostId: jobPost?.id
                }
            } );

            if( result.data ) {
                jobPostRefetch();
                pairChannelModalRef.current.close();
                message.success( t( 'client:jobPost.pairChannel.success' ) );
            }
            else {
                message.error( t( 'translation:error.default' ) );
            }
        } catch( e ) {
            message.error( t( 'translation:error.default' ) );
        }
    };

    return (
        <>
            { renderTitleAndDescription() }

            {
                jobPost && !jobPost?.channel &&
                <InfoMessage>
                  <div className="flex flex-col gap-2">
                        <span>
                            <Trans i18nKey="client:jobPost.pairChannel.description"
                                   components={ [ <span />, <strong /> ] } />
                        </span>
                    <Button onClick={ () => handlePairChannel() }
                            icon={ <ChainLinkFilledIcon /> }
                            className="px-4 py-0 h-8 text-sm w-fit"
                            type="default">
                        { t( 'client:jobPost.pairChannel.button' ) }
                    </Button>
                  </div>
                </InfoMessage>
            }
            <ClientJobPostTalentsContent emptyContentSlot={ renderEmptyContent() } />
            <PairChannelModal ref={ pairChannelModalRef }
                              onChannelIdSumbitted={ ( channelId: string ) => onPairChannel( channelId ) } />
        </>
    );
};

export default ClientJobPostTalents;
