import SkeletonInput from 'antd/es/skeleton/Input';

interface FreelancerListItemSkeletonProps {
    isLoading: boolean;
    count: number;
    height?: number;
    rounded?: '2xl' | '4xl';
}

export default function ListSkeleton( {
                                          isLoading,
                                          count,
                                          height = 474,
                                          rounded = '2xl'
                                      }: FreelancerListItemSkeletonProps ) {

    return (
        <>
            {
                isLoading &&
                [ ...Array( count ).keys() ].map( ( index ) => {
                    return <SkeletonInput active={ true }
                                          className={ `w-full rounded-${ rounded }` }
                                          style={ { height: height + 'px' } }
                                          key={ index } />;
                } )
            }
        </>
    );
}