import { Trans, useTranslation } from 'react-i18next';
import { JobPostFormRepository } from '@/pages/client/views/job-post/job-post-form/jobPostFormRepository';
import SelectMultiTags from '@/components/SelectMultiTags';
import { Controller } from 'react-hook-form';
import { GraduationHatOutlinedIcon } from '@/assets/icons/CustomIcons';
import { ExperienceTypeEnum } from '@/gql/graphql';

interface InputProps {
    control: any;
    errors?: any;
    onChange?: (title: string, value: any) => void,
}

export default function JobPostFormExperiences( { control, errors, onChange: onChangeMethod }: InputProps ) {

    const { t } = useTranslation();

    const repository: JobPostFormRepository = new JobPostFormRepository();

    const handleExperienceChanged = ( experiences: ExperienceTypeEnum[] ) => {       
        onChangeMethod( 'experiences', experiences );
    };

    return (
        <>
            <Controller
                name='experiences'
                control={ control }
                rules={{ required: true }}
                render={() => (
                    <SelectMultiTags
                        onTagsSelected={ ( value ) => handleExperienceChanged( value as ExperienceTypeEnum[] ) }
                        tags={ Object.values( ExperienceTypeEnum )?.map( ( experience ) => ( {
                            id: experience,
                            label: (
                                <div className="flex items-center gap-x-2">
                                    <GraduationHatOutlinedIcon className='text-lg' />
                                    <Trans
                                        i18nKey={`translation:enum.experience.${ experience }`}
                                        components={[<strong />]}
                                    />
                                </div>
                            )
                        } ) ) }
                        defaultSelectedIds={ repository.experiences }/>
                )}
            />
            { errors.experiences &&
                <div className="w-fit text-sm font-normal text-red mt-1.5">
                    {t('common:error.form.required')}
                </div>
            }
        </>
            
    );
};