import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { ArrowRightFilledIcon, FlashFilledIcon } from '@/assets/icons/CustomIcons';
import Stepper from '@/components/Stepper';

interface FormLayoutProps {
    content: JSX.Element;
    hideNextButton?: boolean;
    onNext?: () => void;
    onBack?: () => void;
    setCurrentStep: ( value: number ) => void;
    loadingNext?: boolean;
    isLastStep?: boolean;
    totalStep?: number;
    currentStep?: number;
    progressBar?: any;
}

export default function FormLayout( {
                                        content,
                                        onNext,
                                        onBack,
                                        loadingNext,
                                        currentStep,
                                        totalStep,
                                        progressBar,
                                        hideNextButton = false,
                                        setCurrentStep
                                    }: FormLayoutProps ) {

    const handleNext = () => {
        onNext?.();
        window.scrollTo( 0, 0 );
    };

    const handleBack = () => {
        onBack?.();
        window.scrollTo( 0, 0 );
    };

    const { t } = useTranslation();

    return (
        <div className="w-full flex flex-col justify-between px-2 md:px-6">
            <div className="flex flex-col items-center w-full mb-auto">
                <div className="z-20 flex-col items-center max-w-[calc(668px-48px)] md:max-w-[668px] sticky top-0 pt-2 md:pt-4 w-full bg-white">
                    <div className="self-center px-2 md:px-8 md:pl-8 md:pr-2">
                        <Stepper steps={ progressBar?.map( item => item.label ) }
                                 currentStep={ currentStep }
                                 onStepClick={ setCurrentStep } />
                    </div>
                    <Divider type="horizontal"
                             className="mt-8 mb-0 hidden md:block" />
                </div>

                <div className="flex-grow w-full max-w-[calc(668px-48px)] md:max-w-[668px] pt-8 mb-8 md:pb-20">
                    { content }
                </div>
            </div>
            <div className="w-full flex max-w-[calc(668px-48px)] md:max-w-[668px] self-center justify-between py-4 md:py-8 sticky bottom-0 bg-white gap-3 z-[1] border-t border-grey-light">
                <Button type="text"
                        loading={ loadingNext }
                        disabled={ loadingNext }
                        onClick={ () => handleBack() }
                        className={ `w-full md:w-fit text-grey bg-gray-100 md:bg-inherit ${ currentStep !== 0
                                                                                            ? 'visible'
                                                                                            : 'invisible' }` }>
                    <span>{ t( 'common:button.back' ) }</span>
                </Button>

                <Button type="primary"
                        loading={ loadingNext }
                        disabled={ loadingNext }
                        onClick={ () => handleNext() }
                        className={ twMerge( 'w-full md:w-fit', hideNextButton ? 'hidden' : '' ) }>
                        <span className="flex gap-2">
                            { currentStep === totalStep - 1 ?
                              <span>{ t( 'common:button.post' ) } <FlashFilledIcon className="text-sm pl-.5" /></span>
                                                            : currentStep === totalStep - 2 ?
                                                              <>
                                                                  <span>{ t( 'common:button.reviewAndPost' ) }</span><ArrowRightFilledIcon className="text-sm" /></>
                                                                                            : <>
                                                                  <span>{ t( 'common:button.next' ) }</span><ArrowRightFilledIcon className="text-sm" /></>
                            }
                        </span>
                </Button>
            </div>
        </div>
    );
}