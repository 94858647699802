import { Trans, useTranslation } from 'react-i18next';
import { OrderTypeEnum } from '@/gql/graphql';
import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { SparklesFilledIcon } from '@/assets/icons/CustomIcons';
import { planTypeName, SegmentEvent, trackSegmentEvent } from '@/utils/analytics';

interface PremiumAnnualPassProps {
    createOrder: ( orderType: OrderTypeEnum ) => void;
    isDisabled?: boolean;
}

const PremiumAnnualPass = ({ createOrder, isDisabled = false }: PremiumAnnualPassProps) => {
    const { t } = useTranslation();

    const features = [
        <li className='flex items-center gap-2 text-xs font-bold' ><CheckCircleFilled className='text-primary-500 text-base bg-white rounded-full' />{t('client:plan.premiumAnnualPass.feature1')}</li>,
        <li className='flex items-center gap-2 text-xs font-normal' ><CheckCircleFilled className='text-primary-500 text-base bg-white rounded-full' />{t('client:plan.premiumAnnualPass.feature2')}</li>,
        <li className='flex items-center gap-2 text-xs font-normal' ><CheckCircleFilled className='text-primary-500 text-base bg-white rounded-full' />{t('client:plan.premiumAnnualPass.feature3')}</li>,
        <li className='flex items-center gap-2 text-xs font-normal' ><CheckCircleFilled className='text-primary-500 text-base bg-white rounded-full' />{t('client:plan.premiumAnnualPass.feature4')}</li>,
    ];

    const onSubmit = () => {
        trackSegmentEvent( SegmentEvent.UpgradePlanClicked, { plan_type: planTypeName.AnnualPass } );
        createOrder(OrderTypeEnum.PREMIUM_ANNUAL_PASS);
    };

    return (
        <div className="plan-card w-full h-auto flex-1 bg-[#101828] text-white rounded-xl p-6 flex flex-col gap-4 justify-between">
            <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                    <div className="relative ">
                        <div className="bg-[linear-gradient(89.25deg,_#8C96F2_-33.77%,_#9610FF_44.77%,_#2C4992_99.36%)] rounded-xl border-[1px] border-transparent">
                            <div className="relative bg-primary-500 rounded-xl h-full w-full flex items-center justify-center">
                                <SparklesFilledIcon className='text-4xl'/>
                            </div>
                        </div>
                    </div>
                    <div className='rounded-4xl font-semibold text-xs h-fit w-fit px-2 py-1 bg-gradient-to-r from-[#8C96F2] via-[#9610FF] to-[#2C4992]'>{ t('client:plan.premiumAnnualPass.mostPopular') }</div>
                </div>
                <h4 className='text-sm font-medium'>{ t('client:plan.premiumAnnualPass.title') }</h4>
            </div>
            <div className='flex flex-col gap-4'>
                <div>
                    <Trans i18nKey="client:plan.premiumAnnualPass.price">
                        <span className="font-sharp text-2xl"></span>
                        <span className="font-sharp text-5xl"></span>
                        <span className="text-base"></span>
                    </Trans>
                </div>

           
                <ul className="list-none list-inside mb-4 flex flex-col gap-2">
                    {features.map((feature, index) => <div key={ index }>{feature}</div> )}
                </ul>
            </div>
            
            <Button
                type='primary'
                disabled={ isDisabled }
                className={ isDisabled && 'bg-[#E6E9F0]/90' }
                onClick={() => onSubmit()}
            >
                { isDisabled ? t('client:plan.activatedButton') : t('client:plan.activateButton') }
            </Button>
        </div>
    );
};

export default PremiumAnnualPass;
