import { useEffect, useState } from 'react';
import FormStepHeader from '../layout/FormStepHeader';

interface JobPostFormStepMainProps {
    currentStep: number;
    steps: [
        {
            content: JSX.Element,
            titleRight: string;
            subtitle: string;
            optional: boolean;
        }
    ];
}

export default function JobPostFormStepMain( { currentStep, steps }: JobPostFormStepMainProps ) {

    const [ step, setStep ] = useState( null );

    useEffect( () => {
        if( currentStep != null ) {
            setStep( steps[ currentStep ] );
        }
    }, [ currentStep ] );

    return (
        <>
            <FormStepHeader title={ step?.titleRight }
                                   subTitle={ step?.subtitle }
                                   optional={ step?.optional } />

            <div className="w-full">
                { step?.content }
            </div>
        </>
    );
}