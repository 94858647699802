import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { PlusOutlinedIcon } from '@/assets/icons/CustomIcons';
import { twMerge } from 'tailwind-merge';

interface FreelanceProfileBioProps {
    editable?: boolean;
    text: string;
    maxLength?: number;
    noText?: string;
    className?: string;
    showMoreButtonText?: string;
    showLessButtonText?: string;
    noBioMessage?: string;
    editBio?: () => void;
    classNameShowMoreButton?: string;
}

export default function Paragraphs( {
                                        editable,
                                        text,
                                        maxLength,
                                        noText,
                                        className,
                                        showLessButtonText,
                                        showMoreButtonText,
                                        noBioMessage,
                                        editBio,
                                        classNameShowMoreButton
                                    }: FreelanceProfileBioProps ) {

    const { t } = useTranslation();

    const defaultShowLessButtonText: string = t( 'common:button.showLessButton' ),
          defaultShowMoreButtonText: string = t( 'common:button.showMoreButton' );

    maxLength = maxLength ? maxLength : 200;

    const [ textToDisplay, setTextToDisplay ] = useState<string>( null );
    const [ isTextExpanded, setIsTextExpanded ] = useState<boolean>( false );
    const [ _showLessButtonText, setShowLessButtonText ] = useState<string>( defaultShowLessButtonText );
    const [ _showMoreButtonText, setShowMoreButtonText ] = useState<string>( defaultShowMoreButtonText );

    useEffect( () => {
        if( text != null ) {
            handleReadLess();
        }
    }, [ text ] );

    useEffect( () => {
        if( showLessButtonText != null ) {
            setShowLessButtonText( showLessButtonText );
        }
    }, [ showLessButtonText ] );

    useEffect( () => {
        if( showMoreButtonText != null ) {
            setShowMoreButtonText( showMoreButtonText );
        }
    }, [ showMoreButtonText ] );

    const handleReadMore = () => {
        setTextToDisplay( text );
        setIsTextExpanded( true );
    };

    const handleReadLess = () => {
        if( text.length > 0 ) {
            setTextToDisplay( text?.substring( 0, maxLength ) + ( text.length > maxLength ? '...' : '' ) );
        }
        setIsTextExpanded( false );
    };

    if( textToDisplay == null || textToDisplay == '' ) {
        if( editable ) {
            return <Button type="default"
                           onClick={ editBio }
                           icon={ <PlusOutlinedIcon className="text-sm" /> }
                           className={ twMerge( 'w-fit', className ) }>
                { noBioMessage || t( 'freelancer:account.edit-profile.defaultEditButton' ) }
            </Button>;
        }
        return (
            <p className={ twMerge( 'text-sm mt-1 whitespace-pre-wrap', className ) }>
                { noText }
            </p>
        );
    }

    return (
        <p className={ `text-base font-normal text-grey whitespace-pre-wrap ${ className }` }>
            { textToDisplay }
            <br />
            {
                textToDisplay.length > maxLength ?
                <span className={ `cursor-pointer text-black font-semibold ${ classNameShowMoreButton }` }
                      onClick={ isTextExpanded ? handleReadLess : handleReadMore }>
                            {
                                isTextExpanded
                                ? _showLessButtonText
                                : _showMoreButtonText
                            }
                </span> :
                null
            }
        </p>
    );
}