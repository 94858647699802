import { Controller } from 'react-hook-form';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { Select } from 'antd';
import React, { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface SelectProps {
    options: any;
    className?: string;
    defaultValue?: string | number;
    control: any;
    name: string;
    label?: string;
    filterOption?: any;
    placeholder: string;
    rules?: any;
    errors: any;
    optionRender?: any;
    dropdownRender?: any;
    suffix?: JSX.Element | string;
    defaultActiveFirstOption?: boolean;
}


export default function SelectComponent( {
                                             options,
                                             className,
                                             defaultValue,
                                             control,
                                             name,
                                             label,
                                             filterOption,
                                             placeholder,
                                             optionRender,
                                             dropdownRender,
                                             rules,
                                             errors,
                                             defaultActiveFirstOption = false
                                         }: SelectProps ) {
    const [ isFocused, setIsFocused ] = useState( false );
    const selectRef = useRef( null );

    const focusBorder = isFocused ? 'border-black' : 'border-grey-light';
    const errorBorder = errors[ name ] ? 'border-red' : '';

    return (
        <>
            {
                options != null && options.length > 0 ?
                <div className={ twMerge( 'w-full', className ) }
                     onClick={ () => selectRef.current.focus() }>
                    <Controller control={ control }
                                name={ name }
                                rules={ rules }
                                defaultValue={ defaultValue }
                                render={ ( { fieldState: { error }, field: { onChange, onBlur, value, ref } } ) => (
                                    <div className="w-full custom-select">
                                        <div className={ twMerge( 'w-full transition-colors duration-500 ease-in-out border rounded-[10px] px-3.5 py-2.5', focusBorder, errorBorder ) }>
                                            <label className="text-xs text-grey font-normal">
                                                { label }
                                            </label>
                                            <Select options={ options }
                                                    defaultValue={ value }
                                                    defaultActiveFirstOption={ defaultActiveFirstOption }
                                                    showSearch
                                                    className="w-full p-0 m-0"
                                                    ref={ ( e ) => {
                                                        ref( e );
                                                        selectRef.current = e;
                                                    } }
                                                    onBlur={ () => {
                                                        setIsFocused( false );
                                                        onBlur();
                                                    } }
                                                    optionLabelProp="label"
                                                    placeholder={ placeholder }
                                                    onFocus={ () => setIsFocused( true ) }
                                                    optionFilterProp="children"
                                                    filterOption={ filterOption }
                                                    status={ error ? 'error' : '' }
                                                    onChange={ onChange }
                                                    bordered={ false }
                                                    optionRender={ optionRender } />
                                        </div>
                                        {
                                            errors[ name ] &&
                                            (
                                                <div className="w-fit text-sm font-normal text-red mt-1.5">
                                                    { firstLetterUppercaseFormatter( errors[ name ].message.toString() ) }
                                                </div>
                                            )
                                        }
                                    </div>
                                ) }
                    />
                </div> :
                null

            }
        </>
    );
}