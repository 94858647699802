import { gql } from '@apollo/client';

const freelancerGQL = gql`
    query Freelancer($id: String!) {
        freelancer(id: $id) {
            id
            customUrl
            firstName
            lastName
            bio
            profilePicture
            averageDailyRate
            priceCurrency
            experience
            isAvailable
            isCertified
            trainerAppreciation
            portfolioElementStats {
                countViews
                countElements
            }
            creatorsIWorkedWith {
                id
                name
                channelUrl
                thumbnailUrl
                subscriberCount
            }
            mainJob
            jobs
            expertises
            languages
            skills
        }
    }
`;

export default freelancerGQL;