import numeral from 'numeral';
import i18next from 'i18next';
import { currencySymbolFormatter } from './currencySymbolFormatter';
import { CurrencyEnum } from '@/gql/graphql';

interface priceFormatterOptions {
    currency?: CurrencyEnum;
    amount: number;
    lng?: string;
    vat?: boolean | null;
    decimals?: boolean;
}

export function priceFormatter( {
                                    currency,
                                    amount,
                                    lng = i18next.language,
                                    vat,
                                    decimals
                                }: priceFormatterOptions ): string {
    let res: string = '';
    const numeralFormat: string = decimals ? 'formatNumberWithDecimals' : 'formatNumberWithoutDecimals';

    switch( lng ) {
        case 'fr':
            res = `${ numeral( amount ).format( i18next.t( numeralFormat ) ) } ${ currency ? currencySymbolFormatter( currency ) : '' }`;
            break;
        case 'en':
            res = `${ currency ? currencySymbolFormatter( currency ) : '' }${ numeral( amount ).format( i18next.t( numeralFormat ) ) }`;
            break;
    }

    if( vat != null ) {
        res += vat
               ? ` ${ i18next.t( 'vat-format.TTC' ) }`
               : ` ${ i18next.t( 'vat-format.HT' ) }`;
    }

    return res;
}