import Header from '@/components/layout/Header';
import SupportButton from '@/components/SupportButton';
import { useMediaQuery } from 'react-responsive';

export default function AuthHeader() {

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const rightJSX = (
        <div className="flex items-center justify-center gap-x-1 lg:gap-x-4">
            <SupportButton onlyIcon={ isTabletOrMobile } />
        </div>
    );

    return (
        <Header logoTo="/"
                right={ rightJSX } />
    );
}