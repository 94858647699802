import { useEffect } from 'react';
import { useIntercomHook } from '@/plugins/intercom/useIntercomHook';
import { UserTypeEnum } from '@/gql/graphql';
import { SessionRepository } from '@/core/auth/sessionRepository';

export default function IntercomProvider( { children } ) {
    const sessionRepository: SessionRepository = new SessionRepository();
    const { isVisible } = useIntercomHook();

    useEffect( () => {
        const observable = sessionRepository.session$.subscribe( ( sessionProps ) => {
            let intercomSettings = {
                'hide_default_launcher': true,
                api_base: 'https://api-iam.intercom.io',
                app_id: import.meta.env.VITE_INTERCOM_APP_ID
            };

            if( sessionProps.refreshToken !== null ) {
                /* if( window.analytics ) {
                 window.analytics.identify( sessionProps?.user.id, {
                 name: sessionProps?.user.type == UserTypeEnum.CLIENT
                 ? sessionProps?.user.userName
                 : sessionProps?.user.firstName + ' ' + sessionProps?.user.lastName,
                 email: sessionProps?.user.email,
                 userType: sessionProps?.user.type
                 } );
                 }*/

                const loggedIntercomSettings = {
                    name: sessionProps?.user.type == UserTypeEnum.CLIENT
                          ? sessionProps?.user.userName
                          : sessionProps?.user.firstName + ' ' + sessionProps?.user.lastName,
                    email: sessionProps?.user.email,
                    user_id: sessionProps?.user.id,
                    avatar: {
                        type: 'avatar',
                        image_url: sessionProps?.user.profilePicture
                    },
                    customerType: sessionProps?.user.type,
                    environment: import.meta.env.VITE_ENV
                };
                intercomSettings = { ...intercomSettings, ...loggedIntercomSettings };
                if( window.Intercom ) {
                    window.Intercom( 'update', {
                        ...intercomSettings
                    } );
                }
            }
            else {
                if( window.Intercom ) {
                    window.Intercom( 'shutdown' );
                    window.Intercom( 'update', {
                        ...intercomSettings
                    } );
                }

            }
        } );

        return () => observable.unsubscribe();
    }, [] );

    useEffect( () => {
        if( isVisible ) {
            window.Intercom( 'show' );

        }
        else {
            window.Intercom( 'hide' );
        }
    }, [ isVisible ] );


    return (
        <>
            {
                children
            }
        </>
    );
}