import { gql } from '@apollo/client';

const freelancerJobPostsGQL = gql`
    query freelancerJobPosts($page: Int, $filters: JobPostFreelancerFiltersInput, $limit: Int) {
        freelancerJobPosts(page: $page, filters: $filters, limit: $limit ) {
            data {
                channel {
                    thumbnailUrl
                    name
                    subscriberCount
                    id
                }
                id
                name
                description
                createdAt
                role
                priceMax
                priceMin
                status
                expertises
                freelancerHasSentMessage
            }
            total,
            page
        }
    }`;

export default freelancerJobPostsGQL;