import { useLazyQuery } from '@apollo/client';
import { applicationGQL } from '@/gql/global-queries';

export default function applicationHook() {

    const query = useLazyQuery( applicationGQL );

    const getApplication = ( jobPostId: string, freelancerId: string ) => {
        query[ 0 ]( {
            variables: {
                jobPostId,
                freelancerId
            }
        } );
    };

    return {
        getApplication,
        application: query[ 1 ]?.data?.application,
        loading: query[ 1 ]?.loading,
        error: query[ 1 ]?.error
    };
}