import { isIntercomVisible$, setIntercomVisibility, toggleIntercomVisibility } from '@/plugins/intercom/intercomStore';
import { useEffect, useState } from 'react';

export function useIntercomHook() {

    const [ isVisible, setIsVisible ] = useState( false );

    useEffect( () => {
        const observable = isIntercomVisible$.subscribe( ( isVisible ) => {
            setIsVisible( isVisible );
        } );

        return () => {
            observable.unsubscribe();
        };
    }, [] );

    return {
        isVisible,
        toggleIntercomVisibility,
        setIntercomVisibility
    };
}
