import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import AddYTChannelHook from '@/hooks/addYTChannelHook';
import { channelsGQL } from '@/gql/global-queries';

export default function channelsHook() {

    const channelsQuery = useLazyQuery( channelsGQL );
    const [ channels, setChannels ] = useState( [] );

    const getChannels = (): void => {
        channelsQuery[ 0 ]();
    };

    useEffect( () => {
        if( channelsQuery[ 1 ]?.data ) {
            setChannels( channelsQuery[ 1 ]?.data.channels );
        }
    }, [ channelsQuery[ 1 ]?.data ] );

    const addYTChannelHook = AddYTChannelHook();

    useEffect( () => {
        if( addYTChannelHook.channel ) {
            setChannels( [ ...channels, addYTChannelHook.channel ] );
        }
    }, [ addYTChannelHook.channel ] );

    const handleAddChannel = (): void => {
        addYTChannelHook.login();
    };

    return {
        getChannels,
        loading: channelsQuery[ 1 ]?.loading,
        channels,
        handleAddChannel,
        addYTChannelHook
    };
}