import { gql } from '@apollo/client';

const attachChannelToJobPostGQL = gql`
    mutation AttachChannelToJobPost($jobPostId: String!, $channelId: String!) {
        attachChannelToJobPost(jobPostId: $jobPostId, channelId: $channelId) {
            channel {
                id
                name
                thumbnailUrl
                subscriberCount
            }
        }
    }
`;

export default attachChannelToJobPostGQL;