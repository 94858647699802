import { gql } from '@apollo/client';

const loginWithGoogleGQL = gql`
    mutation LoginWithGoogleGQL($code: String!){
        loginWithGoogle(code: $code){
            accessToken
            refreshToken
            cometchatAuthToken
            email
            user {
                id
                type
                firstName
                lastName
                phone
                userName
                profilePicture
            }
        }
    }
`;

export default loginWithGoogleGQL;