import { PortfolioElement, PortfolioElementTypeEnum } from '@/gql/graphql';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { getTimeDifference } from '@/utils/getTimeDifference';
import handleResizeHook from '@/hooks/handleResizeHook';
import { PlusOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Link } from 'react-router-dom';

interface FeaturedPortfolioElementProps {
    portfolioElement: PortfolioElement;
    editable?: boolean;
}

export default function FeaturedPortfolioElement( { portfolioElement, editable }: FeaturedPortfolioElementProps ) {

    const { t } = useTranslation();
    const containerRef = useRef( null );
    const playerHeight = handleResizeHook( { ref: containerRef } );

    if( !portfolioElement ) {
        if( editable ) {
            return (
                <Link to="/freelancer/profile/edit-portfolio">
                    <div className="w-full flex flex-col items-center justify-center rounded-2xl bg-[#F9F9FB] cursor-pointer"
                        ref={ containerRef }
                        style={ {
                            height: playerHeight + 'px'
                        } }>
                        <Button type="default"
                                shape="circle"
                                className="flex-none border-primary-200 bg-[#F9F9FB]"
                                icon={ <PlusOutlinedIcon className="text-xl text-primary-400" /> } />
                        <span className="text-primary-500 mt-4 font-semibold	">
                        { t( 'freelancer:account.edit-portfolio.addFeaturedVideo' ) }
                    </span>
                    </div>
                </Link>
            );
        } else {
            return <div className='w-full flex flex-col items-center justify-center rounded-2xl bg-[#F9F9FB]'
                ref={ containerRef }
                style={ {
                    height: playerHeight + 'px'
                } }>
                <span>{ t( 'freelancer:account.edit-portfolio.noVideo' ) }</span>
            </div>
        }
    }

    return (
        <div className="w-full">
            <div className="rounded-2xl overflow-hidden relative"
                 ref={ containerRef }>
                <ReactPlayer url={ portfolioElement?.url }
                             muted={ true }
                             volume={ 1 }
                             width="100%"
                             height={ playerHeight + 'px' }
                             light={ true }
                             playing={ true } />
            </div>

            <div className="mt-4 flex items-center font-medium text-base space-x-2">
                <Avatar size={ 32 }
                        icon={ <UserOutlined /> }
                        src={ portfolioElement?.portfolioChannel?.thumbnailUrl } />

                <span className="text-black">
                    { portfolioElement?.portfolioChannel?.name }
                </span>
                {

                    portfolioElement?.type == PortfolioElementTypeEnum.VIDEO ?
                    <span className="flex-none  text-grey">
                        { `${ abbreviationNumberFormatter( portfolioElement?.viewCount ) } ${ t( 'common:freelancerProfile.card.views', { count: portfolioElement.viewCount } ) } • ${ getTimeDifference( portfolioElement?.publishedAt ) }` }
                    </span> : ''
                }
            </div>
        </div>
    );
}