import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { App, Button, Divider, Skeleton, Tag } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { ContentTypeEnum, ContractTypeEnum, ExperienceTypeEnum, ExpertiseEnum, Freelancer, JobEnum, LanguageEnum, SkillEnum } from '@/gql/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { freelancerProfileGQL, updateFreelancerProfileGQL } from '@/gql/global-queries';
import { identifySegmentUser, SegmentEvent, trackSegmentEvent, userTypeName } from '@/utils/analytics';
import numberFormatter from '@/utils/numberFormatter';
import * as Sentry from '@sentry/react';
import { Form } from 'react-router-dom';
import InputComponent from '@/components/custom-element-form/InputComponent';
import TextAreaComponent from '@/components/custom-element-form/TextAreaComponent';
import SelectMultiTags from '@/components/SelectMultiTags';
import jobToIcon from '@/utils/jobToIcon';
import { ExclamationFilledIcon } from '@/assets/icons/CustomIcons';
import FreelancerAccountEditProfilePicture from '@/pages/freelancer/views/account/freelancer-account-edit-profile/components/freelancerAccountEditProfilePicture';
import jobTypeToIcon from '@/utils/jobTypeToIcon';
import SelectCountry from '@/components/SelectCountry';

interface ProfileEditInformationFormProps {
    onEdited?: () => void;
}

export default function ProfileEditInformationForm( { onEdited }: ProfileEditInformationFormProps ) {
    const maxLengthBio: number = 1000;

    const { t } = useTranslation();
    const {
              trigger,
              control,
              setValue,
              reset,
              formState: { errors, dirtyFields },
              handleSubmit
          } = useForm();

    const sessionRepository: SessionRepository = new SessionRepository();
    const { message } = App.useApp();

    const profilePictureRef = useRef( null );

    const [ isLoading, setIsLoading ] = useState<boolean>( true );
    const [ isUpdating, setIsUpdating ] = useState<boolean>( false );
    const [ defaultExpertiseIds, setDefaultExpertiseIds ] = useState<string[]>( [] );
    const [ defaultContractTypes, setDefaultContractTypes ] = useState<string[]>( [] );
    const [ defaultContentTypes, setDefaultContentTypes ] = useState<string[]>( [] );
    const [ defaultSecondaryLanguageIds, setDefaultSecondaryLanguageIds ] = useState<string[]>( [] );
    const [ defaultNativeLanguageIds, setDefaultNativeLanguageIds ] = useState<string[]>( [] );
    const [ defaultSkillIds, setDefaultSkillIds ] = useState<string[]>( [] );
    const [ defaultJobIds, setDefaultJobIds ] = useState<string[]>( [] );
    const [ defaultExperience, setDefaultExperience ] = useState<ExperienceTypeEnum>( null );
    const [ mainJobId, setMainJobId ] = useState<string>( null );
    const [ experiencesTag, setExperiencesTag ] = useState<any[]>( [] );
    const [ nativeLanguagesTag, setNativeLanguagesTag ] = useState<LanguageEnum[]>( [] );

    const freelancerProfileQuery = useQuery( freelancerProfileGQL );
    const updateFreelancerProfileMutation = useMutation( updateFreelancerProfileGQL );

    const expertiseTagFormRef = useRef( null );
    const nativeLanguagesTagFormRef = useRef( null );
    const secondaryLanguagesTagFormRef = useRef( null );
    const skillTagFormRef = useRef( null );
    const jobFormRef = useRef( null );
    const experienceFormRef = useRef( null );
    const contentTypesFormRef = useRef( null );
    const contractTypeFormRef = useRef( null );


    useEffect( () => {
        const experiences: ExperienceTypeEnum[] = Object.values( ExperienceTypeEnum );
        const options: any[] = [];
        experiences.forEach( ( experience: ExperienceTypeEnum ) => {
            options.push( {
                label: t( `enum.experiences.long.${ experience }` ),
                id: experience
            } );
        } );
        setExperiencesTag( options );
    }, [] );

    useEffect( () => {
        if( freelancerProfileQuery.data ) {
            const freelancer: Freelancer = freelancerProfileQuery.data.meFreelancer as Freelancer;
            sessionRepository.updateUser( freelancer );

            setFormValues( freelancer );
            setIsLoading( false );
        }
    }, [ freelancerProfileQuery.data ] );

    const setFormValues = ( freelancer: Freelancer ) => {

        setValue( 'averageDailyRate', freelancer.averageDailyRate );
        setValue( 'bio', freelancer.bio );
        setValue( 'countryBased', freelancer.countryBased );

        setDefaultExpertiseIds( freelancer.expertises );
        setDefaultContentTypes( freelancer.contentTypes );
        setDefaultContractTypes( freelancer.contractTypes );
        setDefaultNativeLanguageIds( freelancer.nativeLanguages );
        setDefaultSecondaryLanguageIds( freelancer.secondaryLanguages );
        setDefaultSkillIds( freelancer.skills );
        setDefaultJobIds( freelancer.jobs );
        setMainJobId( freelancer.mainJob );
        setDefaultExperience( freelancer.experience || null );

        setNativeLanguagesTag( freelancer.nativeLanguages );
    };

    const handleSelectNativeLanguages = ( value: LanguageEnum[] ) => {
        setNativeLanguagesTag( value );
    };

    const onSubmit = async( data ): Promise<void> => {
        setIsUpdating( true );

        await profilePictureRef.current.uploadProfilePicture();

        if( !( await trigger() ) ) {
            return;
        }

        const args = {
            averageDailyRate: numberFormatter( data.averageDailyRate ),
            bio: data.bio,
            experience: ( experienceFormRef.current as any ).getSelectedIds()?.[ 0 ],
            expertises: ( expertiseTagFormRef.current as any ).getSelectedIds(),
            nativeLanguages: ( nativeLanguagesTagFormRef.current as any ).getSelectedIds(),
            secondaryLanguages: ( secondaryLanguagesTagFormRef.current as any ).getSelectedIds(),
            countryBased: data.countryBased,
            contentTypes: ( contentTypesFormRef.current as any ).getSelectedIds(),
            contractTypes: ( contractTypeFormRef.current as any ).getSelectedIds(),
            skills: ( skillTagFormRef.current as any ).getSelectedIds(),
            jobs: ( jobFormRef.current as any ).getSelectedIds(),
            mainJob: ( jobFormRef.current as any ).getMainTagId()
        };

        try {
            const response = await updateFreelancerProfileMutation[ 0 ]( {
                variables: {
                    args
                }
            } );

            if( response.data?.freelancerUpdate ) {
                const refetchData = await freelancerProfileQuery.refetch();
                await identifySegmentUser( refetchData.data.meFreelancer, userTypeName.Freelancer );
                sessionRepository.updateUser( response.data.freelancerUpdate );
                message.success( t( 'common:form.successfullySubmitted' ) );
               trackSegmentEvent( SegmentEvent.ProfileUpdated );
                reset( {}, { keepValues: true } );
                onEdited?.();
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to edit freelancer profile', e );
            message.error( t( 'common:form.submitError' ) );
        }

        setIsUpdating( false );
    };

    if( isLoading ) {
        return <Skeleton active={ true } />;
    }

    return (
        <>
            <Divider type="horizontal"
                     className="my-2 lg:mb-6" />
            <Form className="flex flex-col gap-y-6 pb-6 lg:pb-0"
                  onSubmit={ handleSubmit( ( data ) => onSubmit( data ) ) }>
                <div className="flex justify-between w-full">
                    <h1 className="font-sharp text-lg">{ t( 'freelancer:account.edit-profile.mainInfo' ) }</h1>
                    <Tag color="error"
                         icon={
                             <div className="flex items-center justify-center mr-1 w-4 h-4 rounded-full border-[1.5px] border-red">
                                 <ExclamationFilledIcon className="text-red text-[12px]" />
                             </div> }>{ t( 'freelancer:account.edit-profile.mandatory' ) }
                    </Tag>
                </div>

                <FreelancerAccountEditProfilePicture ref={ profilePictureRef } />

                <div className="flex flex-col justify-start w-full gap-4">
                    <div className="flex flex-col gap-2">
                        <label className="font-semibold text-base">
                            { t( 'freelancer:account.edit-profile.myJobs' ) }
                        </label>

                        <label className="text-sm text-[#7A7F85]">
                            { t( 'freelancer:account.edit-profile.myJobsDescription' ) }
                        </label>
                    </div>

                    <SelectMultiTags
                        enableMainTag={ true }
                        tags={ Object.values( JobEnum ).map( ( job ) => ( {
                            id: job,
                            label: (
                                <div>
                                    { jobToIcon( job, 'text-lg' ) }
                                    <span className="ml-2">
                                        { t( 'translation:enum.jobs.' + job ) }
                                    </span>
                                </div>
                            )
                        } ) ) }
                        ref={ jobFormRef }
                        defaultMainTagId={ mainJobId }
                        defaultSelectedIds={ defaultJobIds } />
                </div>

                <div>
                    <label className="font-semibold text-base">
                        { t( 'freelancer:account.edit-profile.myAverageDailyPriceLabel' ) }
                    </label>

                    <InputComponent control={ control }
                                    className="mt-2"
                                    name="averageDailyRate"
                                    label={ t( 'freelancer:account.edit-profile.myAveragePrice' ) }
                                    tooltipLabel={ t( 'freelancer:account.edit-profile.myAveragePriceDescription' ) }
                                    placeholder={ t( 'freelancer:account.edit-profile.myAveragePricePlaceholder' ) }
                                    prefix="$"
                                    errors={ errors }
                                    rules={ {
                                        required: t( 'common:error.form.required' ),
                                        min: {
                                            value: 0,
                                            message: t( 'freelancer:account.edit-profile.error.min' )
                                        }
                                    } } />
                </div>

                <div className="flex flex-col justify-start w-full">
                    <label className="font-semibold text-base mb-2">
                        { t( 'freelancer:account.edit-profile.experience' ) }
                    </label>
                    <SelectMultiTags tags={ experiencesTag }
                                     limit={ 1 }
                                     ref={ experienceFormRef }
                                     defaultSelectedIds={ [ ExperienceTypeEnum[ defaultExperience ] ] } />
                </div>

                <div className="flex flex-col justify-start w-full gap-y-4">
                    <div className="flex flex-col gap-2">
                        <label className="font-semibold text-base">
                            { t( 'freelancer:account.edit-profile.myCountryBased' ) }
                        </label>
                        <p className="text-sm text-[#7A7F85]">
                            { t( 'freelancer:account.edit-profile.countryDesc' ) }
                        </p>
                    </div>
                    <SelectCountry control={ control }
                                   controlName="countryBased"
                                   placeholder={ t( 'freelancer:account.edit-profile.myCountryBasedPlaceholder' ) }
                                   errors={ errors } />
                </div>

                <div className="flex flex-col justify-start w-full gap-y-4">
                    <div className="flex flex-col gap-2">
                        <label className="font-semibold text-base">
                            { t( 'freelancer:account.edit-profile.myNativeLanguages' ) }
                        </label>
                        <p className="text-sm text-[#7A7F85]">
                            { t( 'freelancer:account.edit-profile.nativeLanguagesDesc' ) }
                        </p>
                    </div>
                    <SelectMultiTags defaultSelectedIds={ defaultNativeLanguageIds }
                                     tags={ Object.values( LanguageEnum )
                                                  .map( ( language ) => ( {
                                                      id: language,
                                                      label: t( 'translation:enum.languages.' + language )
                                                  } ) ) }
                                     ref={ nativeLanguagesTagFormRef }
                                     onTagsSelected={ ( value ) => handleSelectNativeLanguages( value as LanguageEnum[] ) } />
                </div>

                <Divider type="horizontal"
                         className="my-2 lg:my-4 bg-gray-300" />

                <div className="flex justify-between w-full">
                    <span className="font-sharp text-lg">{ t( 'freelancer:account.edit-profile.optionalInfo' ) }</span>
                </div>

                <div className="flex flex-col justify-start w-full gap-y-4">
                    <div className="flex flex-col gap-2">
                        <label className="font-semibold text-base">
                            { t( 'freelancer:account.edit-profile.mySecondaryLanguages' ) }
                        </label>
                        <p className="text-sm text-[#7A7F85]">
                            { t( 'freelancer:account.edit-profile.secondaryLanguagesDesc' ) }
                        </p>
                    </div>
                    <SelectMultiTags defaultSelectedIds={ defaultSecondaryLanguageIds }
                                     tags={ Object.values( LanguageEnum )
                                                  .filter( language => !nativeLanguagesTag?.includes( language ) )
                                                  .map( ( language ) => ( {
                                                      id: language,
                                                      label: t( 'translation:enum.languages.' + language )
                                                  } ) ) }
                                     ref={ secondaryLanguagesTagFormRef } />
                </div>

                <div className="flex flex-col justify-start w-full gap-y-4">
                    <div className="flex flex-col gap-2">
                        <label className="font-semibold text-base">
                            { t( 'freelancer:account.edit-profile.mySkills' ) }
                        </label>
                        <p className="text-sm text-[#7A7F85]">
                            { t( 'freelancer:account.edit-profile.skillDesc' ) }
                        </p>
                    </div>
                    <SelectMultiTags defaultSelectedIds={ defaultSkillIds }
                                     tags={ Object.values( SkillEnum ).map( ( skill ) => ( {
                                         id: skill,
                                         label: t( 'translation:enum.skills.' + skill )
                                     } ) ) }
                                     ref={ skillTagFormRef } />
                </div>

                <div className="flex flex-col justify-start w-full gap-4">
                    <div className="flex flex-col gap-2">
                        <label className="font-semibold text-base">
                            { t( 'freelancer:account.edit-profile.contentGenre' ) }
                        </label>
                        <p className="text-sm text-[#7A7F85]">
                            { t( 'freelancer:account.edit-profile.contentGenreDescription' ) }
                        </p>
                    </div>
                    <SelectMultiTags defaultSelectedIds={ defaultExpertiseIds }
                                     tags={ Object.values( ExpertiseEnum ).map( ( expertise ) => ( {
                                         id: expertise,
                                         label: t( 'translation:enum.expertises.' + expertise )
                                     } ) ) }
                                     ref={ expertiseTagFormRef } />
                </div>

                <div className="flex flex-col justify-start w-full gap-4">
                    <div className="flex flex-col gap-2">
                        <label className="font-semibold text-base">
                            { t( 'freelancer:account.edit-profile.contentType' ) }
                        </label>
                        <p className="text-sm text-[#7A7F85]">
                            { t( 'freelancer:account.edit-profile.contentTypeDescription' ) }
                        </p>
                    </div>
                    <SelectMultiTags defaultSelectedIds={ defaultContentTypes }
                                     tags={ Object.values( ContentTypeEnum ).map( ( contentType ) => ( {
                                         id: contentType,
                                         label: t( 'translation:enum.formatTypes.' + contentType )
                                     } ) ) }
                                     ref={ contentTypesFormRef } />
                </div>

                <div className="flex flex-col justify-start w-full gap-4">
                    <div className="flex flex-col gap-2">
                        <label className="font-semibold text-base">
                            { t( 'freelancer:account.edit-profile.jobType' ) }
                        </label>
                        <p className="text-sm text-[#7A7F85]">
                            { t( 'freelancer:account.edit-profile.jobTypeDescription' ) }
                        </p>
                    </div>
                    <SelectMultiTags defaultSelectedIds={ defaultContractTypes }
                                     tags={ Object.values( ContractTypeEnum ).map( ( type ) => ( {
                                         id: type,
                                         label: (
                                             <div className="flex items-center gap-x-2">
                                                 { jobTypeToIcon( type ) }
                                                 <span>
                                                    { t( 'translation:enum.types.' + type ) }
                                                </span>
                                             </div>
                                         )
                                     } ) ) }
                                     ref={ contractTypeFormRef } />
                </div>

                <div className="flex flex-col justify-start w-full">
                    <label className="font-semibold text-base mb-2">
                        { t( 'freelancer:account.edit-profile.aboutYourself' ) }
                    </label>

                    <TextAreaComponent control={ control }
                                       name="bio"
                                       rows={ 2 }
                                       label={ t( 'freelancer:account.edit-profile.aboutYourselfLabel' ) }
                                       maxLength={ maxLengthBio }
                                       rules={ {
                                           maxLength: {
                                               value: maxLengthBio,
                                               message: t( 'common:error.form.maxLength', { maxLength: maxLengthBio } )
                                           }
                                       } }
                                       errors={ errors } />
                </div>

                <Button type="primary"
                        htmlType="submit"
                        loading={ isUpdating }
                        className="w-full lg:w-[160px] mt-8">
                    { t( 'freelancer:account.edit-profile.submit' ) }
                </Button>
            </Form>
        </>
    );
}