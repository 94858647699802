import SkeletonAvatar from 'antd/es/skeleton/Avatar';

export default function FreelancerProfileLoaderChannelsPart() {
    return (
        <div className="flex space-x-4 mt-4">
            {
                [ ...Array( 6 ).keys() ].map( ( index ) => {
                    return <SkeletonAvatar active={ true }
                                           style={ { width: '62px', height: '62px' } }
                                           key={ index } />;
                } )
            }
        </div>
    );
}