import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';


export enum ColorSchemeSettingsEnum {
    LIGHT  = 'light',
    DARK   = 'dark',
    SYSTEM = 'system'
}

export enum ColorSchemeEnum {
    LIGHT = 'light',
    DARK  = 'dark'
}

interface ColorSchemeProps {
    mode: ColorSchemeSettingsEnum;
}

const colorSchemeStore = createStore( { name: 'darkMode' },
    withProps<ColorSchemeProps>( {
        mode: ColorSchemeSettingsEnum.SYSTEM
    } )
);

persistState( colorSchemeStore, {
    key: 'colorScheme',
    storage: localStorageStrategy
} );

export class ColorSchemeRepository {

    colorScheme$ = colorSchemeStore.pipe( select( ( state ) => state.mode ) );

    get colorScheme(): ColorSchemeSettingsEnum {
        return colorSchemeStore.getValue().mode;
    }

    updateColorScheme( mode: ColorSchemeSettingsEnum ) {
        colorSchemeStore.update( ( state ) => ( {
            ...state,
            mode
        } ) );
    }
}