import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Channel } from '@/gql/graphql';
import { addChannelGQL } from '@/gql/global-queries';

export default function AddYTChannelHook() {

    const [ addChannelQuery, { data, loading, error } ] = useMutation( addChannelGQL );
    const [ channel, setChannel ] = useState<Channel>( null );

    useEffect( () => {
        if( data ) {
            setChannel( data.addChannelToUser );
        }
    }, [ data ] );


    const login = useGoogleLogin( {
        flow: 'auth-code',
        scope: 'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/yt-analytics-monetary.readonly https://www.googleapis.com/auth/youtube.readonly',
        onSuccess: async( codeResponse ) => {
            addChannelQuery( {
                variables: {
                    code: codeResponse.code
                }
            } );
        },
        onError: errorResponse => errorResponse.error
    } );

    return {
        login,
        channel,
        loading,
        error
    };
}