import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useMediaQuery } from 'react-responsive';

export interface AccountNavigationItem {
    title: string;
    route: string;
    isExternal?: boolean;
    hidden?: boolean;
    children?: AccountNavigationItem[];
}

interface MenuSectionProps {
    routes: AccountNavigationItem[];
    className?: string;
}

export default function MenuSection( { routes, className }: MenuSectionProps ) {
    const location = useLocation();

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const navCSS: string = 'w-fit whitespace-nowrap px-3.5 py-2.5 text-grey hover:text-primary-500 hover:bg-primary-100 rounded-xl font-semibold border border-grey-light md:border-none ';

    const isRouteActive = ( route: string ) => location.pathname === route;

    return (
        <>
            <nav className={ twMerge( 'md:pb-0 w-full overflow-x-scroll md:overflow-auto flex flex-row md:flex-col gap-x-2 md:gap-y-2', className ) }>
                {
                    routes?.map( ( item, index ) => {
                        const isParentActive = item.children?.some( child => isRouteActive( child.route ) ) || isRouteActive( item.route );

                        return (
                            <div key={ index }
                                 className="flex flex-col">
                                {
                                    item.isExternal ? (
                                        <a href={ item.route }
                                           target="_blank"
                                           className={ twMerge( navCSS, item.hidden && 'hidden', isParentActive && 'text-primary-500 bg-primary-100 border-primary-400' ) }
                                           rel="noreferrer">
                                            { item.title }
                                        </a>
                                    ) : (
                                        <NavLink to={ item.route }
                                                 className={ ( { isActive } ) => {
                                                     const active = isActive || isParentActive
                                                                    ? 'text-primary-500 bg-primary-100 border-primary-400'
                                                                    : '';
                                                     return twMerge( navCSS, active, item.hidden && 'hidden' );
                                                 } }>
                                            { item.title }
                                        </NavLink>
                                    )
                                }
                                {
                                    item.children &&
                                    (
                                        <div className="hidden md:flex md:flex-col gap-y-1 pt-1 ml-5">
                                            {
                                                item.children.map( ( subItem, subIndex ) => (
                                                    <NavLink
                                                        to={ subItem.route }
                                                        key={ subIndex }
                                                        className={ ( { isActive: isSubMenuActive } ) => {
                                                            const state = isSubMenuActive
                                                                          ? 'text-primary-500 bg-primary-100 border-primary-400'
                                                                          : 'font-normal text-sm';
                                                            return twMerge( navCSS, state );
                                                        } }>
                                                        { subItem.title }
                                                    </NavLink>
                                                ) )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        );
                    } )
                }
            </nav>

            {
                isTabletOrMobile &&
                <nav className={ twMerge( 'md:pb-0 w-full overflow-x-scroll md:overflow-auto mt-2', className ) }>
                    {
                        routes?.map( ( item, index ) => {
                            const isParentActive = item.children?.some( child => isRouteActive( child.route ) ) || isRouteActive( item.route );

                            return (
                                <div key={ index }
                                     className="flex flex-row md:flex-col gap-x-2 md:gap-y-2">
                                    {
                                        isParentActive && item.children &&
                                        (
                                            <>
                                                {
                                                    item.children.map( ( subItem, subIndex ) => (
                                                        <NavLink to={ subItem.route }
                                                                 key={ subIndex }
                                                                 className={ ( { isActive: isSubMenuActive } ) => {
                                                                     const state = isSubMenuActive
                                                                                   ? 'text-primary-500 bg-primary-100 border-primary-400'
                                                                                   : 'font-normal text-sm';
                                                                     return twMerge( navCSS, state );
                                                                 } }>
                                                            { subItem.title }
                                                        </NavLink>
                                                    ) )
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            );
                        } )
                    }
                </nav>
            }
        </>
    );
}
