import FreelancerProfileLoaderChannelsPart from '@/components/freelance-profile/components/loader/FreelancerProfileLoaderChannelsPart';
import FreelancerProfileCreatorList from '@/components/freelance-profile/components/FreelancerProfileCreatorList';
import { useTranslation } from 'react-i18next';

interface FreelancerProfileCreatorIWorkedWithSectionProps {
    portfolioChannels: any;
    selected: any;
    loadingChannels: boolean;
    width: number;
    className?: string;
}

export default function FreelancerProfileCreatorIWorkedWithSection( {
                                                                        portfolioChannels,
                                                                        selected,
                                                                        loadingChannels,
                                                                        width,
                                                                        className
                                                                    }: FreelancerProfileCreatorIWorkedWithSectionProps ) {
    const { t } = useTranslation();

    const titleJSX = (
        <h2>
            {
                t( 'common:freelancerProfile.card.creatorIWorkWithTitleWithCount_other', {
                        count: portfolioChannels?.length | 0
                    }
                )
            }
        </h2>
    );

    if( loadingChannels ) {
        return (
            <section>
                { titleJSX }
                <FreelancerProfileLoaderChannelsPart />
            </section>

        );
    }

    if( selected?.length == 0 ) {
        return <></>;
    }

    return (
        <section className={ className }
                 style={ {
                     width: width + 'px'
                 } }>
            { titleJSX }
            <FreelancerProfileCreatorList channels={ portfolioChannels }
                                          gap={ 4 }
                                          className="mt-4 w-full"
                                          avatarSize={ 64 } />
        </section>
    );
}