interface ToggleProps {
    isOn: boolean;
    onClick: () => void;
    onContent: JSX.Element;
    offContent: JSX.Element;
}

const ToggleSwitch = ( { isOn, onClick, onContent, offContent }: ToggleProps ) => {

    const Handle = ( { text, active } ) => (
        <div className="flex items-center relative">
            <div className={ `text-xs font-bold uppercase mx-4 relative z-10 ${ active
                                                                                ? 'text-white'
                                                                                : 'text-grey' }` }>
                { text }
            </div>
            { active && (
                <div className="absolute inset-0 bg-primary-400 rounded-full" />
            ) }
        </div>
    );

    return (
        <button className="w-fit block p-1 cursor-pointer text-left appearance-none border-2 rounded-full border-gray-200 bg-gray-50 outline-none"
                onClick={ onClick }
                type="button">
            <div className="flex items-stretch h-10 rounded-full whitespace-nowrap">
                <Handle text={ onContent }
                        active={ !isOn } />
                <Handle text={ offContent }
                        active={ isOn } />
            </div>
        </button>
    );
};

export default ToggleSwitch;
