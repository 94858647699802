import { gql } from '@apollo/client';

export const createFreelancerGQL = gql`
    mutation CreateFreelancer($args: CreateFreelancerInput!){
        createFreelancer(args: $args){
            id
            firstName
        }
    }
`;
