import { gql } from '@apollo/client';

export const UpdateClientProfileGQL = gql`
    mutation UpdateClientProfile($args: UpdateClientInput!) {
        updateClient(args: $args) {
            id
            userName
            email
            phone
            profilePicture
            currency
        }
    }`;


