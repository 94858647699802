import { useTranslation } from 'react-i18next';

import { Avatar, Button, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import FreelancerMainJob from '@/components/freelance-profile/components/FreelancerMainJob';
import { freelancerProfileLinkHook } from '@/hooks/freelancerProfileLinkHook';
import { Application, ApplicationTypeEnum, Freelancer } from '@/gql/graphql';
import LanguagesTag from '@/LanguagesTag';
import { CTALinkOutlinedIcon, DollarOutlinedIcon, GraduationHatOutlinedIcon, MessageOutlinedIcon, SuperLikeFilledIcon } from '@/assets/icons/CustomIcons';
import FreelancerPortfolioStats from '@/components/freelance-profile/components/FreelancerPortfolioStats';
import ApplicationContactDetailsModal from '@/pages/client/views/job-post/ApplicationContactDetailsModal';
import { useRef } from 'react';
import ApplicationFollowUpModal from '@/pages/client/views/job-post/ApplicationFollowUpModal';

export interface FreelancerProfileCardProps {

    freelancer: Freelancer;
    application?: Application;
    hireButton?: boolean;
    favouriteButton?: boolean;
    onSuperLike?: () => void;
}

export default function FreelancerMainInfo( {
                                                freelancer,
                                                onSuperLike,
                                                application
                                            }: FreelancerProfileCardProps ) {

    const { t } = useTranslation();
    const { getProfileLink } = freelancerProfileLinkHook();

    const contactDetailsModalRef = useRef( null );
    const followUpModalRef = useRef( null );

    const copyCustomUrlInClipboard = async(): Promise<void> => {
        const id: string = freelancer.customUrl ? freelancer.customUrl : freelancer.id;
        getProfileLink( id );
    };

    const openSuperLikeModal = () => {
        followUpModalRef.current.open();
    }

    const openContactDetailsModal = () => {
        contactDetailsModalRef.current.open();
    };

    const conditionToDisplayContactDetailsButton: boolean = ( application != null &&
                                                     application.isUnlock &&
                                                     ( application.status == ApplicationTypeEnum.INTERESTED || application.status == ApplicationTypeEnum.FAVORITE ) );

    const conditionToSuperLike: boolean = ( application != null &&
                                            application.isUnlock &&
                                            application.superLikeSentAt == null &&
                                            application.status == ApplicationTypeEnum.OUTREACHED );

    return (
        <>
            <div className="relative flex flex-col items-center space-y-4">
                <Button className="absolute top-0 right-0"
                        onClick={ copyCustomUrlInClipboard }
                        icon={ <CTALinkOutlinedIcon className="text-[20px] text-primary-400" /> } />
                <Avatar size={ 128 }
                        icon={ <UserOutlined /> }
                        src={ freelancer?.profilePicture } />
                <div className="text-2xl leaning-[100px] font-medium font-sharp">
                    { freelancer?.firstName } { freelancer?.lastName?.toUpperCase() }.
                </div>

                <FreelancerMainJob job={ freelancer?.mainJob } />

                {
                   conditionToDisplayContactDetailsButton &&
                    <Button type="primary"
                            className="w-full"
                            icon={<MessageOutlinedIcon className="text-xl text-white" />}
                            onClick={ openContactDetailsModal }>
                        { t( 'common:freelancerProfile.contactDetails' ) }
                    </Button>
                }

                {
                    conditionToSuperLike &&
                    <Button type="primary"
                            className="w-full"
                            icon={  <SuperLikeFilledIcon className="text-xl text-white" /> }
                            onClick={ () => openSuperLikeModal() }>
                        { t( 'common:freelancerProfile.sendMessage' ) }
                    </Button>
                }
                <FreelancerPortfolioStats stats={ {
                    countViews: freelancer?.portfolioElementStats?.countViews || 0,
                    countElements: freelancer?.portfolioElementStats?.countElements || 0
                } } />

                <div className="flex space-x-2">
                    <Tag bordered={ true }
                         icon={ <DollarOutlinedIcon className="text-black" /> }
                         className="text-black border-greyscale bg-greyscale ">
                    <span>
                        { t( 'transverse:freelancers.averageDailyRate', { value: freelancer?.averageDailyRate } ) }
                    </span>
                    </Tag>

                    {
                        freelancer?.experience &&
                        <Tag bordered={ true }
                             icon={ <GraduationHatOutlinedIcon className="text-black" /> }
                             className="text-black border-greyscale bg-greyscale ">
                        <span>
                            { t( 'translation:enum.experiences.short.' + freelancer?.experience ) } { t( 'transverse:years' ) }
                        </span>
                        </Tag>
                    }
                </div>

                <LanguagesTag languages={ freelancer?.languages } />
            </div>

            <ApplicationContactDetailsModal ref={ contactDetailsModalRef }
                                            application={ application } />

            <ApplicationFollowUpModal ref={ followUpModalRef }
                                      application={ application }
                                      onFollowUpMessageSubmitted={ onSuperLike } />
        </>
    );
}