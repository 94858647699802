import { gql } from '@apollo/client';

const nextUnlockedApplicationGQL = gql`
    query NextUnlockedApplicationGQL($jobPostId: String!) {
        getNextUnlockedApplication(jobPostId: $jobPostId) {
            id
            isUnlockAt
        }
    }`;

export default nextUnlockedApplicationGQL;