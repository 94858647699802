import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ordersGQL } from '@/gql/global-queries/ordersGQL';
import { OrderStatusEnum } from '@/gql/graphql';
import { OrderTypeEnum } from '@/gql/graphql';
import { ordersHook } from '@/hooks/ordersHook';
import PremiumAnnualPass from './components/PremiumAnnualPass';
import PremiumJobPass from './components/PremiumJobPass';
import FreePlan from './components/FreePlan';
import { useIntercomHook } from '@/plugins/intercom/useIntercomHook';
import { activeSubscriptionOrderGQL } from '@/gql/global-queries/activeSubscriptionOrderGQL';
import { PageName, trackPageView } from '@/utils/analytics';

export default function ClientAccountPlansBilling() {

    const { t } = useTranslation();
    const [ page, setPage ] = useState( 1 );

    const createOrder = ordersHook();
    
    const { loading, data: orders } = useQuery(ordersGQL, {
        variables: {
            status: OrderStatusEnum.PAID,
            page,
            limit: 10
        },
    });

    const { data: subscriptionData, loading: isLoadingActivePlan } = useQuery( activeSubscriptionOrderGQL );
    const isSubscriptionActive = subscriptionData?.activeSubscriptionOrder?.isActive;

    useEffect( () => {
        trackPageView( PageName.ClientPlansAndBilling );
    }, [ ] );

    const { toggleIntercomVisibility } = useIntercomHook();

    const jobPassOrders = orders?.orders?.data?.filter(order => !order.isSubscription);

    return (
        <div className="h-full w-full flex flex-col">
            <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-2">
                    <h1 className='p-0'>{ t( 'client:account.plans-billing.title' ) }</h1>

                    <p className="font-normal text-sm text-grey">
                        { t( 'client:account.plans-billing.description' ) }
                    </p>
                </div>

                <div className="flex flex-col lg:flex-row gap-4">
                    <FreePlan isActivated={ jobPassOrders?.length === 0 && !isSubscriptionActive } isDisabled={ isSubscriptionActive || jobPassOrders?.length > 0 } />
                    <PremiumJobPass isDisabled={ isSubscriptionActive } jobPassOrders={ jobPassOrders } createOrder={ createOrder } />
                    <PremiumAnnualPass isDisabled={ isSubscriptionActive } createOrder={ createOrder } />
                </div>
            </div>

            {/* TODO Order history */}

            {/*   <h1>{ t( 'client:account.order-history.title' ) }</h1>

                <p className="font-normal text-sm text-grey mt-2">
                    { t( 'client:account.order-history.description' ) }
                </p>

                {
                    orders?.orders?.data && orders?.orders?.data.length > 0 && !loading && 
                    <>
                        <OrdersTable orders={ orders?.orders?.data } />
                        <Pagination 
                            pageSize={ 5 } 
                            currentPageNumber={ orders?.orders?.page } 
                            itemCount={ orders?.orders?.total } 
                            changePage={ handlePageChange } 
                        />
                    </>
                }   */}

            <p className="font-normal text-sm text-grey mt-16">
                <Trans i18nKey="client:account.plans-billing.contact">
                    <span onClick={ () => toggleIntercomVisibility() } className="text-primary-500 cursor-pointer"></span>
                </Trans>
            </p>
        </div>
    );
}