import { useTranslation } from 'react-i18next';
import { ExperienceTypeEnum } from '@/gql/graphql';
import { useEffect, useState } from 'react';
import { Tag } from 'antd';

interface JobPostExpertiseTagProps {
    expertise: ExperienceTypeEnum;
    bordered?: boolean;
    className?: string;
    iconClassName?: string;
}

export default function JobPostExpertiseTag( {
    expertise,
    bordered,
    className,
}: JobPostExpertiseTagProps ) {

    const { t } = useTranslation();

    const [ _expertise, setExperience ] = useState<ExperienceTypeEnum>();

    useEffect( () => {
        if( _expertise == null ) {
            setExperience( expertise );
        }
    }, [ expertise ] );

    return (
        <Tag bordered={ bordered }
            className={ className }>
            <span>
                { t( 'translation:enum.expertises.' + _expertise?.toUpperCase(), { defaultValue: t( 'translation:enum.expertises.unknown' ) } ) }
            </span>
        </Tag>
    );
}