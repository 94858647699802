import FreelancerProfile from '@/components/freelance-profile/FreelancerProfile';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import applicationHook from '@/hooks/applicationHook';

export default function ClientFreelancerProfile() {

    const { id: freelancerIdParams } = useParams();
    const { jobPostId } = useParams<{ jobPostId: string }>();

    const { getApplication, application, loading } = applicationHook();

    useEffect( () => {
        if( jobPostId ) {
            getApplication( jobPostId, freelancerIdParams );
        }
    }, [ jobPostId ] );

    return (
        <FreelancerProfile id={ freelancerIdParams }
                           application={ application }
                           editable={ false } />
    );
}