import { gql } from '@apollo/client';

const deletePortfolioChannelGQL = gql`
    mutation DeletePortfolioChannelGQL($id: String!) {
        deletePortfolioChannel(id: $id) {
            id
        }
    }
`;

export default deletePortfolioChannelGQL;