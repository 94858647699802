import { gql } from '@apollo/client';

const applicationGQL = gql`
    query Application($jobPostId: String!, $freelancerId: String!) {
        application(jobPostId: $jobPostId, freelancerId: $freelancerId) {
            id
            status
            isUnlock
            superLikeSentAt
            freelancer {
                profilePicture
                firstName
                lastName
                mainJob
            }
        }
    }`;

export default applicationGQL;

