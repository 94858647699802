import { useTranslation } from 'react-i18next';
import { UserTypeEnum } from '@/gql/graphql';
import { Avatar, Dropdown, MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { IncognitoFilledIcon, LifeBuoyFilledIcon, LogoutOutlinedIcon, SettingsOutlinedIcon } from '@/assets/icons/CustomIcons';
import { resetStores } from '@/core/resetStores';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SkeletonInput from 'antd/es/skeleton/Input';
import { useIntercomHook } from '@/plugins/intercom/useIntercomHook';

interface myAccountButtonProps {
    data: {
        type: UserTypeEnum;
        userName: string;
        profilePicture?: string;
        subAvatar?: string | JSX.Element;
    };
    customSection?: JSX.Element[];
    customActions?: MenuProps['items'];
    selectedKeys?: string[];
    avatarSize?: number;
    className?: string;
}

export default function MyAccountButton( {
                                             data,
                                             customSection,
                                             customActions,
                                             className,
                                             avatarSize = 48,
                                             selectedKeys = []
                                         }: myAccountButtonProps ) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { toggleIntercomVisibility } = useIntercomHook();

    const bootstrapItems: MenuProps['items'] = [
        {
            key: 'header',
            label: (
                <div className="py-1.5">
                    <SkeletonInput active={ true } />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: 'support',
            label: (
                <div className="text-sm font-medium py-1.5">
                    { t( 'common:supportButton' ) }
                </div>
            ),
            icon: <LifeBuoyFilledIcon className="font-medium text-base" />,
            onClick: () => toggleIntercomVisibility()
        },
        {
            key: 'settings',
            label: (
                <div className="text-sm font-medium py-1.5">
                    { t( 'common:dropdownMyAccount.settings' ) }
                </div>
            ),
            icon: <SettingsOutlinedIcon className="font-medium text-base" />,
            onClick: () => navigate( `/${ data?.type.toLowerCase() }/settings` )
        },
        {
            type: 'divider'
        },
        {
            key: 'logout',
            label: (
                <div className="text-sm font-medium py-1.5">
                    { t( 'common:dropdownMyAccount.logout' ) }
                </div>
            ),
            icon: <LogoutOutlinedIcon className="font-medium text-base" />,
            onClick: () => handleLogout()
        }
    ];

    const [ items, setItems ] = useState<MenuProps['items']>( bootstrapItems );

    useEffect( () => {
        const copyItems = [ ...bootstrapItems ];

        if( data ) {
            copyItems[ 0 ][ 'label' ] = (
                <div className="flex items-center py-1.5">
                    <Avatar size={ avatarSize }
                            shape="circle"
                            icon={ <UserOutlined /> }
                            src={ data.profilePicture } />
                    <div className="font-sharp text-sm font-medium ml-3">
                        { data.userName }
                    </div>
                </div>
            );

            if( customSection ) {
                let counter: number = 0;
                while( counter < customSection.length ) {
                    copyItems.splice( counter + 2, 0, {
                        key: `custom-section-${ counter + 1 }`,
                        label: customSection[ counter ]
                    } );
                    counter++;
                }
                copyItems.splice( counter + 2, 0, { type: 'divider' } );
            }
        }

        if( customActions ) {
            let counter: number = 0;
            while( counter < customActions.length ) {
                copyItems.splice( copyItems.length - 4, 0, {
                    key: customActions[ counter ][ 'key' ],
                    label: customActions[ counter ][ 'label' ],
                    icon: customActions[ counter ][ 'icon' ],
                    onClick: customActions[ counter ][ 'onClick' ]
                } );
                counter++;
            }
        }

        setItems( copyItems );
    }, [ data, customSection, customActions ] );

    const handleLogout = () => {
        resetStores();
    };

    return (
        <Dropdown className={ className }
                  menu={ {
                      items,
                      selectable: true,
                      selectedKeys
                  } }
                  overlayClassName="disable-hover-first-element">
            <div className="relative">
                <Avatar size={ 40 }
                        shape="circle"
                        icon={ <UserOutlined /> }
                        src={ data?.profilePicture } />

                {
                    typeof data?.subAvatar == 'string' ?
                    <Avatar size={ 19 }
                            className="absolute bottom-0 right-0 border-[1.5px] border-white"
                            shape="circle"
                            icon={ <IncognitoFilledIcon className="text-white" /> }
                            src={ data?.subAvatar } /> :
                    data?.subAvatar
                }
            </div>
        </Dropdown>
    );
}