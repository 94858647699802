import FreelancerProfile from '@/components/freelance-profile/FreelancerProfile';
import { useEffect, useState } from 'react';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { useQuery } from '@apollo/client';
import CheckIfProfileIsCompletedGQL from '@/gql/global-queries/checkIfProfileIsCompletedGQL';
import { useTranslation } from 'react-i18next';
import { AlertFilledIcon } from '@/assets/icons/CustomIcons';
import { FreelancerAccountStatusEnum } from '@/gql/graphql';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';

export default function FreelancerFreelancerProfile() {

    const { t } = useTranslation();
    const [ freelancerId, setFreelancerId ] = useState<string | null>( null );
    const sessionRepository: SessionRepository = new SessionRepository();

    const { data, loading } = useQuery( CheckIfProfileIsCompletedGQL );

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    useEffect( () => {
        setFreelancerId( sessionRepository.userId );
    }, [] );

    return (
        <>
            {
                !loading && data?.checkIfProfileIsCompleted?.status != FreelancerAccountStatusEnum.APPROVED &&
                <div className="w-full bg-[#FCEEED] h-[100px] md:h-[76px] flex items-center rounded-xl mb-6  px-2 mb:px-6">
                  <div className="flex-none rounded-full w-[46px] h-[46px] bg-[#d92d201f] flex items-center justify-center">
                    <AlertFilledIcon className="text-[#D92D20] text-xl" />
                  </div>
                  <div className="ml-2">
                    <div className="font-sharp text-lg text-blacktext-lg font-bold">
                        { t( 'freelancer:account.profileNotCompletedTitle' ) }
                    </div>
                    <div className="text-black text-sm">
                        { t( 'freelancer:account.profileNotCompletedSubTitle' ) }
                    </div>
                  </div>
                </div>
            }
            <FreelancerProfile id={ freelancerId }
                               editable={ true } />
        </>
    );
}