import { gql } from '@apollo/client';

const updatePortfolioElementSegmentGQL = gql`
    mutation UpdatePortfolioElementSegment($portfolioElementId: String!, $segment: SegmentPortfolioElementEnum!) {
        updatePortfolioElementSegment(portfolioElementId: $portfolioElementId, segment: $segment) {
            id
            type
            title
            url
            thumbnailUrl
            segment
            order
            viewCount
            publishedAt
            freelancerId
            portfolioChannel {
                id
                thumbnailUrl
                name
                subscriberCount
                viewCount
                videoCount
            }
        }
    }
`;

export default updatePortfolioElementSegmentGQL;