import { useLazyQuery, useMutation } from '@apollo/client';
import { PortfolioChannel, PortfolioElement, PortfolioElementsPaginatedOutput, PortfolioElementStatsOutput, SegmentPortfolioElementEnum } from '@/gql/graphql';
import { useEffect, useState } from 'react';

import { createPortfolioElementGQL, deletePortfolioChannelGQL, deletePortfolioElementGQL, portfolioChannelsGQL, portfolioElementsGQL, portfolioStatsGQL, updatePortfolioChannelsOrderGQL, updatePortfolioElementSegmentGQL, updatePortfolioElementsOrderGQL } from '@/gql/global-queries';
import { App } from 'antd';

export default function usePortfolio() {
    const { message } = App.useApp();

    const [ featuredItem, setFeaturedItem ] = useState<PortfolioElement | null>( null );
    const [ selectedItems, setSelectedItems ] = useState<PortfolioElement[]>( [] );
    const [ bucketItems, setBucketItems ] = useState<PortfolioElementsPaginatedOutput>();
    const [ channels, setChannels ] = useState<PortfolioChannel[]>( [] );
    const [ statistics, setStatistics ] = useState<PortfolioElementStatsOutput>( {} );

    const createLazyQuery = ( query ) => {
        const [ execQuery, { data, loading, error, refetch } ] = useLazyQuery( query, {
            notifyOnNetworkStatusChange: true
        } );
        return { execQuery, data, loading, error, refetch };
    };

    const {
              execQuery: queryGetFeatured,
              data: dataPortfolioElementFeatured,
              loading: loadingFeatured
          } = createLazyQuery( portfolioElementsGQL );
    const {
              execQuery: queryGetBucket,
              data: dataPortfolioElementBucket,
              loading: loadingBucket,
              refetch: refetchBucket
          } = createLazyQuery( portfolioElementsGQL );
    const {
              execQuery: queryGetSelected,
              data: dataPortfolioElementSelected,
              loading: loadingSelected,
              refetch: refetchSelected
          } = createLazyQuery( portfolioElementsGQL );
    const {
              execQuery: queryGetStats,
              data: dataPortfolioElementStats,
              loading: loadingStats,
              refetch: refetchStats
          } = createLazyQuery( portfolioStatsGQL );


    const {
              execQuery: queryGetChannels,
              data: dataChannels,
              loading: loadingChannels,
              refetch: refetchChannel,
          } = createLazyQuery( portfolioChannelsGQL );

    const deletePortfolioElementMutation = useMutation( deletePortfolioElementGQL );
    const updateOrderPortfolioElementMutation = useMutation( updatePortfolioElementsOrderGQL );
    const  updateSegmentMutation = useMutation( updatePortfolioElementSegmentGQL );


    const [
              createPortfolioElementMutation, {
            data: dataCreatePortfolioElement,
            loading: loadingCreatePortfolioElement
        }
          ] = useMutation( createPortfolioElementGQL );

    const getFeatured = ( freelancerId: string ) => {
        queryGetFeatured( {
            variables: {
                freelancerId,
                args: { segment: SegmentPortfolioElementEnum.FEATURED }
            }
        } );
    };

    const getBucket = ( freelancerId: string, { limit = 5, page = 1 } = {} ) => {
        queryGetBucket( {
            variables: {
                freelancerId,
                args: { segment: SegmentPortfolioElementEnum.BUCKET },
                limit,
                page
            }
        } );
    };

    const getSelected = ( freelancerId: string ) => {
        queryGetSelected( {
            variables: {
                freelancerId,
                args: { segment: SegmentPortfolioElementEnum.SELECTED }
            }
        } );
    };

    const getChannels = (freelancerId: string) => {
        queryGetChannels({
            variables: {
                freelancerId
            }
        });
    }

    const getStats = ( freelancerId: string ) => {
        queryGetStats( {
            variables: { freelancerId }
        } );
    };

    const handleBucketChangePage = ( freelancerId: string, { search = undefined, limit = 5, page = 1 } = {} ) => {
        refetchBucket( {
            freelancerId,
            args: { 
                segment: SegmentPortfolioElementEnum.BUCKET,
                search
            },
            limit,
            page
        } );
    };

    const handleCreatePortfolioElement = (url: string, freelancerId: string, { search = undefined, limit = 5, page = 1 } = {}) => {
        createPortfolioElementMutation({
            variables: { url },
            onCompleted: () => {
                refetchBucket({
                    freelancerId,
                    args: { 
                        segment: SegmentPortfolioElementEnum.BUCKET,
                        search 
                    },
                    limit,
                    page
                });

                refetchStats({freelancerId});
                refetchChannel({freelancerId});
            }
        });
    };

    const handleSearchBucketByName = ( freelancerId: string, name: string ) => {
        refetchBucket( {
            freelancerId,
            args: {
                segment: SegmentPortfolioElementEnum.BUCKET,
                search: name,
            },
            page: 1
        } );
    };

    const handleUpdateSegment = ( portfolioElementId: string, segment: SegmentPortfolioElementEnum ) => {
        updateSegmentMutation[0]( {
            variables: { portfolioElementId, segment }
        } );
    };

    useEffect( () => {
        if( dataPortfolioElementFeatured?.portfolioElements.data?.length > 0 ) {
            setFeaturedItem( dataPortfolioElementFeatured.portfolioElements.data[ 0 ] );
        }
    }, [ dataPortfolioElementFeatured ] );

    useEffect( () => {
        if( dataPortfolioElementSelected?.portfolioElements ) {
            setSelectedItems( dataPortfolioElementSelected.portfolioElements.data );
        }
    }, [ dataPortfolioElementSelected ] );

    useEffect( () => {
        if( dataPortfolioElementBucket?.portfolioElements ) {
            setBucketItems( dataPortfolioElementBucket.portfolioElements );
        }
    }, [ dataPortfolioElementBucket ] );

    useEffect( () => {
        if( dataPortfolioElementStats ) {
            setStatistics( dataPortfolioElementStats.portfolioElementStats );
        }
    }, [ dataPortfolioElementStats ] );


    useEffect( () => {
        if( dataChannels?.portfolioChannels ) {
            setChannels( dataChannels.portfolioChannels );
        }
    }, [ dataChannels ] );

    const handleSegmentUpdate = (itemMoved: PortfolioElement) => {
        const oldItem: PortfolioElement = findItemById(itemMoved.id);
        clearOldSegmentState(oldItem);
        setNewSegmentState(itemMoved);
    };

    const clearOldSegmentState = (oldItem: PortfolioElement) => {
        switch (oldItem.segment) {
            case SegmentPortfolioElementEnum.FEATURED:
                setFeaturedItem(null);
                break;
            case SegmentPortfolioElementEnum.SELECTED:
                setSelectedItems(selectedItems.filter(item => item.id !== oldItem.id));
                break;
            case SegmentPortfolioElementEnum.BUCKET:
                refetchSegmentItems(oldItem.freelancerId, SegmentPortfolioElementEnum.BUCKET);
                break;
        }
    };

    const setNewSegmentState = (itemMoved: PortfolioElement) => {
        switch (itemMoved.segment) {
            case SegmentPortfolioElementEnum.FEATURED:
                setFeaturedItem(itemMoved);
                refetchSegmentItems(itemMoved.freelancerId, SegmentPortfolioElementEnum.BUCKET);
                break;
            case SegmentPortfolioElementEnum.SELECTED:
                refetchSegmentItems(itemMoved.freelancerId, SegmentPortfolioElementEnum.SELECTED);
                break;
            case SegmentPortfolioElementEnum.BUCKET:
                refetchSegmentItems(itemMoved.freelancerId, SegmentPortfolioElementEnum.BUCKET);
                break;
        }
    };

    useEffect( () => {
        if (updateSegmentMutation[1]?.data) {
            const itemMoved = updateSegmentMutation[1].data.updatePortfolioElementSegment;
            handleSegmentUpdate(itemMoved);
        }
    }, [updateSegmentMutation[1]?.data] );

    const refetchSegmentItems = (freelancerId: string, segment: SegmentPortfolioElementEnum) => {
        const refetchFunction = segment === SegmentPortfolioElementEnum.SELECTED ? refetchSelected : refetchBucket;
        refetchFunction({
            freelancerId,
            args: { segment }
        });
    };

    useEffect( () => {
        if( updateSegmentMutation[1].error ) {
            message.error( updateSegmentMutation[1].error.message );
        }
    }, [updateSegmentMutation[1].error] );

    useEffect( () => {
        if( deletePortfolioElementMutation[1]?.data ) {
            const itemDeleted = deletePortfolioElementMutation[1]?.data?.deletePortfolioElement;
            const oldItem: PortfolioElement = findItemById( itemDeleted.id );

            if( oldItem.segment === SegmentPortfolioElementEnum.FEATURED ) {
                setFeaturedItem( null );
            }
            else if( oldItem.segment === SegmentPortfolioElementEnum.SELECTED ) {
                const newSelectedItems: PortfolioElement[] = selectedItems.filter( item => item.id !== oldItem.id );
                setSelectedItems( newSelectedItems );
            }
            else if( oldItem.segment === SegmentPortfolioElementEnum.BUCKET ) {
                refetchBucket( {
                    freelancerId: oldItem.freelancerId,
                    args: { segment: SegmentPortfolioElementEnum.BUCKET }
                } );
            }
        }
    }, [ deletePortfolioElementMutation[1].data ] );

    useEffect( () => {
        if( dataCreatePortfolioElement ) {
            const itemsCreated: PortfolioElement[] = dataCreatePortfolioElement.createPortfolioElement.portfolioElements;
            setBucketItems( ( value ) => {
                const newData = value.data ? [...itemsCreated, ...value.data] : [itemsCreated];
                newData.pop(); // Remove the last element
                return {
                    ...value,
                    data: newData as PortfolioElement[]
                };
            } );

        }
    }, [ dataCreatePortfolioElement ] );

    const findItemById = ( id: string ): PortfolioElement | null => {
        // Check in featuredItem
        if( featuredItem && featuredItem.id === id ) {
            return featuredItem;
        }

        // Check in selectedItems
        const foundInSelected = selectedItems.find( item => item.id === id );
        if( foundInSelected ) {
            return foundInSelected;
        }

        // Check in bucketItems
        const foundInBucket = bucketItems.data?.find( item => item.id === id );
        if( foundInBucket ) {
            return foundInBucket;
        }

        // Return null if not found
        return null;
    };

    const handleOrderChange = ( items: PortfolioElement[] ) => {
        updateOrderPortfolioElementMutation[0]( {
            variables: {
                args: items.map( ( item, index ) => ( {
                    portfolioElementId: item.id,
                    order: index + 1
                } ) )
            }
        } );
    };

    const handleDelete = ( item: PortfolioElement ) => {
        deletePortfolioElementMutation[0]( {
            variables: { id: item.id }
        } );
    };

    return {
        featuredItem,
        selectedItems,
        bucketItems,
        channels,
        statistics,
        getFeatured,
        getBucket,
        getSelected,
        getChannels,
        getStats,
        handleCreatePortfolioElement,
        handleUpdateSegment,
        handleOrderChange,
        handleSearchBucketByName,
        handleDelete,
        handleBucketChangePage,
        loadingUpdateSegment: updateSegmentMutation[1].loading,
        loadingFeatured,
        loadingSelected,
        loadingChannels,
        loadingBucket,
        loadingStats,
        loadingCreatePortfolioElement
    };
}