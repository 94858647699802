import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer/signUpFrelancerRepository';
import { useLazyQuery, useMutation } from '@apollo/client';
import { createFreelancerGQL } from '@/pages/auth/sign-up/sign-up-freelancer/createFreelancerGQL';
import { useEffect, useRef, useState } from 'react';
import loginHook from '@/hooks/loginHook';
import { SessionRepository } from '@/core/auth/sessionRepository';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { ProfilePicturePreSignUrl } from '@/pages/client/views/account/client-account-edit-profile/queries/getProfilePicturePresignUrl';
import SignUpFreelanceStepProfile from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelanceStepProfile';
import SignUpFreelancerStepVerificationPhoneNumberCode from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepVerificationPhoneNumberCode';
import { phoneNumberVerificationHook } from '@/hooks/phoneNumberVerificationHook';
import { FreelancerSignupStep, SegmentEvent, trackSegmentEvent } from '@/utils/analytics';

enum ProgressBarItemEnum {
    PERSONAL_INFO             = 'PERSONAL_INFO',
    PHONE_NUMBER_VERIFICATION = 'PHONE_NUMBER_VERIFICATION',
}

export default function signUpFreelancerHook() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const repository = new SignUpFreelancerRepository();
    const [ isLoadingNextStep, setIsLoadingNextStep ] = useState( false );
    const [ createFreelancer, { data, loading: isLoadingMutation, error } ] = useMutation( createFreelancerGQL );

    const [ currentStep, setCurrentStep ] = useState( 0 );

    const isLoading: boolean = isLoadingNextStep || isLoadingMutation;

    const { sendCode } = phoneNumberVerificationHook( {
        phoneNumber: repository.fullPhoneNumberFormatted,
        lastCodeSentAt: repository.lastCodeSentAt
    } );

    const login = loginHook();
    const session = new SessionRepository();

    const refStep0 = useRef( null );
    const refStep1 = useRef( null );
    const [ isLastStep, setIsLastStep ] = useState( false );

    useEffect( () => {
        const subscription = repository.isLoadingNextStep$.subscribe( ( isLoading ) => {
            setIsLoadingNextStep( isLoading );
        } );

        return () => {
            repository.clearStore();
            subscription.unsubscribe();
        };
    }, [] );

    useEffect( () => {
        setIsLastStep( currentStep === items.length - 1 );
        const observable = repository.step$.subscribe( ( step ) => {
            if( step != currentStep ) {
                setCurrentStep( step );
            }
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [ currentStep ] );

    const getPreSignedUrl = useLazyQuery( ProfilePicturePreSignUrl );

    const getPresignedUploadUrl = async(): Promise<string> => {
        try {
            const preSignedUrl = await getPreSignedUrl[ 0 ]( {
                variables: {
                    contentType: repository.profilePicture.type
                }
            } );
            return preSignedUrl.data.profilePicturePreSignUrl;
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to get preSignUrl', e );
            message.error( t( 'error.default' ) );
        }
    };

    const createFreelancerAccount = async(): Promise<void> => {
        try {
            const args = {
                firstName: repository.firstName,
                lastName: repository.lastName,
                email: repository.email,
                isAgreeForNewsletter: repository.isAgreeForNewsletter || true,
                password: repository.password,
                phone: repository.fullPhoneNumberFormatted
            };

            const response = await createFreelancer( {
                variables: {
                    args
                }
            } );

            if( response?.data ) {
                const queryParameters = new URLSearchParams(window.location.search);
                const gclid = queryParameters.get('gclid');
                trackSegmentEvent( SegmentEvent.SignUpCompleted, { gclid } );
                message.success( t( 'common:form.accountCreated' ) );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to create a new freelancer account', e );
            message.error( {
                content: t( 'freelancer:auth.sign-up.errors.submitError' ),
                duration: 4
            } );
        }
    };

    const nextStep = async() => {
        switch( currentStep ) {
        case 0:
            repository.isLoadingNextStep = true;
            const step0IsValid = await refStep0?.current?.validateFields();
            repository.isLoadingNextStep = false;
            if( step0IsValid ) {
                await sendCode( repository.fullPhoneNumberFormatted );
                repository.nextStep();
            }
            break;
        case 1:
            repository.isLoadingNextStep = true;
            const step1IsValid = await refStep1?.current?.validateFields();
            repository.isLoadingNextStep = false;
            if( step1IsValid ) {
                // Only if user does not comme from the next step (back button)
                await createFreelancerAccount();
                const user = await login.query( { email: repository.email, password: repository.password } );
                await session.login( user.data.loginWithPassword );
                
                await repository.clearStore();
                navigate( '/auth/sign-up/freelancer/welcome' );
            }
            break;
        default:
            return;
        }
    };

    const previousStep = () => {
        if( currentStep == 2 ) {
            repository.previousStep( 2 );
            return;
        }

        if( currentStep == 0 ) {
            repository.clearStore();
            navigate( '/auth/sign-up', { replace: true } );
        }

        repository.previousStep();
    };

    const convertCurrentStepInProgressBarIndex = (): number => {
        const id = items[ currentStep ]?.progressBarId;
        return progressBar.findIndex( item => item.id == id );
    };

    const items = [
        {
            progressBarId: ProgressBarItemEnum.PERSONAL_INFO,
            step: FreelancerSignupStep.PersonnalInfo,
            titleRight: t( 'auth:sign-up.freelancer.profile-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.profile-page.subtitle' ),
            content: <SignUpFreelanceStepProfile ref={ refStep0 } />
        },
        {
            progressBarId: ProgressBarItemEnum.PHONE_NUMBER_VERIFICATION,
            step: FreelancerSignupStep.PhoneVerification,
            titleRight: t( 'auth:sign-up.freelancer.phone-number-verification-page.titleRight' ),
            subtitle: t( 'auth:sign-up.freelancer.phone-number-verification-page.subtitle', {
                phoneNumber: repository?.fullPhoneNumberFormatted
            } ),
            content: <SignUpFreelancerStepVerificationPhoneNumberCode ref={ refStep1 } />
        }
    ];

    const progressBar = [
        {
            id: ProgressBarItemEnum.PERSONAL_INFO,
            label: t( 'auth:sign-up.freelancer.steps.personalInformation' )
        },
        {
            id: ProgressBarItemEnum.PHONE_NUMBER_VERIFICATION,
            label: t( 'auth:sign-up.freelancer.steps.phone-number-verification' )
        }
    ];

    return {
        items,
        progressBar,
        isLastStep,
        currentStep,
        nextStep,
        previousStep,
        isLoading,
        currentProgressBarIndex: convertCurrentStepInProgressBarIndex
    };
};