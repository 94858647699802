import { gql } from '@apollo/client';

export const portfolioChannelsGQL = gql`
    query PortFolioChannelsGQL($freelancerId: String) {
        portfolioChannels(freelancerId: $freelancerId) {
            id
            name
            channelUrl
            videoCount
            viewCount
            subscriberCount
            category
            thumbnailUrl
            freelancerToPortfolioChannels {
                id
                order
            }
        }
    }`;

export default portfolioChannelsGQL;

