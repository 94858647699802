import { ApplicationTypeEnum, Freelancer } from '@/gql/graphql';
import { Avatar, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import CarouselYoutubeVideos from '@/components/freelance-profile/components/CarouselYoutubeVideos';
import MouseInactivityDetector from '@/hooks/mouseInactivityDetectorHook';
import { UserOutlined } from '@ant-design/icons';
import FreelancerCreatorsIWorkedWithList from '@/components/freelance-profile/components/FreelancerCreatorsIWorkedWithList';
import { HeartFilledIcon, HeartOutlinedIcon, LockerFilledIcon, MessageOutlinedIcon, SuperLikeFilledIcon } from '@/assets/icons/CustomIcons';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import encryptText from '@/utils/encrypteText';
import { DateTime } from 'luxon';

interface JobPostTalentCardProps {
    talent?: Freelancer;
    creatorHasSentSuperLikeMessage?: boolean;
    freelancerMessage?: string;
    className?: string;
    applyAt?: Date;
    isFavorite?: boolean;
    isRestricted?: boolean;
    canSendSuperLike?: boolean;
    applicationsStatus: ApplicationTypeEnum;
    onFollowUp: () => void;
    onUpdateFavorite: () => void;
    onShowContactDetails?: () => void;
}

export default function JobPostTalentCard( {
                                               talent,
                                               applicationsStatus,
                                               creatorHasSentSuperLikeMessage,
                                               freelancerMessage,
                                               applyAt,
                                               isFavorite,
                                               isRestricted,
                                               canSendSuperLike,
                                               onFollowUp,
                                               onUpdateFavorite,
                                               onShowContactDetails
                                           }: JobPostTalentCardProps ) {

    const { t } = useTranslation( 'translation' );

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const [ isHoverWithTimer, setIsHoverWithTimer ] = useState( false );
    const { isMouseActive, destroyWatcher, startWatcher } = MouseInactivityDetector( { timer: 1000 } );
    const [ isHover, setIsHover ] = useState( false );
    const [ playOnClick, setPlayOnClick ] = useState( false );

    const profileSelected = ( e ) => {
        // viewProfile?.( profile ); // TODO View profile
    };

    useEffect( () => {
        return () => {
            destroyWatcher();
        };
    }, [] );

    useEffect( () => {
        if( isHover ) {
            startWatcher();
        }
        else {
            destroyWatcher();
        }
    }, [ isHover ] );

    useEffect( () => {
        if( isMouseActive ) {
            setIsHoverWithTimer( true );
        }

        if( !isMouseActive ) {
            setIsHoverWithTimer( false );
        }
    }, [ isMouseActive ] );

    const handleIsHover = ( value: boolean ) => {
        setIsHoverWithTimer( value );
        setIsHover( value );
    };

    const handlePlayOnClick = ( e ) => {
        e.preventDefault();
        if( isTabletOrMobile ) {
            setPlayOnClick( ( value ) => !value );
        }
    };

    const encryptedMessage = freelancerMessage ? encryptText(freelancerMessage) : null;

    return (
        <div className="relative group flex flex-col md:gap-4 p-4 rounded-2xl overflow-hidden lg:cursor-pointer card-shadow">
            <div className="flex flex-col lg:flex-row gap-2 md:gap-6 w-full h-full">
                <div className="relative w-full md:w-72 "
                     onMouseEnter={ () => handleIsHover( true ) }
                     onMouseLeave={ () => handleIsHover( false ) }>
                    <div className={ `rounded-2xl overflow-hidden ${ isRestricted && 'blur' }` }
                         onClick={ ( e ) => handlePlayOnClick( e ) }>
                        <CarouselYoutubeVideos videos={ talent?.[ 'mainPortfolioElements' ]?.map( ( element ) => element.url ) }
                                               height="162px"
                                               arrows={ isTabletOrMobile ? null : isHoverWithTimer }
                                               play={ isTabletOrMobile ? playOnClick : isHover } />
                    </div>
                </div>
                <div className="flex flex-col flex-1"
                     onClick={ ( e ) => profileSelected( e ) }>
                    <div className="flex justify-between w-full">
                        <div className={ `flex items-center ${ isRestricted && 'blur' }` }>
                            <Avatar size={ 30 }
                                    className="flex-none"
                                    icon={ <UserOutlined /> }
                                    src={ talent?.profilePicture } />
                            <div className="flex flex-col font-medium justify-between ml-2 text-base">
                                { talent?.firstName } { talent?.lastName }.
                            </div>
                        </div>
                        <div className="relative">
                            {
                                !!applyAt &&
                                <span className="absolute text-left right-0 whitespace-nowrap text-zinc-500 transition-all duration-200 opacity-100 group-hover:opacity-0">
                                    { t( 'client:jobPost.applicationCard.applied', { date: DateTime.fromISO( applyAt ).setLocale( 'en' ).toRelative( { base: DateTime.now() } ) } ) }
                                </span>
                            }
                            <div className="w-full">
                                {
                                    applicationsStatus == ApplicationTypeEnum.OUTREACHED ?
                                    canSendSuperLike ?
                                    !creatorHasSentSuperLikeMessage ?
                                    <Button type="primary"
                                            className="w-full text-sm lg:w-fit mt-0 transition-all duration-500 opacity-0 group-hover:opacity-100"
                                            onClick={ ( e ) => {
                                                e.preventDefault();
                                                onFollowUp();
                                            } }>
                                        <SuperLikeFilledIcon className="text-sm text-white" />
                                        { t( 'client:jobPost.applicationCard.followUpButton' ) }
                                    </Button> :
                                    <span className="italic transition-all duration-500 opacity-0 group-hover:opacity-100">{ t( 'client:jobPost.applicationCard.superLikeSent' ) }</span>
                                                     :
                                    <Button type="default"
                                            className="w-full text-sm lg:w-fit mt-0 transition-all duration-500 opacity-0 group-hover:opacity-100"
                                            icon={ <LockerFilledIcon className="text-xl" /> }
                                            disabled={ true }>
                                        { t( 'client:jobPost.applicationCard.followUpButton' ) }
                                    </Button> :
                                    null
                                }
                            </div>

                            {
                                ( applicationsStatus === ApplicationTypeEnum.INTERESTED || applicationsStatus === ApplicationTypeEnum.FAVORITE ) &&
                                (
                                    !isRestricted ?
                                    <div className="flex gap-2">
                                        <Tooltip title={ isFavorite
                                                         ? t( 'common:favorite.remove' )
                                                         : t( 'common:favorite.add' ) }>
                                            <Button type="default"
                                                    shape="circle"
                                                    className="h-10 w-10 flex justify-center rounded-full text-sm mt-0 transition-all duration-500 opacity-0 group-hover:opacity-100"
                                                    icon={ isFavorite ?
                                                           <HeartFilledIcon className="text-xl text-primary-500" /> :
                                                           <HeartOutlinedIcon className="text-xl" /> }
                                                    onClick={ ( e ) => {
                                                        e.preventDefault();
                                                        onUpdateFavorite();
                                                    } }>
                                            </Button>
                                        </Tooltip>
                                        <Button type="primary"
                                                className="w-full text-sm lg:w-fit mt-0 h-10 transition-all duration-500 opacity-0 group-hover:opacity-100 flex items-center"
                                                onClick={ ( e ) => {
                                                    e.preventDefault();
                                                    onShowContactDetails();
                                                } }>
                                            <MessageOutlinedIcon className="text-xl text-white -mb-0.5" />
                                            { t( 'client:jobPost.applicationCard.contactDetails' ) }
                                        </Button>
                                    </div> :
                                    <Tooltip title={ isFavorite
                                                     ? t( 'common:favorite.remove' )
                                                     : t( 'common:favorite.add' ) }>
                                        <Button type="default"
                                                icon={ <HeartOutlinedIcon className="text-xl" /> }
                                                className="h-10  mt-0 transition-all duration-500 opacity-0 group-hover:opacity-100"
                                                shape="circle"
                                                disabled={ true }>
                                        </Button>
                                    </Tooltip>
                                )
                            }
                        </div>
                    </div>
                    <div className="flex justify-between mt-2">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-wrap gap-2 md:gap-4">
                                <div className="flex gap-x-1 md:gap-x-2 items-end">
                                    <span className="text-lg md:text-2xl font-semibold font-sharp leading-tight">{ abbreviationNumberFormatter( talent?.portfolioElementStats?.countViews || 0 ) }</span>
                                    <span className="text-grey">{ t( 'common:channel-summary.view', { count: talent?.portfolioElementStats?.countViews || 0 } ) }</span>
                                </div>
                                <div className="flex gap-x-1 md:gap-x-2 items-end">
                                    <span className="text-lg md:text-2xl font-semibold font-sharp leading-tight">{ abbreviationNumberFormatter( talent?.portfolioElementStats?.countElements || 0 ) }</span>
                                    <span className="text-grey">{ t( 'common:channel-summary.video', { count: talent?.portfolioElementStats?.countElements || 0 } ) }</span>
                                </div>
                                <div className="flex gap-x-1 md:gap-x-2 items-end">
                                    <span className="text-lg md:text-2xl font-semibold font-sharp leading-tight">{ t( 'common:freelancerProfile.card.experience.' + talent.experience, { defaultValue: t( 'common:freelancerProfile.card.experience.unknown' ) } ) }</span>
                                    <span className="text-grey">{ t( 'common:channel-summary.experience' ) }</span>
                                </div>
                            </div>

                            <div className="flex flex-col gap-1.5">
                                <span className="text-sm text-grey">{ t( 'client:jobPost.applicationCard.workWith' ) }</span>
                                <FreelancerCreatorsIWorkedWithList creators={ talent?.creatorsIWorkedWith }
                                                                   max={ 8 } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                freelancerMessage &&
                <div className="p-4 rounded-2xl bg-primary-100 flex flex-col gap-1">
                  <div className="flex items-center gap-2">
                    <div className="h-8 w-8 flex justify-center items-center rounded-full bg-primary-500 text-white">
                      <MessageOutlinedIcon />
                    </div>
                    <span className="font-sharp"> { t( 'client:jobPost.applicationCard.introductionMessage', { firstName: talent.firstName } ) }</span>
                  </div>
                  <span className={ `text-grey text-sm ${ isRestricted && 'blur-sm' }`}>{ isRestricted ? encryptedMessage : freelancerMessage }</span>
                </div>
            }
        </div>
    );
}
