import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ResponsiveModal from '@/components/ResponsiveModal';
import { ChainLinkFilledIcon, GoogleLogoIcon, PlusOutlinedIcon } from '@/assets/icons/CustomIcons';
import channelsHook from '@/hooks/channelsHook';
import ListSkeleton from '@/components/ListSkeleton';
import CustomCheckboxComponent from '@/components/CustomCheckboxComponent';
import ChannelItem from '@/components/ChannelItem';

interface PairChannelModalProps {
    onChannelIdSumbitted?: (channelId: string) => void;
}

const PairChannelModal = forwardRef((props: PairChannelModalProps, ref) => {
    const { t } = useTranslation();
    const { onChannelIdSumbitted } = props
    const { loading, getChannels, channels, handleAddChannel } = channelsHook();
    const { control, watch } = useForm();
    const projectRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open() {
            projectRef.current.open();
        },
        close() {
            projectRef.current.close();
        },
    }))

    const channelSelected = watch('channelId');

    const addChannel = () => {        
        handleAddChannel();
    }

    const handlePairChannel = async () => {        
        if( !channelSelected ) return;
        onChannelIdSumbitted?.( channelSelected );
    }
    
    useEffect( () => {
        getChannels();
    }, [] );

    const loadingChannelListJSX = (
        <div className="flex flex-col space-y-4">
            <ListSkeleton isLoading={ true }
                count={ 3 }
                height={ 70 } />
        </div>
    );

    return (
        <ResponsiveModal 
            ref={ projectRef } 
            rootClassName='custom-modal' 
            destroyOnClose={true} 
            desktopWidth="559px"
            title={
                <div className="flex flex-col md:px-6 md:pt-5">
                    <span className='font-semibold text-lg'>{t('client:jobPost.form.channelPage.myChannels')}</span>
                </div>
             }
        >
            <>
                <Divider className="mt-0 mb-4" />
                <div className="flex gap-28 md:px-6 md:pb-6">
                    <div>
                        <div className="flex flex-col gap-1.5 mb-6">
                            <span className='text-grey'>
                                {
                                channels?.length !== 0 ?
                                    t('client:jobPost.form.channelPage.myChannelsInstruction')
                                    :
                                    t('client:jobPost.form.channelPage.myChannelsNotPairedInstruction')
                                }
                            </span>
                        </div>
                        {
                            loading ?
                                loadingChannelListJSX 
                                :
                                (
                            channels?.length !== 0 ?
                                <>
                                    <CustomCheckboxComponent
                                        control={ control }
                                        renderOptionContent={ ( channel ) => (
                                            <ChannelItem channel={ channel }
                                                avatarSize={ 50 }
                                                className="rounded-2xl py-3 px-2"
                                                titleClass={ `hover:text-black group-hover:text-black ${channel.id === channelSelected && 'text-primary-500'}` }
                                            />
                                        ) }
                                        options={ channels }
                                        limit={1}
                                        name="channelId"
                                        direction="vertical"
                                        defaultSelectedIds={ [] }
                                    />
                                    
                                    <Button className="flex items-center justify-center mt-4"
                                        loading={ loading }
                                        onClick={ addChannel }>
                                        <span className="font-semibold text-sm">
                                            { t( 'client:jobPost.form.channelPage.addNewChannelButton' ) }
                                        </span>
                                        <PlusOutlinedIcon className="text-xs h-3" />
                                    </Button>
                                </>
                                :
                                <Button className="flex items-center justify-center w-full"
                                    loading={ loading }
                                    onClick={ addChannel }>
                                    <GoogleLogoIcon className="text-xl h-5 w-5" />
                                    <span className="font-semibold">
                                        { t( 'client:jobPost.form.channelPage.googleAuthButton' ) }
                                    </span>
                                </Button>
                                )
                        }
                        {
                            channels?.length !== 0 && 
                            <Button type="primary" onClick={ () => handlePairChannel() } className="w-full text-sm mt-4" htmlType="submit">
                                <ChainLinkFilledIcon />
                                {t('client:jobPost.pairChannel.linkChannelButton')}
                            </Button>
                        }
                    </div>
                </div>
            </>
        </ResponsiveModal>
    )
})

export default PairChannelModal
