import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import VerificationCode from '@/components/VerificationCode';
import { phoneNumberVerificationHook } from '@/hooks/phoneNumberVerificationHook';
import { SignUpClientRepository } from '../signUpClientRepository';

const SignUpClientStepVerificationPhoneNumberCode = forwardRef( ( props, ref ) => {

    const { t } = useTranslation();
    const signUpClientRepository: SignUpClientRepository = new SignUpClientRepository();

    const {
              _lastCodeSentAt,
              sendCode,
              setCode,
              timeRemaining,
              verifyCode,
              isSendingNewCode
          } = phoneNumberVerificationHook( {
        phoneNumber: signUpClientRepository.fullPhoneNumberFormatted,
        lastCodeSentAt: signUpClientRepository.lastCodeSentAt
    } );

    useImperativeHandle( ref, () => ( {
        validateFields: async() => {
            const isVerify: boolean = await verifyCode( signUpClientRepository.fullPhoneNumberFormatted );
            signUpClientRepository.phoneNumberVerified = isVerify;
            return isVerify;
        }
    } ) );

    useEffect( () => {
        signUpClientRepository.lastCodeSentAt = _lastCodeSentAt;
    }, [ _lastCodeSentAt ] );

    return (
        <div className="flex flex-col justify-center items-center">
            <VerificationCode onCodeCompleted={ ( value: string ) => setCode( value ) } />
            <div className="mt-8 flex items-center justify-center">
                <span className="text-grey font-normal">
                    { t( 'auth:sign-up.freelancer.phone-number-verification-page.dontReceived' ) }
                </span>
                <Button type="link"
                        size="small"
                        onClick={ () => sendCode( signUpClientRepository.fullPhoneNumberFormatted, true ) }
                        loading={ isSendingNewCode }
                        disabled={ isSendingNewCode || timeRemaining > 0 }
                        className="text-primary-400 text-sm">
                    {
                        timeRemaining > 0
                        ? t( 'auth:sign-up.freelancer.phone-number-verification-page.resendCodeWithTiming', { timeRemaining } )
                        : t( 'auth:sign-up.freelancer.phone-number-verification-page.resendCode' )
                    }
                </Button>
            </div>
        </div>
    );
} );

export default SignUpClientStepVerificationPhoneNumberCode;