import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import { useEffect, useRef } from 'react';
import MenuSection from '@/components/MenuSection';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { JobPostsFreelancerStatusEnum } from '@/gql/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { App } from 'antd';
import JobPostDetail from '@/components/job-post/JobPostDetail';
import NoDataIcon from '@/components/NoDataIcon';
import { createApplicationGQL, declineApplicationGQL, privateJobPostGQL } from '@/gql/global-queries';
import ApplyJobPostModal from '@/components/job-post/ApplyJobPostModal';
import { jobPostOriginTypeName, PageName, SegmentEvent, trackPageView, trackSegmentEvent } from '@/utils/analytics';
import { SessionRepository } from '@/core/auth/sessionRepository';

export default function FreelancerJobPostDetail() {

    const { jobPostId } = useParams();
    const { t } = useTranslation();
    const { message } = App.useApp();

    const [ loadJobPostQuery, { data, loading, error, refetch } ] = useLazyQuery( privateJobPostGQL );

    const createApplicationMutation = useMutation( createApplicationGQL );
    const declineApplicationMutation = useMutation( declineApplicationGQL );

    const session: SessionRepository = new SessionRepository();

    const loadJobPost = () => {
        loadJobPostQuery( {
            variables: { jobPostId }
        } );
    };

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: false
    } );

    useEffect( () => {
        trackPageView( PageName.FreelancerJobPostDetail );
    }, [] );

    const ApplyJobPostModalRef = useRef( null );

    const handleJobPostApply = async( freelancerMessage ) => {

        const args = {
            jobPostId,
            message: freelancerMessage
        };

        try {
            const response = await createApplicationMutation[ 0 ]( {
                variables: {
                    args
                }
            } );

            if( response.data ) {
                message.success( t( 'freelancer:jobPost.applicationSuccess' ) );
                ApplyJobPostModalRef.current?.close();
                trackSegmentEvent(
                    SegmentEvent.FreelancerApplied,
                    {
                        freelancer_first_name: session?.user.firstName,
                        freelancer_last_name: session?.user.lastName,
                        freelancer_id: session?.user.id,
                        job_post_id: jobPostId,
                        superliked: false,
                        message_added: !!freelancerMessage,
                        apply_origin: jobPostOriginTypeName.Listing
                    }
                );
                refetch();
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to apply to a job offer', e );
            message.error( t( 'common:form.submitError' ) );
        }
    };

    const handleJobPostDeclined = async() => {
        const args = jobPostId;

        try {
            const response = await declineApplicationMutation[ 0 ]( {
                variables: {
                    args
                }
            } );

            if( response.data ) {
                refetch();

                trackSegmentEvent(
                    SegmentEvent.FreelancerNotInterested,
                    {
                        freelancer_id: session.user?.id,
                        job_post_id: jobPostId,
                        rejected_origin: jobPostOriginTypeName.Details
                    }
                );
                message.success( t( 'freelancer:jobPost.declinedSuccess' ) );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to decline a job offer', e );
            message.error( t( 'common:form.submitError' ) );
        }
    };


    const nav = [
        {
            title: t( 'freelancer:jobPosts.menu.all' ),
            value: JobPostsFreelancerStatusEnum.ALL,
            route: '/freelancer/job-posts/all'
        },
        {
            title: t( 'freelancer:jobPosts.menu.applied' ),
            value: JobPostsFreelancerStatusEnum.APPLIED,
            route: '/freelancer/job-posts/applied'
        },
        {
            title: t( 'freelancer:jobPosts.menu.notInterested' ),
            value: JobPostsFreelancerStatusEnum.DECLINED,
            route: '/freelancer/job-posts/not-interested'
        }
    ];

    useEffect( () => {
        if( jobPostId != null ) {
            loadJobPost();
        }
    }, [ jobPostId ] );

    return (
        <>

            <div className="w-full flex flex-col lg:flex-row">
                <MenuSection routes={ nav }
                             className="md:w-[180px] hidden lg:block" />
                <div className="mt-0 w-full flex flex-col gap-6 md:ml-20 mb-5">
                    {
                        !data && error ?
                        <div className="w-full flex justify-center">
                            <NoDataIcon />
                        </div> :
                        <JobPostDetail jobPost={ data?.privateJobPost }
                                       onDecline={ handleJobPostDeclined }
                                       onApply={ ApplyJobPostModalRef.current?.open }
                                       isLoading={ loading } />
                    }
                </div>
            </div>
            <ApplyJobPostModal ref={ ApplyJobPostModalRef }
                               isLoading={ createApplicationMutation[ 1 ].loading }
                               onJobPostApply={ ( message ) => handleJobPostApply( message ) } />
        </>
    );
}