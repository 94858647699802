import UpgradePlanModal from '@/pages/client/views/account/client-account-plans-billing/UpgradePlanModal';
import { paywallOriginTypeName, SegmentEvent, trackSegmentEvent } from '@/utils/analytics';
import { Button } from 'antd';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const UpgradePlanBanner = ( { ...props } ) => {
    const { t } = useTranslation();

    const upgradePlanModalRef = useRef( null );

    const onPaywallClicked = () => {
        upgradePlanModalRef.current.open();
        trackSegmentEvent( SegmentEvent.PaywallClicked, { paywall_origin: paywallOriginTypeName.Banner } );
    };

    return (
        <div { ...props }>
            <div className="rounded-lg flex flex-col md:flex-row md:justify-between md:items-center gap-y-4 md:gap-y-0  bg-white p-4 text-white"
                 style={ { background: 'linear-gradient(89.25deg, rgba(140, 150, 242, 0.92) -33.77%, rgba(150, 16, 255, 0.92) 44.77%, rgba(44, 73, 146, 0.92) 99.36%)' } }>
                <div className="flex flex-col">
                        <span className="text-sm lg:text-lg font-sharp">
                            { t( 'client:upgradePlanBanner.title' ) }
                        </span>
                    <span>{ t( 'client:upgradePlanBanner.description' ) }</span>
                </div>
                <Button onClick={ () => onPaywallClicked() }
                        type="default"
                        className="text-sm text-[#7F4DE7] hover:text-black">
                    { t( 'client:upgradePlanBanner.upgradeButton' ) }
                </Button>
            </div>

            <UpgradePlanModal ref={ upgradePlanModalRef } />
        </div>
    );
};

export default UpgradePlanBanner;
