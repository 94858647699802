import { useEffect, useRef, useState } from 'react';
import portfolioElementsHook from '@/hooks/portfolioElementsHook';
import useFreelancerIdHook from '@/hooks/useFreelancerIdHook';
import FreelancerProfileHook from '@/hooks/freelancerProfileHook';
import portfolioChannelsHook from '@/hooks/portfolioChannelsHook';

export default function freelancerProfilePageHook() {

    const [ isEditDrawerOpened, setIsEditDrawerOpened ] = useState<boolean>( null );
    const [ width, setWidth ] = useState<number>( 400 );

    const freelancerProfileRef = useRef( null );
    const portfolioRef = useRef<HTMLDivElement>( null );

    const {
              featured,
              loadFeatured,
              loadingFeatured,
              loadingSelected,
              selected,
              loadSelected
          } = portfolioElementsHook();

    const { setInitialFreelancerId, freelancerId, isLoading: loadingFreelancerHook, error } = useFreelancerIdHook();
    const { load, freelancerProfile, isLoading: loadingProfile, refetch } = FreelancerProfileHook();
    const { getPortfolioChannels, portfolioChannels, loading: loadingChannels } = portfolioChannelsHook();

    const loadingMainInfo: boolean = loadingProfile || loadingFreelancerHook;

    useEffect( () => {
        function handleResize() {
            if( portfolioRef.current ) {
                const width = portfolioRef.current.offsetWidth;
                setWidth( width );
            }
        }

        handleResize();
        window.addEventListener( 'resize', handleResize );
        return () => window.removeEventListener( 'resize', handleResize );
    }, [] );

    useEffect( () => {
        if( freelancerId ) {
            load( freelancerId );
            loadFeatured( freelancerId );
            loadSelected( freelancerId );
            getPortfolioChannels( freelancerId );
        }
    }, [ freelancerId ] );



    return {
        setInitialFreelancerId,
        freelancerProfile,
        portfolioChannels,
        featured,
        selected,
        isEditDrawerOpened,
        setIsEditDrawerOpened,
        loadingMainInfo,
        loadingFeatured: loadingFeatured || loadingProfile,
        loadingSelected: loadingSelected || loadingProfile,
        loadingChannels: loadingChannels || loadingProfile,
        refetch,
        error,
        width,
        portfolioRef,
        freelancerProfileRef
    }
}