import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { FreePlanIcon } from '@/assets/icons/CustomIcons';

interface FreePlanProps {
    isDisabled?: boolean;
    isActivated?: boolean;
}

const FreePlan = ({ isDisabled = false, isActivated = false }: FreePlanProps) => {
    const { t } = useTranslation();

    const features = [
        t('client:plan.freePlan.feature1'),
        t('client:plan.freePlan.feature2'),
        t('client:plan.freePlan.feature3'),
    ];

    return (
        <div className="plan-card w-full h-auto flex-1 rounded-xl p-6 flex flex-col justify-between gap-4">
            <div className='flex flex-col gap-4'>
                <div className="flex flex-col gap-2">
                    <FreePlanIcon className='text-[40px]'/>
                    <h4 className='text-sm font-medium'>{ t('client:plan.freePlan.title') }</h4>
                </div>

                <div>
                    <Trans i18nKey="client:plan.freePlan.price">
                        <span className="font-sharp text-2xl"></span>
                        <span className="font-sharp text-5xl"></span>
                        <span className="text-[#71767B] text-base"></span>
                    </Trans>
                </div>


                <ul className="list-none list-inside mb-4 flex flex-col gap-2 text-[#71767B]">
                    {features.map((feature, index) => (
                        <li key={index} className='flex items-center gap-2 text-xs' ><CheckCircleFilled className='text-primary-500 text-base bg-white rounded-full' />{feature}</li>
                    ))}
                </ul>

            </div>

            <Button
                type='primary'
                disabled={ isDisabled || isActivated }
                className={ ( isDisabled || isActivated ) && 'bg-[#E6E9F0]' }
            >
                { isActivated ? t('client:plan.activatedButton') : t('client:plan.activateButton') }
            </Button>
        </div>
    );
};

export default FreePlan;
