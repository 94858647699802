import jobTypeToIcon from '@/utils/jobTypeToIcon';
import { JobPostFormRepository } from '@/pages/client/views/job-post/job-post-form/jobPostFormRepository';
import SelectMultiTags from '@/components/SelectMultiTags';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { ContractTypeEnum } from '@/gql/graphql';

interface InputProps {
    control: any;
    errors?: any;
    onChange?: ( title: string, value: any ) => void,
}

export default function JobPostJobType( { control, errors, onChange: onChangeMethod }: InputProps ) {

    const { t } = useTranslation();
    const repository: JobPostFormRepository = new JobPostFormRepository();

    const handleOnChangeFormatSelected = ( types: ContractTypeEnum[] ) => {
        onChangeMethod( 'type', types[ 0 ] );
    };

    const getTypes = () => {
        const types: ContractTypeEnum[] = Object.values( ContractTypeEnum );
        const options: any[] = [];
        types.forEach( ( type: ContractTypeEnum ) => {
            options.push( {
                label: t( `enum.types.${ type }` ),
                id: type
            } );
        } );

        return options;
    };

    return (
        <>
            <Controller name="type"
                        control={ control }
                        rules={ { required: true } }
                        render={ () => (
                            <SelectMultiTags
                                onTagsSelected={ ( value ) => handleOnChangeFormatSelected( value as ContractTypeEnum[] ) }
                                limit={ 1 }
                                tags={ getTypes()?.map( ( type ) => ( {
                                    id: type.id,
                                    label: (
                                        <div className="flex items-center gap-x-2">
                                            { jobTypeToIcon( type.id ) }
                                            <span>
                                        { type.label }
                                    </span>
                                        </div>
                                    )
                                } ) ) }
                                defaultSelectedIds={ [ repository.type ] } />
                        ) }
            />
            {
                errors.type &&
                <div className="w-fit text-sm font-normal text-red mt-1.5">
                    { t( 'common:error.form.required' ) }
                </div>
            }
        </>
    );
};