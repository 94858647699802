import { Button, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { SegmentPortfolioElementEnum } from '@/gql/graphql';
import { useMemo } from 'react';
import { ChevronDownFilledIcon } from '@/assets/icons/CustomIcons';

interface FreelancerProfileEditPortfolioDropdownMoveToProps {
    currentSegment: SegmentPortfolioElementEnum;
    handleMoveTo: ( segment: SegmentPortfolioElementEnum ) => void;
}

export default function FreelancerProfileEditPortfolioDropdownMoveTo( {
                                                                          currentSegment,
                                                                          handleMoveTo
                                                                      }: FreelancerProfileEditPortfolioDropdownMoveToProps ) {
    const { t } = useTranslation();

    const items: MenuProps['items'] = useMemo( () => {
        const segments: SegmentPortfolioElementEnum[] = Object.values( SegmentPortfolioElementEnum ).filter( ( segment ) => segment !== currentSegment );
        return segments.map( ( segment ) => ( {
            key: segment,
            label: t( `freelancer:account.edit-portfolio.segments.${ segment.toLowerCase() }`, `Segment: ${ segment }` ),
            onClick: () => handleMoveTo( segment )
        } ) );
    }, [ currentSegment, t ] );

    return (
        <Dropdown menu={ { items } }>
            <div className="border border-grey-light font-normal flex items-center justify-center space-x-1 rounded-[10px] px-3 py-1 cursor-pointer">
                { t( 'freelancer:account.edit-portfolio.moveTo' ) }
                <div className="items-center pt-1">
                    <ChevronDownFilledIcon className="text-lg" />
                </div>
            </div>
        </Dropdown>
    );
}