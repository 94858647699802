import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { JobPostFormRepository } from '@/pages/client/views/job-post/job-post-form/jobPostFormRepository';

import JobPostCard from '@/components/job-post/JobPostCard';
import FreelancerProfilePortfolioListItem from '@/components/freelance-profile/FreelancerProfilePortfolioListItem';
import channelsHook from '@/hooks/channelsHook';
import JobPostSkillTag from '@/components/job-post/component/JobPostSkillsTag';
import ListSkeleton from '@/components/ListSkeleton';

export default function JobPostFormReview() {

    const { t } = useTranslation();
    const repository = new JobPostFormRepository();

    const { getChannels, channels, loading } = channelsHook();

    const getChannel = () => {
        return channels.find(channel => channel.id == repository.channelId)
    }

    useEffect( () => {
        getChannels();
    }, [] );

    const loadingJobPostCardJSX = (
        <div className="flex flex-col space-y-4">
            <ListSkeleton isLoading={ true }
                count={ 1 }
                height={ 200 } />
        </div>
    );

    return (
        <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-4'>
                <div className="flex flex-col">
                    <span className='font-semibold text-lg'>{t('client:jobPost.form.reviewPage.jobPostPreview')}</span>
                    <span className='block pb-2 text-grey'>{t('client:jobPost.form.reviewPage.jobPostPreviewInstruction')}</span>
                </div>
                {
                    loading ?
                        loadingJobPostCardJSX :
                        <JobPostCard channel={ getChannel() }
                                     expertiseIds={ repository.expertiseIds }
                                     role={ repository.role }
                                     title={ repository.title }
                                     description={ repository.description }
                                     budgetMin={ repository.budgetMin }
                                     budgetMax={ repository.budgetMax }
                                     onLinkChannel={ () => repository.previousStep() }
                        />
                }
            </div>

            <div className='flex flex-col gap-4'>
                <div className="flex flex-col">
                    <span className='font-semibold text-lg'>{t('client:jobPost.form.reviewPage.jobPostDetails')}</span>
                </div>
                <div className="flex flex-col lg:flex-row gap-4 justify-between">
                    {
                        repository?.contentType !== null &&
                        <div className='flex flex-col flex-1'>
                            <span className='text-grey'>{t('client:jobPost.form.reviewPage.formatType')}</span>
                            <span className='font-semibold text-base'>
                                { t('translation:enum.formatTypes.' + repository?.contentType?.toUpperCase(), { defaultValue: t('translation:enum.formatTypes.undefined') }) }
                            </span>
                        </div>
                    }

                    {
                        repository?.type !== null && 
                        <div className='flex flex-col flex-1'>
                            <span className='text-grey'>{t('client:jobPost.form.reviewPage.jobType')}</span>
                            <span className='font-semibold text-base'>
                                { t('translation:enum.types.' + repository?.type.toUpperCase(), { defaultValue: t('translation:enum.types.undefined') }) }
                            </span>
                        </div>
                    }

                    <div className='flex flex-col flex-1'>
                        <span className='text-grey'>{t('client:jobPost.form.reviewPage.experienceRequired')}</span>
                        <span className='font-semibold text-base'>
                            {
                                repository?.experiences?.map((experience, index) => (
                                    <>
                                        <Trans
                                            i18nKey={ t('translation:enum.experience.' + experience, { defaultValue: t('translation:enum.experience.noRequirements') }) }
                                            components={[<span />]}
                                        />
                                        { index < repository.experiences.length - 1 && ', '}
                                    </>
                                ))
                            }
                        </span>
                    </div>
                    
                    <div className='flex flex-col flex-1'>
                        <span className='text-grey'>{t('client:jobPost.form.reviewPage.languageRequired')}</span>
                        <span className='font-semibold text-base'>
                            {
                                repository?.languageIds?.map((language) => (
                                    t('translation:enum.languages.' + language?.toUpperCase(), { defaultValue: t('translation:enum.languages.unknown') })
                                )).join(', ')
                            }
                        </span>
                    </div>
                </div>
                {
                    repository?.skillIds && repository?.skillIds?.length !== 0 && (
                        <div className='mt-2'>
                            <span className='text-grey'>
                                {t('client:jobPost.form.reviewPage.requiredSkills')}
                            </span>
                            <div className='flex flex-wrap text-primary-500 gap-2 mt-1'>
                                { repository.skillIds?.map(( skill ) =>
                                    <div key={ skill }>
                                        <JobPostSkillTag skill={ skill } className='text-black text-sm py-2 px-3 font-normal border border-blue-100' />
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }
                
            </div>
 
            {
                repository.portfolioVideos && repository.portfolioVideos?.length !== 0 && 
                <div className='flex flex-col'>
                    <div className='flex items-center gap-1.5'>
                        <span className='font-semibold text-lg'>{t('client:jobPost.form.reviewPage.supportingLink')}</span>
                    </div>
                    <div className="grid grid-cols-2 gap-6 my-6">
                        {
                            repository.portfolioVideos?.map((element) => 
                                <FreelancerProfilePortfolioListItem key={element.id} portfolioElement={element} />
                            )
                        }
                    </div>
                </div>
            }
        </div>
    );
};

