import { createStore, select, withProps } from '@ngneat/elf';
import { ContentTypeEnum, ContractTypeEnum, ExperienceTypeEnum, PortfolioElement } from '@/gql/graphql';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

export interface JobPostFormProps {
    data: {
        channelId: string | null;
        title: string | null;
        description: string | null;
        role: string | null;
        experiences: ExperienceTypeEnum[] | null;
        type: ContractTypeEnum | null;
        contentType: ContentTypeEnum | null;
        skillIds: string[] | null;
        portfolioVideos: PortfolioElement[];
        languageIds: string[] | null;
        expertiseIds: string[] | null;
        budgetMin: number | string | null,
        budgetMax: number | string | null,
    };
    step: number;
    isLoadingNextStep: boolean;
}

const jobPostFormStore = createStore(
    { name: 'jobPostForm' },
    withProps<JobPostFormProps>( {
        data: {
            channelId: null,
            experiences: null,
            type: null,
            description: null,
            title: null,
            contentType: null,
            role: null,
            skillIds: null,
            portfolioVideos: [],
            languageIds: null,
            expertiseIds: null,
            budgetMin: null,
            budgetMax: null,
        },
        step: 0,
        isLoadingNextStep: false,
    } )
);

export const persist = persistState(jobPostFormStore, {
    key: 'jobPostAuth',
    storage: localStorageStrategy,
});

export class JobPostFormRepository {

    step$ = jobPostFormStore.pipe( select( ( state ) => state.step ) );

    isLoadingNextStep$ = jobPostFormStore.pipe( select( ( state ) => state.isLoadingNextStep ) );

    get data() {
        return jobPostFormStore.getValue().data;
    }

    set data( data: JobPostFormProps['data'] ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data
            };
        } );
    }

    get experiences(): ExperienceTypeEnum[] {
        return jobPostFormStore.getValue().data.experiences;
    }

    set experiences( experiences: ExperienceTypeEnum[] ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    experiences
                }
            };
        } );
    }

    get type(): ContractTypeEnum {
        return jobPostFormStore.getValue().data.type;
    }

    set type( type: ContractTypeEnum ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    type
                }
            };
        } );
    }

    get contentType(): ContentTypeEnum {
        return jobPostFormStore.getValue().data.contentType;
    }

    set contentType( contentType: ContentTypeEnum ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    contentType
                }
            };
        } );
    }

    get skillIds(): string[] {
        return jobPostFormStore.getValue().data.skillIds;
    }

    set skillIds( skillIds: string[] ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    skillIds
                }
            };
        } );
    }

    get portfolioVideos(): PortfolioElement[] {
        return jobPostFormStore.getValue().data.portfolioVideos;
    }

    set portfolioVideos( portfolioVideos: PortfolioElement[] ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    portfolioVideos
                }
            };
        } );
    }

    get role(): string {
        return jobPostFormStore.getValue().data.role;
    }

    set role( role: string ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    role
                }
            };
        } );
    }

    get channelId(): string {
        return jobPostFormStore.getValue().data.channelId;
    }

    set channelId( channelId: string ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    channelId
                }
            };
        } );
    }

    get description(): string {
        return jobPostFormStore.getValue().data.description;
    }

    set description( description: string ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    description
                }
            };
        } );
    }

    get title(): string {
        return jobPostFormStore.getValue().data.title;
    }

    set title( title: string ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    title
                }
            };
        } );
    }

    get budgetMax(): string | number {
        return jobPostFormStore.getValue().data.budgetMax;
    }

    set budgetMax( budgetMax: string | number ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    budgetMax
                }
            };
        } );
    }

    get budgetMin(): string | number {
        return jobPostFormStore.getValue().data.budgetMin;
    }

    set budgetMin( budgetMin: string | number ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    budgetMin
                }
            };
        } );
    }

    get languageIds(): string[] {
        return jobPostFormStore.getValue().data.languageIds;
    }

    set languageIds( languageIds: string[] ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    languageIds
                }
            };
        } );
    }

    get expertiseIds(): string[] {
        return jobPostFormStore.getValue().data.expertiseIds;
    }

    set expertiseIds( expertiseIds: string[] ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    expertiseIds
                }
            };
        } );
    }

    set props( props: JobPostFormProps ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                ...props
            };
        } );
    }

    set isLoadingNextStep( isLoadingNextStep: boolean ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                isLoadingNextStep
            };
        } );
    }

    updateData( data: Partial<JobPostFormProps['data']> ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...data
                }
            };
        } );
    }

    updateStep( count: number = 1 ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                step: count
            };
        } );
    }

    nextStep( count: number = 1 ) {        
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                step: state.step + count
            };
        } );
    }

    previousStep( count: number = 1 ) {
        jobPostFormStore.update( ( state ) => {
            return {
                ...state,
                step: state.step - count
            };
        } );
    }

    clearStore(): void {
        jobPostFormStore.reset();
    }
}