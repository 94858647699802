import { CurrencyEnum } from '@/gql/graphql';

export function currencySymbolFormatter( currency: CurrencyEnum ) {

    switch( currency ) {
        case CurrencyEnum.USD:
            return '$';
        case CurrencyEnum.EUR:
            return '€';
    }
}