import React, { useEffect, useState } from 'react';
import { PortfolioElement, SegmentPortfolioElementEnum } from '@/gql/graphql';
import ListSkeleton from '@/components/ListSkeleton';
import { useTranslation } from 'react-i18next';
import { Reorder } from 'framer-motion';
import FreelancerProfileEditPortfolioItem from '@/pages/freelancer/views/profile/profile-edit-portfolio/components/FreelancerProfileEditPortfolioItem';
import { twMerge } from 'tailwind-merge';

interface FreelancerAccountEditOrderedListProps {
    portfolioElements: PortfolioElement[];
    isLoading?: boolean;
    className?: string;

    onOrderChange?( orderedList: PortfolioElement[] ): void;

    onSegmentChange?( item: PortfolioElement, segment: SegmentPortfolioElementEnum ): void;

    onDeleteItem?( portfolioElement: PortfolioElement ): void;
}

export default function FreelancerProfileEditPortfolioOrderedList( {
                                                                       portfolioElements,
                                                                       isLoading,
                                                                       className,
                                                                       onOrderChange,
                                                                       onSegmentChange,
                                                                       onDeleteItem
                                                                   }: FreelancerAccountEditOrderedListProps ) {

    const { t } = useTranslation();
    const [ orderedList, setOrderedList ] = useState<PortfolioElement[]>( [] );

    useEffect( () => {
        setOrderedList( orderPortfolioElements( portfolioElements ) );
    }, [ portfolioElements ] );

    const orderPortfolioElements = ( orderedList: PortfolioElement[] ) => {
        return orderedList?.sort( ( a, b ) => ( a.order || 0 ) - ( b.order || 0 ) );
    };

    const handleOnDeleteItem = ( portfolioElement: PortfolioElement ) => onDeleteItem?.( portfolioElement );

    const handleReorder = ( newOrder: any ) => {
        const reorderedList = newOrder?.map( index => orderedList[ index ] );
        reorderedList.forEach( ( item, index ) => {
            item.order = index + 1;
        } );
        setOrderedList( reorderedList );
        onOrderChange( reorderedList );
    };

    if( isLoading ) {
        return (
            <div className={ twMerge( 'flex flex-col space-y-3', className ) }>
                <ListSkeleton isLoading={ true }
                              count={ 6 }
                              height={ 62 } />
            </div>
        );
    }

    if( orderedList?.length > 0 ) {
        return (
            <>
                <p className="text-sm font-normal text-grey">
                    { t( 'freelancer:account.edit-portfolio.highlighted' ) }
                </p>
                <Reorder.Group axis="y"
                               className={ twMerge( 'flex flex-col space-y-3 mt-4', className ) }
                               values={ orderedList.map( ( _, index ) => index ) }
                               onReorder={ handleReorder }>
                    {
                        orderedList.map( ( item, index ) => (
                            <Reorder.Item key={ item.id }
                                          value={ index }>
                                <FreelancerProfileEditPortfolioItem portfolioElement={ item }
                                                                    allowMoveDown={ index < orderedList.length - 1 }
                                                                    allowMoveUp={ index > 0 }
                                                                    onMoveUp={ () => handleReorder( [
                                                                        ...orderedList.map( ( _,
                                                                                              i ) => i )
                                                                    ].map( ( i, j ) => j === index - 1
                                                                                       ? index
                                                                                       : j === index
                                                                                         ? index - 1
                                                                                         : j ) ) }
                                                                    onMoveDown={ () => handleReorder( [
                                                                        ...orderedList.map( ( _,
                                                                                              i ) => i )
                                                                    ].map( ( i, j ) => j === index + 1
                                                                                       ? index
                                                                                       : j === index
                                                                                         ? index + 1
                                                                                         : j ) ) }
                                                                    onDelete={ handleOnDeleteItem }
                                                                    onMoveTo={ ( segment ) => onSegmentChange?.( item, segment ) }
                                                                    orderedIcon={ true } />
                            </Reorder.Item>
                        ) )
                    }
                </Reorder.Group>
            </>
        );
    }

}
