import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import ResponsiveModal from '@/components/ResponsiveModal';

interface CloseJobPostModalProps {
    onJobPostClose?: () => void;
    isLoading: boolean;
}

const CloseJobPostModal = forwardRef(( props: CloseJobPostModalProps, ref) => {
    const { t } = useTranslation();
    const { onJobPostClose, isLoading } = props
    const modalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        open() {
            modalRef.current.open();
        },
        close() {
            modalRef.current.close();
        },
    }))

    return (
        <ResponsiveModal 
            ref={ modalRef } 
            rootClassName='custom-modal'  
            destroyOnClose={true} 
            desktopWidth="400px"
            title={
                <div className="flex flex-col md:px-6 md:pt-5">
                    <span className='font-semibold text-lg'>{t('client:jobPost.closeJobPostModal.title')}</span>
                </div>
            }
        >
            <>
                <Divider className="mt-0 mb-4" />
                <div className="gap-20 flex md:px-6 md:pb-6">
                    <div >
                        <div className="flex flex-col gap-6 mb-2">
                            <span className='text-grey'>
                                { t('client:jobPost.closeJobPostModal.description') }
                            </span>
                            <span className='text-grey'>
                                { t('client:jobPost.closeJobPostModal.question') }
                            </span>
                        </div>
    
                        <Button loading={ isLoading } type="primary"  onClick={ () => onJobPostClose() } className="w-full text-sm mt-4">
                            {t( 'common:button.confirm' )}
                        </Button>
                        <Button type="default" onClick={ () => modalRef.current.close() } className="w-full text-sm mt-4">
                            {t( 'common:button.cancel' )}
                        </Button>
                    </div>
                </div>
            </>
        </ResponsiveModal>
    )
})

export default CloseJobPostModal
