import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SignUpClientRepository } from '@/pages/auth/sign-up/sign-up-client/signUpClientRepository';
import PhoneNumberInputs from '@/components/PhoneNumberInputs';

const SignUpClientStepPersonalInfo = forwardRef( ( props, ref ) => {

    const { t } = useTranslation( 'auth' );
    const { trigger, control, watch, getValues, formState: { errors } } = useForm();

    const repository = new SignUpClientRepository();

    const subscription = watch( ( value ) => {
        if( value.phoneCodeCountry != repository.phoneCodeCountry || value.phoneNumber != repository.phoneNumber ) {
            repository.phoneNumberVerified = false;
        }

        repository.updateData( value );
    } );

    useImperativeHandle( ref, () => ( {
        async validateFields(): Promise<boolean> {
            const isValid = await trigger();

            if( isValid ) {
                repository.userName = getValues( 'userName' );
            }

            return isValid;
        }
    } )
    );

    useEffect( () => {
        return () => subscription.unsubscribe();
    }, [] );


    return (
        <form className='flex flex-col gap-5'>
            <div className="flex flex-wrap lg:flex-nowrap gap-x-2 gap-y-4">
                <InputComponent control={ control }
                    name="firstName"
                    defaultValue={ repository.firstName }
                    className="w-full lg:w-1/2"
                    label={ t( 'auth:sign-up.client.personal-info-page.firstname' ) }
                    placeholder={ t( 'auth:sign-up.client.personal-info-page.firstNamePlaceholder' ) }
                    errors={ errors }
                    rules={ {
                        required: t( 'common:error.form.required' )
                    } } />

                <InputComponent control={ control }
                    name="lastName"
                    defaultValue={ repository.lastName }
                    className="w-full lg:w-1/2"
                    label={ t( 'auth:sign-up.client.personal-info-page.lastname' ) }
                    placeholder={ t( 'auth:sign-up.client.personal-info-page.lastNamePlaceholder' ) }
                    errors={ errors }
                    rules={ {
                        required: t( 'common:error.form.required' )
                    } } />
            </div>

            <PhoneNumberInputs control={ control }
                defaultValueSelect={ repository.phoneCodeCountry }
                defaultValueInput={ repository.phoneNumber }
                inputName="phoneNumber"
                selectName="phoneCodeCountry"
                errors={ errors }
                required={ false }
                watch={ watch } />

        </form>
    );
} );

export default SignUpClientStepPersonalInfo;