import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import Header from '@/components/layout/Header';
import SupportButton from '@/components/SupportButton';
import { useMediaQuery } from 'react-responsive';

export function TransverseHeader() {

    const { t, i18n } = useTranslation();
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const rightJSX = (
        <div className="flex items-center justify-center gap-x-1 lg:gap-x-4">
            <SupportButton onlyIcon={ isTabletOrMobile } />
            <Link to="/auth/sign-in">
                <Button type="default"
                        size={ isTabletOrMobile ? 'small' : 'middle' }
                        className="text-sm lg:text-base">
                    { t( 'transverse:header.signIn' ) }
                </Button>
            </Link>

            <Link to="/auth/sign-up">
                <Button type="primary"
                        size={ isTabletOrMobile ? 'small' : 'middle' }
                        className="text-sm lg:text-base">
                    { t( 'transverse:header.signUp' ) }
                </Button>
            </Link>
        </div>
    );

    return (
        <Header logoTo="/"
                right={ rightJSX } />
    );
}