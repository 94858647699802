import SkeletonInput from 'antd/es/skeleton/Input';
import { useRef } from 'react';
import handleResizeHook from '@/hooks/handleResizeHook';

export default function FreelancerProfileLoaderSelectedPart() {
    const containerRef = useRef( null );
    const playerHeight: number = handleResizeHook( { ref: containerRef } );

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {
                [ ...Array( 6 ).keys() ].map( ( index ) => {
                    return <div className="w-full"
                                key={ index }
                                ref={ containerRef }>
                        <SkeletonInput active={ true }
                                       className="w-full rounded-2xl"
                                       style={ { height: playerHeight + 'px' } } />
                    </div>;
                } )
            }
        </div>
    );
}