import { gql } from '@apollo/client';

const clientJobPostsGQL = gql`
    query clientJobPosts($page: Int, $filters: JobPostFiltersByClientInput, $limit: Int) {
        myJobPosts(page: $page, filters: $filters, limit: $limit ) {
            data {
                channel {
                    thumbnailUrl
                    name
                    id
                }
                id
                name
                totalInterestedTalents
                createdAt
                role
                status
            }
            total,
            page
        }
    }`;

export default clientJobPostsGQL;