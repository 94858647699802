import { Button, Tag } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { PlusOutlinedIcon } from '@/assets/icons/CustomIcons';

interface FreelancerProfileTagListProps {
    editable?: boolean;
    tags: string[];
    limit?: number;
    color?: any;
    noTagsMessageNotEditable?: string;
    noTagsMessageEditable?: string;
    className?: string;
    addFirstTag?: () => void;
}

export default function FreelancerProfileTagList( {
                                                      editable,
                                                      tags,
                                                      limit,
                                                      color,
                                                      noTagsMessageEditable,
                                                      noTagsMessageNotEditable,
                                                      addFirstTag,
                                                      className
                                                  }: FreelancerProfileTagListProps ) {

    const { t } = useTranslation();
    const [ maxTags, setMaxTags ] = useState( limit || 4 );
    const [ isShowMoreLess, setIsShowMoreLess ] = useState( false );

    const handleShowLess = () => {
        setMaxTags( limit );
        setIsShowMoreLess( false );
    };

    const handleShowMore = () => {
        setMaxTags( 10000 );
        setIsShowMoreLess( true );
    };

    if( tags?.length == 0 || tags == null ) {
        if( editable ) {
            return <Button type="default"
                           onClick={ addFirstTag }
                           icon={ <PlusOutlinedIcon className="text-sm" /> }
                           className="w-fit">
                { noTagsMessageEditable || t( 'freelancer:account.edit-profile.defaultEditButton' ) }
            </Button>;
        }
        else {
            return <p className="text-grey text-sm">
                { noTagsMessageNotEditable }
            </p>;
        }
    }

    return (
        <div className="flex flex-wrap gap-x-1 gap-y-2">
            {
                tags?.map( ( tag, index ) => {
                    if( index < maxTags ) {
                        return (
                            <Tag key={ index }
                                 className={ className }
                                 color={ color }>
                                { tag }
                            </Tag>
                        );
                    }
                    if( index === maxTags ) {
                        return (
                            <Tag key={ index }
                                 className={ twMerge( 'cursor-pointer', className ) }
                                 onClick={ () => handleShowMore() }
                                 color={ color }>
                                { `${ t( 'common:button.showMoreButton' ) }… +${ tags.length - maxTags }` }
                            </Tag>
                        );
                    }
                } )
            }
            {
                isShowMoreLess &&
                <button className="text-sm font-medium rounded-full hover:bg-grey-light px-3 h-[31px]"
                        onClick={ () => handleShowLess() }>
                    { t( 'common:button.showLessButton' ) }
                </button>
            }
        </div>
    );
}