import { useLazyQuery } from '@apollo/client';
import { JobPostsFreelancerStatusEnum } from '@/gql/graphql';
import { freelancerJobPostsGQL } from '@/gql/global-queries';

interface FreelancerJobPostsHookProps {
    limit?: number;
}

const JobPostOrderByEnum = {
    CREATED_AT : "CREATED_AT",
    APPLIED_AT : "APPLIED_AT",
    DECLINED_AT : "DECLINED_AT",
}

export default function freelancerJobPostsHook( { limit }: FreelancerJobPostsHookProps = {}  ) {

    const [ jobPostsQuery, { data, loading, error, refetch } ] = useLazyQuery( freelancerJobPostsGQL );

    const loadJobPosts = ({
        status = JobPostsFreelancerStatusEnum.ALL,
        forMe = false,
        orderBy = JobPostOrderByEnum.CREATED_AT,
        page = 1,
    } = {}) => {
        jobPostsQuery( {
            variables: {
                filters: {
                    status,
                    orderBy,
                    sortBy: 'DESC',
                    forMe,
                },
                limit,
                page: page
            },
        } );
    };

    return {
        loadJobPosts,
        jobPosts: data?.freelancerJobPosts?.data,
        total: data?.freelancerJobPosts?.total,
        page: data?.freelancerJobPosts?.page,
        loading,
        refetch
    };
}