import React, { useState } from 'react';
import { PortfolioElement, SegmentPortfolioElementEnum } from '@/gql/graphql';
import { ReorderOutlinedIcon, TrashOutlinedIcon } from '@/assets/icons/CustomIcons';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { useTranslation } from 'react-i18next';
import { cuttingTextFormatter } from '@/utils/cuttingTextFormatter';
import { Button } from 'antd';
import FreelancerProfileEditPortfolioDropdownMoveTo from '@/pages/freelancer/views/profile/profile-edit-portfolio/components/FreelanceProfileEditPortfolioDropdownMoveTo';
import { twMerge } from 'tailwind-merge';
import FreelancerProfileEditPortfolioArrowOrder from '@/pages/freelancer/views/profile/profile-edit-portfolio/components/FreelancerProfileEditPortfolioArrowOrder';

interface FreelancerAccountEditPortfolioListItemProps {
    orderedIcon?: boolean;
    portfolioElement: PortfolioElement;
    allowMoveUp?: boolean;
    allowMoveDown?: boolean;
    onMoveTo?: ( segment: SegmentPortfolioElementEnum ) => void;
    onDelete?: ( portfolioElement: PortfolioElement ) => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
}

export default function FreelancerProfileEditPortfolioItem( {
                                                                orderedIcon,
                                                                portfolioElement,
                                                                allowMoveUp,
                                                                allowMoveDown,
                                                                onDelete,
                                                                onMoveTo,
                                                                onMoveUp,
                                                                onMoveDown
                                                            }: FreelancerAccountEditPortfolioListItemProps ) {
    const { t } = useTranslation();
    const [ isDropdownVisible, setIsDropdownVisible ] = useState( false );

    const handleDelete = () => onDelete?.( portfolioElement );
    const handleSegmentUpdate = ( segment: SegmentPortfolioElementEnum ): void => onMoveTo?.( segment );
    const handleMoveUp = () => onMoveUp?.();
    const handleMoveDown = () => onMoveDown?.();

    return (
        <div className="group bg-white flex items-center justify-between border border-grey-light px-3 lg:px-6 py-2 rounded-xl cursor-grab card-shadow h-[62px]"
             onMouseEnter={ () => setIsDropdownVisible( true ) }
             onMouseLeave={ () => setIsDropdownVisible( false ) }>
            <div className="flex items-center">
                { orderedIcon && <ReorderOutlinedIcon className="text-grey text-[16px] mr-4" /> }
                <img src={ portfolioElement?.thumbnailUrl }
                     alt="thumbnail"
                     className="w-[70px] h-[40px] object-cover rounded-lg border-[4px] border-grey-light" />
                <div className="flex flex-col justify-between ml-4">
                    <p className="text-sm font-semibold">
                        { cuttingTextFormatter( portfolioElement?.title, 30 ) }
                    </p>
                    <p className="font-light text-xs mr-4">
                        { cuttingTextFormatter( portfolioElement?.portfolioChannel?.name, 15 ) } • { abbreviationNumberFormatter( portfolioElement?.viewCount ) } { t( 'freelancer:account.edit-portfolio.views' ) }
                    </p>
                </div>
            </div>

            <div className={ twMerge( 'group-hover:flex space-x-4', isDropdownVisible ? 'flex' : 'hidden' ) }>
                <FreelancerProfileEditPortfolioDropdownMoveTo currentSegment={ portfolioElement?.segment }
                                                              handleMoveTo={ ( segment: SegmentPortfolioElementEnum ) => handleSegmentUpdate( segment ) } />
                {
                    ( allowMoveDown || allowMoveUp ) &&
                    <FreelancerProfileEditPortfolioArrowOrder allowMoveUp={ allowMoveUp }
                                                              allowMoveDown={ allowMoveDown }
                                                              onMoveDown={ handleMoveDown }
                                                              onMoveUp={ handleMoveUp } />
                }
                <Button type="link"
                        onClick={ () => handleDelete() }>
                    { <TrashOutlinedIcon className="text-red text-lg" /> }
                </Button>
            </div>
        </div>
    );
}