import { useTranslation } from 'react-i18next';

interface SignUpRightSideHeaderProps {
    title: string;
    subTitle: string;
    optional: boolean;
}

export default function FormStepHeader( { title, subTitle, optional }: SignUpRightSideHeaderProps ) {
    const { t } = useTranslation();
    return (
        <div className="w-full">
            <div className="flex items-center gap-4">
                <div className="text-2xl font-sharp font-medium">
                    { title }
                </div>
                {
                    optional == true && 
                    <div className="rounded-3xl px-2 py-1 border-primary-100 border text-grey text-xs">
                        { t( 'client:jobPost.form.optional' ) }
                    </div>
                }
            </div>
            {
                subTitle &&
                <div className="text-normal text-base mt-4 mb-8">
                    { subTitle }
                </div>
            }
        </div>
    );
}