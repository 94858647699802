import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { App } from 'antd';
import { CreateOrderGQL } from '@/gql/global-queries/createOrderGQL';

export const ordersHook = () => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [createOrderMutation] = useMutation(CreateOrderGQL);

    return async( orderType, jobPostId = null ) => {
        try {
            const { data } = await createOrderMutation( {
                variables: {
                    args: {
                        type: orderType,
                        jobPostId
                    }
                }
            } );

            if( data?.createOrder?.stripePaymentUrl ) {
                window.location.href = data.createOrder.stripePaymentUrl;
            }
        } catch( err ) {
            Sentry.captureException( 'An error occur when trying to create url', err );
            message.error( t( 'common:form.submitError' ) );
        }
    };
};
