import { Avatar, Button, Tag } from 'antd';
import { Channel, ExperienceTypeEnum } from '@/gql/graphql';
import { BankNoteOutlinedIcon, ChainLinkFilledIcon, ProfileSheetFilledIcon } from '@/assets/icons/CustomIcons';
import Paragraphs from '@/components/Paragraphs';
import { useTranslation } from 'react-i18next';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { priceFormatter } from '@/utils/priceFormatter';
import JobPostExpertiseTag from './component/JobPostExpertiseTag';
import InfoMessage from '../InfoMessage';
import jobToIcon from '@/utils/jobToIcon';

interface JobPostCardProps {
    channel: Channel;
    expertiseIds: string[];
    role: string;
    title?: string,
    description?: string,
    budgetMin: number | string,
    budgetMax: number | string,
    onLinkChannel?: () => void
}

export default function JobPostCard( {
    channel,
    expertiseIds,
    role,
    title,
    description,
    budgetMin,
    budgetMax,
    onLinkChannel,
}: JobPostCardProps ) {

    const { t } = useTranslation();

    return (
        <div className="relative w-full h-full rounded-2xl overflow-hidden border bg-white border-grey-light">
            <div className="flex flex-col gap-6 p-6">
                <div className="flex lg:flex-row flex-col items-center gap-4">
                    <Avatar size={ 60 }
                        className="flex-none bg-grey border-2 border-blue-100 lg:self-start self-center"
                        icon={ <ProfileSheetFilledIcon className='text-3xl' /> }
                        src={ channel?.thumbnailUrl } />

                    <div className='flex flex-col w-full gap-1'>
                        <div className="flex flex-col lg:flex-row gap-2">
                            {
                                channel?.name ?
                                    <span className="font-semibold text-xl font-sharp">
                                        { channel?.name }
                                    </span>
                                    :
                                    <span className="text-xl font-sharp text-grey">
                                        {t('client:jobPost.card.unverifiedChannel')}
                                    </span>
                            }
                            {
                                channel?.subscriberCount &&
                                <Tag
                                    className="text-primary-500 text-xs px-2 py-0 self-start lg:self-center"
                                    color="#F4F3FF">
                                    <span>{ abbreviationNumberFormatter( channel?.subscriberCount ) }</span>
                                    <span className="ml-1">
                                        { t( 'common:channel-summary.follower', { count: channel?.subscriberCount } ) }
                                    </span>
                                </Tag>
                            }
                        </div>
                        <div>
                            <span className='font-semibold text-base'>{title}</span>
                            {
                                description && 
                                <Paragraphs text={ description }
                                    className="text-grey text-sm mt-2"
                                    showMoreButtonText={t('client:jobPost.buttons.readMore')}
                                    showLessButtonText={t('client:jobPost.buttons.readLess')}
                                    classNameShowMoreButton="text-primary-500 block mt-2"
                                    maxLength={ 180 } />
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap flex-col lg:flex-row gap-x-6 gap-y-2'>
                    {
                        budgetMax !== null && budgetMax != 0 && (
                            <div>
                                <span className='text-grey'>
                                    {t('client:jobPost.card.budget')}
                                </span>
                                <div className='flex text-primary-500 h-7 items-center'>
                                    <BankNoteOutlinedIcon />
                                    <span className="ml-2 font-semibold whitespace-nowrap">
                                        $
                                        {
                                            priceFormatter( {
                                                amount: parseInt(budgetMin  as string),
                                                decimals: false
                                            } )
                                        }
                                        -
                                        {
                                            priceFormatter( {
                                                amount: parseInt(budgetMax as string),
                                                decimals: false
                                            } )
                                        }
                                    </span>
                                </div>
                            </div>
                        )
                    }
                    
                    <div>
                        <span className='text-grey'>
                            { t('client:jobPost.card.role') }
                        </span>
                        <div className='text-primary-500 max-w-[250px] whitespace-nowrap h-7 flex items-center'>
                            { jobToIcon( role?.toUpperCase(), 'text-inherit inline-block mr-2' ) }
                            <span>
                                { t('translation:enum.jobs.' + role?.toUpperCase(), { defaultValue: t('translation:enum.expertises.unknown') }) }
                            </span>
                        </div>
                    </div>

                    {
                        expertiseIds && expertiseIds?.length != 0 && 
                        <div>
                            <span className='text-grey'>
                                {t('client:jobPost.card.genre')}
                            </span>
                            <div className='flex flex-wrap text-primary-500 gap-2'>
                                { expertiseIds?.map(( expertise: ExperienceTypeEnum ) =>
                                    <div key={ expertise }>
                                        <JobPostExpertiseTag expertise={ expertise } className='text-primary-500 text-[length:inherit] py-0.5 bg-primary-100 font-semibold border-0' />
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
                {
                    !channel && onLinkChannel &&
                    <InfoMessage>
                        <div className='flex lg:flex-row flex-col gap-2 items-center'>
                            <span>{t('client:jobPost.card.channelPairingWarning')}</span>
                            <Button onClick={ onLinkChannel } className='px-4' type="default">
                                {t('client:jobPost.card.channelPairingButton')}
                                <ChainLinkFilledIcon />
                            </Button>
                        </div>
                    </InfoMessage>
                }
            </div>
        </div>
    );
}