import { createStore, select, withProps } from '@ngneat/elf';

interface IntercomStore {
    isVisible: boolean;
}

const intercomStore = createStore(
    { name: 'intercom' },
    withProps<IntercomStore>( { isVisible: false } )
);

const isIntercomVisible = intercomStore.query( ( { isVisible } ) => ( { isVisible } ) );
const isIntercomVisible$ = intercomStore.pipe( select( ( state ) => state.isVisible ) );
const setIntercomVisibility = ( value ) => {
    intercomStore.update( ( state ) => {
        return {
            ...state,
            isVisible: value
        };
    } );
};

const toggleIntercomVisibility = () => {
    const invertVisibility: boolean = !intercomStore.getValue().isVisible;
    setIntercomVisibility( invertVisibility );
};

export { intercomStore, isIntercomVisible, isIntercomVisible$, setIntercomVisibility, toggleIntercomVisibility };