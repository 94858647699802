import { createStore, select, withProps } from '@ngneat/elf';

import parsePhoneNumber, { CountryCode, getCountryCallingCode, PhoneNumber } from 'libphonenumber-js';
export interface SignUpClientProps {
    data: {
        email: string | null;
        password: string | null;
        code: string | null;
        userName: string | null;
        firstName: string | null;
        lastName: string | null;
        phoneCodeCountry: string | null;
        phoneNumber: string | null;
    };
    step: number;
    code: string;
    lastCodeSentAt?: Date;
    phoneNumberVerified: boolean;
    isLoading: boolean;
}

const signUpClientStore = createStore(
    { name: 'signUpClientProps' },
    withProps<SignUpClientProps>( {
        data: {
            email: null,
            password: null,
            code: null,
            userName: null,
            firstName: null,
            lastName: null,
            phoneCodeCountry: null,
            phoneNumber: null
        },
        step: 0,
        code: null,
        lastCodeSentAt: null,
        phoneNumberVerified: false,
        isLoading: false
    } )
);

export class SignUpClientRepository {

    step$ = signUpClientStore.pipe( select( ( state ) => state.step ) );
    isLoading$ = signUpClientStore.pipe( select( ( state ) => state.isLoading ) );

    set isLoading( isLoading: boolean ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                isLoading
            };
        } );
    }

    get data() {
        return signUpClientStore.getValue().data;
    }

    get code() {
        return signUpClientStore.getValue().code;
    }

    set code( code: string ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                code
            };
        } );
    }

    get firstName(): string {
        return signUpClientStore.getValue().data.firstName;
    }

    get lastName(): string {
        return signUpClientStore.getValue().data.lastName;
    }

    get email(): string | null {
        return signUpClientStore.getValue().data.email;
    }

    get password(): string | null {
        return signUpClientStore.getValue().data.password;
    }

    get userName(): string | null {
        return signUpClientStore.getValue().data.userName;
    }

    get step(): number {
        return signUpClientStore.getValue().step;
    }

    set email( email: string | null ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    email
                }
            };
        } );
    }

    set password( password: string | null ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    password
                }
            };
        } );
    }

    set userName( userName: string | null ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    userName
                }
            };
        } );
    }


    get phoneCodeCountry(): string {
        return signUpClientStore.getValue().data.phoneCodeCountry;
    }

    get phoneCodeCountryNumber(): string {
        if( !this.phoneCodeCountry ) {
            return null;
        }

        return `+${ getCountryCallingCode( this.phoneCodeCountry as CountryCode ) }`;
    }

    get phoneNumber(): string {
        return signUpClientStore.getValue().data.phoneNumber;
    }

    get fullPhoneNumber(): string {
        const phoneNumber: string = this.phoneNumber;

        if( !phoneNumber ) {
            return null;
        }

        return `${ this.phoneCodeCountryNumber }${ this.phoneNumber }`;
    }

    get fullPhoneNumberFormatted(): string {
        const fullPhoneNumber: string = this.fullPhoneNumber;

        if( !fullPhoneNumber ) {
            return null;
        }

        const phoneNumber: PhoneNumber = parsePhoneNumber( this.fullPhoneNumber );
        return phoneNumber.format( 'E.164' );
    }

    get lastCodeSentAt(): Date {
        return signUpClientStore.getValue().lastCodeSentAt;
    }

    set lastCodeSentAt( lastCodeSentAt: Date ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                lastCodeSentAt
            };
        } );
    }

    get phoneNumberVerified(): boolean {
        return signUpClientStore.getValue().phoneNumberVerified;
    }

    set phoneNumberVerified( phoneNumberVerified: boolean ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                phoneNumberVerified
            };
        } );
    }

    set step( step: number ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                step
            };
        } );
    }

    updateData( data: Partial<SignUpClientProps['data']> ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...data
                }
            };
        } );
    }

    nextStep( count: number = 1 ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                step: state.step + count
            };
        } );
    }

    previousStep( count: number = 1 ) {
        signUpClientStore.update( ( state ) => {
            return {
                ...state,
                step: state.step - count
            };
        } );
    }

    clearStore(): void {
        signUpClientStore.reset();
    }
}