import { FreelancersRepository } from '@/components/freelancers-filter-items/freelancersRepository';
import { createContext } from 'react';

const freelancersRepository: FreelancersRepository = new FreelancersRepository();

export const FreelancersRepositoryContext = createContext(freelancersRepository);

export default function FreelancersRepositoryProvider({ children }) {
    return (
        <FreelancersRepositoryContext.Provider value={freelancersRepository}>
            {children}
        </FreelancersRepositoryContext.Provider>
    );
}