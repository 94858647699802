import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import { useTranslation } from 'react-i18next';
import ListSkeleton from '@/components/ListSkeleton';
import NoDataIcon from '@/components/NoDataIcon';
import FreelancerJobPostCard from './FreelancerJobPostCard';
import { useState } from 'react';
import { JobPost, JobPostsFreelancerStatusEnum } from '@/gql/graphql';
import { NavLink, useParams } from 'react-router-dom';
import FreelancerJobPostAppliedCard from './FreelancerJobPostAppliedCard';
import { motion } from 'framer-motion';

interface FreelancerProjectListDesktopProps {
    jobPosts: any[];
    isLoading: boolean;
    onJobPostApply?: ( jobPost: JobPost, message: string ) => void;
    onJobPostDecline?: ( jobPost: JobPost ) => void;
    status: JobPostsFreelancerStatusEnum;
}

export default function FreelancerJobPostList( {
                                                    jobPosts,
                                                    isLoading,
                                                    onJobPostApply,
                                                    onJobPostDecline,
                                                    status
                                                }: FreelancerProjectListDesktopProps ) {
    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: false
    } );

    const { t } = useTranslation();

    const { status: jobPostListStatus } = useParams();

    const [ isFlipped, setIsFlipped ] = useState( false );
    const [ selectedJobPost, setSelectedJobPost ] = useState<JobPost>();

    const flipCard = ( jobPost ) => {
        setSelectedJobPost( jobPost );
        setIsFlipped( true );
    };

    const handleApply = async( message ) => {
        await onJobPostApply( selectedJobPost, message );
        await setIsFlipped( false );
    };

    const handleCancel = ( ) => {
        setIsFlipped( false );
    };

    if( isLoading ) {
        return <div className="flex flex-col gap-y-4">
            <ListSkeleton isLoading={ true }
                          height={ 200 }
                          count={ 4 } />
        </div>;
    }

    if( jobPosts?.length > 0 && !isLoading ) {
        return jobPosts?.map( ( jobPost ) =>
            <motion.div key={ jobPost?.id }
                        className="relative w-full h-full">
                <motion.div transition={ { duration: 0.7 } }
                            initial={ false }
                            animate={ { rotateX: isFlipped && selectedJobPost.id === jobPost?.id ? 180 : 0 } }
                            className="backface-hidden border border-grey-light rounded-2xl">
                    <NavLink to={ `/freelancer/job-posts/${ jobPostListStatus }/${ jobPost?.id }` }
                             className="hover:text-inherit"
                             key={ jobPost?.id }>
                        <FreelancerJobPostCard jobPost={ jobPost }
                                               onApply={ flipCard }
                                               onDecline={ onJobPostDecline }
                                               status={ status } />
                    </NavLink>
                </motion.div>

                <motion.div transition={ { duration: 0.7 } }
                            initial={ false }
                            animate={ { rotateX: isFlipped && selectedJobPost.id === jobPost?.id ? 0 : -180 } }
                            className="backface-hidden absolute top-0 left-0 w-full h-full border border-grey-light rounded-2xl">
                    <FreelancerJobPostAppliedCard key={ jobPost?.id }
                                                  onApply={ handleApply }
                                                  onCancel={ handleCancel } />
                </motion.div>
            </motion.div>
        );
    }

    return (
        <div className="flex justify-center items-center">
            <NoDataIcon message={ t( 'freelancer:jobPosts.noDataFound' ) }
                        subMessage={ t( 'freelancer:jobPosts.noDataFoundSubMessage' ) } />
        </div>
    );
}