import { LightningBoltFilledIcon, MyProfileIconFilledIcon, ProjectFolderOutlinedIcon } from '@/assets/icons/CustomIcons';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useEffect, useState } from 'react';
import { Freelancer, FreelancerAccountStatusEnum } from '@/gql/graphql';
import { SessionRepository } from '@/core/auth/sessionRepository';

export default function FreelancerNavBarTouch() {

    const { t } = useTranslation();
    const sessionRepository = new SessionRepository();
    const [ user, setUser ] = useState<Partial<Freelancer>>( null );

    const [ navs, setNavs ] = useState<any[]>( [
        {
            to: '/freelancer/job-posts',
            text: t( 'freelancer:header.desktop.jobPosts' ),
            icon: <ProjectFolderOutlinedIcon className="text-[24px]" />,
            count: null
        },
        {
            to: '/freelancer/profile',
            text: t( 'freelancer:header.desktop.myProfile' ),
            icon: <MyProfileIconFilledIcon className="text-[24px]" />,
            count: null
        }
    ] );

    useEffect( () => {
        const observable = sessionRepository.session$.subscribe( ( session ) => {
            setUser( session.user as Freelancer );

            if( ( session.user as Freelancer )?.accountStatus != FreelancerAccountStatusEnum.APPROVED ) {
                setNavs( ( nav ) => [
                    {
                        to: '/freelancer/getting-started',
                        text: t( 'freelancer:header.desktop.gettingStarted' ),
                        icon: <LightningBoltFilledIcon className="text-[24px]" />,
                        count: null
                    },
                    ...navs
                ] );
            }
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [] );

    return (
        <div className={ twMerge( 'flex-none w-full h-[60px] fixed bottom-0 left-0 right-0 bg-white z-50 grid grid-rows-1 border-t content-center place-items-center border-grey-light' ) } style={{
            "gridTemplateColumns": "repeat(" + navs.length + ", 1fr)"
        }}>
            {
                navs.map( ( nav, index ) => (
                    <NavLink to={ nav.to }
                             key={ index }
                             className={ ( { isActive, isPending } ) => {
                                 const state = isPending ? 'pending' : isActive ? 'text-primary-400' : '';
                                 return twMerge( 'w-fit h-[52px] flex flex-col items-center justify-center flex-none text-grey gap-y-1', state );
                             } }>
                        {
                            ( { isActive, isPending } ) => (
                                <div className="flex flex-col items-center w-fit">
                                    { nav.icon }
                                    <span className="mt-px text-xs uppercase text-[9px] font-semibold">{ nav.text }</span>
                                </div>
                            )
                        }
                    </NavLink>
                ) )
            }
        </div>
    );
}