import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import isUuid from '@/utils/isUuid';
import { useLazyQuery } from '@apollo/client';
import replaceLastSegmentOfUrl from '@/utils/replaceLastSegmentOfUrl';
import freelancerIdFromCustomUrlGQL from '@/gql/global-queries/freelancerIdFromCustomUrl';

export default function useFreelancerIdHook() {
    const navigate: NavigateFunction = useNavigate();

    const [ initialFreelancerId, _setInitialFreelancerId ] = useState<string | null>( null );
    const [ freelancerId, setFreelancerId ] = useState<string | null>( null );
    const [ query, { data, loading: isLoading, error } ] = useLazyQuery( freelancerIdFromCustomUrlGQL );

    const setInitialFreelancerId = ( initialFreelancerId: string | null ): void => {
        if( initialFreelancerId != null ) {
            const isUuidId: boolean | null = isUuid( initialFreelancerId );

            if( !isUuidId ) {
                getId( initialFreelancerId );
            }
            else {
                setFreelancerId( initialFreelancerId );
            }
        }
    }

    const getId = ( id: string ) => {
        query( {
            variables: {
                id
            }
        } );
    };

    useEffect( () => {
        if( data ) {
            const id: string = data.freelancer.id;
            if( id !== freelancerId ) {
                setFreelancerId( id );
                replaceLastSegmentOfUrl( navigate, id );
            }
        }
    }, [ data ] );

    useEffect( () => {
        if( initialFreelancerId != null ) {
            const isUuidId: boolean | null = isUuid( initialFreelancerId );
            if( !isUuidId ) {
                getId( initialFreelancerId );
            }
            else {
                setFreelancerId( initialFreelancerId );
            }
        }
    }, [ initialFreelancerId ] );


    return {
        freelancerId,
        setInitialFreelancerId,
        isLoading,
        error
    };
}