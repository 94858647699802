import { useTranslation } from 'react-i18next';
import { JobPost } from '@/gql/graphql';
import { Button, Divider } from 'antd';
import { DateTime } from 'luxon';

export interface ClientJobPostDetailActionsProps {
    jobPost: JobPost;
    onEdit?: () => void;
}

export default function ClientJobPostDetailActions( {
    jobPost,
    onEdit,
}: ClientJobPostDetailActionsProps ) {

    const { t } = useTranslation();

    return (
        <div className="w-full rounded-2xl  border border-blue-100 p-6 flex flex-col gap-4">
            <h4 className="font-semibold text-base">
                { t('freelancer:jobPosts.jobPostCard.aboutTheJobPost') }
                
            </h4>
            <Divider className="my-0" />

            <span className='text-zinc-500'>{ t( 'client:jobPosts.jobPostCard.posted',  { date: DateTime.fromISO( jobPost?.createdAt ).setLocale('en').toRelative({ base: DateTime.now() }) }) }</span>

            {/* <div className="relative flex flex-col gap-5">
                <Button
                    type="default"
                    className="w-full text-sm mt-4 lg:mt-0"
                    onClick={() => onEdit()}
                >
                    {t('client:jobPost.card.edit')}
                </Button>
            </div> */}

            {/* TODO edit job details */}
        </div>
    );
}