import { ChevronLeftFilledIcon, ChevronRightFilledIcon } from '@/assets/icons/CustomIcons';
import type { PaginationProps as PaginationPropsAnt } from 'antd';
import { Button, Pagination as PaginationAnt } from 'antd';
import { twMerge } from 'tailwind-merge';

export type PaginationProps = {
    disabled?: boolean;
    pageSize: number;
    currentPageNumber: number;
    itemCount: number;
    changePage: ( pageNumber: number ) => void;
    className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Pagination = ( props: PaginationProps ) => {
    const {
              itemCount,
              pageSize,
              currentPageNumber,
              changePage,
              className
          } = props;

    const pageCount = itemCount / pageSize;
    const totalPages = Math.ceil( pageCount );

    const isPrevButtonDisabled = currentPageNumber === 1;
    const isNextButtonDisabled =
              !totalPages || currentPageNumber === totalPages;

    const itemRender: PaginationPropsAnt['itemRender'] = ( page, type, originalElement ) => {
        if( type === 'page' ) {
            return <Button className="h-10 w-10 rounded-full flex justify-center items-center"
                           type={ page === currentPageNumber ? 'primary' : 'text' }
                           onClick={ () => changePage( page ) }>{ page }</Button>;
        }
        if( type === 'prev' ) {
            return;
        }
        if( type === 'next' ) {
            return;
        }
        return originalElement;
    };

    return (
        <div className={ twMerge( 'flex items-center justify-between pt-8 pb-2 gap-2', className ) }>
            <Button type="text"
                    disabled={ isPrevButtonDisabled }
                    onClick={ () => changePage( currentPageNumber - 1 ) }
                    className={ `p-0 hover:bg-inherit ${ !isPrevButtonDisabled && 'hover:text-primary-400' }` }>
                <ChevronLeftFilledIcon className="text-xl" />
            </Button>
            <PaginationAnt className="flex items-baseline"
                           showTitle={ false }
                           current={ currentPageNumber }
                           total={ itemCount }
                           itemRender={ itemRender }
                           showSizeChanger={ false }
                           onChange={ changePage } />
            <Button type="text"
                    disabled={ isNextButtonDisabled }
                    onClick={ () => changePage( currentPageNumber + 1 ) }
                    className={ `p-0 hover:bg-inherit ${ !isNextButtonDisabled && 'hover:text-primary-400' }` }>
                <ChevronRightFilledIcon className="text-xl" />
            </Button>
        </div>
    );
};

export default Pagination;