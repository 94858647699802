import { createStore, select, withProps } from '@ngneat/elf';

interface FooterStore {
    headerVisible: boolean;
    bottomNavBarVisible: boolean;
}

const layoutStore = createStore(
    { name: 'footerStore' },
    withProps<FooterStore>( {
        headerVisible: false,
        bottomNavBarVisible: false
    } )
);

export class LayoutRepository {
    layoutVisibility$ = layoutStore.pipe( select( ( state ) => {
        return {
            headerVisible: state.headerVisible,
            bottomNavBarVisible: state.bottomNavBarVisible
        };
    } ) );

    isHeaderVisible$ = layoutStore.pipe( select( ( state ) => state.headerVisible ) );
    isBottomNavBarVisible$ = layoutStore.pipe( select( ( state ) => state.bottomNavBarVisible ) );

    get isBottomNavBarVisible() {
        return layoutStore.getValue().bottomNavBarVisible;
    }

    get isHeaderVisible() {
        return layoutStore.getValue().headerVisible;
    }

    set bottomNavBarVisible( value ) {
        layoutStore.update( ( state ) => {
            return {
                ...state,
                bottomNavBarVisible: value
            };
        } );
    };

    set headerVisible( value ) {
        layoutStore.update( ( state ) => {
            return {
                ...state,
                headerVisible: value
            };
        } );
    };

    setVisibility = ( { headerVisible, bottomNavBarVisible } ) => {
        layoutStore.update( ( state ) => {
            return {
                ...state,
                headerVisible,
                bottomNavBarVisible
            };
        } );
    };
}
