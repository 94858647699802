import { Outlet } from 'react-router-dom';

import AuthHeader from '@/pages/auth/layout/header/AuthHeader';

export default function AuthRoot() {
    return (
        <div className="flex flex-col w-full h-full min-h-screen">
            <AuthHeader />
            <Outlet />
        </div>
    );
}