import { Controller } from 'react-hook-form';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { Input, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { TooltipOutlinedIcon } from '@/assets/icons/CustomIcons';

interface InputProps {
    type?: string;
    defaultValue?: string | number;
    className?: string;
    inputClassName?: string;
    control: any;
    name: string;
    label?: string;
    tooltipLabel?: string;
    disabled?: boolean;
    min?: number;
    placeholder?: string;
    rules?: any;
    errors?: any;
    suffix?: JSX.Element | string;
    prefix?: JSX.Element | string;
    onChange?: (data: any) => void,
}


export default function InputComponent( {
                                            type = 'text',
                                            defaultValue,
                                            className,
                                            inputClassName,
                                            control,
                                            name,
                                            min,
                                            label,
                                            tooltipLabel,
                                            placeholder,
                                            disabled,
                                            rules,
                                            errors,
                                            suffix,
                                            prefix,
                                            onChange: onChangeMethod,
                                        }: InputProps ) {
    const [ isFocused, setIsFocused ] = useState( false );
    const inputRef = useRef( null );

    const focusBorder = isFocused ? 'border-black' : 'border-grey-light';
    const errorBorder = errors && errors[ name ] ? 'border-red' : '';

    return (
        <div className={ twMerge( 'w-full', className ) }
             onClick={ () => inputRef.current.focus() }>
            <Controller control={ control }
                        name={ name }
                        defaultValue={ defaultValue }
                        rules={ rules }
                        render={ ( { fieldState: { error }, field: { onChange, onBlur, value, ref } } ) => (
                            <div className="w-full">
                                <div className={ twMerge( 'w-full transition-colors duration-500 ease-in-out border rounded-[10px] px-3.5 py-2.5', focusBorder, errorBorder ) }>
                                    <div className="w-full h-full flex items-center justify-center gap-x-2">
                                        <div className="w-full">
                                            <div>
                                                <label className="text-xs text-grey font-normal">
                                                    { label }
                                                </label>
                                                {
                                                    tooltipLabel &&
                                                    <Tooltip className="ml-2"
                                                             title={ tooltipLabel }>
                                                      <span><TooltipOutlinedIcon /></span>
                                                    </Tooltip>
                                                }
                                            </div>
                                            <Input onChange={ onChangeMethod || onChange }
                                                   ref={ ( e ) => {
                                                       ref( e );
                                                       inputRef.current = e;
                                                   } }
                                                   disabled={ disabled }
                                                   type={ type }
                                                   onBlur={ () => {
                                                       setIsFocused( false );
                                                       onBlur();
                                                   } }
                                                   onFocus={ () => setIsFocused( true ) }
                                                   value={ value }
                                                   prefix={ prefix }
                                                   className={ twMerge( 'p-0', inputClassName ) }
                                                   min={ min }
                                                   size="middle"
                                                   status={ error ? 'error' : '' }
                                                   placeholder={ placeholder }
                                                   bordered={ false } />
                                        </div>
                                        {
                                            suffix &&
                                            <>
                                                {
                                                    suffix
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                    errors &&
                                    errors[ name ] &&
                                    (
                                        <div className="w-fit text-sm font-normal text-red mt-1.5">
                                            { firstLetterUppercaseFormatter( errors[ name ].message.toString() ) }
                                        </div>
                                    )
                                }
                            </div>
                        ) }
            />
        </div>
    );
}