import { gql } from '@apollo/client';

const countApplicationsByStatusGQL = gql`
    query CountApplicationByStatusGQL($jobPostId: String!) {
        countApplicationsByStatus(jobPostId: $jobPostId) {
            countInFavorite
            countInterested
            countOutreached
            countPerfectMatchInterested
            countPerfectMatchOutreached
        }
    }`;

export default countApplicationsByStatusGQL;

