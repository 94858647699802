import { useLazyQuery } from '@apollo/client';
import { clientJobPostsGQL } from '@/gql/global-queries';

interface ClientJobPostsHookProps {
    limit?: number;
}

export default function clientJobPostsHook( { limit }: ClientJobPostsHookProps = {}  ) {
  
    const [ jobPostsQuery, { data, loading, error, refetch } ] = useLazyQuery( clientJobPostsGQL );

    const loadJobPosts = (
        status: string = 'IN_PROGRESS',
        sortBy: string | null = null,
        search: string | null = null,
        page: number = 1
    ) => {
        jobPostsQuery( {
            variables: {
                filters: {
                    search,
                    sortBy,
                    status
                },
                limit,
                page
            },
        } );
    };

    return {
        loadJobPosts,
        jobPosts: data?.myJobPosts?.data,
        loading,
        total: data?.myJobPosts?.total,
        page: data?.myJobPosts?.page,
        refetch
    };
}