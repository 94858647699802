import { FilmsOutlinedIcon, LightBulbOutlinedIcon, PencilOutlinedIcon, PenNibOutlinedIcon, RocketOutlinedIcon, TimeSheetOutlinedIcon, UserCalendarOutlinedIcon, VoiceOutlinedIcon } from '@/assets/icons/CustomIcons';

export default function jobToIcon( job: string, className: string ) {
    switch( job ) {
    case 'VIDEO_EDITOR':
        return <FilmsOutlinedIcon className={ className } />;
    case 'THUMBNAIL_DESIGNER':
        return <PencilOutlinedIcon className={ className } />;
    case 'SCRIPT_WRITER':
        return <PenNibOutlinedIcon className={ className } />;
    case 'EDITOR_ASSISTANT':
        return <UserCalendarOutlinedIcon className={ className } />;
    case 'VOICE_OVER':
        return <VoiceOutlinedIcon className={ className } />;     
    case 'CREATIVE_DIRECTOR':
        return <LightBulbOutlinedIcon className={ className } />;
    case 'PUBLISHING_MANAGER':
        return <TimeSheetOutlinedIcon className={ className } />;
    case 'MOTION_DESIGNER':
        return <PenNibOutlinedIcon className={ className } />;
    case 'STRATEGIST_MANAGER':
        return <RocketOutlinedIcon className={ className } />;
    default:
        return null;
    }
}