import { useTranslation } from 'react-i18next';
import { ChainLinkFilledIcon, IncognitoFilledIcon } from '@/assets/icons/CustomIcons';
import { Avatar, Button } from 'antd';
import { jobPostLinkHook } from '@/hooks/jobPostLinkHook';
import { JobPost } from '@/gql/graphql';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { useMediaQuery } from 'react-responsive';

export interface JobPostDetailHeaderProps {
    jobPost: JobPost;
}

export default function JobPostDetailHeader( {
                                                  jobPost
                                              }: JobPostDetailHeaderProps ) {
    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const { t } = useTranslation();
    const { getJobPostLink } = jobPostLinkHook();

    const copyCustomUrlInClipboard = async(): Promise<void> => {
        const id: string = jobPost.id;
        getJobPostLink( id );
    };

    return (
        <div className="w-full rounded-2xl bg-blue-100">
            {
                jobPost?.channel?.bannerImageUrl &&
                <div className={ `h-44 w-full rounded-2xl bg-cover bg-center` }
                     style={ {
                         backgroundImage: `url('${ jobPost?.channel?.bannerImageUrl }')`
                     } }>
                </div>
            }
            <div className="flex flex-col lg:flex-row gap-4 p-6 justify-between">

                <div className="flex flex-col lg:flex-row lg:items-center gap-x-4 font-medium lg:gap-y-0 gap-y-2">
                    <div className="flex gap-4 items-center">
                        <Avatar src={ jobPost?.channel?.thumbnailUrl }
                                icon={ <IncognitoFilledIcon /> }
                                className={ `flex-none ${ !jobPost?.channel?.thumbnailUrl && 'bg-black' }` }
                                size={ isTabletOrMobile ? 42 : 56 } />
                        <div>
                            <span className="font-sharp text-xl md:text-2xl">
                                { jobPost?.channel?.name || t( 'client:jobPosts.jobPostCard.anonymous' ) }
                            </span>
                            {
                                jobPost?.channel &&
                                <div className="text-grey text-sm font-normal">
                                <span>
                                    { abbreviationNumberFormatter( jobPost?.channel?.subscriberCount || 0 ) } &nbsp;
                                    { t( 'common:channel-summary.follower', { count: jobPost?.channel?.subscriberCount || 0 } ) }
                                </span>
                                  &nbsp;‧&nbsp;
                                  <span>
                                    { t( 'common:channel-summary.videoWithCount', { count: jobPost?.channel?.videoCount || 0 } ) }
                                </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="relative flex">
                    <Button type="primary"
                            size="small"
                            icon={ <ChainLinkFilledIcon className="text-base md:text-xl leading-none" />}
                            className="text-sm md:text-base text-white"
                            onClick={ () => copyCustomUrlInClipboard() }>
                        <span>{ t( 'common:button.shareJobPost' ) }</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}