/**
 * Function to convert a country code into a flag emoji.
 *
 * @param {string} countryCode - The country code to be converted into a flag emoji.
 * @returns {string} The flag emoji corresponding to the given country code.
 *
 * @example
 * // returns '🇺🇸'
 * getFlagEmoji('us');
 *
 * @example
 * // returns '🇬🇧'
 * getFlagEmoji('gb');
 */
export default function getFlagEmoji( countryCode: string ) {
    const codePoints: number[] = countryCode
        .toUpperCase()
        .split( '' )
        .map( char => 127397 + char.charCodeAt( 0 ) );
    return String.fromCodePoint( ...codePoints );
}