import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import { useEffect, useState } from 'react';
import MenuSection from '@/components/MenuSection';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import clientJobPostsHook from '@/hooks/clientJobPostsHook';
import ClientJobPostList from './components/ClientJobPostList';
import SortBy from '@/components/SortBy';
import SearchBar from '@/components/SearchBar';
import { JobPostStatusEnum } from '@/gql/graphql';
import Pagination from '@/components/Pagination';
import gettingStartedHook from '@/hooks/gettingStartedHook';
import { PageName, trackPageView } from '@/utils/analytics';
import ListSkeleton from '@/components/ListSkeleton';

export default function ClientJobPosts() {

    const { status } = useParams();
    const { t } = useTranslation();


    const {
              loadJobPosts,
              jobPosts,
              loading: isLoadingData,
              page,
              refetch,
              total
          } = clientJobPostsHook( { limit: 10 } );

    const { gettingStartedCompleted } = gettingStartedHook();

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    const [ selectedStatus, setSelectedStatus ] = useState( null );
    const [ selectedSort, setSelectedSort ] = useState( null );
    const [ searchTerm, setSearchTerm ] = useState( null );
    const [ selectedPage, setSelectedPage ] = useState();
    const [ isLoadingSearch, setIsLoadingSearch ] = useState( false );

    const sortByItems = [
        {
            label: t( 'common:sort.newest' ),
            key: 'DESC'
        },
        { type: 'divider' },
        {
            label: t( 'common:sort.oldest' ),
            key: 'ASC'
        }
    ];

    const handleSortChange = ( value ) => {
        const selectedItem = sortByItems.find( ( item ) => item?.key === value.key );
        setSelectedSort( selectedItem );
        loadJobPosts( selectedStatus, value.key, searchTerm, selectedPage );
    };

    const handleSearchChange = ( value ) => {
        setIsLoadingSearch( true );
        setSearchTerm( value );
    };

    const handlePageChange = ( value ) => {
        setSelectedPage( value );
        loadJobPosts( selectedStatus, selectedSort?.key, searchTerm, value );
    };

    useEffect( () => {
        if( searchTerm != null ) {
            const delayDebounceFn = setTimeout( () => {
                loadJobPosts( selectedStatus, selectedSort?.key, searchTerm, 1 );
                setIsLoadingSearch( false );
            }, 1000 );
            return () => clearTimeout( delayDebounceFn );
        }
    }, [ searchTerm ] );

    const nav = [
        {
            title: t( 'client:jobPosts.ongoing' ),
            value: JobPostStatusEnum.IN_PROGRESS,
            route: '/client/job-posts/status/ongoing'
        },
        {
            title: t( 'client:jobPosts.closed' ),
            value: JobPostStatusEnum.CLOSED,
            route: '/client/job-posts/status/closed'
        }
    ];

    useEffect( () => {
        if( status != null ) {
            let statusEnum: JobPostStatusEnum;
            switch( status ) {
                case 'ongoing':
                    statusEnum = JobPostStatusEnum.IN_PROGRESS;
                    break;
                case 'closed':
                    statusEnum = JobPostStatusEnum.CLOSED;
                    break;
                default:
                    statusEnum = JobPostStatusEnum.IN_PROGRESS;
            }

            setSelectedStatus( statusEnum );
            loadJobPosts( statusEnum );
        }
    }, [ status ] );

    useEffect( () => {
        trackPageView( PageName.ClientJobPosts );
    }, [] );

    return (
        <div className="w-full flex flex-col lg:flex-row md:gap-20 relative">
            <div className="w-full md:w-[180px]">
                <MenuSection routes={ nav } />
            </div>
            <div className="mt-2 md:mt-6 lg:mt-0 w-full lg:w-10/12 flex flex-col gap-6 mb-5">
                <div className="hidden md:flex flex-col gap-2">
                    <div className="font-medium text-2xl font-sharp">
                        { t( 'client:jobPosts.title' ) }
                    </div>
                    <div className="text-sm text-grey">
                        <Trans i18nKey="client:jobPosts.description" />
                    </div>
                </div>
                <div className="flex md:justify-between md:flex-row gap-4">
                    <div className="flex-grow md:max-w-56">
                        <SearchBar placeholder={ t( 'common:search' ) }
                                   onChange={ handleSearchChange } />
                    </div>
                    <div className="flex-end flex justify-end">
                        <SortBy items={ sortByItems }
                                onItemSelect={ handleSortChange }
                                selectedItem={ selectedSort } />
                    </div>
                </div>
                <div>
                    <ClientJobPostList jobPosts={ jobPosts }
                                        jobPostsRefetch={ refetch }
                                        isGettingStartedCompleted={ gettingStartedCompleted }
                                        isLoading={ isLoadingData || isLoadingSearch } />
                    {
                        total && page ?
                        <Pagination pageSize={ 10 }
                                    currentPageNumber={ page }
                                    itemCount={ total }
                                    changePage={ handlePageChange } />
                                      : null
                    }
                </div>
            </div>
        </div>
    );
}