import parsePhoneNumberFromString, { CountryCode, PhoneNumber } from 'libphonenumber-js';

export default function extractDetailsFromPhoneNumber( phoneNumber: string ) {
    const phoneDetails: PhoneNumber = parsePhoneNumberFromString( phoneNumber );    

    if( !phoneDetails ) {
        return null;
    }

    const countryCode: CountryCode = phoneDetails.country as CountryCode;
    const number: string = phoneDetails.nationalNumber;

    return { countryCode, number };
}