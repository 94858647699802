import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Logo } from '@/assets/icons/Logo';
import AnimationCards from '@/components/AnimationCards';
import BackButton from '@/components/BackButton';
import SignInForm from '@/pages/auth/sign-in/components/SignInForm';
import { useEffect } from 'react';
import { PageName, trackPageView } from '@/utils/analytics';

export default function SignIn() {

    const { t } = useTranslation();

    useEffect( () => {
        trackPageView( PageName.SignIn );
    }, [ ] );

    return (
        <div className="w-full default-padding-x py-16 flex flex-col justify-between">
            <div className="flex-grow pt-6 lg:pt-[10%] flex flex-col justify-center">
                <Logo className="text-black h-10" />
                <div className="w-full flex flex-col justify-center items-center mt-12">
                    <div className="w-full max-w-[500px]">
                        <SignInForm />
                        <div className="flex items-center justify-between mt-10 text-center text-sm w-full">
                            <div className="flex">
                                <div className="font-medium">
                                    { t( 'auth:sign-in.noAccount' ) }
                                </div>
                                <Link to="/auth/sign-up"
                                      replace={ true }>
                                    <div className="text-primary-400 underline ml-2">
                                        { t( 'auth:sign-in.signUp' ) }
                                    </div>
                                </Link>
                            </div>
                            <Link to="/auth/password/recovery"
                                  className="font-semibold text-grey cursor-pointer">
                                { t( 'auth:sign-in.forgotPassword' ) }
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}