import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorSchemeContext } from '@/core/dark-mode/colorSchemeProvider';
import { Drawer, Space } from 'antd';
import { twMerge } from 'tailwind-merge';
import { CloseOutlinedIcon } from '@/assets/icons/CustomIcons';
import ProfileEditInformationForm from '@/pages/freelancer/views/profile/profile-edit-information/ProfileEditInformationForm';
import { useMediaQuery } from 'react-responsive';

interface ResponsiveModalProps {
    isOpen?: boolean;
    onClose?: ( value?: any | null ) => void;
    rootClassName?: string;
}

const ProfileEditInformationDrawer = forwardRef( ( props: ResponsiveModalProps, ref ) => {

    const isTabletOrMobile = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const [ placement, setPlacement ] = useState<string>();
    const [ width, setWidth ] = useState<string>();
    const { t } = useTranslation( 'freelancer' );

    const {
              isOpen = false,
              onClose,
              rootClassName
          } = props;

    const [ _isOpen, setIsOpen ] = useState( isOpen );
    const colorSchemeContext = useContext( ColorSchemeContext );

    useImperativeHandle( ref, () => ( {
            open() {
                setIsOpen( true );
            },
            close() {
                setIsOpen( false );
            },
            isOpen: _isOpen
        } )
    );

    useEffect( () => {
        const placement: string = isTabletOrMobile ? 'bottom' : 'right';
        const width: string = isTabletOrMobile ? '100%' : '700px';
        setPlacement( placement );
        setWidth( width );
    }, [ isTabletOrMobile ] );

    useEffect( () => {
        setIsOpen( isOpen );
    }, [ isOpen ] );

    const handleClose = () => {
        setIsOpen( false );
        onClose?.();
    };

    return (
        <Drawer open={ _isOpen }
                placement={ placement as 'right' | 'bottom' }
                className={ twMerge( 'overflow-x-hidden', isTabletOrMobile
                                                          ? 'rounded-t-xl mt-[5%]'
                                                          : 'rounded-l-xl' ) }
                styles={ {
                    body: {
                        padding: '0 24px 24px 24px'
                    },
                    header: {
                        fontFamily: 'SharpGrotesk',
                        fontSize: '18px',
                        fontWeight: 500,
                        border: 0
                    }
                } }
                width={width}
                height="100%"
                closable={ false }
                rootClassName={ twMerge( colorSchemeContext.toLowerCase(), rootClassName ) }
                title={<h3 className='mt-6 mb-0 font-sharp text-2xl'>{ t( 'freelancer:dashboard.profile.drawerTitleEditProfileInfo' ) }</h3>}
                extra={
                    <Space>
                        <div onClick={ () => handleClose() }>
                            <CloseOutlinedIcon className="text-[14px] text-grey cursor-pointer" />
                        </div>
                    </Space>
                }
                destroyOnClose={ true }>
            <ProfileEditInformationForm onEdited={ handleClose } />
        </Drawer>
    );
} );

export default ProfileEditInformationDrawer;