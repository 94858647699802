import ResponsiveModal from '@/components/ResponsiveModal';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'antd';
import VerificationCode from '@/components/VerificationCode';
import { phoneNumberVerificationHook } from '@/hooks/phoneNumberVerificationHook';

interface PhoneNumberFormProps {
    onCodeVerified?: ( value: boolean ) => void;
    phoneNumber: string;
}

const PhoneNumberCodeVerificationModal = forwardRef( ( { onCodeVerified, phoneNumber }: PhoneNumberFormProps, ref ) => {

    const { t } = useTranslation( [ 'common' ] );

    const {
              isSendingNewCode,
              isVerifying,
              timeRemaining,
              verifyCode,
              sendCode,
              setCode
          } = phoneNumberVerificationHook( {
        lastCodeSentAt: new Date(),
        phoneNumber
    } );

    const modalRef = useRef( null );

    useImperativeHandle( ref, () => ( {
        open: openModal,
        close: closeModal
    } ) );

    const openModal = () => modalRef.current?.open();

    const closeModal = () => modalRef.current?.close();

    const handleVerifyCode = async(): Promise<void> => {
        const isVerify: boolean = await verifyCode( phoneNumber );

        if( isVerify ) {
            modalRef.current?.close();
        }

        onCodeVerified?.( isVerify );
    };

    return (
        <ResponsiveModal ref={ modalRef }
                         desktopWidth="600px"
                         destroyOnClose={ true }
                         closable={ false }
                         title={ t( 'common:phoneNumber.verification-code-modal.title' ) }>
            <Divider className="mt-0" />
            <VerificationCode onCodeCompleted={ ( value ) => setCode( value ) } />
            <div className="mt-8 flex items-center justify-center">
                <span className="text-grey font-normal">
                    { t( 'auth:sign-up.freelancer.phone-number-verification-page.dontReceived' ) }
                </span>
                <Button type="link"
                        size="small"
                        onClick={ () => sendCode( phoneNumber, true ) }
                        loading={ isSendingNewCode }
                        disabled={ isSendingNewCode || timeRemaining > 0 }
                        className="text-primary-400 text-sm">
                    {
                        timeRemaining > 0
                        ? t( 'common:phoneNumber.verification-code-modal.resendCodeWithTiming', { timeRemaining } )
                        : t( 'common:phoneNumber.verification-code-modal.resendCode' )
                    }
                </Button>
            </div>

            <Divider />
            <Button type="primary"
                    className="w-full"
                    onClick={ () => handleVerifyCode() }
                    disabled={ isVerifying }
                    loading={ isVerifying }>
                { t( 'common:phoneNumber.verification-code-modal.verifyButton' ) }
            </Button>
        </ResponsiveModal>
    );
} );

export default PhoneNumberCodeVerificationModal;