import { gql } from '@apollo/client';

export const ClientProfileGQL = gql`
    query ClientProfile {
        meClient{
            id
            firstName
            lastName
            userName
            email
            phone
            profilePicture
            currency
            creatorSize
            channels {
                id
                name
            }
            averageRate
        }
    }`;


