import { ExperienceTypeEnum, JobPost, JobPostsFreelancerStatusEnum } from '@/gql/graphql';
import { Avatar, Button, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { BankNoteOutlinedIcon, IncognitoFilledIcon, RoundedCheckFilledIcon, TrashOutlinedIcon } from '@/assets/icons/CustomIcons';
import jobToIcon from '@/utils/jobToIcon';
import JobPostExpertiseTag from '@/components/job-post/component/JobPostExpertiseTag';
import { priceFormatter } from '@/utils/priceFormatter';
import Paragraphs from '@/components/Paragraphs';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import { DateTime } from 'luxon';
import PopconfirmWrapper from '@/components/PopconfirmWrapper';
import { useMediaQuery } from 'react-responsive';

interface FreelancerJobPostCardProps {
    jobPost?: JobPost;
    onApply?: ( jobPost: JobPost ) => void;
    onDecline?: ( jobPost: JobPost ) => void;
    status: JobPostsFreelancerStatusEnum;
}

export default function FreelancerJobPostCard( {
                                                    jobPost,
                                                    onApply,
                                                    onDecline,
                                                    status
                                                }: FreelancerJobPostCardProps ) {

    const isTabletOrMobile: boolean = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );
    const { t } = useTranslation( 'translation' );

    const handleApply = ( event ) => {
        event.preventDefault();
        onApply( jobPost );
    };

    return (
        <div className="relative w-full h-full group">
            <div className="flex flex-col gap-2 p-4 md:p-6 md:gap-6">
                <div className="w-full flex flex-row md:items-center gap-4">
                        <Avatar size={ isTabletOrMobile ? 48 : 60 }
                                className="flex-none bg-black border-2 border-blue-100"
                                icon={ <IncognitoFilledIcon className="text-3xl text-grey" /> }
                                src={ jobPost?.channel?.thumbnailUrl } />

                        <div className="flex flex-col w-full md:gap-1">
                            <div className="flex justify-between flex-col md:flex-row">
                                <div className="flex flex-col md:flex-row gap-2">
                                    {
                                        jobPost?.channel?.name ?
                                        <span className="font-semibold text-base md:text-xl font-sharp">
                                            { jobPost?.channel?.name }
                                        </span> :
                                        <span className="text-base md:text-xl font-sharp text-grey">
                                            { t( 'freelancer:jobPosts.jobPostCard.unverifiedChannel' ) }
                                        </span>
                                    }
                                    {
                                        jobPost?.channel?.subscriberCount &&
                                        <Tag className="text-primary-500 text-xs px-2 py-0 self-start md:self-center"
                                             color="#F4F3FF">
                                          <span>{ abbreviationNumberFormatter( jobPost?.channel?.subscriberCount ) }</span>
                                          <span className="ml-1">
                                            { t( 'common:channel-summary.follower', { count: jobPost?.channel?.subscriberCount } ) }
                                        </span>
                                        </Tag>
                                    }
                                </div>
                                <span className="hidden md:block text-zinc-500 text-xs md:text-sm">
                                    { DateTime.fromISO( jobPost?.createdAt ).setLocale( 'en' ).toRelative( { base: DateTime.now() } ) }
                                </span>
                            </div>
                            <div>
                                <span className="font-semibold text-base">{ jobPost?.name }</span>
                                <span className="block md:hidden text-zinc-500 text-xs md:text-sm mt-1">
                                    { DateTime.fromISO( jobPost?.createdAt ).setLocale( 'en' ).toRelative( { base: DateTime.now() } ) }
                                </span>
                                {
                                    jobPost?.description &&
                                    !isTabletOrMobile &&
                                    <Paragraphs text={ jobPost?.description }
                                                className="text-grey text-sm mt-2"
                                                showMoreButtonText={ t( 'freelancer:jobPosts.jobPostCard.buttons.readMore' ) }
                                                showLessButtonText={ t( 'freelancer:jobPosts.jobPostCard.buttons.readLess' ) }
                                                classNameShowMoreButton="text-primary-500 block mt-2"
                                                maxLength={ 180 } />
                                }
                            </div>
                        </div>
                </div>
                {
                    jobPost?.description &&
                    isTabletOrMobile &&
                    <Paragraphs text={ jobPost?.description }
                                className="text-grey text-sm"
                                showMoreButtonText={ t( 'freelancer:jobPosts.jobPostCard.buttons.readMore' ) }
                                showLessButtonText={ t( 'freelancer:jobPosts.jobPostCard.buttons.readLess' ) }
                                classNameShowMoreButton="text-primary-500 block mt-2"
                                maxLength={ 180 } />
                }
                <div className="flex justify-between md:items-center items-start flex-col md:flex-row">
                    <div className="flex flex-wrap gap-x-4 gap-y-2 md:gap-6">
                        {
                            jobPost?.priceMax !== null && jobPost?.priceMax != 0 &&
                            (
                                <div>
                                    <span className="text-grey">
                                        { t( 'freelancer:jobPosts.jobPostCard.budget' ) }
                                    </span>
                                    <div className="flex text-primary-500">
                                        <BankNoteOutlinedIcon className="text-base"/>
                                        <span className="ml-2 font-semibold whitespace-nowrap">$
                                            {
                                                priceFormatter( {
                                                    amount: jobPost?.priceMin,
                                                    decimals: false
                                                } )
                                            }
                                                                                               -
                                            {
                                                priceFormatter( {
                                                    amount: jobPost?.priceMax,
                                                    decimals: false
                                                } )
                                            }
                                        </span>
                                    </div>
                                </div>
                            )
                        }

                        <div>
                            <span className="text-grey">
                                { t( 'freelancer:jobPosts.jobPostCard.role' ) }
                            </span>
                            <div className="text-primary-500 max-w-[250px]">
                                { jobToIcon( jobPost?.role?.toUpperCase(), 'text-base inline-block mr-1' ) }
                                <span>
                                    { t( 'translation:enum.jobs.' + jobPost?.role?.toUpperCase(), { defaultValue: t( 'translation:enum.expertises.unknown' ) } ) }
                                </span>
                            </div>
                        </div>

                        {
                            jobPost?.expertises && jobPost?.expertises?.length != 0 &&
                            <div>
                                <span className="text-grey">
                                    { t( 'freelancer:jobPosts.jobPostCard.contentType' ) }
                                </span>
                              <div className="flex flex-wrap text-primary-500 gap-2 mt-1">
                                  { jobPost?.expertises?.map( ( expertise: ExperienceTypeEnum ) =>
                                      <div key={ expertise }>
                                          <JobPostExpertiseTag expertise={ expertise }
                                                               className="text-primary-500 text-sm py-0.5 bg-primary-100 font-semibold border-0" />
                                      </div>
                                  ) }
                              </div>
                            </div>
                        }
                    </div>
                    {
                        status === JobPostsFreelancerStatusEnum.ALL &&
                        !isTabletOrMobile &&
                        <div className="flex gap-4">
                          <PopconfirmWrapper title={ t( 'freelancer:jobPosts.jobPostCard.declinePopUp.title' ) }
                                             description={ t( 'freelancer:jobPosts.jobPostCard.declinePopUp.description' ) }
                                             onConfirm={ () => onDecline( jobPost ) }
                                             okText={ t( 'common:button.confirm' ) }>
                            <Button type="default"
                                    className="w-full text-sm md:w-fit mt-4 md:mt-0 transition-all duration-500 opacity-0 group-hover:opacity-100">
                              <TrashOutlinedIcon className="text-sm" />
                                { t( 'freelancer:jobPosts.jobPostCard.notInterested' ) }
                            </Button>
                          </PopconfirmWrapper>
                          <Button type="primary"
                                  className="w-full text-sm md:w-fit mt-4 md:mt-0 transition-all duration-500 opacity-0 group-hover:opacity-100"
                                  onClick={ ( e ) => {
                                      handleApply( e );
                                  } }>
                            <RoundedCheckFilledIcon className="text-sm text-white" />
                              { t( 'freelancer:jobPosts.jobPostCard.apply' ) }
                          </Button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}