import { useMediaQuery } from 'react-responsive';
import { ClientHeaderDesktop } from '@/pages/client/layout/header/ClientHeaderDesktop';
import { ClientHeaderTouch } from '@/pages/client/layout/header/ClientHeaderTouch';
import { SessionRepository } from '@/core/auth/sessionRepository';
import { useEffect, useState } from 'react';
import { Client } from '@/gql/graphql';

export default function ClientHeader() {

    const isTabletOrMobile: boolean = useMediaQuery( { query: `(max-width: ${ import.meta.env.VITE_MOBILE_BREAK_POINT })` } );

    const sessionRepository = new SessionRepository();
    const [ user, setUser ] = useState<Partial<Client>>( null );

    useEffect( () => {
        const observable = sessionRepository.session$.subscribe( ( session ) => {
            setUser( session.user as Client );
        } );
        return () => {
            observable.unsubscribe();
        };
    }, [] );

    return (
        <>
            {
                isTabletOrMobile ?
                <ClientHeaderTouch user={ user } /> :
                <ClientHeaderDesktop user={ user } />
            }
        </>
    );
}