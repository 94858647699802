export default function encryptText(texte: string): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let encryptedText = '';
    if( texte ) {
        for (let i = 0; i < texte.length; i++) {
            const randomCharacter = characters.charAt(Math.floor(Math.random() * characters.length));
            encryptedText += randomCharacter;
        }
        return encryptedText;
    }   
    return null
    
};