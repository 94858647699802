import { PortfolioElement, SegmentPortfolioElementEnum } from '@/gql/graphql';
import NoDataIcon from '@/components/NoDataIcon';
import { useTranslation } from 'react-i18next';
import FreelancerProfileEditPortfolioItem from '@/pages/freelancer/views/profile/profile-edit-portfolio/components/FreelancerProfileEditPortfolioItem';
import SkeletonInput from 'antd/es/skeleton/Input';
import { twMerge } from 'tailwind-merge';
import React from 'react';

interface FreelancerAccountEditOrderedListProps {
    portfolioElement: PortfolioElement;
    isLoading?: boolean;
    isDeleting: boolean;
    className?: string;

    onUpdateSegment?( segment: SegmentPortfolioElementEnum ): void;

    onDeleteItem?( portfolioElement: PortfolioElement ): void;
}

export default function FreelancerProfileEditPortfolioFeaturedItem( {
                                                                        portfolioElement,
                                                                        className,
                                                                        isLoading,
                                                                        isDeleting,
                                                                        onDeleteItem,
                                                                        onUpdateSegment
                                                                    }: FreelancerAccountEditOrderedListProps ) {

    const { t } = useTranslation();

    const handleOnDeleteItem = ( portfolioElement: PortfolioElement ) => {
        onDeleteItem( portfolioElement );
    };

    if( isLoading ) {
        return <SkeletonInput active={ true }
                              className={ twMerge( 'w-full rounded-xl h-[62px]', className ) } />;
    }

    if( portfolioElement != null ) {
        return <div className={ className }>
            <p className="text-sm font-normal text-grey mb-4">
                { t( 'freelancer:account.edit-portfolio.highlighted' ) }
            </p>
            <FreelancerProfileEditPortfolioItem portfolioElement={ portfolioElement }
                                                onMoveTo={ ( segment: SegmentPortfolioElementEnum ) => onUpdateSegment( segment ) } />
        </div>;
    }

    return <p className="text-sm font-normal text-grey">
        { t( 'freelancer:account.edit-portfolio.noDataFeatured' ) }
    </p>
}
