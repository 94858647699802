import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { OrderTypeEnum } from '@/gql/graphql';
import { Button, Select, Spin } from 'antd';
import clientJobPostsHook from '@/hooks/clientJobPostsHook';
import { Controller, useForm } from 'react-hook-form';
import { JobPassIcon } from '@/assets/icons/CustomIcons';
import { Order } from '@/gql/graphql';
import { planTypeName, SegmentEvent, trackSegmentEvent } from '@/utils/analytics';

interface PremiumJobPassProps {
    createOrder: (orderType: OrderTypeEnum, jobPostId: string) => void;
    isDisabled?: boolean;
    jobPassOrders: Order[];
}

const PremiumJobPass = ({ createOrder, isDisabled = false, jobPassOrders }: PremiumJobPassProps) => {
    const { t } = useTranslation();

    const { loadJobPosts, jobPosts, loading: isLoadingData, page, refetch, total } = clientJobPostsHook({ limit: 10 });
    const { control, handleSubmit, watch } = useForm();

    const selectedJobPost = watch('jobPost');

    useEffect(() => {
        loadJobPosts();
    }, []);

    const handleScroll = async (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { target } = event;
        if ((target as HTMLDivElement).scrollTop + (target as HTMLDivElement).clientHeight >= (target as HTMLDivElement).scrollHeight) {
            if (jobPosts.length < total) {
                loadJobPosts(page + 1);
            }
        }
    };

    const onSubmit = () => {
        if (selectedJobPost) {
            trackSegmentEvent( SegmentEvent.UpgradePlanClicked, { plan_type: planTypeName.JobPass } );
            createOrder(OrderTypeEnum.PREMIUM_JOB_PASS, selectedJobPost);
        }
    };

    const filteredJobPosts = jobPosts?.filter(
        (offer) => !jobPassOrders.some((order) => order.jobPostId === offer.id)
    );

    return (
        <div className="plan-card w-full h-auto flex-1 rounded-xl p-6 overflow-hidden flex flex-col justify-between gap-4">
            <div className="flex flex-col gap-2">
                <JobPassIcon className='text-[40px]' />
                <h4 className='text-sm font-medium'>{ t('client:plan.premiumJobPass.title') }</h4>
            </div>
            <div className='flex flex-col gap-4'>
                <div>
                    <Trans i18nKey="client:plan.premiumJobPass.price">
                        <span className="font-sharp text-2xl"></span>
                        <span className="font-sharp text-5xl"></span>
                        <span className="text-[#71767B] text-base"></span>
                    </Trans>
                </div>
                <span className='italic text-xs text-[#71767B]'>
                    <Trans i18nKey="client:plan.premiumJobPass.description">
                        <span className="text-primary-500 font-bold"></span>
                    </Trans>
                </span>

                <Controller
                    name="jobPost"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            showSearch
                            className='h-11 w-full'
                            disabled={ isDisabled }
                            placeholder={ t('client:plan.premiumJobPass.selectPlaceholder') }
                            optionFilterProp="children"
                            notFoundContent={ isLoadingData ? <Spin size="small" /> : null }
                            onPopupScroll={ handleScroll }
                            onChange={ (value) => field.onChange(value) }
                        >
                            {filteredJobPosts?.map((offer) => (
                                <Select.Option key={ offer.id } value={ offer.id }>
                                    { offer.name }
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                />
            </div>
            
            <Button
                type='primary'
                disabled={ isDisabled || !selectedJobPost }
                className={ ( isDisabled || !selectedJobPost ) && 'bg-[#E6E9F0]' }
                onClick={ handleSubmit(onSubmit) }
            >
                {t('client:plan.activateButton')}
            </Button>
        </div>
    );
};

export default PremiumJobPass;
