import { gql } from '@apollo/client';

const checkIfProfileIsCompletedGQL = gql`
    query CheckIfProfileIsCompletedGQL {
        checkIfProfileIsCompleted {
            status
            contacts
            portfolio
            profile
        }
    }`;

export default checkIfProfileIsCompletedGQL;