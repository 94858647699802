import { gql } from '@apollo/client';

const freelancerProfileGQL = gql`
    query FreelancerProfileGQL {
        meFreelancer {
            id
            isAvailable
            firstName
            lastName
            bio
            experience
            accountStatus
            phone
            profilePicture
            priceCurrency
            averageDailyRate
            averageRate
            accountStatus
            rejectedReason
            customUrl
            createdAt
            jobs
            skills
            email
            mainJob
            languages
            nativeLanguages
            secondaryLanguages
            countryBased
            expertises
            contractTypes
            contentTypes
            discordUserTag
            instagramUserTag
            twitterUserTag
        }
    }`;

export default freelancerProfileGQL;