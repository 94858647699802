import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Badge } from 'antd';
import { UserTypeEnum } from '@/gql/graphql';
import MyAccountButton from '@/components/MyAccountButton';
import HeaderTouch from '@/components/layout/HeaderTouch';
import { ExitOutlinedIcon, ProjectFolderOutlinedIcon } from '@/assets/icons/CustomIcons';

interface ClientHeaderDesktopProps {
    user: any;
    customMyAccountSection?: JSX.Element[];
}

export function ClientHeaderTouch( {
                                       user,
                                       customMyAccountSection,
                                   }: ClientHeaderDesktopProps ) {

    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [ currentNav, setCurrentNav ] = useState( null );

    useEffect( () => {
        const route = navs.find( nav => matchRoute( nav.to ) );
        setCurrentNav( route );
    }, [ currentPath ] );

    const myAccountJSX = (
        <div className="flex items-center justify-center gap-x-2">
            <MyAccountButton avatarSize={ 40 }
                             customSection={ customMyAccountSection }
                             data={ {
                                 type: UserTypeEnum.CLIENT,
                                 userName: user?.userName,
                                 profilePicture: user?.profilePicture
                             } } />
        </div>
    )

    const navs = [
        {
            to: '/client/job-posts',
            text: t( 'client:header.desktop.jobPosts' ),
            closeButton: false,
            navBarVisible: true,
            count: null,
            rightJSX: myAccountJSX
        },
        {
            to: '/client/settings',
            text: t( 'client:header.touch.settings' ),
            navBarVisible: true,
            closeButton: false,
            count: null,
            rightJSX: myAccountJSX
        },
        {
            to: '/client/create-job-post',
            text: null,
            navBarVisible: false,
            closeButton: false,
            count: null,
            rightJSX: (
                <div className="flex h-full items-center justify-center gap-x-4">
                    <div onClick={ () => navigate( -1 ) }
                         className="flex gap-2 hover:cursor-pointer">
                <span className="font-semibold text-grey text-base">
                    { t( 'client:leaveJobPost' ) }
                </span>
                        <ExitOutlinedIcon className="text-grey text-xl" />
                    </div>
                </div>
            )
        }
    ];

    const matchRoute = ( route: string ) => {
        return matchPath( {
                path: route,
                caseSensitive: false,
                end: false
            },
            currentPath );
    };

    const rightJSX: JSX.Element = (
        <>
            {
                navs.map( ( nav, index ) => {
                    return (
                        <div key={ index }
                             className={ matchRoute( nav.to ) ? '' : 'hidden' }>
                            { nav.rightJSX }
                        </div>
                    );
                } )
            }
        </>



    );

    const leftJSX: JSX.Element = (
        <>
            {
                navs.map( ( nav, index ) => {
                    return (
                        <div key={ index }
                             className={ matchRoute( nav.to ) ? '' : 'hidden' }>
                            <Badge count={ nav.count }
                                   className="text-inherit text-2xl font-medium font-sharp"
                                   size="small">
                                { nav.text }
                            </Badge>
                        </div>
                    );
                } )
            }
        </>
    );

    return (
        <HeaderTouch closeButton={ currentNav?.closeButton }
                     right={ rightJSX }
                     left={ leftJSX } />
    );
}