import { createContext, useEffect, useState } from 'react';
import { SessionRepository } from '@/core/auth/sessionRepository';

export const SessionContext = createContext( false );

export default function SessionProvider( { children } ) {

    const [ sessionIsLoaded, setSessionIsLoaded ] = useState( false );
    const sessionRepository: SessionRepository = new SessionRepository();

    useEffect( () => {
        const observable = sessionRepository.session$.subscribe( ( sessionProps ) => {
            setSessionIsLoaded( sessionProps.refreshToken !== null );
        } );

        return () => observable.unsubscribe();
    }, [] );

    return (
        <SessionContext.Provider value={ sessionIsLoaded }>
            { children }
        </SessionContext.Provider>
    );
};