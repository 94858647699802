import { gql } from '@apollo/client';

const freelancerContactDetailsGQL = gql`
    query FreelancerContactDetails($applicationId: String!) {
        freelancerContactDetails( applicationId: $applicationId ) {
            phone
            discordUserTag
            twitterUserTag
            instagramUserTag
        }
    }
`;

export default freelancerContactDetailsGQL;
