import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { Controller, useForm } from 'react-hook-form';

import emailVerificationHook from '@/hooks/emailVerificationHook';
import { inputEmailValidationUnique } from '@/utils/customEmailVerification';
import { SignUpFreelancerRepository } from '@/pages/auth/sign-up/sign-up-freelancer//signUpFrelancerRepository';
import { SessionRepository } from '@/core/auth/sessionRepository';

import InputComponent from '@/components/custom-element-form/InputComponent';
import CheckPasswordValidity from '@/components/CheckPasswordValidity';
import PhoneNumberInputs from '@/components/PhoneNumberInputs';

const SignUpFreelanceStepProfile = forwardRef( function( props, ref ) {
    const { t } = useTranslation();
    const { trigger, control, watch, setValue, formState: { errors } } = useForm();
    const repository = new SignUpFreelancerRepository();
    const sessionRepository = new SessionRepository();
    const sessionUserEmail = sessionRepository.user?.email;

    const { isAvailable } = emailVerificationHook();

    const subscription = watch( ( value ) => {
        if( value.phoneCodeCountry != repository.phoneCodeCountry || value.phoneNumber != repository.phoneNumber ) {
            repository.phoneNumberVerified = false;
        }

        repository.updateData( value );
    } );

    const password = watch( 'password' );

    useImperativeHandle( ref, () => ( {
        async validateFields(): Promise<boolean> {
            return trigger();
        }
    } ) );

    useEffect( () => {
        return () => subscription.unsubscribe();
    }, [] );

    return (
        <form className="flex w-full flex-col gap-y-5">
            <InputComponent control={ control }
                            name="email"
                            defaultValue={ repository.email }
                            label={ t( 'auth:sign-up.freelancer.profile-page.email' ) }
                            placeholder={ t( 'auth:sign-up.freelancer.profile-page.emailPlaceholder' ) }
                            errors={ errors }
                            rules={ {
                                required: t( 'common:error.form.required' ),
                                pattern: {
                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                    message: t( 'common:error.form.invalidEmail' )
                                },
                                validate: {
                                    unique: ( value ) => value === sessionUserEmail
                                                         ? true
                                                         : inputEmailValidationUnique( value, isAvailable, t( 'auth:sign-up.emailAlreadyUsed' ) )
                                }
                            } } />

            <div className="flex flex-col gap-2">
                <InputComponent control={ control }
                                type="password"
                                name="password"
                                className="w-full"
                                label={ t( 'auth:sign-up.freelancer.profile-page.password' ) }
                                placeholder={ t( 'auth:sign-up.freelancer.profile-page.passwordPlaceholder' ) }
                                errors={ errors }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } } />
                <CheckPasswordValidity password={ password } />
            </div>

            <div className="flex flex-wrap lg:flex-nowrap gap-x-2 gap-y-4">
                <InputComponent control={ control }
                                name="firstName"
                                defaultValue={ repository.firstName }
                                className="w-full lg:w-1/2"
                                label={ t( 'auth:sign-up.freelancer.profile-page.firstname' ) }
                                placeholder={ t( 'auth:sign-up.freelancer.profile-page.firstNamePlaceholder' ) }
                                errors={ errors }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } } />

                <InputComponent control={ control }
                                name="lastName"
                                defaultValue={ repository.lastName }
                                className="w-full lg:w-1/2"
                                label={ t( 'auth:sign-up.freelancer.profile-page.lastname' ) }
                                placeholder={ t( 'auth:sign-up.freelancer.profile-page.lastNamePlaceholder' ) }
                                errors={ errors }
                                rules={ {
                                    required: t( 'common:error.form.required' )
                                } } />
            </div>

            <PhoneNumberInputs control={ control }
                               defaultValueSelect={ repository.phoneCodeCountry }
                               defaultValueInput={ repository.phoneNumber }
                               inputName="phoneNumber"
                               selectName="phoneCodeCountry"
                               errors={ errors }
                               watch={ watch } />


            <div className="mt-4 font-medium">
                <Controller name="newsletter"
                            control={ control }
                            defaultValue={ repository.isAgreeForNewsletter }
                            render={ ( { field: { onChange, value, ref } } ) => (
                                <Checkbox onChange={ onChange }
                                          ref={ ref }>
                                    { t( 'freelancer:auth.sign-up.profile-page.news' ) }
                                </Checkbox>
                            ) } />
                <div className="mt-1">
                    <Trans i18nKey="auth:privacyPolicy">
                        <span className="text-center text-xs font-normal text-grey"></span>
                        <a href="https://jellysmack.com/privacy-policy/"
                           target="_blank"
                           className="text-grey text-xs underline font-medium"
                           rel="noopener noreferrer" />
                    </Trans>
                </div>

            </div>
        </form>
    );
} );

export default SignUpFreelanceStepProfile;