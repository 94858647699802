import React, { useEffect } from 'react';
import NoDataIcon from '@/components/NoDataIcon';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useIntercomHook } from '@/plugins/intercom/useIntercomHook';

export default function ErrorBoundary() {
    const { t } = useTranslation();
    const { setIntercomVisibility } = useIntercomHook();
    const code: number = 500;
    let error = useRouteError();

    useEffect( () => {
        if( error ) {
            setIntercomVisibility( true );
            onError( error );
        }
    }, [ error ] );

    const onError = ( error ) => {
        Sentry.captureException( error );
    };

    return (
        <div className="bg-inherit flex flex-col items-center mt-8 h-full w-full px-4 lg:px-8">
            <NoDataIcon message={ t( `error.${ code }.title` ) }
                        subMessage={ t( `error.${ code }.description` ) } />
        </div>
    );
}