import SignUpLayout from '@/pages/auth/sign-up/components/SignUpLayout';
import { signUpClientHook } from '@/hooks/signUpClientHook';
import SignUpFreelancerStepMain from '@/pages/auth/sign-up/sign-up-freelancer/sign-up-freelancer-steps/SignUpFreelancerStepMain';

export default function SignUpClient() {

    const {
              items,
              currentStep,
              isLoading,
              nextStep,
              previousStep,
          } = signUpClientHook();

    return (
        <SignUpLayout rightSide={
                          <SignUpFreelancerStepMain steps={ items as any }
                                                    currentStep={ currentStep } />
                      }
                      loadingNext={ isLoading }
                      hideNextButton={ items[currentStep]?.hideNextButton }
                      onNext={ () => nextStep() }
                      onBack={ () => previousStep() } />
    );
}