import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';
import InputComponent from '@/components/custom-element-form/InputComponent';
import CheckPasswordValidity from '@/components/CheckPasswordValidity';
import { resetPasswordGQL } from '@/gql/global-queries';

export default function ResetPassword() {

    const { t } = useTranslation();
    const [ messageApi, contextHolder ] = message.useMessage();
    const {
              trigger,
              control,
              formState: { errors },
              watch,
              handleSubmit
          } = useForm();
    const navigate = useNavigate();

    const newPasswordValue = watch( 'newPassword' );

    const { token } = useParams();
    const resetPasswordMutation = useMutation( resetPasswordGQL );

    const onSubmit = async( data ): Promise<void> => {
        if( !( await trigger() ) ) {
            return;
        }

        try {
            await resetPasswordMutation[ 0 ]( {
                variables: {
                    args: {
                        token,
                        newPassword: data.newPassword
                    }
                }
            } );
            messageApi.success( {
                content: t( 'auth:reset-password.notification', { email: data.email } ),
                duration: 4
            } );
            navigate( '/auth/sign-in' );
        } catch( e ) {
            Sentry.captureException( t( 'auth:reset-password.error' ), e );
            messageApi.error( t( 'error.default' ) );
        }
    };

    return (
        <div className="flex flex-col items-center justify-center">

            <div className="w-full max-w-[600px]">
                <h1 className="text-2xl font-bold text-center mt-12">
                    { t( 'auth:reset-password.title' ) }
                </h1>

                <h4 className="text-sm text-center text-grey-medium mt-4">
                    { t( 'auth:reset-password.subtitle' ) }
                </h4>

                { contextHolder }
                <form className="flex flex-col gap-4 w-full mt-8"
                      onSubmit={ handleSubmit( onSubmit ) }>

                    <InputComponent control={ control }
                                    type="password"
                                    name="newPassword"
                                    label={ t( 'client:account.edit-password.inputs.new-password.input' ) }
                                    placeholder={ t( 'client:account.edit-password.inputs.new-password.placeholderNewPassword' ) }
                                    rules={ {
                                        required: t( 'common:error.form.required' ),
                                        pattern: {
                                            value: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*\W).{8,}$/,
                                            message: t( 'client:account.edit-password.inputs.new-password.validation' )
                                        }
                                    } }
                                    errors={ errors } />

                    <InputComponent control={ control }
                                    type="password"
                                    name="confirmNewPassword"
                                    label={ t( 'client:account.edit-password.inputs.new-password.input' ) }
                                    placeholder={ t( 'client:account.edit-password.inputs.new-password.placeholderConformiation' ) }
                                    rules={ {
                                        required: t( 'common:error.form.required' ),
                                        validate: ( value ) =>
                                            value === newPasswordValue || 'Passwords do not match'
                                    } }
                                    errors={ errors } />

                    <CheckPasswordValidity password={ newPasswordValue } />

                    <Button type="primary"
                            htmlType="submit"
                            loading={ resetPasswordMutation[ 1 ].loading }
                            className="w-full mt-2">
                        { t( 'auth:reset-password.submit' ) }
                    </Button>
                </form>
            </div>
        </div>
    );
}