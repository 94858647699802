import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { CheckFilledIcon } from '@/assets/icons/CustomIcons';
import { useTranslation } from 'react-i18next';

interface CheckPasswordValidityHookProps {
    password: string;
}

export default function CheckPasswordValidity( { password }: CheckPasswordValidityHookProps ) {

    const { t } = useTranslation();

    const [ isLengthValid, setLengthValid ] = useState( false );
    const [ isCharacterMixValid, setCharacterMixValid ] = useState( false );

    const liStyle = 'text-sm font-normal flex items-center gap-2';

    useEffect( () => {
        if( password ) {
            // 1. Use at least 8 characters
            setLengthValid( password.length >= 8 );

            // 2. Use a mix between letters, numbers, and special characters
            const hasLetter = /[a-zA-Z]/.test( password );
            const hasNumber = /\d/.test( password );
            const hasSpecialChar = /[~`!@#$%^&*\(\)_\-+=\[\]{}|;:'",.<>?/]/.test( password );
            setCharacterMixValid( hasLetter && hasNumber && hasSpecialChar );
        }
        else {
            setLengthValid( false );
            setCharacterMixValid( false );
        }
    }, [ password ] );

    return (
        <ul className="flex flex-col gap-y-2">
            <li className={ twMerge( liStyle, isLengthValid ? 'text-green-200' : 'text-grey' ) }>
                <CheckFilledIcon className="text-xl" />
                { t( 'client:account.edit-password.instructions.1' ) }
            </li>
            <li className={ twMerge( liStyle, isCharacterMixValid ? 'text-green-200' : 'text-grey' ) }>
                <CheckFilledIcon className="text-xl" />
                { t( 'client:account.edit-password.instructions.2' ) }
            </li>
        </ul>
    );
}