import React, { useState, ReactNode } from 'react';
import { Popconfirm } from 'antd';

interface PopconfirmProps {
    title: string;
    description: string;
    onConfirm: () => void;
    okText?: string;
    children: ReactNode;
    isLoading?: boolean;
}

const PopconfirmWrapper: React.FC<PopconfirmProps> = ({ title, description, onConfirm, children, isLoading, okText }) => {

    const [ open, setOpen ] = useState( false );

    const showPopconfirm = ( event ) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleOk = async ( event ) => {
        event.preventDefault();
        try {
            await onConfirm();
        } finally {
            setOpen( false );
        }
    };

    const handleCancel = ( event ) => {
        event.preventDefault();
        setOpen( false );
    };

    return (
        <Popconfirm
            title={ title }
            description={ description }
            open={ open }
            onConfirm={ (e) => handleOk(e) }
            okButtonProps={{ loading: isLoading }}
            onCancel={ (e) => handleCancel(e) }
            okText={ okText }
        >
            <div onClick={ ( e ) => { showPopconfirm( e )} }>
                { children }
            </div>
        </Popconfirm>
    );
};

export default PopconfirmWrapper;
