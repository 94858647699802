import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import clientApplicationsHook from '@/hooks/clientApplicationsHook';
import SortBy from '@/components/SortBy';
import {  ApplicationTypeEnum, JobPost } from '@/gql/graphql';
import Pagination from '@/components/Pagination';
import JobPostTalentList from '../../components/JobPostTalentList';
import ToggleSwitch from '@/components/ToggleSwitch';
import { SparklesOutlinedIcon } from '@/assets/icons/CustomIcons';
import SelectSearch from '@/components/SelectSearch';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { App, Avatar, Checkbox, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import abbreviationNumberFormatter from '@/utils/abbrevationNumberFormatter';
import ApplicationFollowUpModal from '../../ApplicationFollowUpModal';
import ApplicationContactDetailsModal from '../../ApplicationContactDetailsModal';

import { clientApplicationChannelsGQL } from '@/gql/global-queries';

import { UpdateFavoriteStatusGQL } from '@/gql/global-queries/updateFavoriteStatusGQL';
import JobPostTalentListRestricted from '../../components/JobPostTalentListRestricted';
import { applicationTypeStatus, SegmentEvent, trackSegmentEvent } from '@/utils/analytics';
import countApplicationsByStatusGQL from '@/gql/global-queries/countApplicationsByStatusGQL';

const ClientJobPostTalentsContent = ( { emptyContentSlot } ) => {
    const { message } = App.useApp();
    const { jobPost, jobPostRefetch } = useOutletContext<{ jobPost: JobPost,  jobPostRefetch: any }>();

    const { status, jobPostId } = useParams();
    const { t } = useTranslation();

    const {
              loadJobPosts,
              refetch,
              applications,
              loading,
              page,
              total
          } = clientApplicationsHook( { limit: 10 } );

    const [ getClientApplicationChannel ] = useLazyQuery( clientApplicationChannelsGQL );
    const updateFavoriteStatusMutation = useMutation( UpdateFavoriteStatusGQL );

    const [ applicationSelected, setApplicationSelected ] = useState( null );
    const [ selectedStatus, setSelectedStatus ] = useState( null );
    const [ countTotalApplications, setCountTotalApplications ] = useState( 0 );
    const [ selectedSort, setSelectedSort ] = useState( 'DESC' );
    const [ selectedPage, setSelectedPage ] = useState();
    const [ perfectMatchValue, setPerfectMatchValue ] = useState( false );
    const [ channelWorkedWith, setChannelWorkedWith ] = useState( [] );
    const [ applicationChannelsOptions, setApplicationChannelsOptions ] = useState( [] );
    const [ applicationsChannelList, setApplicationsChannelList ] = useState( [] );

    const countApplicationByStatusQuery = useQuery( countApplicationsByStatusGQL, {
        variables: {
            jobPostId
        }
    } );

    const handleSearch = async( value: string ) => {
        const newTaglist = applicationsChannelList.filter( ( tag ) => tag.name.toLowerCase().includes( value.toLowerCase() ) );
        setApplicationChannelsOptions( newTaglist );
    };

    const onSuperLikeSent = () => refetch();

    const fetchCreatorWorkedWith = async( {
                                              jobPostId = null,
                                              applicationStatus = selectedStatus,
                                              page = 1,
                                              onlyPerfectMatch = perfectMatchValue,
                                              search = null
                                          } = {} ) => {
        await setChannelWorkedWith([]);
        const { data } = await getClientApplicationChannel( {
            variables: {
                page,
                args: {
                    applicationStatus,
                    jobPostId,
                    onlyPerfectMatch,
                    search
                }
            }
        } );

        if( data?.findChannelByJobPost ) {
            setApplicationsChannelList( data.findChannelByJobPost );
            setApplicationChannelsOptions( data?.findChannelByJobPost );
        }
    };

    const sortByItems = [
        {
            label: t( 'common:sort.newest' ),
            key: 'DESC'
        },
        { type: 'divider' },
        {
            label: t( 'common:sort.oldest' ),
            key: 'ASC'
        }
    ];

    const handleSortChange = ( value ) => {
        setSelectedSort( value.key );
        loadJobPosts( {
            jobPostId,
            orderBy: value.key,
            applicationStatus: selectedStatus,
            page: selectedPage,
            onlyPerfectMatch: perfectMatchValue,
            youtubeIds: channelWorkedWith
        } );
    };


    const handlePerfectMatchFilterChange = () => {
        loadJobPosts( {
            jobPostId,
            applicationStatus: selectedStatus,
            orderBy: selectedSort,
            page: 1,
            onlyPerfectMatch: !perfectMatchValue
        } );

        setApplicationChannelsOptions( [] );
        fetchCreatorWorkedWith( {
            page,
            jobPostId,
            applicationStatus: selectedStatus,
            onlyPerfectMatch: !perfectMatchValue
        } );

        setChannelWorkedWith([]);
        setPerfectMatchValue( !perfectMatchValue );
    };

    const handleCreatorWorkedWith = ( value ) => {
        loadJobPosts( {
            jobPostId,
            applicationStatus: selectedStatus,
            orderBy: selectedSort,
            page: selectedPage,
            onlyPerfectMatch: perfectMatchValue,
            youtubeIds: value
        } );

        setApplicationChannelsOptions( applicationsChannelList );
        setChannelWorkedWith( value );
    };

    const handlePageChange = ( value ) => {
        setSelectedPage( value );
        loadJobPosts( {
            jobPostId,
            applicationStatus: selectedStatus,
            orderBy: selectedSort,
            page: value,
            onlyPerfectMatch: perfectMatchValue,
            youtubeIds: channelWorkedWith
        } );
        window.scrollTo( {
            top: 0,
            behavior: 'smooth'
        } );
    };

    useEffect( () => {
        if( status != null && jobPostId !== null ) {
            let statusEnum: ApplicationTypeEnum;
            let countTotalApplications = 0;
            
            switch( status ) {
                case 'notified':                    
                    statusEnum = ApplicationTypeEnum.OUTREACHED;
                    countTotalApplications = countApplicationByStatusQuery?.data?.countApplicationsByStatus?.countOutreached
                    break;
                case 'interested':
                    statusEnum = ApplicationTypeEnum.INTERESTED;
                    countTotalApplications = countApplicationByStatusQuery?.data?.countApplicationsByStatus?.countInterested
                    break;
                case 'favorites':
                    statusEnum = ApplicationTypeEnum.FAVORITE;
                    countTotalApplications = countApplicationByStatusQuery?.data?.countApplicationsByStatus?.countInFavorite
                    break;
                default:
                    statusEnum = ApplicationTypeEnum.OUTREACHED;
            }

            setSelectedStatus( statusEnum );
            setCountTotalApplications(countTotalApplications);
            setApplicationChannelsOptions( [] );
            fetchCreatorWorkedWith( {
                page,
                jobPostId,
                applicationStatus: statusEnum
            } );
            loadJobPosts( {
                jobPostId,
                applicationStatus: statusEnum,
                orderBy: selectedSort
            } );
        }
    }, [ status, jobPostId, countApplicationByStatusQuery ] );

    const toggleFilterOnContent = <div>All candidates</div>;
    const toggleFilterOffContent = <div className="flex items-center gap-1">
        <SparklesOutlinedIcon className="text-lg" /> Perfect match </div>;

    const followUpModalRef = useRef( null );
    const contactDetailsModalRef = useRef( null );

    const handleFollowUp = ( project ) => {
        setApplicationSelected( project );
        followUpModalRef.current.open();
    };

    const handleShowContactDetails = ( project ) => {
        setApplicationSelected( project );
        trackSegmentEvent( SegmentEvent.ContactDetailsClicked, { contact_details_clicked: applicationTypeStatus[ selectedStatus ] } );
        contactDetailsModalRef.current.open();
    };

    const handleUpdateFavoriteStatus = async( isFavoriteStatus, applicationId ) => {
        try {
            const { data } = await updateFavoriteStatusMutation[ 0 ]( {
                variables: {
                    applicationId: applicationId,
                    isFavorite: isFavoriteStatus
                }
            } );

            if( data.updateFavoriteStatus ) {
                refetch();
                if( isFavoriteStatus ) {
                    trackSegmentEvent( SegmentEvent.AddedOnFavorite );
                }
            }

        } catch( e ) {
            message.error( t( 'translation:error.default' ) );
        }
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <div className="flex justify-start lg:justify-between lg:flex-row flex-col gap-4">
                {
                    (countTotalApplications > 0) &&
                    <div className="w-full h-full flex lg:flex-row flex-col justify-between gap-6 md:gap-12 md:items-center">
                        <div>
                            {
                                selectedStatus !== ApplicationTypeEnum.FAVORITE &&
                                <ToggleSwitch isOn={ perfectMatchValue }
                                                onClick={ () => handlePerfectMatchFilterChange() }
                                                onContent={ toggleFilterOnContent }
                                                offContent={ toggleFilterOffContent } />
                            }
                        </div>
                        <div className="flex gap-4 w-full md:w-fit h-full overflow-hidden">
                            <SelectSearch loadMore={ fetchCreatorWorkedWith }
                                            handleCreatorworkedWith={ handleCreatorWorkedWith }
                                            handleSearch={ handleSearch }
                                            mode="multiple"
                                            className="flex-grow w-full md:min-w-80"
                                            popUpClassName="max-w-80">
                                {
                                    applicationChannelsOptions.map( ( option, index ) => (
                                        <Select.Option key={ index }
                                                        value={ option.youtubeId }>
                                            <div className="flex gap-2 my-0 justify-between">
                                                <div className="flex flex-1 justify-between items-center gap-2">
                                                    <div className="flex items-center gap-2">
                                                        <Avatar size={ 24 }
                                                                icon={ <UserOutlined /> }
                                                                src={ option.thumbnailUrl } />
                                                        <span className="text-sm">{ option.name }</span>
                                                    </div>
                                                    <span className="additional-info text-xs text-grey">
                                                        { abbreviationNumberFormatter( option?.subscriberCount || 0 ) }
                                                    </span>
                                                </div>
                                                <Checkbox checked={ channelWorkedWith && channelWorkedWith.includes( option.youtubeId ) } />
                                            </div>
                                        </Select.Option>
                                    ) )
                                }
                            </SelectSearch>
                            
                            {
                                selectedStatus !== ApplicationTypeEnum.INTERESTED &&
                                <SortBy items={ sortByItems }
                                        onItemSelect={ handleSortChange }
                                        selectedItem={ selectedSort }
                                className="flex-none" />
                            }

                        </div>
                    </div>
                }
            </div>
            {
                loading || applications?.length > 0 ? (
                    <>
                        {
                            selectedStatus !== ApplicationTypeEnum.INTERESTED || jobPost?.isPaidOffer ?
                            <JobPostTalentList applications={ applications }
                                               onUpdateFavoriteStatus={ ( status,
                                                                           applicationId ) => handleUpdateFavoriteStatus( status, applicationId ) }
                                               onFollowUpMessage={ ( project ) => handleFollowUp( project ) }
                                               onShowContactDetails={ ( project ) => handleShowContactDetails( project ) }
                                               applicationsStatus={ selectedStatus }
                                               isLoading={ loading } /> :
                            <JobPostTalentListRestricted applications={ applications }
                                                         onUpdateFavoriteStatus={ ( status,
                                                                                     applicationId ) => handleUpdateFavoriteStatus( status, applicationId ) }
                                                         jobPostId={ jobPostId }
                                                         onFollowUpMessage={ ( project ) => handleFollowUp( project ) }
                                                         onShowContactDetails={ ( project ) => handleShowContactDetails( project ) }
                                                         applicationsStatus={ selectedStatus }
                                                         isLoading={ loading } />
                        }
                        {
                            total && page ?
                            <Pagination pageSize={ 10 }
                                        currentPageNumber={ page }
                                        itemCount={ total }
                                        changePage={ handlePageChange } />
                                          : null
                        }
                    </>
                ) : (
                    <div className="w-full flex flex-col items-center text-center mt-8">
                        <img
                            src="/img/no-data.png"
                            alt="no data" />
                        <div className="lg:w-1/2 w-full -mt-12">
                            { emptyContentSlot }
                        </div>
                    </div>
                )
            }

            <ApplicationFollowUpModal ref={ followUpModalRef }
                                      application={ applicationSelected }
                                      onFollowUpMessageSubmitted={ () => onSuperLikeSent() } />


            <ApplicationContactDetailsModal ref={ contactDetailsModalRef }
                                            application={ applicationSelected } />
        </div>
    );
};

export default ClientJobPostTalentsContent;
