import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { App } from 'antd';

export function jobPostLinkHook() {
    const { t } = useTranslation();
    const { message } = App.useApp();

    const getUrl = ( id?: string | null ): string => {
        return `${ import.meta.env.VITE_ENDPOINT }/freelancer/job-post/${ id }`;
    };

    const getJobPostLink = async( id?: string | null ): Promise<void> => {
        const profileUrl: string = getUrl( id );
        try {
            await navigator.clipboard.writeText( profileUrl );
            message.success( t( 'freelancer:account.successCopyInClipboard' ) );
        } catch( e ) {
            Sentry.captureException( e );
            message.error( t( 'freelancer:account.errorCopyInClipboard' ) );
        }
    };

    return {
        getJobPostLink,
        getUrl
    };
}