import { DateTime } from 'luxon';

export const getTimeDifference = ( date: Date ) => {

    if( !date ) return '';

    const now = DateTime.now();
    const pastDate = DateTime.fromISO( date );
    const diff = now.diff( pastDate, [ 'years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds' ] ).toObject();

    if( diff.years >= 1 ) {
        return `${ Math.floor( diff.years ) } year${ Math.floor( diff.years ) > 1 ? 's' : '' } ago`;
    }
    else if( diff.months >= 1 ) {
        return `${ Math.floor( diff.months ) } month${ Math.floor( diff.months ) > 1 ? 's' : '' } ago`;
    }
    else if( diff.weeks >= 1 ) {
        return `${ Math.floor( diff.weeks ) } week${ Math.floor( diff.weeks ) > 1 ? 's' : '' } ago`;
    }
    else if( diff.days >= 1 ) {
        return `${ Math.floor( diff.days ) } day${ Math.floor( diff.days ) > 1 ? 's' : '' } ago`;
    }
    else if( diff.hours >= 1 ) {
        return `${ Math.floor( diff.hours ) } hour${ Math.floor( diff.hours ) > 1 ? 's' : '' } ago`;
    }
    else if( diff.minutes >= 1 ) {
        return `${ Math.floor( diff.minutes ) } minute${ Math.floor( diff.minutes ) > 1 ? 's' : '' } ago`;
    }
    else {
        return `${ Math.floor( diff.seconds ) } second${ Math.floor( diff.seconds ) > 1 ? 's' : '' } ago`;
    }
};