import { useTranslation } from 'react-i18next';
import { JobPostFormRepository } from '@/pages/client/views/job-post/job-post-form/jobPostFormRepository';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import FreelancerProfilePortfolioListItem from '@/components/freelance-profile/FreelancerProfilePortfolioListItem';
import InputComponent from '@/components/custom-element-form/InputComponent';
import { App } from 'antd';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import { publicCreatePortfolioElementGQL } from '@/gql/global-queries';
import SkeletonInput from 'antd/es/skeleton/Input';


export default function JobPostFormReference() {

    const { t } = useTranslation();
    const { message } = App.useApp();

    const repository: JobPostFormRepository = new JobPostFormRepository();

    const [ portfolioVideos, setPortfolioVideos ] = useState( repository.portfolioVideos );
    const addPortfolioElementMutation = useMutation( publicCreatePortfolioElementGQL );

    const { control, setValue, formState: { errors } } = useForm();


    const handlePortfolioInputChange = async (data) => {   
        if (data.target.value && data.target.value.match(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*\??[\w=&\+\%]*)*\/?$/)) {
            addReferenceVideo(data.target.value);
        }
    };

    const handleDeleteReferenceVideo = async( id: string ) => {
        const newPortfolioElements = repository.portfolioVideos.filter(element => element.id !== id);
        repository.portfolioVideos = newPortfolioElements;
        setPortfolioVideos( newPortfolioElements );
    };

    const addReferenceVideo = async( data ) => {
        try {
            const portfolioElement = await addPortfolioElementMutation[ 0 ]( {
                variables: { url: data }
            } );            

            const newPortfolioElements = [
                ...repository.portfolioVideos,
                ...portfolioElement.data.publicCreatePortfolioElement.portfolioElements
            ];

            repository.portfolioVideos = newPortfolioElements
            setPortfolioVideos( newPortfolioElements )
            setValue('referenceUrl', '')
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to add new portfolio element', e );
            message.error( {
                content: t( 'client:jobPost.form.errors.submitError' ),
                duration: 4
            } );
        }
    };

    return (
        <div className='flex flex-col'>
            <div className='flex items-center gap-1.5'>
                <span className='font-semibold text-lg'>{t('client:jobPost.form.jobAdditionalInfoPage.references.title')}</span>
                <div className="rounded-3xl px-2 py-1 border-primary-100 border text-grey text-xs">
                    { t( 'client:jobPost.form.optional' ) }
                </div>
            </div>
            <span className='block pb-2 text-grey'>{t('client:jobPost.form.jobAdditionalInfoPage.references.subtitle')}</span>
            {
                    portfolioVideos?.length !== 0 && (
                    <div className="grid grid-cols-2 gap-6 my-6">
                        {
                                portfolioVideos?.map((element) => 
                                    <FreelancerProfilePortfolioListItem key={element?.id} onRemove={ handleDeleteReferenceVideo } portfolioElement={element} />
                                )
                        }
                        {
                            addPortfolioElementMutation[ 1 ].loading && (
                                <SkeletonInput active={ true }
                                              className="w-full h-[181px] rounded-2xl"
                                             />
                            )
                        }
                    </div>
                )
            }
            <InputComponent control={ control }
                name="referenceUrl"
                placeholder={
                    t( 'client:jobPost.form.jobAdditionalInfoPage.references.youtubeReferencesUrlPlaceholder' )
                }
                label={t( 'client:jobPost.form.jobAdditionalInfoPage.references.youtubeReferencesUrlLabel' )}
                onChange={ handlePortfolioInputChange }
                errors={ errors } 
            />
        </div>
            
    );
};