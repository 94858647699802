import { useTranslation } from 'react-i18next';

import { Button, message } from 'antd';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';
import { Form } from 'react-router-dom';

import InputComponent from '@/components/custom-element-form/InputComponent';
import { PageName, SegmentEvent, trackPageView, trackSegmentEvent } from '@/utils/analytics';
import { useEffect } from 'react';
import { updateFreelancerProfileGQL } from '@/gql/global-queries';
import { Freelancer } from '@/gql/graphql';
import { SessionRepository } from '@/core/auth/sessionRepository';

export default function FreelancerAccountConnectedAccount() {
    const { t } = useTranslation();

    useEffect( () => {
        trackPageView( PageName.FreelancerEditConnectedAccount );
    }, [ ] );

    const {
        trigger,
        control,
        setValue,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm();


    const sessionRepository: SessionRepository = new SessionRepository();
    const updateFreelancerProfileMutation = useMutation( updateFreelancerProfileGQL );
    
    useEffect( () => {
        if( sessionRepository.user ) {
            setFormValues( sessionRepository.user as Freelancer );
        }
    }, [ sessionRepository.user ] );

    const setFormValues = ( freelancer: Freelancer ) => {
        setValue( 'instagramUserTag', freelancer.instagramUserTag );
        setValue( 'discordUserTag', freelancer.discordUserTag );
        setValue( 'twitterUserTag', freelancer.twitterUserTag );
    };

    const onSubmit = async( data ): Promise<void> => {

        if( !( await trigger() ) ) {
            return;
        }

        const args = {
            instagramUserTag: data.instagramUserTag,
            discordUserTag: data.discordUserTag,
            twitterUserTag: data.twitterUserTag,
        };

        try {
            const response = await updateFreelancerProfileMutation[ 0 ]( {
                variables: {
                    args
                }
            } );

            if( response.data?.freelancerUpdate ) {
                message.success( t( 'common:form.successfullySubmitted' ) );
                trackSegmentEvent( SegmentEvent.ProfileUpdated );
                reset( {}, { keepValues: true } );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to edit freelancer connect account', e );
            message.error( t( 'common:form.submitError' ) );
        }
    };

    return (
        <div className="w-full flex flex-col">
            <Form 
                className='flex flex-col gap-6 md:gap-12'
                onSubmit={ handleSubmit( ( data ) => onSubmit( data ) ) }>
                <div className="flex flex-col gap-2">
                    <h4 className='font-sharp text-xl'>{ t( 'freelancer:account.edit-connect-account.discord' ) }</h4>
                    <InputComponent control={ control }
                        name="discordUserTag"
                        label={ t( 'freelancer:account.edit-connect-account.discordLabel' ) }
                        errors={ errors } />
                </div>

                <div className="flex flex-col gap-2">
                    <h4 className='font-sharp text-xl'>{ t( 'freelancer:account.edit-connect-account.instagram' ) }</h4>
                    <InputComponent control={ control }
                        name="instagramUserTag"
                        label={ t( 'freelancer:account.edit-connect-account.instagramLabel' ) }
                        errors={ errors } />
                </div>

                <div className="flex flex-col gap-2">
                    <h4 className='font-sharp text-xl'>{ t( 'freelancer:account.edit-connect-account.twitter' ) }</h4>
                    <InputComponent control={ control }
                        name="twitterUserTag"
                        label={ t( 'freelancer:account.edit-connect-account.twitterLabel' ) }
                        errors={ errors } />
                </div>
                <div className="flex lg:justify-end">
                    <Button type="primary"
                        htmlType="submit"
                        loading={ updateFreelancerProfileMutation[ 1 ]?.loading }
                        className="mt-4 w-full lg:w-fit">
                        { t( 'common:button.save' ) }
                    </Button>
                </div>
            </Form>
        </div>
    );
}