import { useTranslation } from 'react-i18next';
import { JobPostFormRepository } from '@/pages/client/views/job-post/job-post-form/jobPostFormRepository';
import InputComponent from '@/components/custom-element-form/InputComponent';

interface InputProps {
    control: any;
    errors?: any;
    getValues?: any,
}

export default function JobPostFormBudget( { control, getValues, errors }: InputProps ) {

    const { t } = useTranslation();
    const repository = new JobPostFormRepository();

    return (
        <div>
            <div className='flex items-center gap-1.5'>
                <span className='font-semibold text-lg'>{t('client:jobPost.form.jobAdditionalInfoPage.budget.title')}</span>
                <div className="rounded-3xl px-2 py-1 border-primary-100 border text-grey text-xs">
                    { t( 'client:jobPost.form.optional' ) }
                </div>
            </div>
            <span className='block pb-2 text-grey'>{t('client:jobPost.form.jobAdditionalInfoPage.budget.subtitle')}</span>
            <div className="flex flex-wrap lg:flex-nowrap gap-x-2 gap-y-4">
                <InputComponent control={ control }
                    name="budgetMin"
                    defaultValue={ repository.budgetMin }
                    className="w-full lg:w-1/2"
                    inputClassName="!text-primary-500 font-semibold font-sharp"
                    label={ t( 'client:jobPost.form.jobAdditionalInfoPage.budget.budgetMin' ) }
                    errors={ errors }
                    placeholder="-"
                    type='number'
                    prefix='$'
                    rules={ {
                        min: {
                            value: 0,
                            message: t( 'client:jobPost.form.jobAdditionalInfoPage.budget.minValueError' )
                        },
                    } }
                />

                <InputComponent control={ control }
                    name="budgetMax"
                    defaultValue={ repository.budgetMax }
                    className="w-full lg:w-1/2"
                    inputClassName="!text-primary-500 font-semibold font-sharp"
                    placeholder="-"
                    label={ t( 'client:jobPost.form.jobAdditionalInfoPage.budget.budgetMax' ) }
                    errors={ errors }
                    type='number'
                    prefix='$'
                    rules={ {
                        min: {
                            value: 0,
                            message: t( 'client:jobPost.form.jobAdditionalInfoPage.budget.minValueError' )
                        },
                        validate: value => {
                            const min = getValues('budgetMin');
                            if (min && parseFloat(min) !== 0) {
                                return parseFloat(value) > parseFloat(min) || t('client:jobPost.form.jobAdditionalInfoPage.budget.budgetMaxError');
                            }
                            return true;
                        }
                    } } />
            </div>
        </div>
    );
};
