import ErrorPage from '@/pages/errors/ErrorPage';
import React from 'react';

export const ErrorRouting = [
    {
        path: '404',
        element: <ErrorPage code={ 404 } />
    },
    {
        path: '500',
        element: <ErrorPage code={ 500 } />
    }
];