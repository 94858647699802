import InputComponent from '@/components/custom-element-form/InputComponent';
import { Button } from 'antd';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { CloseOutlinedIcon } from '@/assets/icons/CustomIcons';

interface FreelancerJobPostAppliedCardProps {
    onApply: ( message ) => void;
    onCancel: () => void;
}

export default function FreelancerJobPostAppliedCard( { onApply, onCancel }: FreelancerJobPostAppliedCardProps ) {

    const { t } = useTranslation( 'translation' );
    const { control, handleSubmit } = useForm();

    const handleConfirm = async (data: { message: string }) => {
        onApply( data.message );
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col items-center gap-4 p-6 bg-purple-50 w-full h-full rounded-2xl"
        >
            <div className="flex flex-col gap-2 w-full">
                <div className="flex justify-between">
                    <span className="font-sharp text-lg">{ t('freelancer:jobPosts.jobPostAppliedCard.title') }</span>
                    <button onClick={() => onCancel() } className="primary-default cursor-pointer">
                        <CloseOutlinedIcon className='text-sm text-gray-500' />
                    </button>
                </div>
                <p className="text-grey text-sm">{ t('freelancer:jobPosts.jobPostAppliedCard.description') }</p>
            </div>
            <Form className="flex gap-2 w-full" onSubmit={ handleSubmit( handleConfirm ) }>
                <InputComponent control={ control }
                    className='bg-white flex-1'
                    name="message"
                    placeholder={ t( 'freelancer:jobPosts.jobPostAppliedCard.sendAMessagePlaceholder' ) } />
                <Button
                    type="primary"
                    htmlType='submit'
                >
                    { t('freelancer:jobPosts.jobPostAppliedCard.confirmApplication') }
                </Button>
            </Form>
        </motion.div>
    );
};
