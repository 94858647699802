import { Trans, useTranslation } from 'react-i18next';
import { BankNoteOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Divider } from 'antd';
import { ExperienceTypeEnum, JobPost } from '@/gql/graphql';
import FreelancerProfilePortfolioList from '@/components/freelance-profile/components/FreelancerProfilePortfolioList';
import Paragraphs from '@/components/Paragraphs';
import { priceFormatter } from '@/utils/priceFormatter';
import jobToIcon from '@/utils/jobToIcon';
import JobPostSkillTag from './JobPostSkillsTag';

export interface JobPostDetailJobInfoProps {
    jobPost: JobPost;
}

export default function JobPostDetailJobInfo( {
                                                   jobPost
                                               }: JobPostDetailJobInfoProps ) {

    const { t } = useTranslation();

    return (
        <div className="flex flex-col md:w-4/5 gap-4 md:gap-6">
            <div className="w-full flex gap-4 justify-between">
                <h4 className="text-xl md:text-3xl font-medium font-sharp text-black">
                    { jobPost?.name }
                </h4>
            </div>
            <section className="flex gap-11 w-full">
                <div>
                    <span className="text-grey">
                        { t( 'client:jobPost.card.role' ) }
                    </span>
                    <div>
                        { jobToIcon( jobPost?.role, 'text-xl inline-block' ) }
                        <span className="ml-2 text-xl font-sharp">
                            { t( 'translation:enum.jobs.' + jobPost?.role?.toUpperCase(), { defaultValue: '' } ) }
                        </span>
                    </div>
                </div>
                {
                    jobPost?.priceMax !== null && jobPost?.priceMax != 0 && (
                                           <div>
                            <span className="text-grey">
                                { t( 'client:jobPost.card.budget' ) }
                            </span>
                                               <div className="flex">
                                                   <BankNoteOutlinedIcon className="text-2xl" />
                                                   <span className="ml-2 text-xl font-sharp whitespace-nowrap">
                                    $
                                                       {
                                                           priceFormatter( {
                                                               amount: parseInt( `${ jobPost?.priceMin }` ),
                                                               decimals: false
                                                           } )
                                                       }
                                    -
                                                       {
                                                           priceFormatter( {
                                                               amount: parseInt( `${ jobPost?.priceMax }` ),
                                                               decimals: false
                                                           } )
                                                       }
                                </span>
                                               </div>
                                           </div>
                                       )
                }
            </section>
            <Divider className="my-2" />
            <section className="grid grid-cols-2 md:grid-cols-4 gap-x-6 md:gap-x-11 gap-y-4">

                <div className="flex flex-col flex-1">
                    <span className="text-grey">
                    { t( 'client:jobPost.card.type' ) }
                </span>
                    <div className="text-sm md:text-base font-semibold">
                        {
                            t( 'translation:enum.types.' + jobPost?.type?.toUpperCase(), { defaultValue: t( 'translation:enum.expertises.unknown' ) } )
                        }
                    </div>
                </div>

                <div className="flex flex-col flex-1">
                    <span className="text-grey">{ t( 'client:jobPost.form.reviewPage.experienceRequired' ) }</span>
                    <span className="font-semibold text-sm md:text-base">
                            {
                                jobPost?.experiences?.length > 0 ?
                                jobPost?.experiences?.map( ( experience, index ) => (
                                    <>
                                        <Trans
                                            i18nKey={ t( 'translation:enum.experience.' + experience, { defaultValue: t( 'translation:enum.experience.noRequirements' ) } ) }
                                            components={ [ <span /> ] }
                                        />
                                        { index < jobPost.experiences.length - 1 && ', ' }
                                    </>
                                ) ) :
                                <p>{ t( 'client:jobPost.card.noExperience' ) }</p>
                            }
                        </span>
                </div>

                <div className="flex flex-col flex-1">
                    <span className="text-grey">{ t( 'client:jobPost.form.reviewPage.formatType' ) }</span>
                    <span className="font-semibold text-sm md:text-base">
                            { t( 'translation:enum.formatTypes.' + jobPost?.formatType?.toUpperCase(), { defaultValue: t( 'translation:enum.formatTypes.undefined' ) } ) }
                        </span>
                </div>

                <div className="flex flex-col flex-1">
                    <span className="text-grey">{ t( 'client:jobPost.form.reviewPage.languageRequired' ) }</span>
                    <span className="font-semibold text-sm md:text-base">
                        {
                            jobPost?.languages?.map( ( language ) => (
                                t( 'translation:enum.languages.' + language?.toUpperCase(), { defaultValue: t( 'translation:enum.languages.unknown' ) } )
                            ) ).join( ', ' )
                        }
                    </span>
                </div>

                <div className="flex flex-col flex-1">
                    <span className="text-grey">
                    { t( 'client:jobPost.card.genre' ) }
                </span>
                    <div className="text-sm md:text-base font-semibold">
                        {
                            jobPost?.expertises?.length > 0 ?
                            jobPost?.expertises?.map( ( expertise ) => (
                                t( 'translation:enum.expertises.' + expertise?.toUpperCase(), { defaultValue: t( 'translation:enum.expertises.unknown' ) } )
                            ) ).join( ', ' ) :
                            <p>{ t( 'client:jobPost.card.noGenre' ) }</p>
                        }
                    </div>
                </div>

            </section>

            {
                jobPost?.skills?.length > 0 &&
                (
                    <>
                        <Divider className="my-2" />
                        <section>
                            <span className="text-grey">
                                { t( 'client:jobPost.form.reviewPage.requiredSkills' ) }
                            </span>
                            <div className="flex flex-wrap text-primary-500 gap-2 mt-1">
                                { jobPost.skills?.map( ( skill: ExperienceTypeEnum ) =>
                                    <div key={ skill }>
                                        <JobPostSkillTag skill={ skill }
                                                           className="text-black text-sm py-2 px-3 font-normal border border-blue-100" />
                                    </div>
                                ) }
                            </div>
                        </section>
                    </>
                )
            }

            <Divider className="my-2" />

            {
                jobPost?.description &&
                <section>
                  <h2>{ t( 'freelancer:jobPosts.jobDescription' ) }</h2>
                  <div className="mt-3">
                    <Paragraphs text={ jobPost?.description }
                                maxLength={ 1000 } />
                  </div>
                </section>
            }

            {
                jobPost?.portfolioElements?.length > 0 &&
                <section>
                  <h2>{ t( 'freelancer:jobPosts.youTubeVideosReferences', { count: jobPost?.portfolioElements?.length } ) }</h2>

                  <div className="mt-4">
                    <FreelancerProfilePortfolioList portfolio={ jobPost?.portfolioElements || [] } />
                  </div>
                </section>
            }
        </div>

    );
}