import { Controller } from 'react-hook-form';
import firstLetterUppercaseFormatter from '@/utils/firstLetterUppercaseFormatter';
import { Input } from 'antd';
import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface InputProps {
    domain: string,
    type?: string;
    defaultValue?: string | number;
    className?: string;
    control: any;
    name: string;
    label?: string;
    tooltipLabel?: string;
    disabled?: boolean;
    placeholder: string;
    rules?: any;
    errors?: any;
    suffix?: JSX.Element | string;
}


export default function InputUrlComponent( {
                                               type = 'text',
                                               domain,
                                               defaultValue,
                                               className,
                                               control,
                                               name,
                                               placeholder,
                                               disabled,
                                               rules,
                                               errors,
                                               suffix
                                           }: InputProps ) {
    const [ isFocused, setIsFocused ] = useState( false );
    const inputRef = useRef( null );

    const focusBorder = isFocused ? 'border-black' : 'border-grey-light';
    const errorBorder = errors && errors[ name ] ? 'border-red' : '';

    return (
        <div className={ twMerge( 'w-full', className ) }
             onClick={ () => inputRef.current.focus() }>
            <Controller control={ control }
                        name={ name }
                        defaultValue={ defaultValue }
                        rules={ rules }
                        render={ ( { fieldState: { error }, field: { onChange, onBlur, value, ref } } ) => (
                            <div className="w-full">
                                <div className={ twMerge( 'w-full transition-colors duration-500 ease-in-out border rounded-[10px] flex px-3.5', focusBorder, errorBorder ) }>
                                    <div className={ twMerge( 'text-black border-r pr-4 mr-4 py-2.5 whitespace-nowrap', focusBorder, errorBorder, disabled && 'text-black/25' ) }>
                                        { domain }
                                    </div>
                                    <div className="w-full h-full flex items-center justify-center gap-x-2 py-2.5">
                                        <div className="w-full">
                                            <Input onChange={ onChange }
                                                   ref={ ( e ) => {
                                                       ref( e );
                                                       inputRef.current = e;
                                                   } }
                                                   disabled={ disabled }
                                                   type={ type }
                                                   onBlur={ () => {
                                                       setIsFocused( false );
                                                       onBlur();
                                                   } }
                                                   onFocus={ () => setIsFocused( true ) }
                                                   value={ value }
                                                   className="p-0"
                                                   size="middle"
                                                   status={ error ? 'error' : '' }
                                                   placeholder={ placeholder }
                                                   bordered={ false } />
                                        </div>
                                        {
                                            suffix &&
                                            <>
                                                {
                                                    suffix
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                    errors &&
                                    errors[ name ] &&
                                    (
                                        <div className="w-fit text-sm font-normal text-red mt-1.5">
                                            { firstLetterUppercaseFormatter( errors[ name ].message.toString() ) }
                                        </div>
                                    )
                                }
                            </div>
                        ) }
            />
        </div>
    );
}