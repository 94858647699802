import { gql } from '@apollo/client';

const publicCreatePortfolioChannelGQL = gql`
    mutation PublicCreatePortfolioChannelGQL($url: String!) {
        publicCreatePortfolioChannel(url: $url) {
            id
            name
            videoCount
            viewCount
            subscriberCount
            category
            thumbnailUrl
            freelancerToPortfolioChannels {
                id
                order
            }
        }
    }
`;

export default publicCreatePortfolioChannelGQL;