import React from 'react';
import { ExclamationFilledIcon } from '@/assets/icons/CustomIcons';

const InfoMessage = ({ children }) => {
    return (
        <div className='flex flex-row items-center gap-4 bg-orange-50 rounded-lg py-4 px-3 text-sm'>
            <div className="flex justify-center items-center">
                <div className='rounded-full bg-amber-500/40 p-3'>
                    <div className="flex items-center justify-center p-1 rounded-full bg-amber-500">
                        <ExclamationFilledIcon className="text-white text-[12px] rotate-180" />
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default InfoMessage;
