import { Outlet } from 'react-router-dom';

import { FreelancerHeader } from '@/pages/freelancer/layout/header/FreelancerHeader';
import FreelancerNavBarTouch from '@/pages/freelancer/layout/nav-bar/FreelancerNavBarTouch';
import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import { twMerge } from 'tailwind-merge';

export default function FreelancerRoot() {

    const { isTabletOrMobile, isHeaderVisible, isBottomNavBarVisible } = LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    return (
        <>
            <div className="min-h-screen flex flex-col h-full relative">
                {
                    isHeaderVisible &&
                    <FreelancerHeader />
                }
                <div className={twMerge("main-container flex-grow flex flex-col pt-[72px] md:pt-6", isTabletOrMobile ? 'pb-[85px]' : undefined )}>
                    <Outlet />
                </div>
                {
                    isTabletOrMobile &&
                    isBottomNavBarVisible &&
                    <FreelancerNavBarTouch />
                }
            </div>
        </>
    );
}