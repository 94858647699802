import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlinedIcon } from '@/assets/icons/CustomIcons';
import { Button, Divider, Skeleton } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useIntercomHook } from '@/plugins/intercom/useIntercomHook';
import { useQuery } from '@apollo/client';
import { PaymentSucceededGQL } from '@/gql/global-queries/paymentSucceededGQL';

export default function ClientOrderPaymentSuccess() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { toggleIntercomVisibility } = useIntercomHook();
    const queryParams = new URLSearchParams( location.search );

    const orderId = queryParams.get( 'orderId' );
    const sessionId = queryParams.get( 'session_id' );

    const { loading, error, data } = useQuery( PaymentSucceededGQL, {
        variables: { sessionId },
        skip: !sessionId
    } );

    useEffect( () => {
        if( data && !data?.paymentSucceeded ) {
            navigate( `/client/orders/payment-cancelled${ location.search }` );
        }
    }, [ data ] );

    if( error ) {
        navigate( `/client/orders/payment-cancelled${ location.search }` );
    }

    if( loading ) {
        return <Skeleton />;
    }

    return (
        <div className="flex flex-col gap-6 lg:w-2/5 w-full self-center text-base">
            <div className="flex flex-col items-center justify-center gap-6">
                <div className="w-12 h-12 flex justify-center items-center p-3 rounded-full border-2 border-[#17B26A]">
                    <CheckOutlinedIcon className="text-[#17B26A] text-lg" />
                </div>
                <h1>{ t( 'client:order.orderSuccess.title' ) }</h1>
            </div>
            <div className="flex flex-col gap-3">
                <Divider className="my-0" />
                <div className="flex justify-between">
                    <span className="text-[#71767B]">
                        { t( 'client:order.orderSuccess.orderNumber' ) }
                    </span>
                    <span>{ orderId }</span>
                </div>
                <Divider className="my-0" />
            </div>
            <span className="text-[#71767B]">
                { t( 'client:order.orderSuccess.orderSuccessMessage' ) }
            </span>

            <div className="flex flex-col gap-2">
                <span className="text-[#71767B]">
                    { t( 'client:order.orderSuccess.startUsingFeatures' ) }
                </span>
                <Link to={ '/client/job-posts/status/ongoing' }
                      type="primary">
                    <Button type="primary"
                            className="flex gap-1 items-center w-fit">
                        { t( 'client:order.orderSuccess.goToJobPostsButton' ) }
                    </Button>
                </Link>
            </div>

            <div className="flex flex-col gap-2">
                <span className="text-[#71767B]">
                    { t( 'client:order.orderSuccess.supportDescription' ) }
                </span>
                <Button onClick={ () => toggleIntercomVisibility() }
                        type="default"
                        className="flex gap-1 items-center w-fit">
                    { t( 'client:order.orderSuccess.supportButton' ) }
                </Button>
            </div>
        </div>
    );
}
