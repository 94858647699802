/* eslint-disable indent */
import { useTranslation } from 'react-i18next';
import { RoundedCheckFilledIcon, TrashOutlinedIcon } from '@/assets/icons/CustomIcons';
import { ApplicationTypeEnum, JobPost } from '@/gql/graphql';
import { Button, Divider } from 'antd';
import { DateTime } from 'luxon';
import PopconfirmWrapper from '@/components/PopconfirmWrapper';

export interface JobPostDetailActionsProps {
    jobPost: JobPost;
    onApply?: () => void;
    onDecline?: () => void;
}

export default function JobPostDetailActions( {
                                                   jobPost,
                                                   onApply,
                                                   onDecline
                                               }: JobPostDetailActionsProps ) {

    const { t } = useTranslation();

    return (
        <div className="w-full min-w-fit rounded-2xl border border-blue-100 p-6 flex flex-col gap-4">
            <h4 className="font-semibold text-base">
                { t( 'freelancer:jobPosts.jobPostCard.aboutTheJobPost' ) }
            </h4>

            <Divider className="my-0" />

            <span className="text-zinc-500 text-xs">
                { t( 'client:jobPosts.jobPostCard.postInformation' ) }
            </span>
            <span className="text-zinc-500">
                { t( 'client:jobPosts.jobPostCard.posted', { date: DateTime.fromISO( jobPost?.createdAt ).setLocale( 'en' ).toRelative( { base: DateTime.now() } ) } ) }
            </span>

            <div className="relative flex flex-col gap-4">
                {
                    jobPost?.freelancerStatusForLoggedFreelancer === ApplicationTypeEnum.INTERESTED
                    || jobPost?.freelancerStatusForLoggedFreelancer === ApplicationTypeEnum.FAVORITE ?
                    <span className="italic">{ t( 'freelancer:jobPosts.jobPostCard.alreadyApplied' ) }</span> :
                    jobPost?.freelancerStatusForLoggedFreelancer === ApplicationTypeEnum.DECLINED ?
                    <span className="italic">{ t( 'freelancer:jobPosts.jobPostCard.declined' ) }</span> :
                    <>
                        <Button type="primary"
                                className="w-full text-sm mt-4 lg:mt-0"
                                icon={ <RoundedCheckFilledIcon className="text-sm text-white" /> }
                                onClick={ () => onApply() }>
                            { t( 'freelancer:jobPosts.jobPostCard.apply' ) }
                        </Button>
                        <PopconfirmWrapper title={ t( 'freelancer:jobPosts.jobPostCard.declinePopUp.title' ) }
                                           description={ t( 'freelancer:jobPosts.jobPostCard.declinePopUp.description' ) }
                                           onConfirm={ () => onDecline() }
                                           okText={ t( 'common:button.confirm' ) }>
                            <Button type="default"
                                    icon={ <TrashOutlinedIcon className="text-sm" /> }
                                    className="w-full min-w-fit text-sm ">
                                { t( 'freelancer:jobPosts.jobPostCard.notInterested' ) }
                            </Button>
                        </PopconfirmWrapper>
                    </>
                }
            </div>
        </div>
    );
}