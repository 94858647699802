import { JobPostFormRepository } from '@/pages/client/views/job-post/job-post-form/jobPostFormRepository';
import SelectMultiTags from '@/components/SelectMultiTags';
import { useTranslation } from 'react-i18next';
import jobToIcon from '@/utils/jobToIcon';
import { Controller } from 'react-hook-form';
import { JobEnum } from '@/gql/graphql';

interface InputProps {
    control: any;
    errors?: any;
    onChange?: (title: string, value: any) => void;
}

export default function JobPostFormJob( { control, errors, onChange: onChangeMethod }: InputProps ) {

    const { t } = useTranslation();
    const repository: JobPostFormRepository = new JobPostFormRepository();

    const handleOnChangeJobSelected = ( role: string[] ) => {        
        onChangeMethod( 'role', role[0] );
    };

    return (
        <>
            <Controller
                name='role'
                control={ control }
                rules={{ required: true }}
                render={() => (
                <SelectMultiTags
                    enableMainTag={ false }
                    onTagsSelected={ ( value ) => handleOnChangeJobSelected( value ) }
                    limit={ 1 }
                    tags={ Object.values( JobEnum ).map( ( job ) => ( {
                        id: job,
                        label: (
                            <div className="flex items-center gap-x-2">
                                { jobToIcon( job, 'text-lg' ) }
                                <span>
                                    { t( 'translation:enum.jobs.' + job ) }
                                </span>
                            </div>
                        )
                    } ) ) }
                    defaultSelectedIds={ [ repository.role ] } />
                )}
            />
            { errors.role &&
                <div className="w-fit text-sm font-normal text-red mt-1.5">
                    {t('common:error.form.required')}
                </div>
            }
        </>
            
    );
};