import { useTranslation } from 'react-i18next';
import SkeletonInput from 'antd/es/skeleton/Input';
import JobPostDetailHeader from './component/JobPostDetailHeader';
import { BackOutlinedIcon } from '@/assets/icons/CustomIcons';
import JobPostDetailActions from './component/JobPostDetailActions';
import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import JobPostDetailJobInfo from './component/JobPostDetailJobInfo';
import { JobPost } from '@/gql/graphql';

interface JobPostDetailProps {
    jobPost: JobPost;
    isLoading: boolean;
    onDecline?: () => void;
    onApply?: () => void;
}

export default function JobPostDetail( {
                                            isLoading,
                                            jobPost,
                                            onDecline,
                                            onApply
                                        }: JobPostDetailProps ) {

    const navigate = useNavigate();
    const { status } = useParams();
    const { t } = useTranslation();

    if( isLoading ) {
        return (
            <div className="flex flex-col items-center gap-y-12">
                <SkeletonInput active={ true }
                               className="w-full h-[210px] rounded-2xl" />
                <SkeletonInput active={ true }
                               className="w-full h-[900px] rounded-2xl" />
            </div>
        );
    }


    return (
        <div className="flex flex-col">
            <Button type="text"
                    className="text-grey w-fit flex gap-1 leading-none hover:bg-inherit hover:text-primary-500"
                    onClick={ () => navigate( `/freelancer/job-posts/${ status }` ) }>
                <BackOutlinedIcon />
                { t( 'common:button.back' ) }
            </Button>
            <div className="flex flex-col gap-y-4 md:gap-y-12 mt-2 lg:mt-0">
                <JobPostDetailHeader jobPost={ jobPost } />
                <div className="flex flex-col lg:flex-row gap-4 md:gap-8">
                    <JobPostDetailJobInfo jobPost={ jobPost } />
                    <div className="w-full md:w-3/12">
                        <JobPostDetailActions onApply={ onApply }
                                              onDecline={ onDecline }
                                              jobPost={ jobPost } />
                    </div>
                </div>
            </div>
        </div>
    );
};