import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import { useEffect, useState } from 'react';
import MenuSection from '@/components/MenuSection';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import freelancerJobPostsHook from '@/hooks/freelancerJobPostsHook';
import FreelancerJobPostList from './components/FreelancerJobPostList';
import { JobPostOrderByEnum, JobPostsFreelancerStatusEnum } from '@/gql/graphql';
import ToggleSwitch from '@/components/ToggleSwitch';
import Pagination from '@/components/Pagination';
import { useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { App } from 'antd';
import { scrollToTop } from '@/utils/scrollTop';
import { createApplicationGQL, declineApplicationGQL } from '@/gql/global-queries';
import { LockerFilledIcon, SparklesOutlinedIcon } from '@/assets/icons/CustomIcons';
import { jobPostOriginTypeName, PageName, SegmentEvent, trackPageView, trackSegmentEvent } from '@/utils/analytics';
import { SessionRepository } from '@/core/auth/sessionRepository';
import CheckIfProfileIsCompletedGQL from '@/gql/global-queries/checkIfProfileIsCompletedGQL';
import NoDataIcon from '@/components/NoDataIcon';
import ListSkeleton from '@/components/ListSkeleton';

export default function FreelancerJobPosts() {

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: 'isTabletOrMobile'
    } );

    const { status } = useParams();
    const { t } = useTranslation();
    const session: SessionRepository = new SessionRepository();
    const { message } = App.useApp();

    const [ canAccessToJobPosts, setCanAccessToJobPosts ] = useState( false );
    const [ selectedStatus, setSelectedStatus ] = useState( null );
    const [ selectedOrderBy, setSelectedOrderBy ] = useState<JobPostOrderByEnum>( JobPostOrderByEnum.CREATED_AT );
    const [ forMeFilterValue, setForMeFilterValue ] = useState( false );
    const [ sectionTitle, setSectionTitle ] = useState<string>( '' );

    const checkIfProfileIsCompleteQuery = useQuery( CheckIfProfileIsCompletedGQL ); // todo refacto cette partie pour le mettre dans la session
    const createApplicationMutation = useMutation( createApplicationGQL );
    const declineApplicationMutation = useMutation( declineApplicationGQL );

    const {
              loadJobPosts,
              refetch,
              jobPosts,
              loading: isLoadingData,
              total,
              page
          } = freelancerJobPostsHook( { limit: 10 } );

    useEffect( () => {
        trackPageView( PageName.FreelancerJobPosts );
    }, [] );

    useEffect( () => {
        if( checkIfProfileIsCompleteQuery.data ) {
            setCanAccessToJobPosts( checkIfProfileIsCompleteQuery.data.checkIfProfileIsCompleted.status == 'APPROVED' );
        }
    }, [ checkIfProfileIsCompleteQuery ] );

    useEffect( () => {
        if( status != null && canAccessToJobPosts ) {
            let statusEnum: JobPostsFreelancerStatusEnum;
            let orderByEnum: JobPostOrderByEnum;
            let title: string;

            switch( status ) {
                case 'all':
                    statusEnum = JobPostsFreelancerStatusEnum.ALL;
                    title = t( 'freelancer:jobPosts.menu.all' );
                    orderByEnum = JobPostOrderByEnum.CREATED_AT;
                    break;
                case 'applied':
                    statusEnum = JobPostsFreelancerStatusEnum.APPLIED;
                    title = t( 'freelancer:jobPosts.menu.applied' );
                    orderByEnum = JobPostOrderByEnum.CREATED_AT;
                    break;
                case 'not-interested':
                    statusEnum = JobPostsFreelancerStatusEnum.DECLINED;
                    title = t( 'freelancer:jobPosts.menu.notInterested' );
                    orderByEnum = JobPostOrderByEnum.DECLINED_AT;
                    break;
                default:
                    statusEnum = JobPostsFreelancerStatusEnum.ALL;
                    orderByEnum = JobPostOrderByEnum.CREATED_AT;
            }

            scrollToTop();

            loadJobPosts( {
                status: statusEnum,
                orderBy: orderByEnum
            } );

            setSelectedStatus( statusEnum );
            setSelectedOrderBy( orderByEnum );
            setForMeFilterValue( false );
            setSectionTitle( title );
        }
    }, [ status, canAccessToJobPosts ] );

    const handlePageChange = ( value ) => {
        loadJobPosts( {
            status: selectedStatus,
            orderBy: selectedOrderBy,
            forMe: forMeFilterValue,
            page: value
        } );
    };

    const handleForMeFilterChange = () => {
        loadJobPosts( {
            status: selectedStatus,
            orderBy: selectedOrderBy,
            forMe: !forMeFilterValue,
            page: null
        } );

        setForMeFilterValue( !forMeFilterValue );
    };

    const handleJobPostApply = async( jobPost, freelancerMessage = null ) => {
        const args = {
            jobPostId: jobPost.id,
            message: freelancerMessage
        };

        try {
            const response = await createApplicationMutation[ 0 ]( {
                variables: {
                    args
                }
            } );

            if( response.data.createApplicationForFreelancer ) {
                await refetch();
                message.success( t( 'freelancer:jobPost.applicationSuccess' ) );
                trackSegmentEvent(
                    SegmentEvent.FreelancerApplied,
                    {
                        freelancer_first_name: session?.user.firstName,
                        freelancer_last_name: session?.user.lastName,
                        freelancer_id: session?.user.id,
                        job_post_id: jobPost.id,
                        superliked: false,
                        message_added: !!freelancerMessage,
                        apply_origin: jobPostOriginTypeName.Listing
                    }
                );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to apply to a job offer', e );
            message.error( t( 'common:form.submitError' ) );
        }
    };

    const handleJobPostDecline = async( value ) => {
        const args = value.id;

        try {
            const response = await declineApplicationMutation[ 0 ]( {
                variables: {
                    args
                }
            } );

            if( response.data ) {
                await refetch();
                trackSegmentEvent(
                    SegmentEvent.FreelancerNotInterested,
                    {
                        freelancer_id: session.user?.id,
                        job_post_id: value.id,
                        rejected_origin: jobPostOriginTypeName.Listing
                    }
                );
            }
        } catch( e ) {
            Sentry.captureException( 'An error occur when trying to decline a job offer', e );
            message.error( t( 'common:form.submitError' ) );
        }
    };

    const nav = [
        {
            title: t( 'freelancer:jobPosts.menu.all' ),
            value: JobPostsFreelancerStatusEnum.ALL,
            route: '/freelancer/job-posts/all'
        },
        {
            title: t( 'freelancer:jobPosts.menu.applied' ),
            value: JobPostsFreelancerStatusEnum.APPLIED,
            route: '/freelancer/job-posts/applied'
        },
        {
            title: t( 'freelancer:jobPosts.menu.notInterested' ),
            value: JobPostsFreelancerStatusEnum.DECLINED,
            route: '/freelancer/job-posts/not-interested'
        }
    ];

    const toggleFilterOnContent = <div>{ t( 'freelancer:jobPosts.filters.all' ) }</div>;
    const toggleFilterOffContent = <div className="flex items-center gap-1">
        <SparklesOutlinedIcon className="text-lg" /> { t( 'freelancer:jobPosts.filters.forYou' ) }</div>;

    // todo découper la page pour créer un composant parent avec la navigation et un enfant avec les job posts

    return (
        <div className="w-full flex flex-col md:flex-row relative">
            <div className="md:w-[180px] w-full ">
                <MenuSection routes={ nav } />
            </div>
            <div className="mt-6 lg:mt-0 w-full lg:w-10/12 flex flex-col gap-6 lg:ml-20 mb-5">
                <div className="hidden lg:block font-medium text-2xl font-sharp">
                    { sectionTitle }
                </div>
                {
                    selectedStatus === JobPostsFreelancerStatusEnum.ALL &&
                    <ToggleSwitch isOn={ forMeFilterValue }
                                  onClick={ () => handleForMeFilterChange() }
                                  onContent={ toggleFilterOnContent }
                                  offContent={ toggleFilterOffContent } />
                }
                {
                    checkIfProfileIsCompleteQuery.loading ?
                    <ListSkeleton isLoading={ true }
                                  height={ 200 }
                                  count={ 4 } /> :
                    canAccessToJobPosts ?
                    <>
                        <FreelancerJobPostList jobPosts={ jobPosts }
                                               onJobPostApply={ handleJobPostApply }
                                               onJobPostDecline={ handleJobPostDecline }
                                               status={ selectedStatus }
                                               isLoading={ isLoadingData } />
                        {
                            total && page ?
                            <Pagination pageSize={ 10 }
                                        currentPageNumber={ page }
                                        itemCount={ total }
                                        changePage={ handlePageChange } />
                                          : null
                        }
                    </> :
                    <div className="w-full flex items-center justify-center">
                        <NoDataIcon className="mt-4 md:mt-10"
                                    icon={
                                        <div className="rounded-full border border-primary-500 flex items-center justify-center p-2">
                                            <LockerFilledIcon className="text-4xl text-primary-500" />
                                        </div>
                                    }
                                    message={ t( 'freelancer:jobPosts.accessRestricted.title' ) }
                                    subMessage={ t( 'freelancer:jobPosts.accessRestricted.description' ) }
                                    button={ {
                                        to: '/freelancer/getting-started',
                                        text: t( 'freelancer:jobPosts.accessRestricted.button' )
                                    } } />
                    </div>
                }
            </div>
        </div>
    );
}