import { gql } from '@apollo/client';

const createCustomUrlGQL = gql`
    mutation CreateCustomUrlGQL($url: String!) {
        createCustomUrl(name: $url) {
            id
        }
    }
`;

export default createCustomUrlGQL;