import { Button } from 'antd';
import { AlignBottomArrowOutlinedIcon, AlignTopArrowOutlinedIcon } from '@/assets/icons/CustomIcons';

interface FreelancerProfileEditPortfolioArrowOrderProps {
    allowMoveUp: boolean;
    allowMoveDown: boolean;
    onMoveUp: () => void;
    onMoveDown: () => void;
}

export default function FreelancerProfileEditPortfolioArrowOrder( {
                                                                      allowMoveUp,
                                                                      allowMoveDown,
                                                                      onMoveDown,
                                                                      onMoveUp
                                                                  }: FreelancerProfileEditPortfolioArrowOrderProps ) {

    return (
        <div className="flex space-x-4 items-center">
            <Button icon={ <AlignTopArrowOutlinedIcon className="text-xl text-inherit" /> }
                    type="link"
                    className="p-0 border-none w-fit text-grey hover:text-primary-400"
                    onClick={ onMoveUp }
                    disabled={ !allowMoveUp } />
            <Button icon={ <AlignBottomArrowOutlinedIcon className="text-xl text-inherit"/> }
                    type="link"
                    className="p-0 border-none w-fit text-grey hover:text-primary-400"
                    onClick={ onMoveDown }
                    disabled={ !allowMoveDown } />
        </div>
    );
}