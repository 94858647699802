import { JobPostStatusEnum } from '@/gql/graphql';
import { Avatar, Button } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ChainLinkFilledIcon, IncognitoFilledIcon, ProfileFilledIcon, ShareOutlinedIcon } from '@/assets/icons/CustomIcons';
import jobToIcon from '@/utils/jobToIcon';
import { jobPostLinkHook } from '@/hooks/jobPostLinkHook';
import { useNavigate } from 'react-router-dom';

export interface ClientJobPostCardInterface {
    id: string;
    status: JobPostStatusEnum;
    name: string;
    channel?: {
        thumbnailUrl: string;
        name: string;
    };
    role: string;
    totalInterestedTalents: number;
    createdAt: string;
}

interface ClientJobPostCardProps {
    jobPost?: ClientJobPostCardInterface;
    onAuthenticate: ( jobPost ) => void;
    className?: string;
}

export default function ClientJobPostCard( {
                                                jobPost,
                                                onAuthenticate,
                                                className = ''
                                            }: ClientJobPostCardProps ) {
    const { t } = useTranslation( 'translation' );
    const navigate = useNavigate();

    const { getJobPostLink } = jobPostLinkHook();

    const handleShareJobPost = ( event ) => {
        event.preventDefault();
        getJobPostLink( jobPost.id );
    };

    const handleAuthenticate = ( event ) => {
        event.preventDefault();
        onAuthenticate( jobPost );
    };

    const handleReviewTalent = ( event ) => {
        event.preventDefault();
        navigate( `/client/job-posts/${ jobPost.id }/notified` );
    };

    return (
        <div className={ `card-shadow w-full group rounded-2xl bg-white cursor-pointer border border-grey-light p-4 flex flex-col lg:flex-row ${ className }` }>
            <div className="w-full flex flex-col gap-4">
                <div className="flex flex-col gap-3">
                    <div className="w-full flex flex-col md:flex-row gap-y-1 md:gap-x-4 justify-between">
                        <h4 className="text-lg font-medium font-sharp text-black">
                            { jobPost?.name }
                        </h4>
                        <span className="flex-none text-zinc-500 whitespace-nowrap">
                            { t( 'client:jobPosts.jobPostCard.posted', { date: DateTime.fromISO( jobPost?.createdAt ).setLocale( 'en' ).toRelative( { base: DateTime.now() } ) } ) }
                        </span>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:items-center gap-x-4 font-medium lg:gap-y-0 gap-y-2">
                        <div className="flex items-center">
                            <Avatar src={ jobPost?.channel?.thumbnailUrl }
                                    icon={ <IncognitoFilledIcon /> }
                                    className={ `flex-none ${ !jobPost?.channel?.thumbnailUrl && 'bg-black' }` }
                                    size={ 20 } />
                            <span className="ml-2">
                                { jobPost?.channel?.name || t( 'client:jobPosts.jobPostCard.anonymous' ) }
                            </span>
                        </div>
                        <div>
                            <div className="text-primary-500 max-w-[250px]">
                                { jobToIcon( jobPost?.role?.toUpperCase(), 'text-md inline-block mr-1' ) }
                                <span>
                                    { t( 'translation:enum.jobs.' + jobPost?.role?.toUpperCase(), { defaultValue: '' } ) }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-start lg:justify-between items-center">
                    <div>
                        {
                            jobPost?.totalInterestedTalents > 0 &&
                            (
                                <Trans i18nKey="client:jobPosts.jobPostCard.interestedTalent"
                                       count={ jobPost?.totalInterestedTalents }>
                                    <strong className="font-sharp text-xl"></strong>
                                    <span className="text-sm text-zinc-500"></span>
                                </Trans>
                            )
                        }
                    </div>
                    <div className="hidden md:block md:w-auto w-full">
                        {
                            jobPost.status == JobPostStatusEnum.IN_PROGRESS &&
                            (
                                <div className="flex gap-2 whitespace-nowrap lg:flex-row flex-col mt-4 lg:mt-0">
                                    <>
                                        <Button type="default"
                                                className="w-full text-sm lg:w-fit mt-0 transition-all duration-500 md:opacity-0 md:group-hover:opacity-100"
                                                onClick={ ( e ) => {
                                                    handleShareJobPost( e );
                                                } }>
                                            <ShareOutlinedIcon className="text-xs" />
                                            { t( 'client:jobPosts.jobPostCard.shareJobPost' ) }
                                        </Button>

                                        { jobPost.channel ?
                                          <Button type="primary"
                                                  className="w-full text-sm lg:w-fit mt-0 transition-all duration-500 md:opacity-0 md:group-hover:opacity-100"
                                                  onClick={ ( e ) => {
                                                      handleReviewTalent( e );
                                                  } }>
                                              <ProfileFilledIcon className="text-sm text-white" />
                                              { t( 'client:jobPosts.jobPostCard.reviewTalent' ) }
                                          </Button> :
                                          <Button type="default"
                                                  className="w-full text-sm lg:w-fit mt-0 transition-all duration-500 md:opacity-0 md:group-hover:opacity-100"
                                                  onClick={ ( e ) => {
                                                      handleAuthenticate( e );
                                                  } }>
                                              <ChainLinkFilledIcon />
                                              { t( 'client:jobPosts.jobPostCard.pairChannel' ) }
                                          </Button>
                                        }
                                    </>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}