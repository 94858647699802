import { gql } from '@apollo/client';

const createJobPostGQL = gql`
    mutation CreateJobPostQL($args: CreateJobPostInput!) {
        createJobPost(args: $args) {
            id
        }
    }
`;

export default createJobPostGQL;