import LayoutVisibilityHook from '@/hooks/layoutVisibilityHook';
import BackButton from '@/components/BackButton';
import { GoBackFilledIcon } from '@/assets/icons/CustomIcons';
import ErrorBoundary from '@/pages/errors/ErrorBoundary';
import ProfileEditInformationDrawer from '@/pages/freelancer/views/profile/profile-edit-information/ProfileEditInformationDrawer';
import { Link, useLocation } from 'react-router-dom';
import { Application, SkillEnum } from '@/gql/graphql';
import { Button, Skeleton } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import FreelancerProfileFeaturedSection from '@/components/freelance-profile/components/FreelancerProfileFeaturedSection';
import FreelancerProfileCreatorIWorkedWithSection from '@/components/freelance-profile/components/FreelancerProfileCreatorIWorkedWithSection';
import FreelancerPortfolioSelectedSection from '@/components/freelance-profile/FreelancerProfilePortfolioSelectedSection';
import { useTranslation } from 'react-i18next';
import FreelancerMainInfo from '@/components/freelance-profile/components/FreelancerMainInfo';
import FreelancerProfileTagList from '@/components/freelance-profile/components/FreelancerProfileTagList';
import Paragraphs from '@/components/Paragraphs';
import SkeletonInput from 'antd/es/skeleton/Input';
import freelancerProfilePageHook from '@/hooks/freelancerProfilePageHook';
import { useEffect } from 'react';

interface FreelancerProfileProps {
    id: string;
    application?: Application;
    editable?: boolean;
    goBackButton?: boolean;
}

export default function FreelancerProfile( {
                                               id,
                                               editable = false,
                                               goBackButton = false,
                                               application
                                           }: FreelancerProfileProps ) {
    const { t } = useTranslation();
    const location = useLocation();

    useEffect( () => {
        const params = new URLSearchParams( location.search );
        if( params.get( 'edit' ) === 'open' ) {
            setIsEditDrawerOpened( true );
        }
    }, [ location.search ] );

    useEffect( () => {
        if( id ) {
            setInitialFreelancerId( id );
        }
    }, [ id ] );

    LayoutVisibilityHook( {
        headerVisible: true,
        bottomNavBarVisible: false
    } );

    const {
              setInitialFreelancerId,
              freelancerProfile,
              portfolioChannels,
              featured,
              selected,
              isEditDrawerOpened,
              setIsEditDrawerOpened,
              loadingMainInfo,
              loadingChannels,
              loadingFeatured,
              loadingSelected,
              width,
              refetch,
              error,
              portfolioRef
          } = freelancerProfilePageHook();

    const handleBack = () => window.history.back();

    if( error ) {
        return <ErrorBoundary />;
    }

    return (
        <>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
                <div className="flex-none w-full md:w-[345px]">
                    {
                        goBackButton &&
                        <BackButton onClick={ () => handleBack() }
                                    icon={ <GoBackFilledIcon /> }
                                    className="w-fit mb-2" />
                    }
                    <div className="flex flex-col gap-y-6 md:gap-y-10 mt-2">
                        <div>
                            {
                                editable &&
                                <Button type="default"
                                        size="small"
                                        onClick={ () => editable && setIsEditDrawerOpened( true ) }
                                        className="w-fit border-primary-200, bg-primary-100 text-primary-500 mb-4">
                                  <EditOutlined className="text-primary-500" />
                                    { t( 'freelancer:dashboard.profile.editYourProfileInfo' ) }
                                </Button>
                            }
                            <div className="rounded-3xl border border-blue-100 w-full p-4 lg:p-6">
                                {
                                    loadingMainInfo ?
                                    <SkeletonInput active
                                                   className="w-full h-[424px] rounded-3xl " /> :
                                    <FreelancerMainInfo freelancer={ freelancerProfile }
                                                        onSuperLike={ () => refetch( { id } ) }
                                                        application={ application } />
                                }
                            </div>
                        </div>

                        <FreelancerProfileFeaturedSection featured={ featured }
                                                          isLoading={ loadingFeatured }
                                                          editable={ editable }
                                                          className="md:hidden" />

                        <FreelancerProfileCreatorIWorkedWithSection loadingChannels={ loadingChannels }
                                                                    portfolioChannels={ portfolioChannels }
                                                                    selected={ selected }
                                                                    width={ width }
                                                                    className="md:hidden" />

                        <section className="w-full">
                            <h2>{ t( 'common:freelancerProfile.card.skillsTitle' ) }</h2>
                            <div className="mt-2">
                                {
                                    loadingMainInfo ?
                                    <Skeleton active /> :
                                    <FreelancerProfileTagList tags={ freelancerProfile?.skills?.map( ( skill: SkillEnum ) => t( 'enum.skills.' + skill ) ) }
                                                              limit={ 20 }
                                                              editable={ editable }
                                                              noTagsMessageNotEditable={ t( 'freelancer:account.edit-profile.noSkills' ) }
                                                              addFirstTag={ () => editable && setIsEditDrawerOpened( true ) }
                                                              noTagsMessageEditable={ t( 'freelancer:account.edit-profile.addSkills' ) }
                                                              className="text-black text-sm font-normal	 px-2 py-1"
                                                              color="#C1DEFF" />
                                }
                            </div>
                        </section>

                        <section className="w-full">
                            <h2>{ t( 'common:freelancerProfile.card.expertisesTitle' ) }</h2>
                            <div className="mt-2">
                                {
                                    loadingMainInfo ?
                                    <Skeleton active /> :
                                    <FreelancerProfileTagList tags={ freelancerProfile?.expertises?.map( ( expertise ) => t( 'enum.expertises.' + expertise ) ) }
                                                              limit={ 20 }
                                                              editable={ editable }
                                                              noTagsMessageNotEditable={ t( 'freelancer:account.edit-profile.noContentSpecialities' ) }
                                                              addFirstTag={ () => editable && setIsEditDrawerOpened( true ) }
                                                              noTagsMessageEditable={ t( 'freelancer:account.edit-profile.addPreferredGenres' ) }
                                                              className="text-black text-sm font-normal	 px-2 py-1"
                                                              color="#E6E9F0" />
                                }
                            </div>
                        </section>


                        <section>
                            <h2>{ t( 'common:freelancerProfile.card.bio' ) }</h2>
                            {
                                loadingMainInfo ?
                                <Skeleton active /> :
                                <Paragraphs text={ freelancerProfile?.bio }
                                            editable={ editable }
                                            noText={ t( 'transverse:freelancers.profile.noBio' ) }
                                            noBioMessage={ t( 'freelancer:account.edit-profile.addBio' ) }
                                            editBio={ () => editable && setIsEditDrawerOpened( true ) }
                                            showMoreButtonText={ t( 'common:button.showMoreButton' ) }
                                            className="mt-2"
                                            maxLength={ 1000 } />
                            }
                        </section>
                    </div>
                </div>
                <div className="grow">
                    <div ref={ portfolioRef }
                         className="flex flex-col space-y-8">
                        <div className="hidden md:block">
                            {
                                editable &&
                                <Link to="/freelancer/profile/edit-portfolio">
                                  <Button type="default"
                                          size="small"
                                          className="w-fit border-primary-200, bg-primary-100 text-primary-500 mb-4">
                                    <EditOutlined className="text-primary-500" />
                                      { t( 'freelancer:dashboard.profile.editYourPortfolio' ) }
                                  </Button>
                                </Link>
                            }

                            <FreelancerProfileFeaturedSection featured={ featured }
                                                              isLoading={ loadingFeatured }
                                                              editable={ editable } />
                        </div>


                        <FreelancerProfileCreatorIWorkedWithSection loadingChannels={ loadingChannels }
                                                                    portfolioChannels={ portfolioChannels }
                                                                    selected={ selected }
                                                                    width={ width }
                                                                    className="hidden md:block" />

                        <FreelancerPortfolioSelectedSection portfolioElement={ selected }
                                                            isLoading={ loadingSelected }
                                                            editable={ editable } />
                    </div>
                </div>
            </div>
            <ProfileEditInformationDrawer isOpen={ isEditDrawerOpened }
                                          onClose={ () => {
                                              setIsEditDrawerOpened( false );
                                              refetch( { id } );
                                          } } />
        </>
    );
};