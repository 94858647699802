import { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import './scrollable-container.scss';
import { ChevronLeftFilledIcon, ChevronRightFilledIcon } from '@/assets/icons/CustomIcons';
import { twMerge } from 'tailwind-merge';

interface ScrollableContainerProps {
    children: React.ReactNode;
    className?: string;
}

function ScrollableContainer( { className, children }: ScrollableContainerProps ) {
    const [ showLeft, setShowLeft ] = useState( false );
    const [ showRight, setShowRight ] = useState( false );
    const containerRef = useRef( null );

    const handlers = useSwipeable( {
        onSwipedLeft: () => handleScroll( 'right' ),
        onSwipedRight: () => handleScroll( 'left' ),
        trackMouse: true
    } );

    useEffect( () => {
        const container = containerRef.current;
        const updateButtonsVisibility = () => {
            if( container ) {
                setShowLeft( container.scrollLeft > 0 );
                setShowRight( container.scrollLeft < container.scrollWidth - container.clientWidth );
            }
        };

        // Initial check and setup event listener for scroll
        if( container ) {
            updateButtonsVisibility();
            container.addEventListener( 'scroll', updateButtonsVisibility );
        }

        // Cleanup the event listener
        return () => {
            if( container ) {
                container.removeEventListener( 'scroll', updateButtonsVisibility );
            }
        };
    }, [ containerRef ] );

    const handleScroll = ( direction ) => {
        if( containerRef.current ) {
            containerRef.current.scrollBy( {
                left: direction === 'left' ? -414 : 414,
                behavior: 'smooth'
            } );
        }
    };

    return (
        <div { ...handlers }
             className={ twMerge( 'scrollable-container gap-x-6', className ) }>
            { showLeft && (
                <button onClick={ () => handleScroll( 'left' ) }>
                    <ChevronLeftFilledIcon className="text-[24px]" />
                </button>
            ) }
            <div className="scroll-wrapper flex-none"
                 ref={ containerRef }>
                { children }
            </div>
            { showRight && (
                <button onClick={ () => handleScroll( 'right' ) }>
                    <ChevronRightFilledIcon className="text-[24px]" />
                </button>
            ) }
        </div>
    );
}


export default ScrollableContainer;
