import { ClockRotateOutlinedIcon, ClockTimerOutlinedIcon, DollarSheetOutlinedIcon } from '@/assets/icons/CustomIcons';
import { ContractTypeEnum } from '@/gql/graphql';

export default function jobTypeToIcon( job: ContractTypeEnum ) {
    switch( job ) {
        case 'FULL_TIME':
            return <DollarSheetOutlinedIcon className="text-lg" />;
        case 'PROJECT':
            return <ClockTimerOutlinedIcon className="text-lg" />;
        case 'PART_TIME':
            return <ClockRotateOutlinedIcon className="text-lg" />;
        default:
            return null;
    }
}