import { gql } from '@apollo/client';


const portfolioElementsGQL = gql`
    query PortFolioElementsGQL($freelancerId: String!, $args: FindAllPortfolioElementFiltersInput, $limit: Int, $page: Int) {
        portfolioElements(freelancerId: $freelancerId, args: $args, limit: $limit, page: $page) {
            data {
                id
                type
                title
                url
                thumbnailUrl
                segment
                order
                viewCount
                publishedAt
                freelancerId
                portfolioChannel {
                    id
                    thumbnailUrl
                    name
                    subscriberCount
                    viewCount
                    videoCount
                }
            }
            total
            lastPage
            limit
            page
        }
    }`;

export default portfolioElementsGQL;

