import { useEffect, useRef, useState } from 'react';

interface FreelancerCreatorsNameListProps {
    names: string[];
    availableWidth: number;
}

export default function FreelancerCreatorsNameList( { names, availableWidth }: FreelancerCreatorsNameListProps ) {
    const cardRef = useRef( null );
    const [ visibleNames, setVisibleNames ] = useState( [] );
    const [ overflowCount, setOverflowCount ] = useState( 0 );

    useEffect( () => {
        if( !cardRef.current ) {
            return;
        }

        const cardWidth: number = availableWidth;

        // Create an off-screen div to measure text
        const measureDiv = document.createElement( 'div' );
        measureDiv.style.position = 'absolute';
        measureDiv.style.left = '-9999px';
        measureDiv.style.whiteSpace = 'nowrap';
        document.body.appendChild( measureDiv );

        let tempNames = [];
        let hiddenCount = 0;
        let currentWidth = 0;

        names.forEach( ( name ) => {
            measureDiv.textContent = name;
            const nameWidth = measureDiv.offsetWidth;

            measureDiv.textContent = `+${ hiddenCount + 1 }`;
            const overflowWidth = measureDiv.offsetWidth;

            if( currentWidth + nameWidth + overflowWidth <= cardWidth ) {
                tempNames.push( name );
                currentWidth += nameWidth;
            }
            else {
                hiddenCount++;
            }
        } );

        setVisibleNames( tempNames );
        setOverflowCount( hiddenCount );

        // Cleanup
        document.body.removeChild( measureDiv );
    }, [ names ] );

    return (
        <div className="relative overflow-hidden whitespace-nowrap font-normal truncate"
             ref={ cardRef }>
            {
                visibleNames.join( ', ' )
            }
            {
                overflowCount > 0 &&
                <span className="ml-1">
                    +{ overflowCount }
                </span>
            }
        </div>
    );
};

