import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Tag } from 'antd';

interface JobPostSkillTagProps {
    skill: string;
    bordered?: boolean;
    className?: string;
    iconClassName?: string;
}

export default function JobPostSkillTag( {
    skill,
    bordered,
    className,
}: JobPostSkillTagProps ) {

    const { t } = useTranslation();

    const [ _skill, setExperience ] = useState<string>();

    useEffect( () => {
        if( _skill == null ) {
            setExperience( skill );
        }
    }, [ skill ] );

    return (
        <Tag bordered={ bordered }
             className={ className }>
            <span>
                { t( 'translation:enum.skills.' + _skill?.toUpperCase(), { defaultValue: t( 'translation:enum.skills.unknown' ) } ) }
            </span>
        </Tag>
    );
}