import { PortfolioElement, PortfolioElementsPaginatedOutput, SegmentPortfolioElementEnum } from '@/gql/graphql';
import ListSkeleton from '@/components/ListSkeleton';
import NoDataIcon from '@/components/NoDataIcon';
import { useTranslation } from 'react-i18next';
import FreelancerProfileEditPortfolioItem from '@/pages/freelancer/views/profile/profile-edit-portfolio/components/FreelancerProfileEditPortfolioItem';
import { twMerge } from 'tailwind-merge';
import Pagination from '@/components/Pagination';
import React from 'react';

interface FreelancerProfileEditPortfolioBucketProps {
    portfolioElementsPaginated: PortfolioElementsPaginatedOutput;
    className?: string;
    isLoading?: boolean;

    onSegmentChange?( portfolioElementId: string, segment: SegmentPortfolioElementEnum ): void;

    onPageChange?( page: number ): void;

    onDeleteItem?( portfolioElement: PortfolioElement ): void;
}

export default function FreelancerProfileEditPortfolioBucket( {
                                                                  portfolioElementsPaginated,
                                                                  className,
                                                                  isLoading,
                                                                  onDeleteItem,
                                                                  onPageChange,
                                                                  onSegmentChange
                                                              }: FreelancerProfileEditPortfolioBucketProps ) {

    const { t } = useTranslation();

    const handleDeleteItem = ( portfolioElement: PortfolioElement ) => onDeleteItem?.( portfolioElement );
    const handleChangePage = ( page: number ) => onPageChange?.( page );

    if( isLoading ) {
        return (
            <div className={ twMerge( 'flex flex-col space-y-3', className ) }>
                <ListSkeleton isLoading={ true }
                              count={ 5 }
                              height={ 62 } />
            </div>
        );
    }

    if( portfolioElementsPaginated?.data?.length > 0 ) {
        return (
            <>
                <div className={ twMerge( 'flex flex-col space-y-3', className ) }>
                    {
                        portfolioElementsPaginated?.data?.map( ( item, index ) => (
                            <FreelancerProfileEditPortfolioItem portfolioElement={ item }
                                                                key={ index }
                                                                onMoveTo={ ( segment: SegmentPortfolioElementEnum ) => onSegmentChange?.( item.id, segment ) }
                                                                onDelete={ handleDeleteItem } />
                        ) )
                    }
                </div>
                <Pagination pageSize={ portfolioElementsPaginated?.limit }
                            className="w-fit"
                            currentPageNumber={ portfolioElementsPaginated?.page }
                            itemCount={ portfolioElementsPaginated?.total }
                            changePage={ ( page: number ) => handleChangePage( page ) } />
            </>
        );
    }
}
