import { gql } from '@apollo/client';

export const GettingStartedStepsGQL = gql`
    query GettingStartedStepsGQL {
        meClient{
            gettingStartedCompleted
            gettingStartedSteps
        }
    }`;


