import { gql } from '@apollo/client';

const privateJobPostGQL = gql`
    query PrivateJobPostGQL($jobPostId: String!) {
        privateJobPost(id: $jobPostId) {
            id
            type
            channel {
                name
                thumbnailUrl
                bannerImageUrl
                subscriberCount
                videoCount
            }
            role
            isPaidOffer
            name
            description
            priceMax
            formatType
            priceMin
            skills
            languages
            expertises
            experiences
            freelancerHasSentMessage
            freelancerStatusForLoggedFreelancer
            portfolioElements {
                id
                title
                thumbnailUrl
                url
                type
                viewCount
                portfolioChannel {
                    name
                    id
                }
            }
            status
            totalViews
            createdAt
        }
    }
`;

export default privateJobPostGQL;